import { Component, Input } from '@angular/core';
import { SmsMessage } from '../../../models/sms-message';

@Component({
  selector: 'app-sms-messages-content',
  templateUrl: './sms-messages-content.component.html',
  styleUrls: ['./sms-messages-content.component.scss']
})
export class SmsMessagesContentComponent {
  @Input() smsMessage: SmsMessage;
}
