export enum DocumentCard {
  Generate = 'Generate',
  Finalize = 'Finalize',
  Upload = 'Upload',
  Download = 'Download'
}

export enum AgreementDocumentStates {
  None,
  Draft,
  'Pending Signature',
  Signed
}

export enum DOCUMENT_GENERATION {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

export enum DocumentHubMessages {
  PdfSuccess = 'PdfGenerationSuccess',
  PdfFailure = 'PdfGenerationFail'
}
