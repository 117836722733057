<div class="dialog-wrapper">
  <!-- Header -->
  <h1 mat-dialog-title class="header">
    {{ title }} Preview
  </h1>

  <!-- Body -->
  <mat-dialog-content class="mx-1">
    <div class="flex-layout" fxLayout="row" fxLayoutAlign="left">
      <div class="preview" [innerHTML]="sanitizer.bypassSecurityTrustHtml(contentText)">
      </div>
    </div>
  </mat-dialog-content>

  <!-- Footer -->
  <mat-card-actions align="end" fxLayout="row" fxLayout.xs="column" class="mx-1 my-2">
    <button mat-raised-button mat-dialog-close color="primary">Close</button>
  </mat-card-actions>
</div>
