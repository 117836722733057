import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';


import { SecurityQuestion } from '../../models/security-question.model';
import { Person } from '../../models/person.model';
import { AuthService } from '../../../shared/auth.service';

@Component({
  selector: 'app-person-update-security-question',
  templateUrl: './person-update-security-question.component.html',
  styleUrls: ['./person-update-security-question.component.scss']
})
export class PersonUpdateSecurityQuestionComponent implements OnChanges {

  @Input() person: Person;
  @Input() securityQuestions: Array<SecurityQuestion> = new Array<SecurityQuestion>();
  @Input() usersSecurityQuestions: Array<SecurityQuestion> = new Array<SecurityQuestion>();
  @Output() changeSecurityQuestionsEvent: EventEmitter<any> = new EventEmitter<any>();

  get firstQuestionAnswerCtrl() { return this.questionsForm.get('firstQuestionAnswer'); }
  get secondQuestionAnswerCtrl() { return this.questionsForm.get('secondQuestionAnswer'); }

  isViewOnly = false;

  public questionsForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService
  ) {
    this.isViewOnly = this.authService.isViewOnly;
  }

  ngOnChanges() {
    if (this.usersSecurityQuestions.length > 0) {
      this.createForm();
    }
  }

  /**
   * Initializes FormGroup instance.
   */
  public createForm() {
    this.questionsForm = this.fb.group({
      firstQuestion: new UntypedFormControl(this.usersSecurityQuestions[0].id),
      secondQuestion: new UntypedFormControl(this.usersSecurityQuestions[1].id),
      firstQuestionAnswer: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20)
      ]),
      secondQuestionAnswer: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(20)
      ])
    });
  }

  /**
   * Get current available security questions.
   *
   * @param index Which security question is selected.
   */
  public getAvailableQuestions(index) {
    const firstQuestionId = this.questionsForm.controls['firstQuestion'].value;
    const secondQuestionId = this.questionsForm.controls['secondQuestion'].value;

    if (index === 'firstQuestion') {
      return this.securityQuestions
        .filter((question: { id: string }) => question.id !== secondQuestionId);
    } else {
      return this.securityQuestions
        .filter((question: { id: string }) => question.id !== firstQuestionId);
    }
  }

  /**
   * OnSubmit which will trigger update of the security questions for selected user.
   */
  public onSubmit() {
    if (this.questionsForm.invalid) {
      this.firstQuestionAnswerCtrl.markAsTouched();
      this.secondQuestionAnswerCtrl.markAsTouched();
    } else {
      this.changeSecurityQuestions(this.getNewSecurityQuestionsForUser());
    }
  }

  /**
   * Update current User's security questions.
   *
   * @param changedQuestions Updated security questions with User Id for which questions are updated.
   */
  public changeSecurityQuestions(changedQuestions) {
    this.changeSecurityQuestionsEvent.emit(changedQuestions);
  }

  /**
   * Return true if control is touched or dirty and has invalid state
   *
   * @param controlName Name of the control
   */
  hasErrorOfType(controlName: string, errorType: string): boolean {
    const ctrl = this.questionsForm.controls[controlName];
    return ctrl && (ctrl.touched || ctrl.dirty) && ctrl.hasError(errorType);
  }
  /**
   * Returns an array of two newly created security questions.
   */
  private getNewSecurityQuestionsForUser() {
    const firstSecurityQuestion = {
      questionId: this.questionsForm.value.firstQuestion,
      answerText: this.questionsForm.value.firstQuestionAnswer
    };

    const secondSecurityQuestion = {
      questionId: this.questionsForm.value.secondQuestion,
      answerText: this.questionsForm.value.secondQuestionAnswer
    };

    return [firstSecurityQuestion, secondSecurityQuestion];
  }
}
