import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FeatureName } from '../enums/feature-name';
import { ConfigrationKeyName } from '../enums/configration-key-name';

@Injectable({ providedIn: 'root' })
export class AppConfigurationService {

  private featureFlags: { [key in FeatureName]?: boolean } = {};
  private configrationKeys: { [key in ConfigrationKeyName ]?: boolean } = {};

  constructor(private http: HttpClient) { }

  getFeatureFlag(featureName: FeatureName) {
    if (this.featureFlags[featureName] === undefined) {
      return this.http.get<boolean>(`${environment.configurationApiUrl}/api/features/${featureName}`)
        .pipe(
          tap(value => this.featureFlags[featureName] = value),
          catchError(() => of(false))
        );
    } else {
      return of(this.featureFlags[featureName]);
    }
  }

  getConfigration(KeyName: ConfigrationKeyName) {
    if (this.configrationKeys[KeyName] === undefined) {
      return this.http.get<any>(`${environment.configurationApiUrl}/api/configurations/${KeyName}`)
        .pipe(
          tap(value => this.configrationKeys[KeyName] = value),
          catchError(() => of(false))
        );
    } else {
      return of(this.configrationKeys[KeyName]);
    }
  }
}
