import { BusinessServiceType } from '../../shared/enums/business-service-type';
import { DecisionType } from '../enums/decision-type';

export class ScreeningProcess {
  id: string;
  data: ScreeningProcessData;
  state: BulkProcessState;
  type: ProcessType;
  createdOn: Date;
  createdBy: string;
  errors: string[];
}

export class ScreeningProcessData {
  initiatedBy: string;
  screeningName: string;
  subscriptionsTypeName: string;
  subscriptions: ScreeningProcessServiceSubscription[];
  recordsFound: number;
}

export class ScreeningProcessServiceSubscription {
  serviceId: string;
  serviceType: BusinessServiceType;
}

export enum ProcessType {
  None = 'None',
  OFACScreening = 'OFACScreening'
}

export enum BulkProcessState {
  None,
  Created,
  Initializing,
  Initialized,
  Validating,
  Validated,
  Executing,
  Executed,
  Completed,
  Failed
}

export interface ScreeningProcessRequest {
  initiatedBy: string;
  subscriptionsTypeName: any;
  screeningName: any;
  subscriptions: ScreeningProcessServiceSubscription[];
  type: ProcessType;
  filter: {
    companyids?: string[];
    personids?: string[];
    dateRange?: {
      startDate: Date;
      endDate: Date;
    };
  };
}

export interface ScreeningResult {
  createdOn: Date;
  id: string;
  entityId: string;
  name: string;
  type: ScreeningEntityType;
  matchingStatus: string;
  reasonToDecision: string;
  reviewedOn: Date;
  decisionOn: Date;
  providerResponse: CaseMatch[];
  highestMatchingScore: number;
  decisionType: DecisionType;
}

export interface CaseMatch {
  sdnType: string;
  source: string;
  fullName: string;
  programs: string[];
  dob: string;
  gender: string;
  citizenship: string[];
  score: number;
  remarks: string;
  additionalSanctions: string[];
  ids: Identification[];
  akas: Aka[];
  addresses: Address[];
  title: string;
}

export interface Identification {
  type: string;
  id: string;
  country: string;
  issueDate: string;
  expirationDate: string;
}

export interface Aka {
  category: string;
  firstName: string;
  lastName: string;
}

export interface Address {
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  stateOrProvince: string;
  postalCode: string;
  country: string;
}

export enum ScreeningEntityType {
  None,
  Company,
  Person
}

export interface ScreeningLog {
  id: string;
  processId: string;
  screenedBy: string;
}
