export const PasswordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*?[#?!@$%^&*\-()_=+{}\[\];'\/"\\|<>,.`~:])([^\s]){8,20}$/;
export const WebsiteRegex = /^(http\:\/\/|https\:\/\/|HTTP\:\/\/|HTTPS\:\/\/)?([a-zA-Z0-9][a-zA-Z0-9\-]*\.)+[a-zA-Z0-9][a-zA-Z0-9\-]*$/;
export const PhoneNumberRegex = /^[0-9]{10,15}$/;
export const USZipCodeRegex = /^[0-9]{5}(-[0-9]{4})?$/;
export const USZipCodeShortRegex = /^[0-9]{5}$/;
export const CANZipCodeShortRegex = /^[ABCEGHJKLMNPRSTVXY]\d[A-Z] \d[A-Z]\d$/;
export const CanadaZipCodeRegex = /^[ABCEGHJKLMNPRSTVXY]{1}[0-9]{1}[A-Z]{1} *[0-9]{1}[A-Z]{1}[0-9]{1}$/;
export const AddressLineRegex = /^[A-Za-z0-9-\/#\s]*$/;
export const CityRegex = /^[A-Za-z-\/#\s]*$/;
export const PhoneNumberWithCountryCodeRegex = /^[+]*[0-9]{10,15}$/;
export const NameRegex = /^[a-zA-Z '.]+$/;
export const FeinIdRegex = /^[0-9]{9}$/;
// eslint-disable-next-line max-len
export const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line max-len
export const Angular_EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
export const USPhoneNumberRegex = /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/;
export const AccountPolicyNumberRegex = /^[0-9]+(-?[0-9]+)*$/;
export const AccountPolicyPostalCodeRegex = /^[a-zA-z0-9 ]?(-?[a-zA-z0-9 ]+)*$/;
export const UsPostalAllowedCharsRegex = /^[0-9\-]*$/;
export const Base64Regex = /^[a-zA-Z0-9\+/]*={0,3}$/;
export const DateTimeRegex = /'([1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])T([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)Z)'/;
export const AccountNumberRegex = /^[0-9]{1,10}$/;
