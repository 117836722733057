import { MarketplacePartnerAffiliationType } from '../../companies/models/marketplace-partner-affiliation-type';
import { MarketplaceAgsServicePermissionStatus } from './marketplace-ags-service-permission-status';
import { MarketplacePartnerServicePermission } from './marketplace-partner-service-permission';

export class MarketplaceAgsServicePermission implements MarketplacePartnerServicePermission {
  // MarketplacePartnerAffiliation interface implementation START
  affiliationType: MarketplacePartnerAffiliationType;
  startDate?: Date;
  endDate?: Date;
  // MarketplacePartnerAffiliation interface implementation END
  memberId: string;
  status: MarketplaceAgsServicePermissionStatus;
}
