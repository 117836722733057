import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { filter, skip, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AuthService } from './shared/auth.service';
import { AnalyticsService } from './shared/services/app-insights.service';
import { forkJoin } from 'rxjs';
import { FeatureName } from './shared/enums/feature-name';
import { AppConfigurationService } from './shared/services/app-configuration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  direction: string;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private applicationInsights: AnalyticsService,
    private authService: AuthService,
    private router: Router,
    private appConfigurationService: AppConfigurationService
  ) {

    const sessionDirection = sessionStorage.getItem('portal-direction');
    this.direction = sessionDirection || 'ltr';

    this.authService.setAccessToken();
    this.oidcSecurityService.isAuthenticated$.pipe(skip(1))
      .subscribe(({ isAuthenticated }) => {
        this.authService.setAccessToken();
        localStorage.setItem('oidcIsAuthenticated', isAuthenticated ? 'true' : 'false');
      });

    /**
     * set userContext for telemetry
     */
    this.oidcSecurityService
      .getUserData()
      .pipe(filter(data => !!data))
      .subscribe(({ userData }) => {
        this.applicationInsights.setUsername(userData?.sub);
      });

    this.oidcSecurityService.checkSessionChanged$.subscribe(
      sessionChanged => {
        this.onCheckSessionChangedComplete(sessionChanged);
      }
    );

  }
  private onCheckSessionChangedComplete(sessionchanged: boolean) {
    if (environment.authLogConsoleDebugActive) {
      console.log('CheckSession result received sessionChanged :' + sessionchanged);
    }

    if (sessionchanged) {
      this.oidcSecurityService.forceRefreshSession();
      this.router.navigate(['/external/login']);
    }
  }
}

