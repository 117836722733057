const currentDate = new Date();

export function getPreviousQuarterStartDate() {
  return new Date(new Date().getFullYear(), getCurrentQuarter() * 3 - 3, 1);
}

export function getPreviousQuarterEndDate() {
  return new Date(currentDate.getFullYear(), getPreviousQuarterStartDate().getMonth() + 3, 0);
}

export function getPreviousMonthStartDate() {
  const date = new Date(currentDate);
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);

  return date;
}

export function getPreviousMonthEndDate() {
  const date = new Date(currentDate);
  date.setDate(0);
  return date;
}

export function getCurrentQuarter() {
  return Math.floor(currentDate.getMonth() / 3);
}
