import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Address } from '../../models/address';
import { USZipCodeRegex, CanadaZipCodeRegex } from '../../../utils/regex';
const states = require('../../../utils/states.json');
const countries = require('../../../utils/countries.json');

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  @Input() parent: UntypedFormGroup;
  @Input() address: Partial<Address>;
  @Input() requiredValidator = true;
  @Input() isFormDisabled = false;

  addressForm: UntypedFormGroup;
  states: any;
  countries: any[];
  listOfStates: any[];

  constructor(
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.countries = countries;
    this.states = states;
    this.buildAddressForm();
  }

  buildAddressForm() {
    if (!this.address) {
      this.address = new Address();
    }
    this.addressForm = this.fb.group({
      addressLine1: this.fb.control(this.address.addressLine1, this.requiredValidator ? Validators.required : null),
      addressLine2: this.fb.control(this.address.addressLine2),
      postCode: this.fb.control(this.address.postCode, this.requiredValidator ? Validators.required : null),
      city: this.fb.control(this.address.city, this.requiredValidator ? Validators.required : null),
      country: this.fb.control(this.getCountry(this.address.countryId),
        this.requiredValidator ? Validators.required : null),
      state: this.fb.control(this.address.stateId, this.requiredValidator ? Validators.required : null)
    });

    this.onCountryChange(this.address.countryId, false);
    if (this.parent && this.parent instanceof UntypedFormGroup) {
      this.parent.addControl('address', this.addressForm);
    }

    for (const i in this.addressForm.value) {
      if (this.addressForm.get(i).value != null && this.addressForm.get(i).value !== ''
        && this.addressForm.get(i).invalid && i !== 'country' && i !== 'state') {
        this.addressForm.get(i).markAsTouched();
      }
    }

    if (this.isFormDisabled) {
      this.addressForm.disable();
    }
  }

  getCountry(countryId) {
    if (countryId === 'US' || countryId === 'USA') {
      return 'USA';
    }
    if (countryId === 'CA' || countryId === 'CAN') {
      return 'CA';
    }
    return countryId;
  }

  onStateChange(selectedState: string) {
    if (selectedState === '-1' && this.requiredValidator) {
      this.addressForm.get('state').setErrors({required: true});
      this.addressForm.get('state').markAsTouched();
    }
  }

  onCountryChange(selectedCountry: string, clearStates = true) {
    if (selectedCountry === 'USA' || selectedCountry === 'US') {
      this.listOfStates = [...states['US_States']];
      this.addressForm.get('postCode').setValidators([Validators.required, Validators.pattern(USZipCodeRegex)]);
    } else if (selectedCountry === 'CA' || selectedCountry === 'CAN') {
      this.listOfStates = [...states['Canada_States']];
      this.addressForm.get('postCode').setValidators([Validators.required, Validators.pattern(CanadaZipCodeRegex)]);
    } else if (selectedCountry === '-1' && this.requiredValidator) {
      this.addressForm.get('country').setErrors({required: true});
      this.addressForm.get('country').markAsTouched();
    }
    if (clearStates) {
      this.addressForm.get('state').setValue('-1');
      this.addressForm.get('state').updateValueAndValidity();
      if (this.requiredValidator) {
        this.addressForm.get('state').setErrors({required: true});
        this.addressForm.get('state').markAsTouched();
      }
    }
  }
}
