import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { SecurityQuestion } from '../models/security-question.model';
import { Observable } from 'rxjs';
import { encodeBase64 } from '@progress/kendo-file-saver';

@Injectable()
export class PersonIdentityService {

  public readonly apiDomain = environment.identityApiUrl;
  public readonly endpoint = 'Account';

  constructor(private http: HttpClient) { }

  /**
   * Generates password automatically.
   */
  generatePassword() {
    const api = 'GeneratePassword';

    return this.http
      .get(`${this.apiDomain}/${this.endpoint}/${api}`, { responseType: 'text' });
  }

  /**
   * Updates Person's password.
   *
   * @param userId Person reference Id.
   * @param newPassword New password for Person.
   */
  updatePassword(userId: string, newPassword: string, sendEmail: boolean) {
    const api = 'UpdatePassword';
    newPassword = encodeBase64(newPassword);
    return this.http
      .post(`${this.apiDomain}/${this.endpoint}/${api}`, { userId, newPassword, sendEmail });
  }

  /**
   * Get Person's Security questions.
   *
   * @param userId ReferenceId of the Person.
   */
  getUsersSecurityQuestions(userId: string): Observable<Array<SecurityQuestion>> {
    const api = 'GetSecurityQuestionFromUser';

    return this.http
      .get<Array<SecurityQuestion>>(`${this.apiDomain}/${this.endpoint}/${api}/${userId}`);
  }

  /**
   * Update current User's security questions.
   *
   * @param changedQuestions Updated security questions with User Id for which questions are updated.
   */
  changeSecurityQuestions(changedQuestions) {
    const api = 'ChangeSecurityQuestions';

    const answer1 = changedQuestions['securityQAs'][0]['answerText'];
    const answer2 = changedQuestions['securityQAs'][1]['answerText'];

    changedQuestions['securityQAs'][0]['answerText'] = encodeBase64(answer1);
    changedQuestions['securityQAs'][1]['answerText'] = encodeBase64(answer2);

    return this.http
      .post(`${this.apiDomain}/${this.endpoint}/${api}`, changedQuestions);
  }
}
