export class DeleteConfirmationWithCommentData {
  title: string;
  displayText: string;
  isDeleteConfirmed = false;

  constructor(title: string, displayText: string, isDeleteConfirmed?: boolean) {
    this.title = title;
    this.displayText = displayText;
    this.isDeleteConfirmed = isDeleteConfirmed;
  }
};
