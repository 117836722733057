<div class="row">
  <div class="col-6">
    <p class="portal-top-text float-left">
      View health and liveliness of services across platform.
    </p>
  </div>
  <div class="col-6">
    <span class="portal-top-text float-right">
        <a class="mr-3" (click)="refresh()" style="cursor: pointer;"  target="_blank" title="Refresh">
            <img src="assets/images/JMicons/JM-icon-19.png" alt="">
          </a>
    </span>
  </div>
</div>

<div class="pr-3 pl-3">
  <div class="row" *ngIf="apiList">
    <div class="col-12 col-md-6 col-lg-4 col-xl-3" *ngFor="let api of apiList">
      <div class="checkup-summary position-relative">
        <div>
          <div class="title">
            <img [src]="api.imagePath" alt="icon" class="icon">
            <span>{{ api.name }}</span>
          </div>
        </div>
        <div class="position-relative">
          <div class="status">
            <div class="d-flex justify-content-between" [ngClass]="{ 'flex-row-reverse': !api.url }">
              <div *ngIf="api.url" class="options refresh position-relative" (click)="onRefresh(api)" matTooltip="Refresh status" matTooltipPosition="above"
                matTooltipClass="quartz-tooltip">
                <img *ngIf="api.status === 'Healthy'" src="assets/images/JMicons/JM-icon-17.png" alt="icon">
                <img *ngIf="api.status !== 'Healthy' && api.status !== 'Checking'" src="assets/images/JMicons/JM-icon-18.png" alt="icon">
                <span *ngIf="api.status !== 'Checking'">{{ api.status }}</span>
                <app-in-progress [loading]="api.status === 'Checking'" [small]="true"></app-in-progress>
              </div>
              <div class="options">
                <ng-container *ngIf="api.status === 'Healthy' && isLogVisible()">
                  <a class="health-link mr-3" [routerLink]="getHealthCheckDetailsLink(api.name)">
                    <img src="assets/images/JMicons/info_outline.png" alt="">
                  </a>
                </ng-container>
                <!-- // Hide Swagger UI since we are exposing APIs via API Management -->
                <!-- <a class="mr-3" [href]="api.swaggerLink" target="_blank" title="Swagger">
                  <img src="assets/images/JMicons/swagger_icon.png" alt="">
                </a> -->
                <a class="mr-3" [routerLink]="getLogsLink(api.name)" *ngIf="isLogVisible() && api.name !== 'Auction'">
                  <img src="assets/images/JMicons/logs_icon.png" alt="">
                </a>
                <span *ngIf="configurable(api.name)" class="sectionToggle" (click)="toggleSectionVisibility(api.name)" [matTooltip]="getConfigureTooltipText(api.name)"
                  matTooltipPosition="above" matTooltipClass="quartz-tooltip">
                  <img *ngIf="visibleSection?.visibility && api.name === visibleSection.name; else off" src="assets/images/JMicons/settings_toggle_on.png"
                    alt="">
                  <ng-template #off>
                    <img src="assets/images/JMicons/settings_toggle_off.png" alt="">
                  </ng-template>
                </span>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>