import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AccountValidationResult, AccountValidationInput, CommercialLinesAccount } from '../models/commercial-lines-subscription';
import { Observable } from 'rxjs';

import { BaseHttpService } from '../../shared/services/base-http.service';
import { AccountUserSettings } from '../../persons/models/commercial-permissions.model';

@Injectable()
export class AccountService extends BaseHttpService<CommercialLinesAccount> {

  constructor(
    private http: HttpClient
  ) {
    super(http);
    this.apiDomain = environment.accountApiUrl;
    this.endpoint = 'accounts';
  }

  validateAccount(insuranceAccount: AccountValidationInput): Observable<AccountValidationResult> {
    return this.http.post<AccountValidationResult>(`${this.apiDomain}/api/${this.endpoint}/validate`, insuranceAccount);
  }

  getAccountUserSettings(personId: string): Observable<AccountUserSettings> {
    return this.http.get<AccountUserSettings>(`${this.apiDomain}/api/usersettings`, { params: { personId } });
  }

  saveAccountUserSettings(accountUserSettings: AccountUserSettings): Observable<AccountUserSettings> {
    return this.http.post<AccountUserSettings>(`${this.apiDomain}/api/usersettings`, accountUserSettings);
  }

  updateAccountUserSettings(accountUserSettings: AccountUserSettings): Observable<AccountUserSettings> {
    return this.http.put<AccountUserSettings>(`${this.apiDomain}/api/usersettings/${accountUserSettings.id}`, accountUserSettings);
  }
}
