import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { AppInsights } from 'applicationinsights-js';
import { Angulartics2AppInsights } from 'angulartics2';
import { Angulartics2 } from 'angulartics2';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

const applicationInsights = new ApplicationInsights({
  config: {
    instrumentationKey: `${environment.appInsights.instrumentationKey}`
  }
});

@Injectable()
export class AnalyticsService extends Angulartics2AppInsights {
  public subscriptions = new Array<Subscription>();
  events: any[] = [];

  constructor(
    public analytics: Angulartics2,
    private documentTitle: Title,
    private route: Router
  ) {
    super(analytics, documentTitle, route);
    if (!AppInsights.config) {
      AppInsights.downloadAndSetup(applicationInsights.config);
    }
  }

  /**
   * start tracking time of a custom event
   * @param eventName Name of the event
   */
  startCustomTracking(eventName: string) {
    this.events.push({
      event: eventName,
      startTime: new Date()
    });
  }
  /**
   * stop tracking time of custom event
   * @param eventName Name of the event
   */
  stopCustomTracking(eventName: string) {
    const event = this.events.find(x => x.event === eventName);
    if (event != null) {
      event['stopTime'] = new Date();
    }
  }
  /**
   * get tracked time of the custom event
   * @param eventName Name of the event
   */
  getTrackedTime(eventName: string) {
    const event = this.events.find(x => x.event === eventName);
    if (event != null) {
      const totalTime = (+event.stopTime - (+event.startTime)).toString();
      return totalTime;
    }
  }
  /**
   * generate correlationId to relate custom events
   */
  generateCorrelationId() {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

  /**
   * Override NgxAnalyticsAppInsights pageTrack method to track page title
   */
  pageTrack(path: string) {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }

    if (this.route.routerState) {
      this.trackPageTitle();
    } else {
      const sub = this.route.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.trackPageTitle(event.url);
        }
      });
      this.subscriptions.push(sub);
    }
  }

  private trackPageTitle(url?: string) {
    const title = this.getTitle(this.route.routerState, this.route.routerState.root).join('-');
    appInsights.trackPageView(
      title,
      url,
      this.dimensions,
      this.metrics,
      this.loadTime
    );
  }

  private getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
