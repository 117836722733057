import { NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { LayoutsModule } from './layouts/layouts.module';

import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthModule } from 'angular-auth-oidc-client';
import { provideNgxMask } from 'ngx-mask';
import { getOidcConfig } from './config/oidc.config';
import { FeatureGuard } from './guards/feature-guard';
import { LeaveGuard } from './guards/leave.guard';
import { OnlyLoggedInUsersGuardService } from './guards/only-logged-in-users-guard.service';
import { RoleGuard } from './guards/role.guard';
import { httpInterceptorProviders } from './interceptors';
import { PhoneNumberService } from './shared/services/phone-number.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AuthModule.forRoot({
      config: getOidcConfig()
    }),
    BrowserModule,
    BrowserAnimationsModule,
    LayoutsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    httpInterceptorProviders,
    RoleGuard,
    FeatureGuard,
    LeaveGuard,
    OnlyLoggedInUsersGuardService,
    PhoneNumberService,
    provideNgxMask()
  ]
})
export class AppModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    // Register Font Awesome
    this.matIconRegistry.registerFontClassAlias('fontawesome', 'fa');

    // Register layout icon SVGs
    this.matIconRegistry.addSvgIcon('classic',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/classic.svg')
    );
  }
}