import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../../../layouts/layout.service';
import { ActivatedRoute } from '@angular/router';
import { ApiLogsService } from '../../../services/api-logs.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-health-details',
  templateUrl: './health-details.component.html',
  styleUrls: ['./health-details.component.scss']
})
export class HealthDetailsComponent implements OnInit, OnDestroy {

  public subscriptions = new Array<Subscription>();
  public loadingData;
  public apiHealthDetails;
  apiName = '';

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private layoutService: LayoutService,
    private apiLogService: ApiLogsService
  ) { }

  ngOnInit() {
    this.apiName = this.route.snapshot.data['apiName'];
    this.getHealthCheckDetails(this.apiName);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getHealthCheckDetails(apiName: string) {
    this.loadingData = true;
    const sub = this.apiLogService
      .getHealthCheckInfo(apiName)
      .pipe(
        map((data: any) => {
          if(data.code == null) {
            return this.mapToHealthDetailsViewModel(data);
          }

          return data;
        }))
      .subscribe(
        data => {
          this.loadingData = false;
          this.apiHealthDetails = data;
        },
        errorData => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Unable to get logs for API');
          this.apiHealthDetails = this.mapToHealthDetailsViewModel(errorData.error);
        }
      );

    this.subscriptions.push(sub);
  }

  mapToHealthDetailsViewModel(data: any): any {
    const HelathyStatus = 'Healthy';
    const LegacyOkCode = 200;
    const LegacyErrorCode = 503;
    const result: any = {};
    result.code = data.status === HelathyStatus ? LegacyOkCode : LegacyErrorCode;
    result.checks = [];

    const entries = data.entries;
    for(const property in entries) {
      if(entries.hasOwnProperty(property)) {
        result.checks.push({
          name: property,
          message: entries[property].description || 'OK',
          elapsed: entries[property].duration,
          exception: entries[property].exception,
          isHealthy: entries[property].status === HelathyStatus
        });
      }
    }

    return result;
  }

  goToPreviousPage() {
    this.location.back();
  }

}
