export enum BusinessServiceType {
  CarePlan = 1,
  Shipping = 2,
  CommercialInsurance = 3,
  PersonalInsurance = 4,
  PartnerGateway = 5,
  Appraisal = 6,
  Marketplace = 7,
  PointOfSaleIntegration = 8,
  Memo = 9,
  Studio = 10,
  JewelerPages = 11,
  Auction = 12,
  Custom = 999
}
