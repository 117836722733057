<div *ngIf="!isViewOnly" class="float-right mt-3 mr-2">
    <a [routerLink]="['/locations/company',companyId,'location']"
      class="btn-portal-add mr-4"
      matTooltip="Add new location"
      matTooltipPosition="before"
      matTooltipClass="quartz-tooltip">
      <img src="assets/images/JMicons/JM-icon-15.png" alt="add">
    </a>
  </div>
  <p class="portal-top-text">View all locations.</p>
  <div class="portal-example-page">
    <div class="portal-example-pager__content">
      <div class="example-container list-container">
        <kendo-grid class="no-scrollbar" (dataStateChange)="dataStateChange($event)"
          [data]="dataSource"
          [loading]="loadingData"
          [pageSize]="state.take"
          [skip]="state.skip"
          [sort]="state.sort"
          [filter]="state.filter"
          [sortable]="true"
          [pageable]="true"
          filterable="menu">
          <kendo-grid-column media="(max-width: 680px)" title="Companies">
            <ng-template kendoGridCellTemplate let-location>
              <div class="mobile-container">
                <div class="action-buttons">
                  <mat-icon class="cursorButtons" [routerLink]="['/locations/company',companyId,'location', location.locationID]"
                    matTooltip="Edit location details"
                    matTooltipPosition="before"
                    matTooltipClass="quartz-tooltip">edit</mat-icon>
                  <mat-icon class="cursorButtons" (click)="onDelete(location.locationID)"
                    *ngIf="isMembershipAdmin || isSuperOrPlatformAdmin"
                    matTooltip="Delete location"
                    matTooltipPosition="before"
                    matTooltipClass="quartz-tooltip">delete</mat-icon>
                </div>
                <dl>
                  <dt>Name</dt>
                  <dd>{{ location.locationName }}</dd>
                  <dt>Address</dt>
                  <dd>{{ location.address | address }}</dd>
                  <dt>Phone Number</dt>
                  <dd>{{ location.phoneNumber }}</dd>
                </dl>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column media="(min-width: 680px)" field="locationName" title="Name" [width]="200" class="with-ellipsis"></kendo-grid-column>
          <kendo-grid-column media="(min-width: 680px)" field="address" title="Address" [width]="200">
            <ng-template kendoGridCellTemplate let-location>
              {{ location.address  | address }}
          </ng-template>
          </kendo-grid-column>
          <kendo-grid-column media="(min-width: 680px)" field="phoneNumber" title="Phone Number" [width]="100"></kendo-grid-column>
          <kendo-grid-column media="(min-width: 680px)" title="Actions" headerClass="actions" [width]="50">
            <ng-template kendoGridCellTemplate let-location>
              <div class="text-center">
                <mat-icon *ngIf="!companyHasRestrictedAccess" class="cursorButtons" [routerLink]="['/locations/company',companyId,'location', location.locationID]"
                  matTooltip="Edit location details"
                  matTooltipPosition="before"
                  matTooltipClass="quartz-tooltip">edit</mat-icon>
                <mat-icon class="cursorButtons" (click)="onDelete(location.locationID)"
                  *ngIf="isSuperOrPlatformAdmin || isMembershipAdmin"
                  matTooltip="Delete location"
                  matTooltipPosition="before"
                  matTooltipClass="quartz-tooltip">delete</mat-icon>
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
        <app-in-progress [loading]="inProgress"></app-in-progress>
      </div>
    </div>
  </div>
  