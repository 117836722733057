import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';

@Injectable()
export class ContentTypeJsonInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.headers.has('Content-Type') && (req.method === 'POST' || req.method === 'PUT') && !req.headers.has('uploadFile')) {
      // cloned headers, updated with the Content-Type.
      const cloneReq = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });

      // send cloned request with header to the next handler.
      return next.handle(cloneReq);
    }

    return next.handle(req);
  }

}
