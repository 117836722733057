import { Injectable } from '@angular/core';
import { ActivatedRoute, GuardsCheckEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Direction } from '@angular/cdk/bidi';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { ApiHealth } from '../shared/models/api-health';

@Injectable()
export class LayoutService {
  public isServiceStatusLoaded = false;
  public apiList: Array<ApiHealth>;

  private title$: BehaviorSubject<string> = new BehaviorSubject('');
  private loaderStatus$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private currentTheme$: BehaviorSubject<any> = new BehaviorSubject(null);
  private direction$: BehaviorSubject<Direction>;
  private sidenavSectionVisibility$: BehaviorSubject<any> = new BehaviorSubject(null);

  sidenavSections = [
    { name: 'Communication', visibility: false },
    { name: 'Risk', visibility: false },
    { name: 'Membership', visibility: false },
    { name: 'Entity', visibility: false },
    { name: 'Content', visibility: false },
    { name: 'Identity', visibility: false },
    { name: 'Appraisals', visibility: false },
    { name: 'Point Of Sale Integration', visibility: false }
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {
    // Set direction from session if set.
    const sessionDirection = sessionStorage.getItem('portal-direction');
    const direction = sessionDirection && sessionDirection === 'rtl' ? 'rtl' : 'ltr';
    this.direction$ = new BehaviorSubject(direction);

    // Watch for router events to turn on/off the loader.
    this.router.events
      .subscribe((event: any) => {
        if (event instanceof NavigationStart) {
          this.loaderStatus$.next(true);
        } else if (event instanceof NavigationEnd || event instanceof GuardsCheckEnd) {
          this.loaderStatus$.next(false);
        }
      });

    // Watch for router events to set title using route data.
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(this.activatedRoute),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        if (data['title']) {
          this.setTitle(data['title']);
        }
      });
  }

  setTitle(title: string): void {
    this.title$.next(title);
  }

  getTitle(): Observable<string> {
    return this.title$.asObservable();
  }

  setLoader(status: boolean): void {
    this.loaderStatus$.next(status);
  }

  getLoader(): Observable<boolean> {
    return this.loaderStatus$.asObservable();
  }

  toggleDirection(): void {
    const direction = this.direction$.getValue() === 'rtl' ? 'ltr' : 'rtl';
    sessionStorage.setItem('portal-direction', direction);
    this.direction$.next(direction);
  }

  getDirection(): Observable<Direction> {
    return this.direction$.asObservable();
  }

  isRTL(): Observable<boolean> {
    return this.direction$.asObservable()
      .pipe(
        switchMap(direction => of(direction === 'rtl'))
      );
  }

  currentTheme(): Observable<any> {
    return this.currentTheme$.asObservable();
  }

  setCurrentTheme(theme: any): void {
    this.currentTheme$.next(theme);
  }

  toggleSidebarSection(sectionName: string) {
    this.sidenavSections.forEach(s => {
      s.visibility = s.name === sectionName;
    });

    this.sidenavSectionVisibility$.next({ name: sectionName });
  }

  sectionViibilityChange() {
    return this.sidenavSectionVisibility$.asObservable();
  }

  getSidebarSectionVisibility() {
    return this.sidenavSections;
  }

  /**
   * Show message on the UI
   *
   * @param messageText Text which should be displayed as a message to the user
   * @param action Text for action
   * @param options Configuration options
   */
  showUIMessage(messageText: string = '', action?: string, options?: any): void {
    if (!messageText) {
      return;
    }

    this.snackBar.open(
      messageText,
      action || 'Dismiss',
      options || { duration: 3500 }
    );
  }
}
