import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DeleteConfirmationData } from '../../../models/delete-confirmation-data';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html'
})
export class DeleteConfirmationComponent {
  public displayText = 'Are you sure you want to delete this item?';

  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) data: DeleteConfirmationData
  ) {
    if (data?.displayText) {
      this.displayText = data.displayText;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
