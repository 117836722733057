<div class="flex-layout appraisal-permissions" (click)="hidePopover($event)">
  <form [formGroup]="appraisalForm">
    <div>
      <h2>
        Appraisal Permissions
      </h2>
    </div>
    <div class="row">
      <div class="col-md-3">
        Generate and View Appraisals
        <img src="/assets/images/JMicons/info_outline.png" id="view-mp-info" width="20" [placement]="['bottom-left', 'bottom-right', 'right']" [ngbPopover]="appraisalPopup" #popover="ngbPopover">
      </div>
      <div class="col-md-3 pl-md-2">
        <mat-checkbox formControlName="allowed">Allowed</mat-checkbox>
      </div>
    </div>
    <div class="row footer">
      <mat-card-actions align="end" class="col save-container">
        <button mat-raised-button color="primary" type="button" [disabled]="appraisalForm.invalid" (click)="onSubmit()">Save</button>
      </mat-card-actions>
    </div>
  </form>
</div>
<hr class="divider"/>

<ng-template #appraisalPopup>
  <div class="row info-wrap popup-wrap" id="appraisalPopup">
    <div class="col-10 info-head heading-12-onyx mb-1">
      Allowed must be selected for user to generate appraisals for the customers.
    </div>
    <mat-icon class="col-2 close-wrap mb-1 text-right" width="12">close</mat-icon>
  </div>
</ng-template>

<app-in-progress [loading]="isLoading"></app-in-progress>