<div *ngIf="smsMessage">
  <ul class="list-group">
    <li class="list-group-item">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <div class="pt-2 pb-2">
            To:
            <span class="user-email" *ngFor="let element of smsMessage.to; let isLast=last">
              {{element}}{{isLast ? '' : ', '}}
            </span>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="pt-2 pb-2">
            Status: <strong>{{ smsMessage.status }}</strong>
          </div>
          <div class="pt-2 pb-2">
            Retry Count: <strong>{{ smsMessage.retryCount }}</strong>
          </div>
          <div class="pt-2 pb-2" *ngIf="smsMessage.status === 'Sent'">
            Date Sent: <strong>{{ smsMessage.messageSentOn | date:'short' }}</strong>
          </div>
        </div>
      </div>
    </li>
    <li class="list-group-item message-body">
      <div>
        Message Body: <strong>{{ smsMessage.body }}</strong>
      </div>
    </li>
  </ul>
</div>
