<section>
  <h2> Platform User roles </h2>

  <mat-card>
    <mat-card-content>
        <ng-container *ngFor="let role of roles" >
          <mat-checkbox
            [disabled]="shouldDisableRoleCheckBox(role)"
            [checked]="hasRole(role.id)" 
            (change)="onSelected($event.source.checked, role)"
            class="mr-3">
          {{ role.name }}
        </mat-checkbox>
      </ng-container>
    </mat-card-content>
  </mat-card>
</section>
