import { UserRoleCode } from '../../persons/enums/user-role-code';
import { ODataModel } from './report-settings.model';

export class ReportRequest {
  public reportType: ReportType;
  public columns: string[];
  public context: object;
  public fileType: FileType;
  public reportRequestedBy: string;
  public startDate?: Date;
  public endDate?: Date;
  public id?: string;
  public queryModel?: ODataModel;
  public reportBlobId?: string;
  public failed?: boolean;
  public createdOn?: Date;
  public forceLoad?: boolean;
  public reportName?: string;
  public reports?: ReportType[];
  public reportPeriod?: ReportPeriod;
  public scheduledFor?: Date;
  public reportProgress?: ReportProgress;
}

export class ReportPrevalidateResponse {
  public id: string;
}

export class ScheduledReportRequestViewModel {
  public reportType: ReportType;
  public period: ReportPeriod;
  public startDate: Date;
  public endDate: Date;
}

export enum ReportType {
  // Same as specified by report service endpoint
  Company = 1,
  Person = 2,
  ShipmentSummary = 3,
  ShipmentBilling = 4,
  Invoice = 5,
  InvoiceDetail = 6,
  Payment = 7,
  Transaction = 8,
  Appraisal = 9,
  MarketplaceOrder = 11,
  OperationalUsersByProduct = 12,
  RegisteredPersons = 13,
  Traffic = 14,
  Shipping = 15,
  JewelerPrograms = 16,
  KpiUsageSummary = 17,
  DashboardTraffic = 18,
  PosIntegration = 19,
  GemAndJewelAffiliation = 20,
  StudioOrder = 21,
  MemoReport = 22,
  JMCCFinance = 23,
  TransactionReports = 24,
  JewelerPages = 25,
  JewelerPagesInterest = 26,
  Auction = 27
}

export enum JMCCReportType {
  JMCCFinance = 1
}

export enum FileType {
  Xls = 1,
  Pdf = 2,
  Database = 3
}

export class ReportDetails {
  name: string;
  description: string;
  type?: ReportType;
  allowedRoles?: UserRoleCode[];
  display?: boolean;
  enabled?: boolean;
  showGenerateReport?: boolean;
}

export interface ScheduledReportsStatus {
  reportCompletion: Map<ReportPeriod, Map<ReportType, boolean>>;
}

export function getReportName(reportType: ReportType) {
  switch (reportType) {
    case ReportType.Company:
      return 'Company';
    case ReportType.Person:
      return 'Person';
    case ReportType.Appraisal:
      return 'Appraisal';
    case ReportType.MarketplaceOrder:
      return 'Diamond Marketplace Orders';
    case ReportType.OperationalUsersByProduct:
      return 'Operational';
    case ReportType.RegisteredPersons:
      return 'Registered Persons';
    case ReportType.Traffic:
      return 'Traffic';
    case ReportType.Shipping:
      return 'Shipping';
    case ReportType.JewelerPrograms:
      return 'Jeweler Programs';
    case ReportType.KpiUsageSummary:
      return 'Usage';
    case ReportType.DashboardTraffic:
      return 'Kpi Dashboard';
    case ReportType.PosIntegration:
      return 'POS Integration';
    case ReportType.GemAndJewelAffiliation:
      return 'Gem + Jewel';
    case ReportType.StudioOrder:
      return 'Studio Orders';
    case ReportType.MemoReport:
      return 'Memo Report';
    case ReportType.JewelerPages:
      return 'Jeweler Pages';
    case ReportType.JewelerPagesInterest:
      return 'Jeweler Pages Interest';
    case ReportType.JMCCFinance:
      return 'JMCC Finance';
    case ReportType.Auction:
      return 'Auction';
    default:
      return '';
  }
}

export enum ReportProgress{
  NotStarted = 0,
  InProgress = 1,
  Completed = 2,
  Failed = 3,
  Scheduled = 4,
  Canceled = 5
}

export enum ReportPeriod{
  None = 0,
  Custom = 1,
  LastMonth = 2,
  LastQuarter = 3
}

export const reportProgressDisplay = [
  { 'key': 'Canceled', 'value': ReportProgress.Canceled },
  { 'key': 'Completed', 'value': ReportProgress.Completed },
  { 'key': 'Failed', 'value': ReportProgress.Failed },
  { 'key': 'In Progress', 'value': ReportProgress.InProgress },
  { 'key': 'Not Started', 'value': ReportProgress.NotStarted },
  { 'key': 'Scheduled', 'value': ReportProgress.Scheduled }
];

export const reportPeriodDisplay = [
  { 'key': 'Custom', 'value': ReportPeriod.Custom },
  { 'key': 'Last Month', 'value': ReportPeriod.LastMonth },
  { 'key': 'Last Quarter', 'value': ReportPeriod.LastQuarter }
];
