import { Pipe, PipeTransform } from '@angular/core';
import { ServiceModuleType } from '../../marketplace/models/order.model';

@Pipe({
  name: 'serviceModuleName'
})

export class ServiceModuleNamePipe implements PipeTransform {
  transform(value: number): string {
    const name = ServiceModuleType[value];
    return name ?? 'Undefined';
  }
}
