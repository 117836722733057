import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';

import { switchMap, takeWhile, map } from 'rxjs/operators';
import { timer ,  of } from 'rxjs';

import { VerificationService } from '../../../services/verification.service';

@Component({
  selector: 'app-invites',
  templateUrl: './invites-dialog.component.html'
})
export class InvitesDialogComponent implements OnInit, OnDestroy {

  //#region Public properties

  get personEmailCtrl() { return this.invitePersonForm.controls['personEmail']; }

  //#endregion

  public componentActive = true;

  public inProgress = false;
  public invitePersonForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private verificationService: VerificationService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  /**
   * Initialize FormGroup instance.
   */
  public createForm() {
    this.invitePersonForm = this.fb.group({
      personEmail: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(50)
      ], [this.isEmailInUse.bind(this)])
    });
  }

  /**
   * Performs async validation to check is there any user already registered with the specific email address.
   *
   * @param control AbstractControl instance.
   */
  public isEmailInUse(control: AbstractControl) {
    this.inProgress = true;

    return timer(1500).pipe(
      switchMap(() => {
        if (!control.value) {
          this.inProgress = false;
          return of(null);
        }
        return this.verificationService.isEmailInUse(control.value).pipe(
          takeWhile(() => this.componentActive),
          map(({ isEmailInUse }) => {
            this.inProgress = false;
            return isEmailInUse ? { emailInUse: true } : null;
          }));
      })
    );
  }

  /**
   * Return true if control is touched or dirty and has invalid state.
   *
   * @param controlName Name of the FormControl
   * @param errorType Type of error.
   */
  public hasErrorOfType(controlName: string, errorType: string): boolean {
    const ctrl = this.invitePersonForm.controls[controlName];
    return ctrl && (ctrl.touched || ctrl.dirty) && ctrl.hasError(errorType);
  }
}
