import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../shared/auth.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(private oidcService: AuthService) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const accessToken = this.oidcService.accessToken;

    if (accessToken !== null && accessToken !== undefined && accessToken !== '') {
      const cloneReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + accessToken)
      });
      return next.handle(cloneReq);
    }
    return next.handle(req);
  }

}
