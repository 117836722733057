import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, take, takeWhile } from 'rxjs/operators';
import { IncentiveProgramError, MISSING_PAYEE_CODE } from '../../../incentive-programs/models/Incentive-program-error';
import { IncentiveProgramsService } from '../../../incentive-programs/services/incentive-programs.service';
import { LayoutService } from '../../../layouts/layout.service';
import {
  PROGRAM_ENROLLMENTS_POPULATE_PAYEE_CODE,
  PROGRAM_ENROLLMENTS_WITHOUT_PAYEE_CODE
} from '../../../program-enrollments/utils/program-enrollment-consts';
import { ReportPeriod, ReportType, ScheduledReportRequestViewModel } from '../../../shared/models/report-request.model';
import { ReportSettings } from '../../../shared/models/report-settings.model';
import { ReportingService } from '../../../shared/services/reporting.service';
import {
  getPreviousMonthEndDate,
  getPreviousMonthStartDate,
  getPreviousQuarterEndDate,
  getPreviousQuarterStartDate
} from '../../../utils/date-helper-functions';

@Component({
  selector: 'app-generate-report-dropdown-dialog',
  templateUrl: './generate-report-dropdown-dialog.component.html',
  styleUrls: ['./generate-report-dropdown-dialog.component.scss']
})
export class GenerateReportDropdownDialogComponent implements OnInit {

  public reportForm: UntypedFormGroup;
  public buttonLabel: string;
  isDataLoaded = false;
  reportCompletion: Map<ReportPeriod, Map<ReportType, boolean>> = new Map<ReportPeriod, Map<ReportType, boolean>>();
  reportTypes = [];
  scheduledReport = new ScheduledReportRequestViewModel();
  startDate = new Date();
  endDate = new Date();
  maxDate = moment().utc(true).toDate();
  componentActive = true;
  initialStartDate = moment().startOf('day').subtract(7, 'day').utc(true);
  initialEndDate = moment().endOf('day').utc(true);

  selections = [
    { key: ReportPeriod.LastMonth, value: 'Monthly' },
    { key: ReportPeriod.LastQuarter, value: 'Quarterly' },
    { key: ReportPeriod.Custom, value: 'Periodic' }
  ];
  selectedType: any;

  areCompaniesWithoutPayeeCodeLoading = true;
  companiesWithoutPayeeCode: IncentiveProgramError[];
  companiesWithoutPayeeCodeCount = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<GenerateReportDropdownDialogComponent>,
    private reportService: ReportingService,
    private incentiveProgramsService: IncentiveProgramsService,
    private router: Router,
    private layoutService: LayoutService
  ) {
    this.buttonLabel = data.buttonLabel;
    this.reportCompletion = data.reportCompletion;
    this.reportTypes = data.reportTypesList;
  }

  ngOnInit() {
    this.updateLatestDropdownStates();
    this.createForm();

    this.incentiveProgramsService.validateReport().pipe(
      take(1),
      finalize(() => this.areCompaniesWithoutPayeeCodeLoading = false),
      catchError(error => {
        this.layoutService.showUIMessage(error.error.message);
        return throwError(error);
      })
    ).subscribe(
      data => {
        if (data.errors) {
          this.companiesWithoutPayeeCode = data.errors.filter(el => el.errorCode === MISSING_PAYEE_CODE).slice(0, 5);
          this.companiesWithoutPayeeCodeCount = data.errors.length;
        }
      },
      () => { }
    );
  }

  createForm() {
    this.reportForm = this.fb.group(
      {
        reportTypes: this.fb.control(null, Validators.required),
        reportPeriod: this.fb.control(ReportPeriod.Custom),
        startDate: this.fb.control(this.initialStartDate, [Validators.required, this.startDateMustBeBeforeEndDate()]),
        endDate: this.fb.control(this.initialEndDate, [Validators.required, this.startDateMustBeBeforeEndDate()]),
      }
    );

    this.reportForm.get('reportPeriod').disable();
    this.setListenerOnDateInput();
  }

  /**
   * Handle report form submission and try to make a request to the server to generate report
   */
  downloadReport(form: UntypedFormGroup) {
    const reportSettings = this.extractFormData(form);
    this.dialogRef.close(reportSettings);
  }

  extractFormData(form: UntypedFormGroup): ReportSettings {
    const result: ReportSettings = {
      reports: form.controls['reportTypes'].value,
      period: form.controls['reportPeriod'].value,
      startDate: form.controls['startDate'].value,
      endDate: form.controls['endDate'].value,
      isScheduledReport: true
    };

    return result;
  }

  setDisabledReportTypes() {
    this.reportTypes.forEach(item => {
      item.disabledMonthly = this.getReportCompletionMonthlyStatus(item.key);
      item.disabledQuarterly = this.getReportCompletionQuarterlyStatus(item.key);
    });
  }

  getReportCompletionMonthlyStatus(reportType: ReportType): boolean {
    const monthlyReport = this.reportCompletion['LastMonth'];
    if (monthlyReport) {
      return monthlyReport[ReportType[reportType]];
    }

    return false;
  }

  getReportCompletionQuarterlyStatus(reportType: ReportType): boolean {
    const quarterlyReport = this.reportCompletion['LastQuarter'];
    if (quarterlyReport) {
      return quarterlyReport[ReportType[reportType]];
    }

    return false;
  }

  updateLatestDropdownStates() {
    this.reportService.lastScheduledReportsStatuses().pipe(take(1)).subscribe(data => {
      this.reportCompletion = data.reportCompletion;
      this.setDisabledReportTypes();
      this.isDataLoaded = true;
    });
  }

  getSelectedValue(event) {
    this.scheduledReport.period = event.value;
    if (event.value === ReportPeriod.LastMonth) {
      this.setPreviousMonthDates();
    } else if (event.value === ReportPeriod.LastQuarter) {
      this.setPreviousQuarterDates();
    }

    this.reportForm.get('startDate').setValue(this.initialStartDate);
    this.reportForm.get('endDate').setValue(this.initialEndDate);

    this.reportForm.updateValueAndValidity();
  }

  setPreviousMonthDates() {
    this.startDate = getPreviousMonthStartDate();
    this.endDate = getPreviousMonthEndDate();
  }

  setPreviousQuarterDates() {
    this.startDate = getPreviousQuarterStartDate();
    this.endDate = getPreviousQuarterEndDate();
  }

  resetDates() {
    this.startDate = new Date();
    this.endDate = new Date();
  }

  setSelectedType(reportType) {
    this.setDisabledReportTypes();
    this.reportTypes.forEach(item => {
      if (item.key === reportType) {
        this.selectedType = item;
      }
    });

    if (reportType) {
      this.reportForm.get('reportPeriod').enable();
    } else {
      this.reportForm.get('reportPeriod').disable();
    }
  }

  setListenerOnDateInput(): void {
    this.reportForm.get('startDate').valueChanges
      .pipe(takeWhile(() => this.componentActive), distinctUntilChanged()).subscribe(
        () => this.reportForm.get('endDate').updateValueAndValidity());

    this.reportForm.get('endDate').valueChanges
      .pipe(takeWhile(() => this.componentActive), distinctUntilChanged()).subscribe(
        () => this.reportForm.get('startDate').updateValueAndValidity());
  }

  startDateMustBeBeforeEndDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate = this.reportForm?.get('startDate')?.value;
      const endDate = this.reportForm?.get('endDate')?.value;
      return startDate && endDate && endDate.diff(startDate, 'month') > 5 ? { invalidDateRange: { value: control.value } } : null;
    };
  }

  getDisabledRadioButton(key: ReportPeriod) {
    if (key === ReportPeriod.LastMonth) {
      return this.selectedType?.disabledMonthly;
    }

    if (key === ReportPeriod.LastQuarter) {
      return this.selectedType?.disabledQuarterly;
    }

    return false;
  }

  getTooltipText(key: ReportPeriod) {
    return this.getDisabledRadioButton(key) ? 'Report is already generated and can be located in the existing reports section' : null;
  };

  get ReportPeriod() { return ReportPeriod; }

  navigateToProgramEnrollments() {
    this.router.navigate(
      ['/program-enrollments'],
      { fragment: PROGRAM_ENROLLMENTS_WITHOUT_PAYEE_CODE }
    );
  }

  navigateToPayeeCode() {
    this.router.navigate(
      ['/program-enrollments'],
      { fragment: PROGRAM_ENROLLMENTS_POPULATE_PAYEE_CODE }
    );
  }
}
