<div fxLayout="column">
  <h2> Set New Password </h2>
  <div fxLayout="row" fxLayout.xs="column">
    <div fxFlex="25">
      <form [formGroup]="passwordForm" *ngIf="passwordForm">
        <mat-form-field>
          <input matInput formControlName="newPassword" placeholder="Enter New Password">
          <mat-error *ngIf="hasError(newPasswordCtrl)">
            Please enter valid password.
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div fxFlex="25">
      <button
        class="btn-width-200" 
        mat-raised-button color="primary" 
        [disabled]="isViewOnly"
        (click)="generatePassword()">
        Generate Password <i class="fas fa-key"></i>
      </button>
    </div>
  </div>
  <div class="mt-3" fxLayout="row" fxLayout.xs="column">
    <button 
      class="mr-4 btn-width-200" 
      mat-raised-button 
      [disabled]="isViewOnly"
      color="primary" 
      type="button" 
      (click)="onSubmit(false)">
      Save
    </button>
    <button
      class="btn-width-200" 
      [disabled]="!userHasEmail || isViewOnly"  
      mat-raised-button color="primary" 
      type="button" 
      (click)="onSubmit(true)">
      Save & Email
    </button>
  </div>
</div>
