/* eslint-disable max-len */
import { DocumentCard } from '../enums/enrollment-agreement-document-cards.enum';
import { CardDetails } from '../models/program-enrollment.model';

export const progEnrollmentCardDetails: CardDetails[] = [
  {
    title: 'Document Generation',
    subtitle: 'Generate a Document',
    description: 'Use this to create a new draft/final enrollment agreement document for appropriate programs, so it can be sent to a Jeweler / Retailer / Partner to review and sign. Once the document is finalized/signed, finalize the enrollment agreement document by clicking Finalize button.',
    icon: 'file_open',
    cardType: DocumentCard.Generate,
    headerIcon: 'arrow_forward',
    display: true,
    enabled: false,
    tooltip: 'Generate Program Enrollment document after saving the data'
  },
  {
    title: 'Accept Final Document',
    subtitle: 'Finalize Agreement',
    description: 'Use this to finalize enrollment agreement document for appropriate programs, once enrollment agreement document is finalized it cannot be edited.',
    icon: 'file_download_done',
    cardType: DocumentCard.Finalize,
    headerIcon: 'arrow_forward',
    display: true,
    enabled: false,
    tooltip: 'Finalizes and locks the agreement for further editing'
  },
  {
    title: 'Upload Document',
    subtitle: 'Upload Signed Document',
    description: 'Use this to upload a final and signed final enrollment agreement document for appropriate programs, from a Jeweler / Retailer / Partner who has signed and returned it, to be enrolled in a program.',
    icon: 'upload',
    cardType: DocumentCard.Upload,
    headerIcon: 'check',
    display: true,
    enabled: false,
    tooltip: `Upload a signed agreement`
  },
  {
    title: 'Download Document',
    subtitle: 'Download Draft or Final Document',
    description: 'Use this to download an in progress draft OR final and signed final enrollment agreement document for appropriate programs.',
    icon: 'download',
    cardType: DocumentCard.Download,
    headerIcon: '',
    display: true,
    enabled: false,
    tooltip: `Download the latest generated draft of final document`
  }
];

export const docGenInProgressText = 'Document is in process of generating, please reload the page and download the document manually.';

export const PROGRAM_ENROLLMENTS_WITHOUT_PAYEE_CODE = 'without-payee-code';
export const PROGRAM_ENROLLMENTS_POPULATE_PAYEE_CODE = 'populate-payee-code';
