<div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="10px">
  <div fxFlex="100" class="tree-view-container business-units">
    <label class="root-business-unit">
      Add root business unit 
      <a (click)="addRootBusinessUnit()" class="btn-portal-add mr-4" style="padding-left: 10px" matTooltip="Add new business unit" matTooltipPosition="before"
      matTooltipClass="quartz-tooltip">
      <img src="assets/images/JMicons/JM-icon-15.png" alt="add">
    </a>
    </label>
    <kendo-treeview class="no-icons" [nodes]="businessUnits" textField="text" kendoTreeViewHierarchyBinding childrenField="businessUnits">
      <ng-template kendoTreeViewNodeTemplate let-dataItem>
       <span class="business-unit-item">
        <input type="text" class="business-unit-name" [(ngModel)]="dataItem.name" (keypress)="onChangeBUText()">
        <a (click)="addBusinessUnit(dataItem)" class="btn-portal-add mr-4 business-unit-button" matTooltip="Add new business unit" matTooltipPosition="before"
          matTooltipClass="quartz-tooltip">
          <img src="assets/images/JMicons/JM-icon-15.png" alt="add">
        </a>
        <a *ngIf="!dataItem.businessUnits.length" (click)="onDelete(dataItem)" class="btn-portal-add mr-4 business-unit-button" matTooltip="Remove business unit" matTooltipPosition="before"
          matTooltipClass="quartz-tooltip">
          <img src="assets/images/JMicons/JM-icon-21.png" alt="remove">
        </a>
      </span> 
      </ng-template>

    </kendo-treeview>
  </div>

</div>
<div class="footer">
    <mat-card-actions align="end" fxLayout="row" fxLayout.xs="column">
      <!-- <button mat-raised-button color="primary" (click)="onBack()">
        Cancel
      </button> -->
      <button *ngIf="!isViewOnly" mat-raised-button color="primary" [disabled]="!formIsDirty" (click)="saveBusinessUnits()">
        Save
      </button>
    </mat-card-actions>
  </div>
<app-in-progress [loading]="loadingData"></app-in-progress>