import { Component, OnInit, Input } from '@angular/core';
import { Person } from '../../models/person.model';
import { ServiceSubscription } from '../../../companies/models/service-subscription';

@Component({
  selector: 'app-partner-gateway-permission',
  templateUrl: './partner-gateway-permission.component.html',
  styleUrls: ['./partner-gateway-permission.component.scss']
})
export class PartnerGatewayPermissionComponent {

  @Input() person: Person;
  @Input() subscription: Partial<ServiceSubscription>;

  openPermissionUrl() {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const windowWidth = window.innerWidth ?
      window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const widowHeight = window.innerHeight ?
      window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const width = windowWidth / 2;
    const height = widowHeight * 2 / 3;

    const systemZoom = windowWidth / window.screen.availWidth;
    const left = (windowWidth - width) / 2 / systemZoom + dualScreenLeft;
    const top = (widowHeight - height) / 2 / systemZoom + dualScreenTop;
    const popup = window.open(
      `${this.subscription.additionalData['PermissionUrl']}?companyId=${this.person.companyId}&personId=${this.person.id}`,
      '_blank',
      `
      location=yes,
      resizable=false
      scrollbars=yes,
      width=${width},
      height=${height},
      top=${top},
      left=${left}
      `
    );

    if (window.focus) {
      popup.focus();
    }
  }
}
