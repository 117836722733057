import { ReportType } from '../../models/report-request.model';

export const showTestOptionsForReportTypes: any = {
  simpleReportDialog: ['Registered Persons', 'Jeweler Programs', 'Shipping', 'Traffic', 'Appraisal'],
  reportDialog: ['Company', 'Person']
};

export const defaultTestOptionsLabel = 'Include Test Users';
export const companyTestOptionsLabel = 'Include Test Accounts';

export const reportTypes = [
  {
    key: ReportType.JMCCFinance,
    displayText: 'Jeweler Program Incentive Payments',
    disabledMonthly: false,
    disabledQuarterly: false
  }
];
