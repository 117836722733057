import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { programEnrolmentAgreementTemplateConfig } from '../../utils/program-enrollment-agreement-document.config';

@Component({
  selector: 'app-enrollment-agreement-document',
  templateUrl: './enrollment-agreement-document.component.html',
  styleUrls: ['./enrollment-agreement-document.component.scss']
})
export class EnrollmentAgreementDocumentComponent implements OnInit {

  programEnrolmentAgreementTemplateConfig = programEnrolmentAgreementTemplateConfig;

  constructor(
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient
  ) { }

  templateId = '';
  documentUrl = '';
  incentiveProgramName = '';
  sanitizedUrl: any;

  ngOnInit(): void {
    this.templateId = this.route.snapshot.params.template;

    if (!this.templateId) {
      this.router.navigateByUrl('**');
    }

    this.httpClient.get(`assets/pdf/${this.templateId}`).pipe(take(1))
      .subscribe(() => { }, error => {
        // it's error because it fails to parse pdf, 200 means it's there
        // without this iFrame will catch error and not found page will be in it
        if (error.status !== 200) {
          this.router.navigateByUrl('**');
        }
      });

    this.documentUrl = `../../../../assets/pdf/${this.templateId}`;

    const config = programEnrolmentAgreementTemplateConfig.find(conf => conf.template === this.templateId);

    if (!config) {
      this.router.navigateByUrl('**');
    }

    this.incentiveProgramName = config?.incentiveProgramName;
    this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.documentUrl);
  }
}
