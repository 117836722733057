import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-order-status-reasons',
  templateUrl: './order-status-reasons-dialog.html',
  styleUrls: ['./order-status-reasons-dialog.scss']
})
export class OrderStatusReasonsComponent {
  reasons: string[];

  constructor(
    public dialogRef: MatDialogRef<OrderStatusReasonsComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    if (data?.reasons) {
      this.reasons = data.reasons.split(';');
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
