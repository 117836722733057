import { LayoutService } from './layout.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';

// Layouts
import { LayoutClassicComponent } from './layout-classic/layout-classic.component';

// Components
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { MenuSidenavComponent } from './components/menu-sidenav/menu-sidenav.component';
import { NotificationSidenavComponent } from './components/notification-sidenav/notification-sidenav.component';
import { ThemeSwitcherMenuComponent } from './components/theme-switcher-menu/theme-switcher-menu.component';
import { LayoutSwitcherMenuComponent } from './components/layout-switcher-menu/layout-switcher-menu.component';
import { PortalHeaderTitleDirective } from './directives/portal-header-title.directive';
import { TopHorizontalMenuComponent } from './components/top-horizontal-menu/top-horizontal-menu.component';
import { LayoutLoaderComponent } from './components/layout-loader/layout-loader.component';
import { FooterComponent } from './components/footer/footer.component';

// Directives.
import { PortalScrollTopDirective } from './directives/portal-scroll-top.directive';

@NgModule({
  imports: [
    SharedModule,
    RouterModule
  ],
  declarations: [
    LayoutClassicComponent,
    UserMenuComponent,
    MenuSidenavComponent,
    NotificationSidenavComponent,
    ThemeSwitcherMenuComponent,
    LayoutSwitcherMenuComponent,
    PortalHeaderTitleDirective,
    TopHorizontalMenuComponent,
    PortalScrollTopDirective,
    LayoutLoaderComponent,
    FooterComponent,
    PortalHeaderTitleDirective,
    PortalScrollTopDirective,
    LayoutLoaderComponent,
    FooterComponent
  ],
  providers: [
    LayoutService
  ]
})
export class LayoutsModule { }
