export interface Order {
  orderId: string;
  zingOrderTrackingId: string;
  orderDate: Date;
  supplierItemIdentifier: number;
  supplierOrderIdentifier: string;
  supplierOrderId: string;
  totalTransactionCostAmount: number;
  customerDisplayStatusDescription: string;
  customerEmailAddress: string;
  trackingNumber: string;
  trackingURLAddress: string;
  pricingChange: PriceChangeDetails;
  companyName: string;
  buyersName: string;
  showPaymentReminder: boolean;
  isGenerateWTReportAvailable: boolean;
  paymentMethodCode: string;
  id: string;
  serviceModuleShortName: ServiceModuleType;
}

export enum ServiceModuleType {
  IDEX = 0,
  Studio = 1,
  Nivoda = 2
}

export const moduleTypeToName = {
  [ServiceModuleType.IDEX]: 'Natural',
  [ServiceModuleType.Studio]: 'Photography Studio',
  [ServiceModuleType.Nivoda]: 'LabGrown Diamond'
}

export interface PriceChangeDetails {
  priceField: PriceField;
  dateOfUpdate: Date;
  changeReason: string;
}

export enum PriceField {
  Diamond_Authentication_Fee,
  Shipping_Handling,
  Transaction_Fee,
  Payment_Processing,
  Total_Transaction_Cost,
  Total_Tax_Amount
}

export enum ShippingAndHandlingMethodType {
  None = 0,
  Standard = 1,
  Expedited = 2
}
