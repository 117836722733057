<div [formGroup]="addressForm">
  <div fxLayout="row" fxLayout.xs="column">
    <mat-form-field>
      <input
        matInput
        formControlName="addressLine1"
        type="text"
        placeholder="Address Line 1"
        [required]="requiredValidator"
      />
      <mat-error *ngIf="addressForm.get('addressLine1').hasError('required')">
        This is a required field
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        formControlName="addressLine2"
        type="text"
        placeholder="Address Line 2"
      />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.xs="column">
    <mat-form-field>
      <mat-select
        formControlName="country"
        (selectionChange)="onCountryChange($event.value)"
        placeholder="Country"
        [required]="requiredValidator">
        <mat-option value="-1">Select Country</mat-option>
        <mat-option
          *ngFor="let country of countries"
          [value]="country.code">
          {{ country.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="addressForm.get('country').hasError('required')">
        This is a required field
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-select
        formControlName="state"
        (selectionChange)="onStateChange($event.value)"
        placeholder="State"
        [required]="requiredValidator">
        <mat-option value="-1">Select State</mat-option>
        <mat-option
          *ngFor="let state of listOfStates"
          [value]="state.abbreviation">
          {{ state.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="addressForm.get('state').hasError('required')">
        This is a required field
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.xs="column">
    <mat-form-field>
      <input
        matInput
        formControlName="city"
        type="text"
        placeholder="City"
        [required]="requiredValidator"
      />
      <mat-error *ngIf="addressForm.get('city').hasError('required')">
        This is a required field
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        formControlName="postCode"
        type="text"
        placeholder="Zip Code"
        [required]="requiredValidator"
      />
      <mat-error *ngIf="addressForm.get('postCode').hasError('required')">
        This is a required field
      </mat-error>
    </mat-form-field>
  </div>
</div>
