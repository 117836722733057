import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-activate-deactivate-confirmation-add',
  templateUrl: './activate-deactivate-confirmation-add.component.html'
})
export class ActivateDeactivateConfirmationAddComponent {

  constructor(
    public dialogRef: MatDialogRef<ActivateDeactivateConfirmationAddComponent>
  ) { }

  closeDialog() {
    this.dialogRef.close();
  }
}
