export class Address {
  constructor(
    public addressLine1: string = null,
    public addressLine2: string = null,
    public city: string = null,
    public stateId: string = null,
    public stateName: string = null,
    public postCode: string = null,
    public countryId: string = null,
    public latitude: string | number = 0,
    public longitude: string | number = 0,
    public altitude: string | number = 0
  ) { }
}
