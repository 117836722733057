import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ApiLog } from '../../../models/api-log';
import { ApiLogsService } from '../../../services/api-logs.service';

@Component({
  selector: 'app-api-log-details',
  templateUrl: './api-log-details.component.html'
})
export class ApiLogDetailsComponent {

  apiLog: ApiLog;

  constructor(
    private location: Location,
    private apiLogService: ApiLogsService
  ) {
    this.apiLog = this.apiLogService.apiLogForDetails;
  }

  goToPreviousPage(): void {
    this.location.back();
    this.apiLogService.apiLogForDetails = null;
  }

}
