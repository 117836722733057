import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';

import { takeWhile } from 'rxjs/operators';

import { Person } from '../../models/person.model';
import { PersonIdentityService } from '../../services/person-identity.service';
import { LayoutService } from '../../../layouts/layout.service';
import { PasswordUpdate } from '../../models/password-update.model';
import { SecurityQuestionService } from '../../services/security-question.service';
import { SecurityQuestion } from '../../models/security-question.model';

@Component({
  selector: 'app-person-identity',
  templateUrl: './person-identity.component.html',
  styleUrls: ['./person-identity.component.scss']
})
export class PersonIdentityComponent implements OnInit, OnChanges, OnDestroy {

  @Input() person: Person;

  public componentActive = true;
  public isLoading = false;
  public generatedPassword: string;
  public userHasEmail: boolean;

  /**
   * All Security Questions which are existing on platform.
   */
  public securityQuestions: Array<SecurityQuestion> = new Array<SecurityQuestion>();

  /**
   * Security Questions which User selected.
   */
  public usersSecurityQuestions: Array<SecurityQuestion> = new Array<SecurityQuestion>();

  constructor(
    private personIdentityService: PersonIdentityService,
    private layoutService: LayoutService,
    private securityQuestionService: SecurityQuestionService,
  ) { }

  ngOnInit() {
    this.getSecurityQuestions();
  }

  ngOnChanges() {
    this.userHasEmail = !!this.person.email;

    if (this.person.referenceId) {
      this.getUserSecurityQuestions(this.person.referenceId);
    }
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  /**
   * Generates password automatically.
   */
  public generatePassword() {
    this.personIdentityService.generatePassword()
      .pipe(
        takeWhile(() => this.componentActive)
      )
      .subscribe(
        password => {
          this.generatedPassword = password;
        },
        () => {
          this.layoutService.showUIMessage('Generating password failed, please try again later.');
        }
      );
  }

  /**
   * Updates Person's password.
   *
   * @param userId Person reference Id.
   * @param newPassword New password for Person.
   */
  public updatePassword(password: PasswordUpdate) {
    this.isLoading = true;

    this.personIdentityService.updatePassword(this.person.referenceId, password.newPassword, password.sendEmail)
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        res => {
          this.isLoading = false;

          res
            ? this.layoutService.showUIMessage('You have successfully updated a password.')
            : this.layoutService.showUIMessage('Updating password failed, please try again later.');
        },
        err => {
          this.isLoading = false;

          err.error && err.error.description
            ? this.layoutService.showUIMessage(err.error.description)
            : this.layoutService.showUIMessage('Updating password failed, please try again later.');
        }
      );
  }

  /**
   * Return all SecurityQuestion instances.
   */
  public getSecurityQuestions() {
    this.isLoading = true;

    this.securityQuestionService.getSecurityQuestions()
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        res => {
          this.isLoading = false;
          this.securityQuestions = res['items'];
        },
        () => {
          this.isLoading = false;
          this.layoutService.showUIMessage('Getting security questions failed. Please try again later.');
        }
      );
  }

  /**
   * Get Security Questions which User selected.
   *
   * @param userId Reference id of Person.
   */
  public getUserSecurityQuestions(userId: string) {
    this.isLoading = true;

    this.personIdentityService.getUsersSecurityQuestions(userId)
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        res => {
          this.isLoading = false;
          this.usersSecurityQuestions = res;
        },
        () => {
          this.isLoading = false;
          this.layoutService.showUIMessage('Getting security questions for User failed. Please try again later.');
        }
      );
  }

  /**
   * Update current User's security questions.
   *
   * @param changedQuestions Updated security questions with User Id for which questions are updated.
   */
  public changeSecurityQuestions(changedQuestions) {
    const updateObject = {
      userId: this.person.referenceId,
      securityQAs: changedQuestions
    };
    this.isLoading = true;

    this.personIdentityService.changeSecurityQuestions(updateObject)
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        res => {
          this.isLoading = false;

          res
            ? this.layoutService.showUIMessage('Updating security questions was successful.')
            : this.layoutService.showUIMessage('Updating security questions failed. Please try again later.');
        },
        () => {
          this.isLoading = false;
          this.layoutService.showUIMessage('Updating security questions failed. Please try again later.');
        });
  }
}
