import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router } from '@angular/router';

import { LayoutClassicComponent } from './layouts/layout-classic/layout-classic.component';
import { OnlyLoggedInUsersGuardService } from './guards/only-logged-in-users-guard.service';
import { RoleGuard } from './guards/role.guard';
import { Angulartics2Module } from 'angulartics2';
import { FeatureName } from './shared/enums/feature-name';
import { FeatureGuard } from './guards/feature-guard';
import {
  EnrollmentAgreementDocumentComponent
} from './incentive-programs/components/enrollment-agreement-document/enrollment-agreement-document.component';
import { moduleRoleAccess } from './module-role-access';
import { EnrollmentAgreementDocumentSigningComponent } from './incentive-programs-signing/components/enrollment-agreement-document-signing/enrollment-agreement-document-signing.component';

const routes: Routes =
  [
    {
      path: '',
      redirectTo: 'external/login',
      pathMatch: 'full'
    },
    {
      path: '',
      data: {
        base: true
      },
      component: LayoutClassicComponent,
      canActivateChild: [OnlyLoggedInUsersGuardService],
      children: [
        {
          path: 'dashboards',
          loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule),
        },
        {
          path: 'companies',
          canLoad: [RoleGuard],
          loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule),
          data: {
            allowedRoles: moduleRoleAccess.membership
          }
        },
        {
          path: 'persons',
          canLoad: [RoleGuard],
          loadChildren: () => import('./persons/persons.module').then(m => m.PersonsModule),
          data: {
            allowedRoles: moduleRoleAccess.membership
          }
        },
        {
          path: 'locations',
          canLoad: [RoleGuard],
          loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule),
          data: {
            allowedRoles: moduleRoleAccess.membership
          }
        },
        {
          path: 'communications',
          canLoad: [RoleGuard],
          loadChildren: () => import('./communications/communications.module').then(m => m.CommunicationsModule),
          data: {
            allowedRoles: moduleRoleAccess.communication
          }
        },
        {
          path: 'security',
          canLoad: [RoleGuard],
          loadChildren: () => import('./security/security.module').then(m => m.SecurityModule),
          data: {
            allowedRoles: moduleRoleAccess.security
          }
        },
        {
          path: 'risk',
          canLoad: [RoleGuard],
          loadChildren: () => import('./risk/risk.module').then(m => m.RiskModule),
          data: {
            allowedRoles: moduleRoleAccess.risk
          }
        },
        {
          path: 'billing',
          canLoad: [RoleGuard],
          loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
          data: {
            allowedRoles: moduleRoleAccess.billing
          }
        },
        {
          path: 'shipping',
          canLoad: [RoleGuard],
          loadChildren: () => import('./shipping/shipping.module').then(m => m.ShippingModule),
          data: {
            allowedRoles: moduleRoleAccess.shipping
          }
        },
        {
          path: 'payment',
          canLoad: [RoleGuard],
          loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule),
          data: {
            allowedRoles: moduleRoleAccess.payment
          }
        },
        {
          path: 'identity',
          canLoad: [RoleGuard],
          loadChildren: () => import('./Identity/identity.module').then(m => m.IdentityModule),
          data: {
            allowedRoles: moduleRoleAccess.identity
          }
        },
        {
          path: 'entity',
          canLoad: [RoleGuard],
          loadChildren: () => import('./entity/entity.module').then(m => m.EntityModule),
          data: {
            allowedRoles: moduleRoleAccess.entity
          }
        },
        {
          path: 'content',
          canLoad: [RoleGuard],
          loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
          data: {
            allowedRoles: moduleRoleAccess.content
          }
        },
        {
          path: 'party',
          canLoad: [RoleGuard],
          loadChildren: () => import('./party/party.module').then(m => m.PartyModule),
          data: {
            allowedRoles: moduleRoleAccess.party
          }
        },
        {
          path: 'bulk-upload',
          canLoad: [RoleGuard],
          loadChildren: () => import('./bulk-invite/bulk-invite.module').then(m => m.BulkInviteModule),
          data: {
            allowedRoles: moduleRoleAccess.bulkUpload,
          }
        },
        {
          path: 'program-enrollments',
          canLoad: [RoleGuard, FeatureGuard],
          loadChildren: () => import('./program-enrollments/program-enrollments.module').then(m => m.ProgramEnrollmentsModule),
          data: {
            allowedRoles: moduleRoleAccess.programEnrollments,
            featureName: FeatureName.ZingOldProgramsPages
          }
        },
        {
          path: 'program-enrollments-signing',
          canLoad: [RoleGuard, FeatureGuard],
          loadChildren: () => import('./program-enrollments-signing/program-enrollments-signing.module').then(m => m.ProgramEnrollmentsSigningModule),
          data: {
            allowedRoles: moduleRoleAccess.programEnrollments,
            featureName: FeatureName.ZingDocumentSigning
          }
        },
        {
          path: 'pointofsaleintegration',
          canLoad: [RoleGuard],
          loadChildren: () => import('./pointofsaleintegration/pointofsaleintegration.module').then(m => m.PointOfSaleIntegrationModule),
          data: {
            allowedRoles: moduleRoleAccess.pointOfSaleIntegration
          }
        },
        {
          path: 'terms-and-conditions',
          canLoad: [RoleGuard],
          loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule),
          data: {
            allowedRoles: moduleRoleAccess.termsAndConditions
          }
        },
        {
          path: 'appraisals',
          canLoad: [RoleGuard],
          loadChildren: () => import('./appraisals/appraisals.module').then(m => m.AppraisalsModule),
          data: {
            allowedRoles: moduleRoleAccess.appraisals
          }
        },
        {
          path: 'diamondmarketplace',
          canLoad: [RoleGuard],
          loadChildren: () => import('./marketplace/marketplace.module').then(m => m.MarketplaceModule),
          data: {
            allowedRoles: moduleRoleAccess.diamondMarketplace
          }
        },
        {
          path: 'studio',
          canLoad: [RoleGuard],
          loadChildren: () => import('./studio/studio.module').then(m => m.StudioModule),
          data: {
            allowedRoles: moduleRoleAccess.studio
          }
        },
        {
          path: 'memo',
          canLoad: [RoleGuard],
          loadChildren: () => import('./memo/memo.module').then(m => m.MemoModule),
          data: {
            allowedRoles: moduleRoleAccess.memo
          }
        },
        {
          path: 'auction',
          canLoad: [RoleGuard],
          loadChildren: () => import('./auction/auction.module').then(a => a.AuctionModule),
          data: {
            allowedRoles: moduleRoleAccess.auction
          }
        },
        {
          path: 'promocodes',
          canLoad: [RoleGuard],
          loadChildren: () => import('./promo-code/promo-code.module').then(a => a.PromoCodeModule),
          data: {
            allowedRoles: moduleRoleAccess.promoCode
          }
        },
        {
          path: 'jewelerpages',
          canLoad: [RoleGuard],
          loadChildren: () => import('./jeweler-pages/jeweler-pages.module').then(m => m.JewelerPagesModule),
          data: {
            allowedRoles: moduleRoleAccess.jewelerPages
          }
        },
        {
          path: 'reporting',
          canLoad: [RoleGuard],
          loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule),
          data: {
            allowedRoles: moduleRoleAccess.membership
          }
        },
        {
          path: 'retail-location',
          canLoad: [RoleGuard],
          loadChildren: () => import('./retail-location/retail-location.module').then(m => m.RetailLocationModule),
          data: {
            allowedRoles: moduleRoleAccess.retailLocation
          }
        },
        {
          path: 'incentive-programs',
          canLoad: [RoleGuard, FeatureGuard],
          loadChildren: () => import('./incentive-programs/incentive-programs.module').then(m => m.IncentiveProgramsModule),
          data: {
            allowedRoles: moduleRoleAccess.incentivePrograms,
            featureName: FeatureName.ZingOldProgramsPages
          }
        },
        {
          path: 'incentive-programs-signing',
          canLoad: [RoleGuard, FeatureGuard],
          loadChildren: () => import('./incentive-programs-signing/incentive-programs-signing.module').then(m => m.IncentiveProgramsSigningModule),
          data: {
            allowedRoles: moduleRoleAccess.incentivePrograms,
            featureName: FeatureName.ZingDocumentSigning
          }
        }
      ]
    },
    {
      path: 'enrollment-agreement-document/:template',
      canActivate: [RoleGuard, FeatureGuard],
      component: EnrollmentAgreementDocumentComponent,
      data: {
        allowedRoles: moduleRoleAccess.incentivePrograms,
        featureName: FeatureName.ZingOldProgramsPages
      }
    },
    {
      path: 'enrollment-agreement-signing/:template',
      canActivate: [RoleGuard, FeatureGuard],
      component: EnrollmentAgreementDocumentSigningComponent,
      data: {
        allowedRoles: moduleRoleAccess.incentivePrograms,
        featureName: FeatureName.ZingDocumentSigning
      }
    },
    {
      path: 'external',
      loadChildren: () => import('./external-pages/external-pages.module').then(m => m.ExternalPagesModule)
    },
    {
      path: '**',
      redirectTo: '/external/404'
    }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}),
  Angulartics2Module.forRoot({
    pageTracking: {
    clearIds: true,
    clearQueryParams: true,
    clearHash: true,
    excludedRoutes: [
    new RegExp('loading'),
    new RegExp('callback')
    ]
    }
    })],
  exports: [RouterModule]
  })
export class AppRoutingModule {
  constructor(private router: Router) {
    /**
     * This allows us to switch layouts using the layout switcher.
     */
    const baseRoute = this.router.config.find(route => route.data !== undefined && route.data.base === true);
    switch (sessionStorage.getItem('portal-layout')) {
      case 'toolbar':
        baseRoute.component = LayoutClassicComponent;
        break;
      default:
        // Do nothing.
        break;
    }
  }
}
