import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { of, Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import {
  ReportProgress,
  ReportRequest,
  ReportType,
  getReportName,
  reportPeriodDisplay,
  reportProgressDisplay
} from '../../../shared/models/report-request.model';
import { ReportingService } from '../../../shared/services/reporting.service';
import { concatMap, filter, finalize, take, tap } from 'rxjs/operators';
import { FilterDefinition } from '../../../shared/models/filter-definition';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { AuthService } from '../../../shared/auth.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { AppConfigurationService } from '../../../shared/services/app-configuration.service';
import { FeatureName } from '../../../shared/enums/feature-name';
import { UserRoleCode } from '../../../persons/enums/user-role-code';
import { LayoutService } from '../../../layouts/layout.service';
import { flattenFilter, updateFilterForField } from '../../../utils/filter-help-functions';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ConfirmationWithCommentComponent }
  from '../../../shared/components/dialogs/confirmation-with-dialog/confirmation-with-comment.component';
import {
  cancelReportRequestDialogWithCommentConfig,
  deleteReportDialogWithCommentConfig
} from '../../../incentive-programs/utils/dialog-consts';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit, OnDestroy {
  @Input() reportRequested: Subject<boolean>;

  loadingData = false;
  inProgress = false;
  dataSource: GridDataResult;
  jmccTransactionsReportingEnabled: boolean;
  canSeeReportList = true;
  isAuthorizedToDeleteJmccFinanceReport = false;

  defaultFilter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [{
      logic: 'or',
      filters: [
        { field: 'ReportType', operator: 'eq', value: ReportType.Person },
        { field: 'ReportType', operator: 'eq', value: ReportType.Company }
      ]
    }
    ]
  };

  defaultSort: SortDescriptor = { field: 'CreatedOn', dir: 'desc' };

  public state: FilterDefinition = {
    skip: 0,
    take: 10,
    sort: [this.defaultSort],
    filter: this.defaultFilter
  };

  readonly jmccReportTypes = [ReportType.JMCCFinance];
  readonly jmccReportRoles = [
    UserRoleCode.JMJewelerProgramsFinance,
    UserRoleCode.PlatformAdministrator,
    UserRoleCode.JMPointOfSaleAdministrator
  ];
  readonly jmccReportRolesAuthorizedToDelete = [UserRoleCode.PlatformAdministrator];

  reportTypes = [
    { 'key': ReportType[ReportType.Company], 'value': ReportType.Company },
    { 'key': ReportType[ReportType.Person], 'value': ReportType.Person }
  ];
  selectedReportType: number;

  reportPeriods = reportPeriodDisplay;
  selectedReportPeriod: number;

  reportProgresses = reportProgressDisplay;
  selectedReportProgress: number;

  currentDate = new Date().toISOString();

  getReportType = type => getReportName(type);

  get reportProgress() {
    return ReportProgress;
  }

  constructor(
    private reportService: ReportingService,
    private authService: AuthService,
    private appConfigurationService: AppConfigurationService,
    private dialog: MatDialog,
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.getFeatureFlags(); // when featureFlag is removed call loadData() instead
    this.reportRequested.subscribe(() => this.loadData());
    this.isAuthorizedToDeleteJmccFinanceReport = this.authService.hasAnyRole(this.jmccReportRolesAuthorizedToDelete);
  }

  dataStateChange(state: DataStateChangeEvent): void {
    const currentSortState = state.sort.shift();
    this.state = {
      ...state,
      sort: [currentSortState?.dir ? currentSortState : this.defaultSort]
    };
    this.clearFilters();
    this.loadData();
  }

  loadData() {
    this.loadingData = true;

    this.reportService
      .query(this.state)
      .pipe(take(1),
        finalize(() => this.loadingData = false))
      .subscribe(
        data => {
          if (data) {
            this.dataSource = { data: data.items, total: data.totalRecordCount };
          }
        });
  }

  ngOnDestroy(): void {
    this.reportRequested.unsubscribe();
  }

  onDelete(id: string, reportType: ReportType) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = deleteReportDialogWithCommentConfig;
    this.dialog
      .open(ConfirmationWithCommentComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(
        comment => {
          if (comment) {
            this.onDeleteYes(id, reportType, comment);
          }
        }
      );
  }

  onDeleteYes(id: string, reportType: ReportType, comment: string) {
    this.inProgress = true;
    this.reportService.delete(id, comment)
      .pipe(
        take(1),
        finalize(() => this.inProgress = false))
      .subscribe(() => {
        this.layoutService.showUIMessage('Report deleted successfully.');
        this.loadData();
      },
      err => {
        if (err.status === HttpStatusCode.Forbidden) {
          this.layoutService.showUIMessage('You are not allowed to delete this report.');
        } else {
          this.layoutService.showUIMessage('Report deletion failed.');
        }
      });
  }

  onRefresh(report: ReportRequest) {
    this.loadingData = true;
    const editedReport: ReportRequest = this.dataSource.data.find(x => x.id === report.id);
    this.reportService
      .getById(report.id)
      .pipe(take(1),
        filter(data => !!data),
        finalize(() => this.loadingData = false))
      .subscribe(
        data => {
          editedReport.reportBlobId = data.reportBlobId;
          editedReport.failed = data.failed;
          editedReport.reportName = data.reportName;
          editedReport.reportProgress = data.reportProgress;
        });
  }

  onDownload(id: string, reportName: string) {
    this.loadingData = true;
    this.reportService
      .receiveReport(`/api/Reports/${id}/download`)
      .pipe(take(1),
        finalize(() => this.loadingData = false))
      .subscribe(
        data => {
          saveAs(data, this.getReportName(reportName));
        },
        () => {
          this.layoutService.showUIMessage('Report download failed.');
        }
      );
  }

  onCancel(report: ReportRequest) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = cancelReportRequestDialogWithCommentConfig;
    this.dialog
      .open(ConfirmationWithCommentComponent, dialogConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe(
        comment => {
          if (comment) {
            this.cancelReport(report, comment);
          }
        }
      );
  }

  cancelReport(report: ReportRequest, comment: string) {
    this.loadingData = true;
    this.reportService
      .cancelScheduledReport(report.id, comment)
      .pipe(take(1),
        finalize(() => this.loadingData = false))
      .subscribe(
        () => {
          this.onRefresh(report);
        },
        (error: HttpErrorResponse) => {
          this.layoutService.showUIMessage(error.error.message);
        }
      );
  }

  getFeatureFlags() {
    this.loadingData = true;

    this.appConfigurationService.getFeatureFlag(FeatureName.JmccTransactionsReporting)
      .pipe(
        take(1),
        concatMap(flagEnabled => {
          this.jmccTransactionsReportingEnabled = flagEnabled;
          if (!flagEnabled && this.authService.isJewelerProgramsFinance) {
            this.canSeeReportList = false;
            return of(null);
          } else {
            this.defaultFilter.filters = [{ logic: 'or', filters: this.getReportFilters() }];
            return this.reportService.query(this.state);
          }
        }),
        filter(data => !!data),
        finalize(() => this.loadingData = false)
      )
      .subscribe(data => this.dataSource = { data: data.items, total: data.totalRecordCount });
  }

  getReportFilters() {
    let list = [];

    if (this.jmccTransactionsReportingEnabled && this.authService.hasAnyRole(this.jmccReportRoles)) {
      list = this.jmccReportTypes.map(element => ({ field: 'ReportType', operator: 'eq', value: element }));
      this.reportTypes.push({ 'key': 'JMCC Finance', 'value': ReportType.JMCCFinance });
    }

    if (!this.authService.isJewelerProgramsFinance) {
      list.push({ field: 'ReportType', operator: 'eq', value: ReportType.Person });
      list.push({ field: 'ReportType', operator: 'eq', value: ReportType.Company });
    }

    return list;
  }

  getReportName(reportName: string) {
    return reportName ? reportName : `Report_for_${new Date().toTimeString()}.xlsx`;
  }

  showDeleteIcon(report: ReportRequest) {
    const isJmccFinanceReport = this.jmccReportTypes.includes(report.reportType);
    const canDeleteReport = report.reportBlobId || report.failed;

    if (report.failed && isJmccFinanceReport) {
      return false;
    }

    if (this.authService.isViewOnly) {
      return false;
    }

    if (!canDeleteReport) {
      return false;
    }

    if (!isJmccFinanceReport) {
      return true;
    }

    if (isJmccFinanceReport && this.isAuthorizedToDeleteJmccFinanceReport) {
      return true;
    }

    return false;
  }

  onSelectionFilterChange(fieldName: string, fieldValue) {
    if (!this.state.filter) {
      const state = this.state;
      this.state = { ...state, filter: this.buildFilter(fieldName, fieldValue) };
    }

    const filters = flattenFilter(this.state.filter.filters);
    const isSourceFilterPresent: boolean = filters.some(f => f.field === fieldName);

    if (!isSourceFilterPresent) {
      this.state.filter.filters.push({ field: fieldName, operator: 'eq', value: fieldValue });
    } else {
      updateFilterForField(this.state.filter.filters, fieldName, fieldValue);
    }

    this.state.skip = 0;
    this.state.take = 10;
    this.loadData();
  }

  buildFilter(filedName: string, filedValue: any): CompositeFilterDescriptor {
    return {
      logic: 'and',
      filters: [
        { field: filedName, operator: 'eq', value: filedValue },
      ]
    };
  }

  clearFilters() {
    if (!this.state.filter) {
      return;
    }

    const filters = flattenFilter(this.state.filter.filters);
    const isReportTypeFilterPresent: boolean = filters.some(f => f.field === 'reportType');

    if (!isReportTypeFilterPresent) {
      this.selectedReportType = null;
    }

    const isReportProgressFilterPresent: boolean = filters.some(f => f.field === 'reportProgress');

    if (!isReportProgressFilterPresent) {
      this.selectedReportProgress = null;
    }

    const isPeriodFilterPresent: boolean = filters.some(f => f.field === 'period');

    if (!isPeriodFilterPresent) {
      this.selectedReportPeriod = null;
    }

  }
}
