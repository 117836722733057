export const environment = {
  production: false,
  envName: 'dev-deployed',
  clientId: 'ngadmin',
  baseURL: 'https://platformservices.dev-zing.jewelersmutual.com',
  paymentApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/payment',
  billingApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/billing',
  communicationApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/communication',
  riskApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/businessrisk',
  identityApiUrl: 'https://identity.dev-platform.jewelersmutual.com',
  jmIdentityApiUrl: 'https://dev-identity.jewelersmutual.com',
  contentApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/content',
  authLogConsoleWarningActive: true,
  authLogConsoleDebugActive: true,
  authMaxTokenOffset: 600,
  entityApiURL: 'https://quartz-entityapi-dev.azurewebsites.net',
  membershipApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/membership',
  shippingApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/shipping',
  accountApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/account',
  appraisalApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/appraisal',
  reportingApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/reporting',
  marketplaceApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/marketplace',
  pointOfSaleIntegrationApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/posintegration',
  studioApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/studio',
  documentApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/document',
  configurationApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/configuration',
  partnerGatewayLink: 'https://portal.dev.jewelersmutual.com',
  fedExUrl: 'https://www.fedex.com/apps/fedextrack?trackingnumber=',
  memoApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/memo',
  jewelerPagesApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/jewelerpages',
  appInsights: {
    instrumentationKey: '07723f59-1390-48a4-a872-0d3c4a11dbab'
  },
  apiManagement: {
    enableTrace: true,
    SubscriptionKey: '6f766fdeacfd4a71bc3f9525564a058d'
  },
  agsEnabled: true,
  gemAndJewelEnabled: true,
  guestExperienceEnabled: true,
  jewelerPagesEnabled: false
};
