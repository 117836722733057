import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BusinessUnit } from '../../models/business-unit.model';
import { BusinessUnitService } from '../../services/business-unit.service';
import { Company } from '../../../companies/models/company';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DeleteConfirmationComponent } from '../../../shared/components/dialogs/delete-confirmation/delete-confirmation.component';
import { Subscription } from 'rxjs';
import { LocationService } from '../../../shared/services/location.service';
import { LayoutService } from '../../../layouts/layout.service';
import { CompanyLocation } from '../../../locations/models/company-location';
// eslint-disable-next-line max-len
import { DeleteBusinessUnitWarningComponent } from '../../../shared/components/dialogs/business-unit/delete-business-unit-warning.component';
import { AuthService } from '../../../shared/auth.service';

@Component({
  selector: 'app-business-unit-list',
  templateUrl: './business-unit-list.component.html',
  styleUrls: ['./business-unit-list.component.scss']
})
export class BusinessUnitListComponent implements OnInit, OnDestroy {
  @Input() companyId: string;
  public businessUnits: BusinessUnit[];
  locations: CompanyLocation[];
  subscriptions = new Array<Subscription>();
  loadingData = false;
  formIsDirty = false;

  constructor(private businessUnitService: BusinessUnitService,
    private locationService: LocationService,
    private layoutService: LayoutService,
    private authService: AuthService,
    public matAlert: MatDialog
  ) { }

  ngOnInit() {
    this.formIsDirty = false;
    this.getBusinessUnits();
    this.getLocations();
  }
  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
  getBusinessUnits() {
    this.loadingData = true;
    const sub = this.businessUnitService.getBusinessUnits(this.companyId).subscribe(res => {
      this.loadingData = false;
      this.businessUnits = res;
    }, error => {
      this.layoutService.showUIMessage('Unable to get business unit informations');
    });
    this.subscriptions.push(sub);
  }
  getLocations() {
    const sub = this.locationService
      .getLocationsForCompanyId(this.companyId)
      .subscribe(
        data => {
          this.locations = data;
        },
        error => {
          this.layoutService.showUIMessage('Unable to get business unit informations');
        }
      );

    this.subscriptions.push(sub);
  }
  addBusinessUnit(businessUnit: BusinessUnit) {
    if (businessUnit.name) {
      this.formIsDirty = true;
      const newBusinessUnit = new BusinessUnit();
      newBusinessUnit.name = '';
      newBusinessUnit.tempId = this.generateRandomStringValue();
      businessUnit.businessUnits.push(newBusinessUnit);
    }
  }
  addRootBusinessUnit() {
    this.formIsDirty = true;
    const newBusinessUnit = new BusinessUnit();
    newBusinessUnit.name = '';
    newBusinessUnit.tempId = this.generateRandomStringValue();
    this.businessUnits.push(newBusinessUnit);
  }
  onChangeBUText() {
    this.formIsDirty = true;
  }
  onDelete(businessUnit: BusinessUnit) {
    const businessUnitLocations = this.getBusinessUnitLocations(businessUnit.businessUnitID);
    let sub: any;
    if (businessUnitLocations.length) {
      const locationNames = this.getLocationNames(businessUnitLocations);
      sub = this.matAlert
        .open(DeleteBusinessUnitWarningComponent, { data: { businessUnitName: businessUnit.name, locationNames } })
        .afterClosed()
        .subscribe(
          result => {
          }
        );

    } else {
      sub = this.matAlert
        .open(DeleteConfirmationComponent)
        .afterClosed()
        .subscribe(
          result => {
            if (result === true) {
              this.removeBusinessUnit(this.businessUnits, businessUnit);
              this.formIsDirty = true;
            }
          }
        );
    }
    this.subscriptions.push(sub);
  }

  removeBusinessUnit(businessUnitsToSearch: BusinessUnit[], businessUnitToRemove: BusinessUnit) {
    this.formIsDirty = true;
    let indexOfFoundElement = -1;
    for (let i = 0; i < businessUnitsToSearch.length; i++) {
      if (businessUnitToRemove.businessUnitID && businessUnitsToSearch[i].businessUnitID === businessUnitToRemove.businessUnitID ||
         businessUnitToRemove.tempId && businessUnitToRemove.tempId === businessUnitsToSearch[i].tempId) {
        indexOfFoundElement = i;
        break;
      }
      this.removeBusinessUnit(businessUnitsToSearch[i].businessUnits, businessUnitToRemove);
    }
    if (indexOfFoundElement > -1) {
      businessUnitsToSearch.splice(indexOfFoundElement, 1);
    }
  }

  getBusinessUnitLocations(businessUnitId): CompanyLocation[] {
    if (businessUnitId) {
      return this.locations.filter(x => x.businessUnitID === businessUnitId);
    }
    return [];
  }
  getLocationNames(locations: CompanyLocation[]): string {
    let locationName = '';
    for (let i = 0; i < locations.length; i++) {
      locationName += locations[i].locationName;
      if (i !== locations.length - 1) {
        locationName += ', ';
      }
    }
    return locationName;
  }

  saveBusinessUnits() {
    this.loadingData = true;
    this.businessUnitService.saveBusinessUnits(this.companyId, this.businessUnits).subscribe(res => {
      this.loadingData = false;
      this.layoutService.showUIMessage('Business units information successfully saved.');
      this.formIsDirty = false;
    }, err => {
      this.layoutService.showUIMessage('Unable to save business units information. Try reloading this page.');
    });
  }

  // helper private functions
  private generateRandomStringValue() {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = 10; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  }

  get isViewOnly() {
    return this.authService.isViewOnly;
  }
}
