import { Company } from '../../companies/models/company';
import { IncentiveProgram } from '../../incentive-programs/models/incentive-program';
import { ProgramEnrollmentStatus } from '../enums/program-enrollment-status.enum';
import { encodeBase64 } from '@progress/kendo-file-saver';
import { DocumentCard } from '../enums/enrollment-agreement-document-cards.enum';
import * as moment from 'moment';
import { CompensationTrigger } from '../../shared/enums/compensation-trigger.enum';

export interface ProgramEnrollment {
  createdOn: Date;
  id: string;
  program: IncentiveProgram;
  company: Company;
  enrolledBy: string;
  activeFrom: Date;
  activeUntil: Date;
  compensation: ProgramCompensation;
  payeeCode: string;
  agreementDate: Date;
  status: ProgramEnrollmentStatus;
  reasonToDelete: string;
  deactivatedBy: string;
  deactivatedOn: Date;
  reasonToDeactivate: string;
  declinedBy: string;
  declinedOn: Date;
  reasonToDecline: string;
  termInYears: number;
  businessType: string;
  documents: EnrollmentAgreement[];
  renewalDate: Date;
  modifiedOn: Date;
  canDownload?: boolean;
  canDelete?: boolean;
  needsMigration?: boolean;
};

export class ProgramEnrollmentData {
  constructor(enrollment: ProgramEnrollment) {
    this.id = enrollment.id;
    this.activeFrom = moment(enrollment.activeFrom).startOf('day').utc(true);
    this.activeUntil = moment(enrollment.activeUntil).endOf('day').utc(true);
    this.compensation = enrollment.compensation;
    this.payeeCode = enrollment.payeeCode ? encodeBase64(enrollment.payeeCode) : '';
    this.agreementDate = moment(enrollment.agreementDate).set('hour', 12).set('minute', 0).set('second', 0).utc().toDate();
    this.reasonToDelete = enrollment.reasonToDelete ? encodeBase64(enrollment.reasonToDelete) : enrollment.reasonToDelete;
    this.businessType = enrollment.businessType ? encodeBase64(enrollment.businessType) : enrollment.businessType;
    this.termInYears = enrollment.termInYears;
    this.renewalDate = moment(enrollment.renewalDate).set('hour', 12).set('minute', 0).set('second', 0).utc().toDate();
    this.status = enrollment.status;
  }

  id: string;
  activeFrom: any;
  activeUntil: any;
  compensation: ProgramCompensation;
  payeeCode: string;
  agreementDate: Date;
  reasonToDelete: string;
  businessType: string;
  termInYears: number;
  renewalDate: Date;
  status: ProgramEnrollmentStatus;
}

export class ProgramCompensation {
  amount: number;
  minAmount: number;
  maxAmount: number;
  trigger: CompensationTrigger;
  cadence: PaymentCadence;
}

export enum PaymentCadence {
  'Monthly' = 1,
  'Quarterly' = 2
}

export class EnrollmentAgreement {
  documentId: string;
  documentName: string;
  templateUsed: string;
  state: EnrollmentAgreementState;
  createdOn: Date;
  generationStatus: GenerationStatus;
}

export enum EnrollmentAgreementState {
  'Draft' = 0,
  'Final' = 1
}

export enum GenerationStatus {
  'None' = 0,
  'Received' = 1,
  'Processing' = 2,
  'Processed' = 3,
  'Uploaded' = 4,
  'Failed' = 5
}

export interface CardDetails {
  title: string;
  subtitle: string;
  description: string;
  icon: string;
  cardType: DocumentCard;
  headerIcon?: string;
  display?: boolean;
  enabled?: boolean;
  tooltip?: string;
}

export interface ProgramEnrollmentValidationDataViewModel {
  activeFrom?: Date;
  activeUntil?: Date;
  agreementDate?: Date;
}
