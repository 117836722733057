export enum FeatureName {
  ZingStudioActive = 'Zing.Studio.Active',
  JmccTransactionsReporting = 'Zing.PointOfSaleIntegration.JmccTransactionsReporting',
  ZingStudioUploadActive = 'Zing.Studio.UploadActive',
  ZingJewelerPagesActive = 'Zing.JewelerPages.Active',
  JmccDynamicallyDelivery = 'Zing.PointOfSaleIntegration.DynamicallyDelivery',
  JMIdentitySupportFeature = 'Zing.JMIdentity.Support.Active',
  ZingReportGenerationEnabled = 'Zing.Reporting.Enabled',
  ZingDocumentSigning = 'Zing.Membership.DocumentSigning',
  ZingOldProgramsPages = 'Zing.Membership.OldProgramsEnabled',
  ZingMarketplaceReturnDiamondsEnabled = 'Zing.Marketplace.ReturnDiamondsEnabled',
  ZingJMIdentityManagementActive = 'Zing.JMIdentity.Management.Active',
  ZingMembershipNonJewelerRegistrationActive= 'Zing.Membership.NonJewelerRegistrationActive'
}
