import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppConfigurationService } from '../shared/services/app-configuration.service';

@Injectable()
export class FeatureGuard implements CanLoad, CanActivate {

  constructor(
    private featureService: AppConfigurationService,
    private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const { featureName } = next.routeConfig.data;

    return this.isAuthorized(featureName);
  }

  canLoad(route: Route): Observable<boolean> | boolean {
    const { featureName } = route.data;

    return this.isAuthorized(featureName);
  }

  private isAuthorized(featureName: any) {
    if (featureName) {
      return this.featureService.getFeatureFlag(featureName)
        .pipe(
          take(1),
          tap(enabled => {
            if (!enabled) {
              this.router.navigateByUrl('/external/404');
            }
          })
        );
    } else {
      return true;
    }
  }
}
