import { CommonModule } from '@angular/common';
import { InProgressComponent } from './in-progress.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    InProgressComponent
  ],
  exports: [
    InProgressComponent
  ]
})

export class InProgressModule {}

