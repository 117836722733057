import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DeleteConfirmationWithCommentData } from '../../../models/delete-confirmation-with-comment-data';

@Component({
  selector: 'app-delete-confirmation-with-comment',
  templateUrl: './delete-confirmation-with-comment.component.html',
  styleUrls: ['./delete-confirmation-with-comment.component.scss']
})
export class DeleteConfirmationWithCommentComponent implements OnInit {

  public title = 'Delete';
  public displayText = 'Are you sure you want to delete this item?';

  public deleteComment: string;
  public isDeleteConfirmed = false;
  public deleteConfirmationForm: UntypedFormGroup;

  get deleteCommentCtrl() { return this.deleteConfirmationForm.controls['deleteComment']; }

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<DeleteConfirmationWithCommentComponent>,
    @Inject(MAT_DIALOG_DATA) data: DeleteConfirmationWithCommentData
  ) {
    if (data?.title) {
      this.title = data.title;
    }

    if (data?.displayText) {
      this.displayText = data.displayText;
    }

    if (data?.isDeleteConfirmed) {
      this.isDeleteConfirmed = data.isDeleteConfirmed;
    }
  }

  ngOnInit() {
    this.createForm();
  }

  /**
   * Initialize FormGroup instance.
   */
  public createForm() {
    this.deleteConfirmationForm = this.fb.group({
      deleteComment: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(1000),
        Validators.minLength(25)
      ])
    });
  }

  /**
   * Return true if control is touched or dirty and has invalid state.
   *
   * @param controlName Name of the FormControl.
   * @param errorType Type of error.
   */
  public hasErrorOfType(controlName: string, errorType: string): boolean {
    const ctrl = this.deleteConfirmationForm.controls[controlName];
    return ctrl && (ctrl.touched || ctrl.dirty) && ctrl.hasError(errorType);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onConfirmDelete() {
    this.isDeleteConfirmed = true;
  }
}
