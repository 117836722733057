<div *ngIf="emailMessage">
  <ul class="list-group">
    <li class="list-group-item">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="pt-2 pb-2">
            To:
            <span class="user-email" *ngFor="let element of emailMessage.to; let isLast=last">
              {{element}}{{isLast ? '' : ', '}}
            </span>
          </div>
          <div class="pt-2 pb-2" *ngIf="emailMessage.cc.length">
            Cc:
            <span class="user-email" *ngFor="let element of emailMessage.cc; let isLast=last">
              {{element}}{{isLast ? '' : ', '}}
            </span>
          </div>
          <div class="pt-2 pb-2" *ngIf="emailMessage.bcc.length">
            Bcc:
            <span class="user-email" *ngFor="let element of emailMessage.bcc; let isLast=last">
              {{element}}{{isLast ? '' : ', '}}
            </span>
          </div>
        </div>
        <div class="col-sm-12 col-md-5">
          <div class="pt-2 pb-2">
            Status: <strong>{{ emailMessage.status }}</strong>
          </div>
          <div class="pt-2 pb-2">
            Retry Count: <strong>{{ emailMessage.retryCount }}</strong>
          </div>
          <div class="pt-2 pb-2" *ngIf="emailMessage.status === 'Sent'">
            Date Sent: <strong>{{ emailMessage.messageSentOn | date:'short' }}</strong>
          </div>
          <div class="pt-2 pb-2">
            Service: <strong>{{ getServiceName(emailMessage.serviceCategory === null ? -1 : emailMessage.serviceCategory.serviceType) }}</strong>
          </div>
          <div class="pt-2 pb-2" >
            Category: <strong *ngFor="let item of emailMessage.serviceCategory?.categories; let isLast = last">{{ item.category }}<span *ngIf="!isLast">, </span></strong>
          </div>
        </div>
        <div class="col-sm-6 col-md-3">
          <table class="table table-bordered ">
            <thead>
              <tr>
                <th scope="col">Timestamp</th>
                <th scope="col">Event/Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let status of emailMessage.providerResponse?.statuses">
                <td>{{ status?.receivedOn | date:'short'}}</td>
                <td>{{ status?.status }}</td>
              </tr> 
            </tbody>
          </table>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div>
        Subject: <strong>{{ emailMessage.subject }}</strong>
      </div>
    </li>
    <li class="list-group-item message-body">
       <div >
          Body: <p [innerHTML]="emailMessage.body"></p>
      </div>
    </li>
  </ul>
</div>