import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-template-preview-dialog',
  templateUrl: './template-preview-dialog.component.html',
  styleUrls: ['./template-preview-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatePreviewDialogComponent {

  title = '';
  contentText = '';

  constructor(
    public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<TemplatePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.title = data.title;
    this.contentText = data.contentText;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
