import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { UserRole } from '../components/user-role/shared/user-role.model';
import { BaseHttpService } from '../../shared/services/base-http.service';

@Injectable()
export class UserRoleService extends BaseHttpService<UserRole> {
  constructor(
    private http: HttpClient
  ) {
    super(http);
    this.apiDomain = environment.membershipApiUrl;
    this.endpoint = 'UserRoles';
  }
}
