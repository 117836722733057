<p class="portal-top-text">View all {{ apiName }} API logs</p>
<div class="portal-example-page">
  <div class="portal-example-pager__content portal-example-pager__header-content--push">
    <div class="example-container mat-elevation-z8 grid-container">
      <kendo-grid class="no-scrollbar" *ngIf="itemsToDisplay"
      [data]="itemsToDisplay" 
      [loading]="loadingData">
        <kendo-grid-column media="(max-width: 680px)" title="API Logs">
          <ng-template kendoGridCellTemplate let-logItem>
            <div class="mobile-container">
              <div class="action-buttons">
                <mat-icon color="primary" class="cursorButtons" (click)="goToDetails(logItem)" 
                  matTooltip="View log details" 
                  matTooltipPosition="before"
                  matTooltipClass="quartz-tooltip">description</mat-icon>
              </div>
              <dl>
                <dt>Level</dt>
                <dd>{{ logItem.level }}</dd>
                <dt>Rendered Message</dt>
                <dd>{{ logItem.renderedMessage }}</dd>
                <dt>Time</dt>
                <dd>{{ logItem.timestamp }}</dd>
              </dl>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column media="(min-width: 680px)" field="level" title="Level" [width]="50"></kendo-grid-column>
        <kendo-grid-column media="(min-width: 680px)" field="renderedMessage" title="Rendered Message" [width]="300" class="with-ellipsis"></kendo-grid-column>
        <kendo-grid-column media="(min-width: 680px)" field="timestamp" title="Time" [width]="100"></kendo-grid-column>
        <kendo-grid-column media="(min-width: 680px)" title="Actions" headerClass="actions" [width]="50">
          <ng-template kendoGridCellTemplate let-logItem>
            <div class="text-center">
              <mat-icon color="primary" class="cursorButtons" (click)="goToDetails(logItem)"
                matTooltip="View log details" 
                matTooltipPosition="before"
                matTooltipClass="quartz-tooltip">description</mat-icon>
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      <div class="mt-3">
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-start m-0">
            <li class="page-item" [class.disabled]="pageInfo.currentPage === 1">
              <a class="page-link" href="#" (click)="goToPreviousPage($event)">Previous</a>
            </li>
            <li class="page-item" [class.disabled]="!pageInfo.hasMorePages">
              <a class="page-link" href="#" (click)="goToNextPage($event)" >Next</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>