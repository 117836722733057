import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { finalize, takeWhile } from 'rxjs/operators';

import { FilterDefinition } from '../../../shared/models/filter-definition';
import { PersonService } from '../../services/person.service';
import { LayoutService } from '../../../layouts/layout.service';
import { DeleteConfirmationWithCommentComponent } from '../../../shared/components/dialogs/delete-confirmation-with-comment/delete-confirmation-with-comment.component';
import { InviteService } from '../../../shared/services/invite.service';
import { CompanyLinkingComponent } from '../../../shared/components/dialogs/company-linking/company-linking.component';
import { CompanyService } from '../../../companies/services/company.service';
import { ApprovalStatus } from '../../enums/approval-status';
import { Person } from '../../models/person.model';
import { AuthService } from '../../../shared/auth.service';
import { BulkReInviteProcessService, BULK_PROCESS_STATE } from '../../../shared/services/bulk-reinvite-process.service';
import { UserRoleCode } from '../../enums/user-role-code';
import { SimplePaginationComponent } from '../../../shared/components/simple-pagination/simple-pagination.component';

@Component({
  selector: 'app-person-list',
  templateUrl: './person-list.component.html',
  styleUrls: ['./person-list.component.scss']
})
export class PersonListComponent implements OnInit, OnDestroy {

  /**
   * If Persons are shown inside of a Company view.
   */
  @Input() companyId: string;
  @Input() companyHasRestrictedAccess = false;
  @Input() showReinviteButton = false;
  @Output() reinviteInProgress = new EventEmitter<boolean>();
  @ViewChild(SimplePaginationComponent) simplePagination!: SimplePaginationComponent;

  public componentActive = true;
  public totalInvites = 0;
  public sentInvites = 0;
  public isResendInvitationsInProgress = false;

  public loadingData = false;
  public dataSource: GridDataResult;
  public state: FilterDefinition = {
    filter:{
      logic: 'and',
      filters: [
        {
          logic: 'or',
          filters: [
            { field: 'PersonSource', operator: 'eq', value: 0 },
            { field: 'PersonSource', operator: 'eq', value: 1 },
            { field: 'PersonSource', operator: 'eq', value: 2 },
            { field: 'PersonSource', operator: 'eq', value: 3 },
            { field: 'PersonSource', operator: 'eq', value: 4 }
          ]
        }
      ],
    },
    skip: 0,
    take: 10,
    sort: [{ field: 'CreatedOn', dir: 'desc' }]
  };

  public currentPage = 0;
  public pageSize = 10;
  public hasMore: boolean;

  get isPartnerGatewayAdmin() {
    return this.authService.isPartnerGatewayAdmin;
  }

  get isSuperOrPlatformAdmin() {
    return this.authService.isSuperOrPlatformAdministrator;
  }

  get isViewOnly() {
    return this.authService.isViewOnly;
  }

  get isMembershipAdmin() {
    return this.authService.isMembershipAdmin;
  }

  approvalStatus = ApprovalStatus;

  constructor(
    private layoutService: LayoutService,
    private personService: PersonService,
    private companyService: CompanyService,
    private matAlert: MatDialog,
    private inviteService: InviteService,
    private bulkInviteProcessService: BulkReInviteProcessService,
    private authService: AuthService
  ) {  }

  ngOnInit() {
    this.getInitialPersons(this.state);
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  /**
   * On every data state event, trigger a state change.
   *
   * @param state state of data.
   */
  public dataStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.simplePagination.reset();
  }

  /**
   * Get all persons from Person API.
   *
   * @param filter FilterDefinition instance.
   */
  public getInitialPersons(filter: FilterDefinition) {
    this.loadingData = true;

    /* If company Id has been provided (Persons inside of Company view)
      then get only Persons which belong to the selected company.
      Otherwise, return all Persons.
    */
    this.companyId ? this.getPersonsForCompany(filter) : this.getPersons(filter);
  }

  /**
   * Open Delete confirmation dialog.
   *
   * @param personId Identifier of Person instance.
   */
  public openDeleteDialog(personId: string) {
    this.matAlert
      .open(DeleteConfirmationWithCommentComponent)
      .afterClosed()
      .pipe(
        takeWhile(() => this.componentActive)
      )
      .subscribe(
        comment => {
          if (comment) {
            this.deletePerson(personId, comment);
          }
        }
      );
  }

  // /**
  //  * Open invite dialog for sending an invite to potential new user.
  //  */
  // public openInvitePersonDialog() {
  //   this.matAlert
  //     .open(InvitesDialogComponent)
  //     .afterClosed()
  //     .pipe(
  //       takeWhile(() => this.componentActive)
  //     )
  //     .subscribe(
  //       (email: string) => {
  //         if (email) {
  //           this.invitePerson(email);
  //         }
  //       }
  //     );
  // }

  /**
   * Open company linking dialog for linking person with company.
   */
  public openCompanyLinkingDialog() {
    this.matAlert
      .open(CompanyLinkingComponent)
      .afterClosed()
      .pipe(
        takeWhile(() => this.componentActive)
      )
      .subscribe(
        (email: string) => {
          if (email) {
            this.linkPersonWithCompany(email, this.companyId);
          }
        }
      );
  }

  /**
   * Links Company with Person instance.
   *
   * @param email of the Person which we are linking company to.
   * @param companyId Id of Company.
   */
  public linkPersonWithCompany(email: string, companyId: string) {
    this.loadingData = true;

    this.companyService.linkPersonWithCompany(email, companyId)
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        isLinkingSuccessful => {
          if (isLinkingSuccessful) {
            this.getPersonsForCompany(this.state); // Refresh data in table.
            this.layoutService.showUIMessage('Company linking is successful.');
          } else {
            this.loadingData = false;
            this.layoutService.showUIMessage('Company linking was not successful.');
          }
        },
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Company linking was not successful.');
        }
      );
  }

  /**
   * Remove Person with specific Id from database.
   *
   * @param personId Identifier of Person instance.
   */
  public deletePerson(personId: string, comment?: string) {
    this.loadingData = true;

    this.personService.delete(personId, comment)
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        () => {
          this.loadingData = false;
          this.getInitialPersons(this.state); // Reload table data.
          this.layoutService.showUIMessage('Person successfully deleted.');
        },
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Person failed to be deleted.');
        }
      );
  }

  /**
   * Get only Persons which belong to the specific Company.
   */
  public getPersonsForCompany(filter: FilterDefinition) {
    this.loadingData = true;

    this.personService.getPersonsContinuesByCompanyId(filter, this.companyId)
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        ({ items, hasMore }) => {
          this.loadingData = false;
          this.dataSource = { data: items, total: 0 };
          this.hasMore = hasMore;
        },
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Unable to get persons for this company.');
        }
      );
  }

  /**
   * Get all Persons from server.
   *
   * @param filter FilterDefinition instance.
   */
  public getPersons(filter: FilterDefinition) {

    this.personService.getPersonsContinues(filter)
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        ({ items, hasMore }) => {
          this.loadingData = false;
          this.dataSource = { data: items, total: 0 };
          this.hasMore = hasMore;
        },
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Unable to get persons.');
        }
      );
  }

  /**
   * Resend invite to already invited user
   */
  public resendInvite(email: string) {
    this.loadingData = true;
    this.inviteService.resendInvite(email)
      .subscribe(
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Person invite has been resent successfully.');
        },
        err => {
          this.loadingData = false;
          if (err.status === 404) {
            this.layoutService.showUIMessage('Person invite not found');
          } else {
            this.layoutService.showUIMessage('Person invite has not been resent.');
          }
        }
      );
  }

  /**
   * Resend invites to already invited users in a company
   */
  public resendInvitesForCompany(company: string) {
    this.bulkInviteProcessService.dataReceived.pipe(takeWhile(() => this.isResendInvitationsInProgress),
      finalize(() => {
        this.reinviteInProgress.emit(false);
        this.isResendInvitationsInProgress = false;
      }))
      .subscribe((result: any) => {
        if (result.status === BULK_PROCESS_STATE.EXECUTING) {
          this.totalInvites = result.data.totalInvites;
          this.sentInvites = result.data.sentInvites;
        } else if (result.status === BULK_PROCESS_STATE.FAILURE) {
          this.layoutService.showUIMessage('Bulk Invite process failed!');
          this.isResendInvitationsInProgress = false;
          this.reinviteInProgress.emit(false);
        } else if (result.status === BULK_PROCESS_STATE.COMPLETED) {
          this.totalInvites = 0;
          this.sentInvites = 0;
          this.isResendInvitationsInProgress = false;
          this.reinviteInProgress.emit(false);
          this.layoutService.showUIMessage('Bulk Invite process finished successfully!');
        }
      });

    this.layoutService.showUIMessage(
      'Reinvite requested! Please do not leave this page.'
    );
    this.bulkInviteProcessService
      .processBulkReInviteRequest(this.companyId)
      .pipe(takeWhile(() => this.isResendInvitationsInProgress))
      .subscribe(() => this.reinviteInProgress.emit(true));
  }

  /**
   * Check if invite link can be resent to the user.
   *
   * @param person
   */
  public isPersonReinvitable(person: Person) {
    return person.companyId && person.approvalStatus === 3;
  }

  /**
   * Resends the invitations
   */
  onClickResendInvitations() {
    this.isResendInvitationsInProgress = true;
    this.resendInvitesForCompany(this.companyId);
  }

  /**
   * Check if person has guest role assigned
   *
   * @param person
   */
  isGuestPerson(person: Person) {
    return person
    && person.roles
    && person.roles.length > 0
    && person.roles.some(r => r.userRoles.some(role => role.code === UserRoleCode.JMGuestPerson));
  }

  onPageChange(currentPage: number) {
    this.state.skip = currentPage * this.pageSize;
    this.getInitialPersons(this.state);
  }
}
