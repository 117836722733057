import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

export function flattenFilter(filter: (CompositeFilterDescriptor|FilterDescriptor) []){
  const flat = [];

  filter.forEach(f => {
    if(isCompositeFilterDescriptor(f)){
      const filter = f as CompositeFilterDescriptor;
      flat.push(...flattenFilter(filter.filters));
    } else {
      const filter = f as FilterDescriptor;
      flat.push(filter);
    }
  });

  return flat;
}

export function getFilterForField(filter: (CompositeFilterDescriptor|FilterDescriptor)[], field: string): FilterDescriptor {
  let result: FilterDescriptor;

  filter.forEach(f => {
    if(isCompositeFilterDescriptor(f)){
      const filter = f as CompositeFilterDescriptor;
      result = getFilterForField(filter.filters, field);
    }
    const filter = f as FilterDescriptor;
    if(filter.field === field){
      result = filter;
    }
  });

  return result;
}

export function updateFilterForField(filter: (CompositeFilterDescriptor|FilterDescriptor)[], field: string, value, operator?: string) {
  filter.forEach(f => {
    if(isCompositeFilterDescriptor(f)){
      const filter = f as CompositeFilterDescriptor;
      updateFilterForField(filter.filters, field, value);
    }
    const filter = f as FilterDescriptor;
    if(filter.field !== field){
      return;
    }
    filter.value = value;
    if(operator) {
      filter.operator = operator;
    }
  });
}

export function isCompositeFilterDescriptor(obj: any): obj is CompositeFilterDescriptor{
  return 'logic' in obj && 'filters' in obj;
}

export function removeFilterAndReturnValue(filters: (CompositeFilterDescriptor | FilterDescriptor)[], fieldName: string): string | null {
  for (let i = filters.length - 1; i >= 0; i--) {
    const filter = filters[i];
    if (isCompositeFilterDescriptor(filter)) {
      const compositeFilter = filter as CompositeFilterDescriptor;
      const removedValue = removeFilterAndReturnValue(compositeFilter.filters, fieldName);
      if (removedValue) {
        return removedValue;
      }
    } else {
      const singleFilter = filter as FilterDescriptor;
      if (singleFilter.field === fieldName) {
        filters.splice(i, 1);
        return singleFilter.value;
      }
    }
  }
  return null;
}
