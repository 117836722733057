import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-in-progress',
  template: `
    <div class="app-loader-wrapper" *ngIf="loading">
      <div class="portal-logo" [ngClass]="{ 'small': small }">
        <div class="loader"></div>
      </div>
    </div>`
})
export class InProgressComponent {
  @Input() loading = false;
  @Input() small = false;
}
