import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, skip, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth.service';
import { ZingHubHttpClient } from '../components/signalr-clients/zing-hub-http-client';
import { BaseHttpService } from './base-http.service';

export enum BULK_PROCESS_STATE {
  EXECUTING = 'EXECUTING',
  FAILURE = 'FAILURE',
  COMPLETED = 'COMPLETED'
}

/*  This service is used for generic bulk invites,
//  see BulkReInviteProcessService for format of generating a bulk invite'  */
@Injectable()
export class BulkReInviteProcessService extends BaseHttpService<any> {

  dataReceived = new EventEmitter<object>();
  requestInProgress$ = new BehaviorSubject(false);

  private connectionEstablished$ = new BehaviorSubject(false);
  private _hubConnection: HubConnection;
  private groupId = null;
  hubConnectionRetryCount = 0;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    super(http);
    this.apiDomain = environment.membershipApiUrl;
    this.endpoint = 'bulkreinviteprocess';
    this.createConnection();
    this.registerOnServerEvents();
  }

  private createConnection(): void {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.apiDomain}/bulkprocesses`, {
        httpClient: new ZingHubHttpClient(),
        accessTokenFactory: () => this.authService.accessToken
      })
      .withHubProtocol(new MessagePackHubProtocol())
      .build();
  }

  /**
   * Starts a connection with the Hub
   */
  private startConnection(): void {
    if (this.connectionEstablished$.value || this.groupId == null) {
      throw new Error('Connection already established or groupId is null.');
    }

    this._hubConnection
      .start()
      .then(() => this.subscribeToGroup())
      .then(() => this.connectionEstablished$.next(true))
      .catch(err => {
        // retry 3 times if error
        if (this.hubConnectionRetryCount < 3) {
          this.hubConnectionRetryCount++;
          this.startConnection();
        } else {
          this.endConnection();
        }
      });
  }

  /**
   * Registers the possible events that may be generated by the hub
   */
  private registerOnServerEvents(): void {
    this._hubConnection.on('bulkProcessStateChanged', (data: any) => {
      data = JSON.parse(data);
      if (data.processState === 'Failed') {
        this.dataReceived.emit({
          status: BULK_PROCESS_STATE.FAILURE,
          error: data.errors
        });
        this.endConnection();
      } else if (data.processState === 'Completed') {
        this.dataReceived.emit({
          status: BULK_PROCESS_STATE.COMPLETED,
          data
        });
        this.endConnection();
      } else {
        this.dataReceived.emit({
          status: BULK_PROCESS_STATE.EXECUTING,
          data
        });
      }
    });
  }

  /**
   * Ends the connection with the Hub
   */
  private endConnection(): void {
    if (this.connectionEstablished$.value) {
      this.unsubscribeFromGroup()
        .then(() => this._hubConnection.stop())
        .then(() => this.reset())
        .catch(() => this.reset());
    } else {
      this.reset();
    }
  }

  /**
   * Resets the field values
   */
  private reset(): void {
    this.connectionEstablished$.next(false);
    this.requestInProgress$.next(false);
    this.groupId = null;
  }

  /**
   * Internal subscribe to the group the connection is at
   */
  private async subscribeToGroup() {
    return this._hubConnection.invoke('subscribeToGroup', this.groupId);
  }

  /**
   * Internal unsubscribe from the group the connection is at
   */
  private async unsubscribeFromGroup() {
    return this._hubConnection.invoke('unsubscribeFromGroup', this.groupId);
  }

  /**
   * Sets the group for the client to listen on for reinvites
   *
   * @param groupId The identifier returned by the api call when requesting a reinvite
   */
  private initializeGroup(groupId: string) {
    if (this.groupId == null) {
      this.groupId = groupId;
    }
  }

  /**
   * Resend invites to the already invited users in a specific company
   *
   * @param company for which invites should be resent
   */
  public resendInvitesForCompany(company: string): Observable<any> {
    const url = `${this.apiDomain}/api/${this.endpoint}`;
    return this.http.post(url, { companyId: company });
  }

  public processInvitesForCompany(id: string): Observable<any> {
    const url = `${this.apiDomain}/api/${this.endpoint}/${id}/process`;
    return this.http.post(url, null);
  }

  /**
   * Request a invite, connects to invite request hub, then processes it
   *
   * @param requestData
   */
  public processBulkReInviteRequest(companyId: string): Observable<any> {
    if (this.requestInProgress$.value) {
      return throwError('Request already in progress');
    }
    let requestId = '';
    return of({}).pipe(
      tap(() => this.requestInProgress$.next(true)),
      switchMap(() => this.resendInvitesForCompany(companyId)),
      switchMap(reqId => {
        requestId = reqId;
        this.hubConnectionRetryCount = 0;
        this.initializeGroup(requestId);
        this.startConnection();
        return this.connectionEstablished$;
      }),
      skip(1), // skip initial false
      take(1),
      switchMap(isConnected =>
        isConnected
          ? this.processInvitesForCompany(requestId)
          : throwError('Could not connect to bulk process hub.')
      ),
      catchError(err => {
        this.endConnection();
        return throwError(err);
      })
    );
  }
}
