import { CommunicationMessage } from './communication-message';
import { ProviderResponse } from './provider-response';
import { ServiceCategory } from './service-category';

export interface EmailMessage extends CommunicationMessage {
  cc?: Array<string>;
  bcc?: Array<string>;
  from: string;
  subject?: string;
  createdOn?: string;
  serviceCategory: ServiceCategory;
  providerResponse: ProviderResponse;
}

export enum ServiceType{
  CarePlan = 1,
  Shipping = 2,
  CommercialInsurance = 3,
  PersonalInsurance = 4,
  PartnerGateway = 5,
  Appraisal = 6,
  Marketplace = 7,
  PointOfSaleIntegration = 8,
  Memo = 9,
  Studio = 10,
  Membership = 9999,
  Identity = 10000
}
