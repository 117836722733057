import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class SubscriptionKeyInterceptor implements HttpInterceptor {
  private readonly ocpApimTrace: string = 'Ocp-Apim-Trace';
  private readonly ocpApimSubscriptionKey: string = 'Ocp-Apim-Subscription-Key';

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const enableTrace = environment.apiManagement.enableTrace;
    const subscriptionKey = environment.apiManagement.SubscriptionKey;

    req = req.clone({
      headers: req.headers
        .set(this.ocpApimTrace, enableTrace ? 'true' : 'false')
        .set(this.ocpApimSubscriptionKey, subscriptionKey)
    });

    return next.handle(req);
  }
}
