import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { take, finalize } from 'rxjs/operators';
import { LayoutService } from '../../../layouts/layout.service';
import { PersonService } from '../../services/person.service';
import { Person } from '../../models/person.model';
import { BusinessServiceType } from '../../../shared/enums/business-service-type';

@Component({
  selector: 'app-personal-insurance-permission',
  templateUrl: './personal-insurance-permission.component.html',
  styleUrls: ['./personal-insurance-permission.component.scss']
})

export class PersonalInsurancePermissionComponent implements OnInit {

  @Input() person: Person;
  personalLinesForm: UntypedFormGroup;
  isLoading = false;
  formIsDirty = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private personService: PersonService,
    private layoutService: LayoutService,
    private route: ActivatedRoute
  ) { }

  get personalLinesPermissions() {
    return this.person.servicePermissions.find(x => x.serviceType === BusinessServiceType.PersonalInsurance);
  }

  ngOnInit() {
    this.buildPersonalLinesForm();
    this.loadPersonPermissions();
  }

  buildPersonalLinesForm() {
    this.personalLinesForm = this.formBuilder.group({
      allowed: this.formBuilder.control(true),
    });
  }

  loadPersonPermissions() {
    if (this.personalLinesPermissions) {
      this.personalLinesForm.get('allowed').setValue(this.personalLinesPermissions.allowed);
    }
  }

  onSubmit() {
    this.isLoading = true;
    if (this.personalLinesPermissions) {
      this.personalLinesPermissions.allowed = this.personalLinesForm.get('allowed').value;
    } else {
      this.person.servicePermissions.push({serviceType: BusinessServiceType.PersonalInsurance,
        allowed: this.personalLinesForm.get('allowed').value});
    }
    this.formIsDirty = false;
    this.updatePersonPermissions();
  }

  private updatePersonPermissions() {
    this.personService.update(this.route.snapshot.params['id'], this.person)
      .pipe(take(1), finalize(() => { this.isLoading = false; }))
      .subscribe(res => {
        this.layoutService.showUIMessage('Personal Insurance permissions data updated.');
      },
      err => {
        this.layoutService.showUIMessage('Unable to update the data for Personal Insurance permissions.');
      });
  }

  hidePopover(event: Event) {
    const popover = document.getElementById('view-pl-info');
    if (popover.hasAttribute('aria-describedby') && popover !== event.target) {
      popover.click();
    }
  }

  setFormToDirty() {
    this.formIsDirty = true;
  }
}
