import { Pipe, PipeTransform } from '@angular/core';
import { reportProgressDisplay } from '../../shared/models/report-request.model';

@Pipe({
  name: 'reportProgress'
})
export class ReportProgressPipe implements PipeTransform {

  transform(progress: number): string {
    const reportProgress = reportProgressDisplay.find(p => p.value === progress);
    return reportProgress? reportProgress.key: '-';
  }

}
