import { ConfirmationWithCommentData } from '../../shared/models/confirmation-with-comment-data';
import { DeactivationConfirmationData } from '../../shared/models/deactivation-confirmation-data';

export const deactivateProgramDialogWithCommentConfig: ConfirmationWithCommentData = {
  title: 'Deactivate Incentive Program',
  displayText: 'Are you sure you want to deactivate this incentive program?',
  confirmBtnName: 'Deactivate',
  cancelBtnName: 'Cancel',
  inputPlaceholder: 'Reason to Deactivate',
  validationMessage: 'Enter text between 25 and 1000 characters',
  isConfirmed: true
};

export const deactivateProgramDialogConfig: DeactivationConfirmationData = {
  title: 'Program Deactivation Warning',
  displayText: 'Deactivating this Program will no longer allow any company to take advantage of the Program going forward.'
};

export const deleteTransactionOrderDialogWithCommentConfig: ConfirmationWithCommentData = {
  title: 'Delete Transaction Order',
  displayText: 'Are you sure you want to Delete this Order?',
  confirmBtnName: 'Delete',
  cancelBtnName: 'Cancel',
  inputPlaceholder: 'Reason to Delete',
  validationMessage: 'Enter text between 25 and 1000 characters',
  isConfirmed: false
};

export const cancelReportRequestDialogWithCommentConfig: ConfirmationWithCommentData = {
  title: 'Cancel report request',
  displayText: 'Are you sure you want to Cancel the report generation?',
  confirmBtnName: 'Yes',
  cancelBtnName: 'No',
  inputPlaceholder: 'Reason to cancel',
  validationMessage: 'Enter text between 25 and 1000 characters',
  isConfirmed: false
}

export const deleteReportDialogWithCommentConfig: ConfirmationWithCommentData = {
  title: 'Delete Report',
  displayText: 'Are you sure you want to delete this report?',
  confirmBtnName: 'Delete',
  cancelBtnName: 'Cancel',
  inputPlaceholder: 'Reason to Delete',
  validationMessage: 'Enter text between 25 and 1000 characters',
  isConfirmed: false
};
