<div class="sms-message-details position-relative">
  <div class="d-back d-lg-none">
    <a class="d-lg-none" (click)="goToPreviousPage()" href="javascript:void(0)">
      <img src="assets/images/JMicons/JM-icon-16.png" alt="back"> GO BACK</a>
  </div>
  <h2 class="d-lg-none"> API Log Details</h2>
  <p class="portal-top-text">
    View details of the log
  </p>
  <div class="example-container clearfix m-3 p-3" *ngIf="apiLog">
    <div>
      <ul class="list-group">
        <li class="list-group-item">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="pt-2 pb-2">
                Level: <strong>{{ apiLog.level }}</strong>
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="pt-2 pb-2">
                Time: <strong>{{ apiLog.timestamp }}</strong>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="pt-2 pb-2">
            E-Tag: <strong>{{ apiLog.eTag }}</strong>
          </div>
        </li>
        <li class="list-group-item">
          <div class="pt-2 pb-2">
            Partition Key: <strong>{{ apiLog.partitionKey }}</strong>
          </div>
        </li>
        <li class="list-group-item">
          <div class="pt-2 pb-2">
            Row Key: <strong>{{ apiLog.rowKey }}</strong>
          </div>
        </li>
        <li class="list-group-item">
          <div>
            Message Template: <strong>{{ apiLog.messageTemplate }}</strong>
          </div>
        </li>
        <li class="list-group-item">
          <div>
            Rendered Message: <strong>{{ apiLog.renderedMessage }}</strong>
          </div>
        </li>
        <li class="list-group-item">
          <div>
            Exception: <strong>{{ apiLog.exception }}</strong>
          </div>
        </li>
      </ul>
      <div class="float-right mt-3">
        <button class="footer-button" mat-raised-button color="primary" (click)="goToPreviousPage()">Back</button>
      </div>
    </div>
  </div>
</div>