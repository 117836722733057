<mat-toolbar class="portal-footer mat-elevation-z6" [ngClass]="{ 'portal-transparent-footer': transparent }">
  <mat-toolbar-row>

    <small>
      &copy; 2018 Quartz Platform
    </small>

    <span fxFlex></span>

    <a href="#">
      <mat-icon fontSet="fa" fontIcon="fa-facebook"></mat-icon>
    </a>
    <a href="#">
      <mat-icon fontSet="fa" fontIcon="fa-twitter"></mat-icon>
    </a>

  </mat-toolbar-row>
</mat-toolbar>
