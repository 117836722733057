import { LogSearchCriteria } from './../models/log-search-criteria.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiLog } from '../models/api-log';

@Injectable()
export class ApiLogsService {

  apiLogForDetails: ApiLog;

  constructor(
    private http: HttpClient
  ) { }

  getApiUrlByName(apiName: string) {
    switch (apiName) {
      case 'Communications':
        return environment.communicationApiURL;
      case 'Risk':
        return environment.riskApiURL;
      case 'Identity':
        return environment.identityApiUrl;
      case 'Content':
        return environment.contentApiURL;
      case 'Membership':
        return environment.membershipApiUrl;
      case 'Billing':
        return environment.billingApiURL;
      case 'Shipping':
        return environment.shippingApiURL;
      case 'Payment':
        return environment.paymentApiURL;
      case 'Appraisals':
        return environment.appraisalApiURL;
      case 'Marketplace':
        return environment.marketplaceApiURL;
      case 'PointOfSaleIntegration':
        return environment.pointOfSaleIntegrationApiUrl;
      case 'Studio':
        return environment.studioApiURL;
      case 'Memo':
        return environment.memoApiUrl;
      case 'JewelerPages':
        return environment.jewelerPagesApiUrl;
      default:
        return '';
    }
  }

  getApiLogs(apiName: string, partitionKey: string, rowKey: string) {
    const url = `${this.getApiUrlByName(apiName)}/api/Logs/search`;
    const searchCriteria = new LogSearchCriteria();
    searchCriteria.Filter = '';
    searchCriteria.PageSize = 10;
    searchCriteria.RowKey = '';
    searchCriteria.PartitionKey = '';

    if (partitionKey && rowKey) {
      searchCriteria.RowKey = rowKey;
      searchCriteria.PartitionKey = partitionKey;
    }

    return this.http.post(url, searchCriteria);
  }

  getHealthCheckInfo(apiName: string) {
    const url = `${this.getApiUrlByName(apiName)}/hc`;
    return this.http.get(url);
  }

}
