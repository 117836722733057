import { SelectOption } from '../../../shared/models/select-option.model';
import { environment } from '../../../../environments/environment';
import { MarketplacePartnerAffiliationType } from '../../../companies/models/marketplace-partner-affiliation-type';

const allPartnerAffiliations = [
  { key: MarketplacePartnerAffiliationType.AGS, value: 'AGS', enabled: environment.agsEnabled }
];

export const partnerAffiliations = allPartnerAffiliations.filter(x => x.enabled)
  .map(x => new SelectOption<MarketplacePartnerAffiliationType>(x.key, x.value));
