import { environment } from '../../../../environments/environment';
import { ModuleName } from '../../enums/module-name';
import { ApiHealth } from '../../models/api-health';

const healthCheckURLPart = '/hc';
const indexPage = '/index.html';

export const availableHealthChecks: Readonly<ApiHealth[]> = [
  {
    name: ModuleName.Communication,
    url: environment.communicationApiURL + healthCheckURLPart,
    swaggerLink: environment.communicationApiURL + indexPage,
    imagePath: 'assets/images/icons/Communication.svg',
    status: 'Unknown',
  },
  {
    name: ModuleName.Risk,
    url: environment.riskApiURL + healthCheckURLPart,
    swaggerLink: environment.riskApiURL + indexPage,
    imagePath: 'assets/images/icons/Risk.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.Identity,
    url: environment.identityApiUrl + healthCheckURLPart,
    swaggerLink: '',
    imagePath: 'assets/images/icons/Identity.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.Membership,
    url: environment.membershipApiUrl + healthCheckURLPart,
    swaggerLink: environment.membershipApiUrl + indexPage,
    imagePath: 'assets/images/icons/Membership.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.Content,
    url: environment.contentApiURL + healthCheckURLPart,
    swaggerLink: environment.contentApiURL + indexPage,
    imagePath: 'assets/images/icons/Content.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.Payment,
    url: environment.paymentApiURL + healthCheckURLPart,
    swaggerLink: environment.paymentApiURL + indexPage,
    imagePath: 'assets/images/icons/Payment.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.Shipping,
    url: environment.shippingApiURL + healthCheckURLPart,
    swaggerLink: environment.shippingApiURL + indexPage,
    imagePath: 'assets/images/icons/Shipping.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.Billing,
    url: environment.billingApiURL + healthCheckURLPart,
    swaggerLink: environment.billingApiURL + indexPage,
    imagePath: 'assets/images/icons/Billing.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.Appraisals,
    url: environment.appraisalApiURL + healthCheckURLPart,
    swaggerLink: environment.appraisalApiURL + indexPage,
    imagePath: 'assets/images/icons/Billing.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.PointOfSaleIntegration,
    url: environment.pointOfSaleIntegrationApiUrl + healthCheckURLPart,
    swaggerLink: environment.pointOfSaleIntegrationApiUrl + indexPage,
    imagePath: 'assets/images/icons/Billing.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.DiamondMarketplace,
    url: environment.marketplaceApiURL + healthCheckURLPart,
    swaggerLink: environment.marketplaceApiURL + indexPage,
    imagePath: 'assets/images/icons/Billing.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.Memo,
    url: environment.memoApiUrl + healthCheckURLPart,
    swaggerLink: environment.memoApiUrl + indexPage,
    imagePath: 'assets/images/icons/Billing.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.Auction,
    url: '',
    swaggerLink: '',
    imagePath: 'assets/images/icons/Billing.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.PromoCode,
    url: '',
    swaggerLink: '',
    imagePath: 'assets/images/icons/Billing.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.Studio,
    url: environment.studioApiURL + healthCheckURLPart,
    swaggerLink: environment.studioApiURL + indexPage,
    imagePath: 'assets/images/icons/Billing.svg',
    status: 'Unknown'
  },
  {
    name: ModuleName.JewelerPages,
    url: environment.jewelerPagesApiUrl + healthCheckURLPart,
    swaggerLink: environment.jewelerPagesApiUrl + indexPage,
    imagePath: 'assets/images/icons/Billing.svg',
    status: 'Unknown'
  }
];
