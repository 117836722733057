<div class="confirmation">
  <h2 mat-dialog-title><mat-icon color="warn">warning</mat-icon>{{ dialogData.title }}</h2>
  <p>{{ dialogData.displayText }}</p>
  <div>
      <div *ngIf="dialogData.isConfirmed">
          <form [formGroup]="confirmationForm">
              <mat-form-field>
                <input 
                  matInput 
                  type="text" 
                  placeholder="{{ dialogData.inputPlaceholder }}" 
                  formControlName="comment">
                <mat-error 
                  *ngIf="hasErrorOfType('comment', 'required') || hasErrorOfType('comment', 'maxlength')
                        || hasErrorOfType('comment', 'minlength')">
                  {{ dialogData.validationMessage }}
                </mat-error>
              </mat-form-field>
            </form>
      </div>
      <div class="btn-group" role="group">
          <ng-container *ngIf="dialogData.isConfirmed; else notConfirmed">
            <button mat-button mat-dialog-close>{{dialogData.cancelBtnName ? dialogData.cancelBtnName : 'Cancel' }}</button>
            <button mat-button [disabled]="confirmationForm.invalid" [mat-dialog-close]="confirmationForm.invalid ? '' : commentCtrl.value">{{dialogData.confirmBtnName ? dialogData.confirmBtnName : 'Confirm'}}</button>
          </ng-container>
          
          <ng-template #notConfirmed> 
            <button mat-button mat-dialog-close>No</button>
            <button mat-button (click)="onConfirm()">Yes</button>
        </ng-template>
      </div>
  </div>
</div>