<div class="mt-3">
  <nav aria-label="Page navigation">
    <ul class="pagination justify-content-start m-0">
      <li class="page-item" [class.disabled]="currentPage === 0">
        <button type="button" class="page-link" (click)="goToPreviousPage()">Previous</button>
      </li>
      <li class="page-item" [class.disabled]="!hasMore">
        <button type="button" class="page-link" (click)="goToNextPage()">Next</button>
      </li>
    </ul>
  </nav>
</div>