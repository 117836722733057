<div class="portal-example-page">
  <div class="portal-example-pager__content">
    <div class="example-container list-container">
      <kendo-grid class="no-scrollbar" (dataStateChange)="dataStateChange($event)" [loading]="dataLoading"
        [data]="dataSource" [pageSize]="state.take" [skip]="state.skip" [sort]="state.sort" [filter]="state.filter"
        [sortable]="true" [pageable]="true" filterable="menu">

        <!-- template for mobile -->
        <kendo-grid-column media="(max-width: 680px)" title="Screening results">
          <ng-template kendoGridCellTemplate let-screeningResult>
            <div class="mobile-container">
              <div class="action-buttons">
                <button type="button"
                  class="btn btn-link mr-3 px-0 actions">
                  <u>View Report</u>
                </button>
              </div>
              <dl>
                <dt *ngIf="!hideEntityDetails">Name</dt>
                <dd *ngIf="!hideEntityDetails">{{ screeningResult.name }}</dd>
                <dt *ngIf="!hideEntityDetails">Entity Type</dt>
                <dd *ngIf="!hideEntityDetails">{{ screeningResult.formattedType }}</dd>
                <dt>Status</dt>
                <dd>{{ screeningResult.matchingStatus }}</dd>
                <dt>Score</dt>
                <dd>{{ screeningResult.highestMatchingScore}}</dd>
                <dt *ngIf="!hideCreatedOn">Scan Initiated Date</dt>
                <dd>{{ screeningResult.createdOn | date }}</dd>
                <dt>Comments</dt>
                <dd>{{ screeningResult.reasonToDecision }}</dd>
                <dt>Last updated</dt>
                <dd>{{ screeningResult.reviewedOn | date }}</dd>
              </dl>
            </div>
          </ng-template>
        </kendo-grid-column>
        <!-- template for mobile -->
        
        <!-- template for desktop -->
        <kendo-grid-column *ngIf="!hideEntityDetails" media="(min-width: 680px)" field="name" title="Name" [width]="150" class="with-ellipsis">
        </kendo-grid-column>

        <kendo-grid-column *ngIf="!hideEntityDetails" media="(min-width: 680px)" field="type" title="Entity Type" [width]="100" [filterable]="true">
          <ng-template kendoGridFilterMenuTemplate>
            <mat-form-field >
              <mat-select placeholder="Select Entity type" [(ngModel)]="selectedEntityType" name="entityTypes" (selectionChange)="onSelectionEntityTypeChange()">
                <mat-option *ngFor="let entityType of entityTypes" [value]="entityType.value">
                    {{ entityType.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template>
          <ng-template kendoGridCellTemplate let-screeningResult>
            {{ screeningResult.formattedType }}
          </ng-template>
        </kendo-grid-column>
        
        <kendo-grid-column media="(min-width: 680px)" field="matchingStatus" title="Status" [width]="150" [filterable]="true">
          <ng-template kendoGridFilterMenuTemplate>
            <mat-form-field >
              <mat-select placeholder="Select Matching status" [(ngModel)]="selectedMatchingStatus" name="serviceModules" (selectionChange)="onSelectionStatusChange()">
                <mat-option *ngFor="let matchingStatus of matchingStatuses" [value]="matchingStatus.value">
                    {{ matchingStatus.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column media="(min-width: 680px)" filter="numeric" field="highestMatchingScore" title="Score" [width]="60" >
        </kendo-grid-column>
        
        <kendo-grid-column *ngIf="!hideCreatedOn" media="(min-width: 680px)" field="createdOn" title="Scan Initiated Date" format="MMM d,yyyy" [width]="100" [filterable]="false">
        </kendo-grid-column>

        <kendo-grid-column media="(min-width: 680px)" field="reasonToDecision" title="Comments" [width]="90" [filterable]="true">
        </kendo-grid-column>

        <kendo-grid-column media="(min-width: 680px)" field="reviewedOn" title="Last Updated" format="MMM d,yyyy" [width]="100" [filterable]="false">
        </kendo-grid-column>

        <kendo-grid-column media="(min-width: 680px)" title="Actions" headerClass="actions" [width]="60">
          <ng-template kendoGridCellTemplate let-screeningResult>
            <div *ngIf="screeningResult.status === 1" class="text-center">
              <button type="button"
                class="btn btn-link mr-3 px-0 actions"
                (click)="onViewReport(screeningResult)">
                <u style="text-wrap: wrap;">View Report</u>
              </button>
            </div>
          </ng-template>
        </kendo-grid-column>
        <!-- template for desktop -->
        
      </kendo-grid>
      <button [hidden]="hideInitiateButton" class="initiate-screening float-right" [disabled]="dataLoading" mat-raised-button color="primary" (click)="onInitiateScreening()">
        Initiate Screening
      </button>
      <app-in-progress [loading]="dataLoading"></app-in-progress>
    </div>
    <p *ngIf="isBlockedByCompany">
      Person blocked due to associated Company being OFAC blocked. See 
      <a [routerLink]="['/companies/detail', person.companyId]">Company Details</a> for {{ companyName }}.
    </p>
  </div>
</div>