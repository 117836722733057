<div class="flex-layout jewelry-claims-permissions" (click)="hidePopover($event)">
  <form [formGroup]="marketPlaceForm">
    <div>
      <h2>
        Marketplace Permissions
      </h2>
    </div>
    <div class="row">
      <div class="col-md-3">
        View Marketplace details
        <img src="/assets/images/JMicons/info_outline.png" id="view-mp-info" width="20" [placement]="['bottom-left', 'bottom-right', 'right']" [ngbPopover]="marketPlacePopup" #popover="ngbPopover">
      </div>
      <div class="col-md-3 pl-md-2">
        <mat-checkbox formControlName="allowed" (change)="setFormToDirty()">Allowed</mat-checkbox>
      </div>
    </div>
    <app-partner-affiliations 
      *ngIf="affiliationTypes.length"
      [person]="person"
      [parentForm]="marketPlaceForm" 
      [partnerPermissionsFormArray]="partnerPermissions"
      (submit)="onSubmit()"></app-partner-affiliations>
  </form>
</div>
<hr class="divider"/>
<ng-template #marketPlacePopup>
  <div class="row info-wrap popup-wrap" id="marketPlacePopup">
    <div class="col-10 info-head heading-12-onyx mb-1">
      Allowed must be selected for user to see Marketplace information.
    </div>
    <mat-icon class="col-2 close-wrap mb-1 text-right" width="12">close</mat-icon>
  </div>
</ng-template>

<app-in-progress [loading]="isLoading"></app-in-progress>