import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { finalize, take, takeWhile, filter } from 'rxjs/operators';
import { ReportSettings } from '../../models/report-settings.model';
import { LayoutService } from '../../../layouts/layout.service';
import { AuthService } from '../../auth.service';
import { FileType, getReportName, ReportPeriod, ReportRequest, ReportType } from '../../models/report-request.model';
import { ReportingService, REPORT_GENERATION } from '../../services/reporting.service';
import { GenerateReportDialogSimpleComponent } from '../generate-report-dialog-simple/generate-report-dialog-simple.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GenerateReportDialogComponent } from '../../../reporting/components';
import { companyTestOptionsLabel, defaultTestOptionsLabel, reportTypes, showTestOptionsForReportTypes } from './generate-report-constants';
// eslint-disable-next-line max-len
import { GenerateReportDropdownDialogComponent } from '../../../reporting/components/generate-report-dropdown-dialog/generate-report-dropdown-dialog.component';

@Component({
  selector: 'app-generate-report',
  templateUrl: './generate-report.component.html',
  styleUrls: ['./generate-report.component.scss']
})
export class GenerateReportComponent implements OnDestroy {

  @Output() reportGenerationInProgress = new EventEmitter<boolean>();
  @Input() reportType;
  @Input() oldReport = false;
  @Input() enableReportGeneration = true;
  @Input() reportCompletion: Map<ReportPeriod, Map<ReportType, boolean>>;

  reportSettings: ReportSettings;
  componentActive = true;
  loadingData = false;

  @Input() showGenerateReport;
  @Input() isEditButton = false;

  constructor(
    protected layoutService: LayoutService,
    private reportingService: ReportingService,
    private authService: AuthService,
    protected dialog: MatDialog
  ) {
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  onGenerateReportClicked() {
    if (this.oldReport) {
      if (this.reportType === ReportType.JMCCFinance) {
        this.openDropdownDialog();
      } else {
        this.openDialog();
      }
    } else {
      this.openReportSettingsDialog();
    }
  }

  /**
   * Opens the dialog and allow user to choose options for report
   */
  openDialog(): void {
    const reportType = getReportName(this.reportType);
    const displayTestDataOption = showTestOptionsForReportTypes.reportDialog.some((t: string) => t === reportType);
    const testOptionsLabel = reportType !== 'Company' ? defaultTestOptionsLabel : companyTestOptionsLabel;
    const dialogRef = this.dialog.open(GenerateReportDialogComponent, {
      disableClose: true,
      data: {
        reportType,
        displayTestDataOptions: displayTestDataOption,
        testOptionsLabel,
        reportCompletion: this.reportCompletion
      }
    });

    dialogRef
      .afterClosed()
      .subscribe(result => {
        if (typeof result !== 'undefined' && result !== null) {
          this.loadingData = true;
          this.downloadDetailsXls(result);
        }
      });

  }

  /**
   * Opens the dialog and allow user to choose options for report
   */
  openReportSettingsDialog(): void {
    const reportType = getReportName(this.reportType);
    const displayTestDataOption = showTestOptionsForReportTypes.simpleReportDialog.some((t: string) => t === reportType);
    const dialogRef = this.dialog.open(
      GenerateReportDialogSimpleComponent,
      {
        disableClose: true,
        data: {
          reportType,
          displayTestDataOptions: displayTestDataOption
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.downloadDetailsXls(result);
      }
    });

  }

  /**
   * Opens the dialog and allow user to choose options for report
   */
  openDropdownDialog(): void {
    const reportTypesList = reportTypes;
    const dialogRef = this.dialog.open(GenerateReportDropdownDialogComponent, {
      minHeight: '300px',
      width: '500px',
      disableClose: true,
      data: {
        reportCompletion: this.reportCompletion,
        reportTypesList
      }
    });

    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter(result => !!result)
      )
      .subscribe(result => {
        if (result) {
          this.loadingData = true;
          this.downloadDetailsXls(result);
        }
      });
  }

  checkReport() {
    this.reportingService.dataReceived.pipe(take(1),
      finalize(() => this.reportGenerationInProgress.emit(false))).subscribe((result: any) => {
        if (this.reportSettings && result.status === REPORT_GENERATION.SUCCESS) {
          this.reportingService
            .receiveReport(result.uri)
            .pipe(takeWhile(() => this.componentActive))
            .subscribe(res => {
              const blob = new Blob([res]);
              const file = `${getReportName(this.reportType)} Report ${moment.utc(
                this.reportSettings.startDate
              ).format('MM-DD-YYYY')} - ${moment.utc(
                this.reportSettings.endDate
              ).format('MM-DD-YYYY')}.xlsx`;
              saveAs(blob, file);
            });
        } else if (result.status === REPORT_GENERATION.FAILURE) {
          this.layoutService.showUIMessage('Report generation failed!');
        }
      });
  }

  downloadDetailsXls = (reportSettings: ReportSettings) =>
    this.downloadDetails(FileType.Xls, reportSettings);

  downloadDetails(fileType: number, reportSettings: ReportSettings) {
    if (!this.oldReport) { this.checkReport(); }
    this.reportSettings = reportSettings;
    const reportData: ReportRequest = {
      reportType: this.reportType,
      columns: [],
      context: reportSettings.context || {},
      startDate: reportSettings.startDate,
      endDate: reportSettings.endDate,
      fileType,
      reportRequestedBy: this.authService.userId,
      queryModel: reportSettings.queryModel,
      reports: reportSettings.reports,
      reportPeriod: reportSettings.period
    };
    if (!this.oldReport) {
      this.reportGenerationInProgress.emit(true);
      this.layoutService.showUIMessage(
        'Report requested! Please do not leave this page.'
      );
      this.reportingService
        .processReportRequest(reportData)
        .pipe(take(1),
          finalize(() => this.loadingData = false))
        .subscribe(() => this.reportGenerationInProgress.emit(true), error => this.layoutService.showUIMessage(error.error));
    } else {
      this.reportingService.processReportNoUpdate(reportData).pipe(take(1),
        finalize(() => this.loadingData = false))
        .subscribe(() => {
          this.reportGenerationInProgress.emit(true);
          if (reportSettings.isScheduledReport) {
            this.layoutService
              .showUIMessage('You will be notified by email after the report is generated, upon receiving email refresh the page.');
          }
        }, error => {
          if (reportSettings.isScheduledReport) {
            this.layoutService.showUIMessage('Report scheduling failed.');
          } else {
            this.layoutService.showUIMessage(error.error.message);
          }
        });
    }
  }

}
