
import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-delete-business-unit-warning',
  template: `<div class="confirmation">
    <h2 mat-dialog-title><mat-icon color="warn">warning</mat-icon> Delete</h2>
    <p>{{data.businessUnitName}} business unit is associated with following locations and cannot be deleted:
      {{data.locationNames}}
    </p>
    <div>
      <div class="btn-group" role="group">
        <button mat-button [mat-dialog-close]="true">Close</button>
      </div>
    </div>
  </div>`
})
export class DeleteBusinessUnitWarningComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteBusinessUnitWarningComponent>
  ) { }

  closeDialog() {
    this.dialogRef.close();
  }
}
