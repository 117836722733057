import { Injectable } from '@angular/core';
import { BaseHttpService } from '../../shared/services/base-http.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { BusinessUnit } from '../models/business-unit.model';

@Injectable()
export class BusinessUnitService extends BaseHttpService<BusinessUnit> {
  constructor(
    private http: HttpClient
  ) {
    super(http);
    this.apiDomain = environment.membershipApiUrl;
  }

  getBusinessUnits(companyId: string): Observable<BusinessUnit[]> {
    this.endpoint = `companies/${companyId}/businessunits`;

    return this.http.get<BusinessUnit[]>(`${this.apiDomain}/api/${this.endpoint}`);
  }

  saveBusinessUnits(companyId: string, businessUnits: BusinessUnit[]) {
    this.endpoint = `companies/${companyId}/businessunits`;

    return this.http.put<BusinessUnit[]>(`${this.apiDomain}/api/${this.endpoint}`, businessUnits);
  }
  // Helper
  flattenBusinessUnitName(businessUnits: BusinessUnit[], parentsName: string, flatBusinessUnits: BusinessUnit[]) {
    businessUnits.forEach(businessUnit => {
      businessUnit.flatName = parentsName ? parentsName + ' -> ' + businessUnit.name : businessUnit.name;
      flatBusinessUnits.push(businessUnit);
      this.flattenBusinessUnitName(businessUnit.businessUnits, businessUnit.flatName, flatBusinessUnits);
    });
  }
}
