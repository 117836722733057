import { CompanyRolesComponent } from './../persons/components/company-roles/company-roles.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

// Modules.
import { SharedMaterialModule } from './shared-material.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { BaseHttpService } from './services/base-http.service';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { HealthCheckComponent } from './components/health-check/health-check.component';
import { ApiStatusDetailComponent } from './components/status-details/api-status-details';
import { ApiLogListComponent } from './components/api-logs/api-log-list/api-log-list.component';
import { ApiLogDetailsComponent } from './components/api-logs/api-log-details/api-log-details.component';
import { ApiLogsService } from './services/api-logs.service';
import { RouterModule } from '@angular/router';
import { HealthDetailsComponent } from './components/health-check/health-details/health-details.component';
import { PersonListComponent } from '../persons/components/person-list/person-list.component';
import { PersonService } from '../persons/services/person.service';
import { KeysPipe } from './pipes/keys.pipe';
import { ProfileService } from './services/profile.service';
import { ShippingService } from './services/shipping.service';
import { VerificationService } from './services/verification.service';
import { DeleteConfirmationComponent } from './components/dialogs/delete-confirmation/delete-confirmation.component';
// eslint-disable-next-line max-len
import { DeleteConfirmationWithCommentComponent } from './components/dialogs/delete-confirmation-with-comment/delete-confirmation-with-comment.component';
import { PublishConfirmationComponent } from './components/dialogs/publish-confirmation.component';
import { InvitesDialogComponent } from './components/dialogs/invites/invites-dialog.component';
import { InviteService } from './services/invite.service';
import { CompanyLinkingComponent } from './components/dialogs/company-linking/company-linking.component';
import { LocationListComponent } from '../locations/components/location-list/location-list.component';
import { AddressPipe } from './pipes/address.pipe';
import { AddressComponent } from './components/address/address.component';
import { LocationService } from './services/location.service';
import { BusinessUnitListComponent } from '../business-unit/components/business-unit-list/business-unit-list.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { BusinessUnitService } from '../business-unit/services/business-unit.service';
import { DeleteBusinessUnitWarningComponent } from './components/dialogs/business-unit/delete-business-unit-warning.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { PersonDetailsComponent } from '../persons/components/person-details/person-details.component';
import { PlatformRolesComponent } from '../persons/components/platform-roles/platform-roles.component';
import { LocationRolesComponent } from '../persons/components/location-roles/location-roles.component';
import { UserRoleService } from '../security/services/user-role.service';
import { AnalyticsService } from './services/app-insights.service';
import { PersonIdentityComponent } from '../persons/components/person-identity/person-identity.component';
import { PersonUpdatePasswordComponent } from '../persons/components/person-update-password/person-update-password.component';
// eslint-disable-next-line max-len
import { PersonUpdateSecurityQuestionComponent } from '../persons/components/person-update-security-question/person-update-security-question.component';
import { ShipmentPermissionsComponent } from '../persons/components/shipment-permissions/shipment-permissions.component';
// eslint-disable-next-line max-len
import { CommercialLinesPermissionComponent } from '../persons/components/commercial-lines-permission/commercial-lines-permission.component';
// eslint-disable-next-line max-len
import { PersonalInsurancePermissionComponent } from '../persons/components/personal-insurance-permission/personal-insurance-permission.component';
import { MarketPlacePermissionComponent } from '../persons/components/marketplace-permission/marketplace-permission.component';
import { AppraisalPermissionComponent } from '../persons/components/appraisal-permission/appraisal-permission.component';

import { ReportingService } from './services/reporting.service';
import { BulkReInviteProcessService } from './services/bulk-reinvite-process.service';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { PartnerGatewayPermissionComponent } from '../persons/components/partner-gateway-permission/partner-gateway-permission.component';
import { JewelerPagesPermissionComponent } from '../persons/components/jeweler-pages-permission/jeweler-pages-permission.component';
import { CurrencyExchangePipe } from './pipes/currency-exchange.pipe';
import {
	GenerateReportDialogSimpleComponent
} from './components/generate-report-dialog-simple/generate-report-dialog-simple.component';
import { GenerateReportComponent } from './components/generate-report/generate-report.component';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';
import { PhoneMaskPipe } from './pipes/phone-mask.pipe';
import { ReportSummaryCardComponent } from './components/report-summary-card/report-summary-card.component';
import { PartnerService } from './services/partner.service';
import { ApprovalStatusPipe } from './pipes/approval-status.pipe';
import { FormatDecimalsDirective } from './directives/format-decimals.directive';
// eslint-disable-next-line max-len
import { PartnerAffiliationsComponent } from '../persons/components/marketplace-permission/partner-affiliations/partner-affiliations.component';
// eslint-disable-next-line max-len
import { SinglePartnerAffiliationComponent } from '../persons/components/marketplace-permission/single-partner-affiliation/single-partner-affiliation.component';
// eslint-disable-next-line max-len
import { ActivateDeactivateConfirmationComponent } from './components/dialogs/activate-deactivate-confirmation/activate-deactivate-confirmation.component';
// eslint-disable-next-line max-len
import { ActivateDeactivateConfirmationAddComponent } from './components/dialogs/activate-deactivate-confirmation-add/activate-deactivate-confirmation-add.component';
import { ConfirmationWithCommentComponent } from './components/dialogs/confirmation-with-dialog/confirmation-with-comment.component';
import { DeactivationConfirmationComponent } from './components/dialogs/deactivation-confirmation/deactivation-confirmation.component';
import { PdfUploadDialogComponent } from './components/dialogs/pdf-upload-dialog/pdf-upload-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ServiceModuleNamePipe } from './pipes/service-module-name.pipe';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';
import { BooleanToYesNoPipe } from './pipes/boolean-to-yes-no.pipe';
import { EmailSmsPreviewDialogComponent } from './components/dialogs/email-sms-preview-dialog/email-sms-preview-dialog.component';
// eslint-disable-next-line max-len
import { EmailMessagesContentComponent } from '../communications/components/email-messages/email-messages-content/email-messages-content.component';
import { SmsMessagesContentComponent } from '../communications/components/sms-messages/sms-messages-content/sms-messages-content.component';
import { OrderStatusReasonsComponent } from './components/dialogs/order-status-reasons-dialog/order-status-reasons-dialog';
import { TemplatePreviewDialogComponent } from './components/dialogs/template-preview-dialog/template-preview-dialog.component';
import { InProgressModule } from './components/in-progress.module';
import { ScreeningResultsTableModule } from '../screening/components/screening-results-table/screening-results-table.module';
import { ForbiddenDialogComponent } from './components/dialogs/forbidden-dialog/forbidden-dialog.component';
import { SimplePaginationComponent } from './components/simple-pagination/simple-pagination.component';

declare let hljs: any;

@NgModule({
	imports: [
		RouterModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		SharedMaterialModule,
		GridModule,
		TimePickerModule,
		DatePickerModule,
		TreeViewModule,
		NgbPopoverModule,
		PdfViewerModule,
		InProgressModule,
		ScreeningResultsTableModule,
		NgxMaskDirective,
		NgxMaskPipe
	],
	declarations: [
    AddressComponent,
    HealthCheckComponent,
    HealthDetailsComponent,
    ApiStatusDetailComponent,
    ApiLogListComponent,
    ApiLogDetailsComponent,
    PersonListComponent,
    DeleteConfirmationComponent,
    DeactivationConfirmationComponent,
    DeleteConfirmationWithCommentComponent,
    ConfirmationWithCommentComponent,
    DeleteBusinessUnitWarningComponent,
    PublishConfirmationComponent,
    InvitesDialogComponent,
    CompanyLinkingComponent,
    LocationListComponent,
    BusinessUnitListComponent,
    KeysPipe,
    AddressPipe,
    TruncateStringPipe,
    CurrencyExchangePipe,
    PhoneMaskPipe,
    ServiceModuleNamePipe,
    PersonDetailsComponent,
    PlatformRolesComponent,
    LocationRolesComponent,
    CompanyRolesComponent,
    PersonIdentityComponent,
    PersonUpdatePasswordComponent,
    PersonUpdateSecurityQuestionComponent,
    ShipmentPermissionsComponent,
    CommercialLinesPermissionComponent,
    PersonalInsurancePermissionComponent,
    MarketPlacePermissionComponent,
    ConfirmationDialogComponent,
    PartnerGatewayPermissionComponent,
    JewelerPagesPermissionComponent,
    AppraisalPermissionComponent,
    GenerateReportDialogSimpleComponent,
    GenerateReportComponent,
    ReportSummaryCardComponent,
    ApprovalStatusPipe,
    BooleanToYesNoPipe,
    PartnerAffiliationsComponent,
    SinglePartnerAffiliationComponent,
    FormatDecimalsDirective,
    ActivateDeactivateConfirmationComponent,
    ActivateDeactivateConfirmationAddComponent,
    EmailSmsPreviewDialogComponent,
    TemplatePreviewDialogComponent,
    PdfUploadDialogComponent,
    EmailMessagesContentComponent,
    SmsMessagesContentComponent,
    OrderStatusReasonsComponent,
		ForbiddenDialogComponent,
    SimplePaginationComponent
	],
	exports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		SharedMaterialModule,
		GridModule,
		AddressComponent,
		HealthCheckComponent,
		HealthDetailsComponent,
		PersonListComponent,
		TimePickerModule,
		LocationListComponent,
		BusinessUnitListComponent,
		DatePickerModule,
		NgbPopoverModule,
		KeysPipe,
		AddressPipe,
		TruncateStringPipe,
		PhoneMaskPipe,
		ServiceModuleNamePipe,
		CurrencyExchangePipe,
		PersonDetailsComponent,
		GenerateReportComponent,
		ReportSummaryCardComponent,
		ApprovalStatusPipe,
		BooleanToYesNoPipe,
		FormatDecimalsDirective,
		EmailMessagesContentComponent,
		SmsMessagesContentComponent,
		InProgressModule,
		ScreeningResultsTableModule,
		NgxMaskDirective,
		NgxMaskPipe,
    SimplePaginationComponent
	],
	providers: [
    BaseHttpService,
    ApiLogsService,
    PersonService,
    ProfileService,
    PartnerService,
    ShippingService,
    VerificationService,
    InviteService,
    LocationService,
    BusinessUnitService,
    UserRoleService,
    AnalyticsService,
    ReportingService,
    BulkReInviteProcessService,
		provideEnvironmentNgxMask(),
	],
	entryComponents: [
    ApiStatusDetailComponent,
    DeleteConfirmationComponent,
    DeactivationConfirmationComponent,
    DeleteConfirmationWithCommentComponent,
    ConfirmationWithCommentComponent,
    PublishConfirmationComponent,
    InvitesDialogComponent,
    CompanyLinkingComponent,
    DeleteBusinessUnitWarningComponent,
    ConfirmationDialogComponent,
    GenerateReportDialogSimpleComponent,
    ActivateDeactivateConfirmationComponent,
    ActivateDeactivateConfirmationAddComponent,
    EmailSmsPreviewDialogComponent,
    TemplatePreviewDialogComponent,
    OrderStatusReasonsComponent,
		ForbiddenDialogComponent
  ]
})
export class SharedModule {
	constructor() {
		hljs.registerLanguage('typescript', require('highlight.js/lib/languages/typescript'));
		hljs.registerLanguage('scss', require('highlight.js/lib/languages/scss'));
		hljs.registerLanguage('xml', require('highlight.js/lib/languages/xml'));
	}
}
