import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { UserRoleCode } from '../../../persons/enums/user-role-code';
import { ON_DEMAND_FRAGMENT, ROUTER_LINK_OPTIONS, SCHEDULED_FRAGMENT } from '../../../screening/utils/ofac-screening-consts';
import { AuthService } from '../../../shared/auth.service';
import { FeatureName } from '../../../shared/enums/feature-name';
import { AppConfigurationService } from '../../../shared/services/app-configuration.service';
import { MenuItem } from '../../models/menu-item.model';
import { LayoutService } from './../../layout.service';
import { identitySupportIdentityItems, jmUsersUrl, platformAdminIdentityItems } from './menu-sidenav.config';

@Component({
  selector: 'portal-menu-sidenav',
  templateUrl: './menu-sidenav.component.html',
  styleUrls: ['./menu-sidenav.component.scss'],
})
export class MenuSidenavComponent implements OnDestroy, OnInit {
  /**
   * Import material sidenav so we can access open close functions.
   */
  @Input() sidenav: MatSidenav;
  routerSubscription: Subscription;
  ofacRouterLinkOptions = ROUTER_LINK_OPTIONS;

  /**
   * Reading flags from azure configuration
   */
  documentSigningEnabled$ = this.appConfigurationService.getFeatureFlag(FeatureName.ZingDocumentSigning);
  oldProgramsPages$ = this.appConfigurationService.getFeatureFlag(FeatureName.ZingOldProgramsPages);

  sidenavSections = [
    { name: 'Communication', visibility: false },
    { name: 'Risk', visibility: false },
    { name: 'Membership', visibility: false },
    { name: 'Entity', visibility: false },
    { name: 'Content', visibility: false },
    { name: 'Identity', visibility: false },
    { name: 'Appraisals', visibility: false },
    { name: 'Point Of Sale Integration', visibility: false }
  ];

  private readonly communicationItems: MenuItem[] = [
    {
      link: '/communications/messaging-account/list',
      icon: 'assets/images/JMicons/messaging_white.png',
      displayText: 'Messaging Account'
    },
    {
      link: '/communications/security-configuration/configuration/list',
      icon: 'assets/images/JMicons/security_white.png',
      displayText: 'Security Configuration'
    },
    {
      link: '/communications/templates/list',
      icon: 'assets/images/JMicons/messages_white.png',
      displayText: 'Templates'
    },
    {
      link: '/communications/email-messages/list',
      icon: 'assets/images/JMicons/messages_white.png',
      displayText: 'Email Messages'
    },
    {
      link: '/communications/sms-messages',
      icon: 'assets/images/JMicons/messages_white.png',
      displayText: 'SMS Messages'
    },
    {
      link: '/communications/bulk-communication/list',
      icon: 'assets/images/JMicons/messages_white.png',
      displayText: 'Bulk Communication'
    },
    {
      link: '/communications/service-category/details',
      icon: 'assets/images/JMicons/messages_white.png',
      displayText: 'Service Category'
    }
  ];

  private readonly riskItems: MenuItem[] = [
    {
      link: '/risk/master-configurations',
      icon: 'assets/images/JMicons/JM-icon-12.png',
      displayText: 'Master Configuration'
    },
    {
      link: '/risk/configuration-overview',
      icon: 'assets/images/JMicons/configuration_overview_white.png',
      displayText: 'Risk Configuration Overview'
    },
    /* {
       link: '/risk/metering',
       icon: 'assets/images/JMicons/metering_white.png',
       displayText: 'Metering'
     }*/
  ];

  private readonly membershipItems = [
    {
      link: '/companies',
      icon: 'assets/images/JMicons/JM-icon-1.png',
      displayText: 'Companies'
    },
    {
      link: '/persons',
      icon: 'assets/images/JMicons/JM-icon-1.png',
      displayText: 'Persons'
    },
    // {
    //   link: '/security/user-groups',
    //   icon: 'assets/images/JMicons/user_groups_white.png',
    //   displayText: 'User Groups'
    // },
    // {
    //   link: '/security/organizational-units',
    //   icon: 'assets/images/JMicons/organizational_units.png',
    //   displayText: 'Organizational Unit'
    // },
    {
      link: '/security/user-roles',
      icon: 'assets/images/JMicons/role_white.png',
      displayText: 'Role'
    },
    {
      link: '/security/permissions/list',
      icon: 'assets/images/JMicons/permission_white.png',
      displayText: 'Permission'
    },
    // {
    //   link: '/security/user-directory/list',
    //   icon: 'assets/images/JMicons/directory_white.png',
    //   displayText: 'User Directory'
    // },
    // {
    //   link: '/security/ip-fences/list',
    //   icon: 'assets/images/JMicons/directory_white.png',
    //   displayText: 'Fencing'
    // },
    {
      link: '/reporting',
      icon: 'assets/images/JMicons/directory_white.png',
      displayText: 'Reporting'
    },
    {
      link: '/retail-location',
      icon: 'assets/images/JMicons/role_white.png',
      displayText: 'Retail Location'
    },
    {
      link: '/bulk-upload',
      icon: 'assets/images/JMicons/directory_white.png',
      displayText: 'Bulk Upload'
    }
  ];

  private readonly programEnrollments: MenuItem = {
    link: '/program-enrollments',
    icon: 'assets/images/JMicons/directory_white.png',
    displayText: 'Program Enrollments'
  };

  private readonly incentivePrograms: MenuItem = {
    link: '/incentive-programs',
    icon: 'assets/images/JMicons/directory_white.png',
    displayText: 'Incentive Programs'
  };

  private readonly programEnrollmentsSigning: MenuItem = {
    link: '/program-enrollments-signing',
    icon: 'assets/images/JMicons/directory_white.png',
    displayText: 'JM Enrollment Signing'
  };

  private readonly incentiveProgramsSigning: MenuItem = {
    link: '/incentive-programs-signing',
    icon: 'assets/images/JMicons/directory_white.png',
    displayText: 'JM Incentive Signing'
  };

  private readonly entityItems: MenuItem[] = [
    {
      link: '/entity/list',
      icon: 'assets/images/JMicons/entity.png',
      displayText: 'Entities'
    },
    {
      link: '/entity/items',
      icon: 'assets/images/JMicons/entity_item.png',
      displayText: 'Entity Item'
    },
    {
      link: '/entity/forms',
      icon: 'assets/images/JMicons/form.png',
      displayText: 'Forms'
    }
  ];

  private readonly contentItems: MenuItem[] = [
    {
      link: '/content/placeholder/list',
      icon: 'assets/images/JMicons/placeholder.png',
      displayText: 'Placeholders'
    },
    {
      link: '/content/banner-templates/list',
      icon: 'assets/images/JMicons/messages_white.png',
      displayText: 'Banner Templates'
    }
  ];

  identityItems$ = this.appConfigurationService
    .getFeatureFlag(FeatureName.JMIdentitySupportFeature)
    .pipe(
      map(fFlag => {
        let items = [];

        if (this.authService.isJMIdentitySupportLevelOne) {
          items = identitySupportIdentityItems;
        }

        if (this.authService.isSuperOrPlatformAdministrator || this.authService.isViewOnly) {
          items = platformAdminIdentityItems;
        }

        if (!fFlag) {
          items = items.filter(item => item.link !== jmUsersUrl);
        }

        return items;
      }));

  private readonly appraisalItems: MenuItem[] = [
    {
      link: '/appraisals/reporting',
      icon: 'assets/images/JMicons/directory_white.png',
      displayText: 'Reporting'
    },
    {
      link: '/appraisals/cache',
      icon: 'assets/images/JMicons/directory_white.png',
      displayText: 'Caching'
    }
  ];

  private readonly posiItems: MenuItem[] = [
    {
      link: '/pointofsaleintegration/reporting',
      icon: 'assets/images/JMicons/directory_white.png',
      displayText: 'Reporting'
    },
    {
      link: '/pointofsaleintegration/transactions',
      icon: 'assets/images/JMicons/directory_white.png',
      displayText: 'Transaction Details'
    }
  ];

  private readonly ofacScreeningItems: MenuItem[] = [{
    link: '/risk/ofac-screening',
    icon: 'assets/images/JMicons/JM-icon-12.png',
    displayText: 'On Demand OFAC Screening',
    fragment: ON_DEMAND_FRAGMENT
  },
  {
    link: '/risk/ofac-screening',
    icon: 'assets/images/JMicons/JM-icon-12.png',
    displayText: 'Scheduled OFAC Screening',
    fragment: SCHEDULED_FRAGMENT
  }];

  constructor(
    private router: Router,
    private authService: AuthService,
    private layoutService: LayoutService,
    private appConfigurationService: AppConfigurationService
  ) {
    this.routerSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
      )
      .subscribe((event: RouterEvent) => {
        if (this.sidenav && this.sidenav.mode === 'over' && this.sidenav.opened) {
          this.sidenav.close();
        }
      });

    this.sidenavSections = this.layoutService.getSidebarSectionVisibility();

    this.layoutService.sectionViibilityChange()
      .subscribe(
        newState => {
          if (newState) {
            this.sidenavSections.forEach(s => {
              s.visibility = s.name === newState.name;
            });
          }
        }
      );
  }

  ngOnInit() {
    this.oldProgramsPages$.pipe(take(1)).subscribe(flagEnabled => {
      if (flagEnabled) {
        this.membershipItems.push(this.incentivePrograms);
        this.membershipItems.push(this.programEnrollments);
      }
    });

    this.documentSigningEnabled$.pipe(take(1)).subscribe(flagEnabled => {
      if (flagEnabled) {
        this.membershipItems.push(this.incentiveProgramsSigning);
        this.membershipItems.push(this.programEnrollmentsSigning);
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  getKpiLink() {
    return this.authService.isSuperOrPlatformAdministrator || this.authService.isViewOnly ? '/dashboards/kpi' : '/dashboards/kpi/reporting';
  }

  getCommunicationItems() {
    if (this.authService.isSuperOrPlatformAdministrator || this.authService.isPlatformAdmin || this.authService.isViewOnly) {
      return this.communicationItems;
    }
    return this.communicationItems
      .filter(item => item.displayText !== 'Messaging Account' && item.displayText !== 'Security Configuration');
  }

  getRiskItems() {
    if (this.authService.isSuperOrPlatformAdministrator) {
      return this.riskItems.concat(this.ofacScreeningItems);
    }
    if (this.authService.isRegulatoryComplianceAdministrator) {
      return this.ofacScreeningItems;
    }
    return this.riskItems;
  }

  getMembershipMenuItems() {
    /* Check if user has a SuperAdministrator or PlatformAdministrator role. */
    const isSuperOrPlatformAdmin = this.authService.isSuperOrPlatformAdministrator;
    const isMarketplaceAdmin = this.authService.isMarketplaceAdmin;
    const isStudioAdmin = this.authService.isStudioAdmin;
    const isJPFinance = this.authService.isJewelerProgramsFinance;
    const isPOSadmin = this.authService.isPOSAdmin;
    const isJewelerPagesAdmin = this.authService.isJewelerPagesAdmin;
    const isAuctionAdmin = this.authService.isAuctionAdmin;
    const isCommercialLinesAdmin = this.authService.isCommercialLinesAdmin;
    const isShippingAdmin = this.authService.isShippingAdmin;
    const isPersonalLinesUser = this.authService.isPersonalLinesUser;
    const isAppraisalAdmin = this.authService.isAppraisalAdmin;
    const isPartnerGatewayAdmin = this.authService.isPartnerGatewayAdmin;
    const isRegulatoryComplianceAdmin = this.authService.isRegulatoryComplianceAdmin;
    const isPlatformSupportLevelOne = this.authService.isPlatformSupportLevelOne;
    const isMembershipReportViewer = this.authService.isMembershipReportViewer;
    const isViewOnly = this.authService.isViewOnly;
    const isMembershipAdmin = this.authService.isMembershipAdmin;

    /* Check if user has a CarePlanAdministrator role. */
    const isCarePlanAdmin = this.authService
      .userPlatformRoles
      .some(role => role.code === UserRoleCode.JMCarePlanAdministrator);

    const isCarePlanUser = this.authService
      .userPlatformRoles
      .some(role =>
        role.code === UserRoleCode.JMCarePlanSales
        || role.code === UserRoleCode.JMCarePlanFinance
        || role.code === UserRoleCode.JMCarePlanClaimProcessor
        || role.code === UserRoleCode.JMCarePlanClaimProcessingManager
        || role.code === UserRoleCode.JMCarePlanCallCenter
      );

    if (isSuperOrPlatformAdmin || isViewOnly) {
      return this.membershipItems;
    }

    const membershipMenuItemsSet = new Set();

    if (isCarePlanAdmin) {
      this.membershipItems
        .filter(item => ['Companies', 'Persons', 'Reporting', 'Retail Location'].includes(item.displayText))
        .forEach(i => membershipMenuItemsSet.add(i));
    }
    if (isCarePlanUser) {
      this.membershipItems
        .filter(item => ['Companies', 'Persons', 'Reporting', 'Retail Location'].includes(item.displayText))
        .forEach(i => membershipMenuItemsSet.add(i));
    }
    if (isShippingAdmin
      || isCommercialLinesAdmin
      || isPersonalLinesUser
      || isAppraisalAdmin
      || isPartnerGatewayAdmin
      || isMarketplaceAdmin
      || isStudioAdmin
      || isJewelerPagesAdmin
      || isAuctionAdmin
      || isMembershipAdmin
      || isRegulatoryComplianceAdmin) {
      this.membershipItems
        .filter(item => ['Companies', 'Persons', 'Reporting'].includes(item.displayText))
        .forEach(i => membershipMenuItemsSet.add(i));
    }
    if (isPOSadmin) {
      this.membershipItems
        .filter(item => ['Companies', 'Persons', 'Reporting',
          'Program Enrollments', 'Incentive Programs', 'JM Enrollment Signing', 'JM Incentive Signing'].includes(item.displayText))
        .forEach(i => membershipMenuItemsSet.add(i));
    }
    if (isPlatformSupportLevelOne) {
      this.membershipItems
        .filter(item => ['Companies', 'Persons'].includes(item.displayText))
        .forEach(i => membershipMenuItemsSet.add(i));
    }
    if (isMembershipReportViewer) {
      this.membershipItems
        .filter(item => ['Reporting'].includes(item.displayText))
        .forEach(i => membershipMenuItemsSet.add(i));
    }
    if (isJPFinance) {
      this.membershipItems
        .filter(item => ['Companies', 'Reporting',
          'Program Enrollments', 'Incentive Programs', 'JM Enrollment Signing', 'JM Incentive Signing'].includes(item.displayText))
        .forEach(i => membershipMenuItemsSet.add(i));
    }
    if (membershipMenuItemsSet.size === 0) {
      this.membershipItems
        .filter(item => ['Companies'].includes(item.displayText))
        .forEach(i => membershipMenuItemsSet.add(i));
    }

    return Array.from(membershipMenuItemsSet) as MenuItem[];
  }

  getEntityItems() { return this.entityItems; }

  getContentItems() { return this.contentItems; }

  getAppraisalsItems() {
    const isSuperOrPlatformAdmin = this.authService.isSuperOrPlatformAdministrator;
    const isAppraisalAdmin = this.authService.isAppraisalAdmin;
    const isAppraisalSales = this.authService.isAppraisalSales;
    if (isSuperOrPlatformAdmin || isAppraisalAdmin) {
      return this.appraisalItems;
    }
    if (isAppraisalSales) {
      return this.appraisalItems.filter(item => ['Reporting'].includes(item.displayText));
    }

    return [];
  }

  getPosiItems() {
    const isSuperOrPlatformAdmin = this.authService.isSuperOrPlatformAdministrator;
    const isViewOnly = this.authService.isViewOnly;
    const isPOSAdmin = this.authService.isPOSAdmin;
    const isCustomerService = this.authService.isCustomerService;
    if (isSuperOrPlatformAdmin || isPOSAdmin || isViewOnly) {
      return this.posiItems;
    }
    if (isCustomerService) {
      return this.posiItems.filter(item => ['Transaction Details'].includes(item.displayText));
    }

    return [];
  }

  showKpiDashboard() {
    return this.authService.isSuperOrPlatformAdministrator
      || this.authService.isMarketplaceAdmin || this.authService.isViewOnly;
  }
}
