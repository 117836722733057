<div mat-dialog-content class="h-100 w-100 m-0 p-0">
    <mat-tab-group>
        <mat-tab *ngFor="let entity of providerResponse" [label]="getTabTitle(entity)">
            <ng-template matTabContent>
                <div class="container mt-2">
                    <div class="row">
                        <label class="col mb-0 font-weight-bold font-16">
                            Details:
                        </label>
                    </div>
                    <div class="border bg-light p-2">
                        <div class="row">
                            <label class="col-2 font-weight-bold">
                                Type:
                            </label>
                            <label class="col">
                                {{ entity.sdnType }}
                            </label>
                            <label class="col-2 font-weight-bold">
                                List:
                            </label>
                            <label class="col">
                                {{ entity.source }}
                            </label>
                        </div>
                        <div class="row">
                            <label class="col-2 font-weight-bold">
                                Entity Name:
                            </label>
                            <label class="col">
                                {{ entity.fullName }}
                            </label>
                            <label class="col-2 font-weight-bold">
                                Programs:
                            </label>
                            <label class="col">
                                {{ entity.programs }}
                            </label>
                        </div>  
                        <div class="row">
                            <label class="col-2 font-weight-bold">
                                Date of Birth:
                            </label>
                            <label class="col">
                                {{ entity.dob | date }}
                            </label>
                            <label class="col-2 font-weight-bold">
                                Citizenship:
                            </label>
                            <label class="col">
                                {{ entity.citizenship }}
                            </label>
                        </div>
                        <div class="row">
                            <label class="col-2 font-weight-bold">
                                Gender:
                            </label>
                            <label class="col-1">
                                {{ entity.gender }}
                            </label>
                            <label class="col-2 font-weight-bold">
                                Score:
                            </label>
                            <label class="col-1">
                                {{ entity.score }}
                            </label>
                            <label class="col-2 font-weight-bold">
                              Title:
                            </label>
                            <label class="col-3">
                                {{ entity.title }}
                            </label>
                        </div>
                        <div class="row">
                            <label class="col-2 font-weight-bold">
                                Remarks:
                            </label>
                            <label class="col">
                                {{ entity.remarks }}
                            </label>
                        </div>
                        <div class="row">
                            <label class="col-3 font-weight-bold">
                                Additional Sanctions:
                            </label>
                            <label class="col">
                                {{ entity.additionalSanctions }}
                            </label>
                        </div>
                    </div>               
                </div>
                <div class="container mt-3">
                    <label class="mb-0 font-weight-bold font-16">
                        Identifications:
                    </label>
                    <table class="table p-2">
                        <thead>
                        <tr>
                            <th scope="col">Type</th>
                            <th scope="col">ID</th>
                            <th scope="col">Country</th>
                            <th scope="col" style="white-space: nowrap">Issue Date</th>
                            <th scope="col" style="white-space: nowrap">Expire Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let identification of entity.ids">
                            <td>{{ identification.type }}</td>
                            <td>{{ identification.id }}</td>
                            <td>{{ identification.country }}</td>
                            <td>{{ identification.issueDate | date }}</td>
                            <td>{{ identification.expirationDate | date }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="container mt-3">
                    <label class="mb-0 font-weight-bold font-16">
                        Aliases:
                    </label>
                    <table class="table p-2">
                        <thead>
                        <tr>
                            <th scope="col">Category</th>
                            <th scope="col">Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let aka of entity.akas">
                            <td>{{ aka.category }}</td>
                            <td>{{ aka.firstName }} {{ aka.lastName }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="container mt-3">
                    <label class="mb-0 font-weight-bold font-16">
                        Addresses:
                    </label>
                    <table class="table p-2">
                        <thead>
                        <tr>
                            <th scope="col">Address</th>
                            <th scope="col">City</th>
                            <th scope="col">State/Province</th>
                            <th scope="col" style="white-space: nowrap">Postal Code</th>
                            <th scope="col">Country</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let address of entity.addresses">
                            <td>{{ address.address1 }} {{address.address2}} {{address.address3}} {{ address.address4 }}</td>
                            <td>{{ address.city }}</td>
                            <td>{{ address.stateOrProvince }}</td>
                            <td>{{ address.postalCode }}</td>
                            <td>{{ address.country }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
<mat-dialog-actions class="m-2 border-top">
    <mat-form-field *ngIf="!hideActions" class="ml-4">
        <textarea matInput placeholder="Comment" [(ngModel)]="reasonToDecision"
                rows=2 type="text" maxlength="200" style="resize: none" required>
        </textarea>
        <mat-error>
            Please enter comment
        </mat-error>
    </mat-form-field> 
    <div class="row w-100 mx-4">
        <button *ngIf="!hideActions" mat-raised-button mat-dialog-close color="primary" (click)="onViewProfile()" class="col">
            View Profile
        </button>
        <button mat-flat-button mat-dialog-close class="col">
            Cancel
        </button>
        <button *ngIf="!hideActions" mat-flat-button class="col" (click)="onDecision(DecisionType.Decline)" [disabled]="!reasonToDecision?.trim()">
            No Action Needed
        </button>
        <button *ngIf="!hideActions" mat-raised-button color="primary" class="col mr-2" (click)="onDecision(DecisionType.Approve)" [disabled]="!reasonToDecision?.trim()">
            Block
        </button>
    </div>    
</mat-dialog-actions>