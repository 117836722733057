import { Pipe, PipeTransform } from '@angular/core';
import { reportPeriodDisplay } from '../../shared/models/report-request.model';

@Pipe({
  name: 'reportPeriod'
})
export class ReportPeriodPipe implements PipeTransform {

  transform(period: number): string {
    const reportPeriod = reportPeriodDisplay.find(p => p.value === period);
    return reportPeriod? reportPeriod.key: '-';
  }

}
