export class ConfirmationWithCommentData {
  title: string;
  displayText: string;
  confirmBtnName: string;
  cancelBtnName: string;
  inputPlaceholder: string;
  validationMessage: string;
  isConfirmed = false;

  constructor(
    title: string,
    displayText: string,
    confirmBtnName: string,
    cancelBtnName: string,
    inputPlaceholder: string,
    validationMessage: string,
    isConfirmed: boolean
  ) {
    this.title = title;
    this.displayText = displayText;
    this.confirmBtnName = confirmBtnName;
    this.cancelBtnName = cancelBtnName;
    this.inputPlaceholder = inputPlaceholder;
    this.validationMessage = validationMessage;
    this.isConfirmed = isConfirmed;
  }
};
