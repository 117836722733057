<div class="dialog-wrapper">
  <h1 mat-dialog-title class="header">
    {{ data.title }}
  </h1>
  <mat-dialog-content>
    <div class="message">
      {{ data.content }}
    </div>
    <mat-dialog-actions class="mb-0" align="end">
      <button
        mat-raised-button
        (click)="onNoClick()"
        type="button"
        color="primary"
        mat-dialog-close
      >
        No
      </button>
      <button
        mat-raised-button
        [mat-dialog-close]="true"
        cdkFocusInitial
        type="submit"
        color="primary"
      >
        Yes
      </button>
    </mat-dialog-actions>
  </mat-dialog-content>
</div>
