<div class="position-relative">
  <div class="portal-example-page">
    <div class="portal-example-pager__content portal-example-pager__header-content--push">
      <div class="example-container mat-elevation-z8">
        <div class="example-header">
          <mat-tab-group #tabGroup>
            <!-- Person details -->
            <mat-tab label="Details">
              <div class="flex-layout" fxLayout="row" fxLayoutAlign="left">
                <form [formGroup]="personDetailsForm" *ngIf="personDetailsForm">
                  <span *ngIf="isBlocked" class="red-color-bold-label mb-2">BLOCKED</span>
                  <div fxLayout="column" *ngIf="isAccountLocked">
                    <div fxLayout="row" fxLayout.xs="column" class="float-right">
                      <div fxFlex="50">
                      </div>
                      <div fxFlex="35" class="accountUnlockMessage">
                        <span>
                          Account Locked Until : {{ lockedUntil | date : 'medium' }}
                        </span>
                      </div>
                      <div fxFlex="10">
                        <button class="btn-width-200" mat-raised-button color="primary" (click)="unlockAccount()">
                          Unlock Account
                        </button>
                      </div>
                    </div>
                  </div>

                  <mat-divider class="my-3" *ngIf="isAccountLocked"></mat-divider>
                  <div fxLayout="row" fxLayout.xs="column">
                    <mat-form-field>
                      <input matInput formControlName="firstName" type="text" placeholder="First Name">
                      <mat-error *ngIf="hasError(firstNameCtrl)">
                        This is a required field.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput formControlName="lastName" type="text" placeholder="Last Name">
                      <mat-error *ngIf="hasError(lastNameCtrl)">
                        This is a required field.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayout.xs="column">
                    <mat-form-field>
                      <input matInput formControlName="middleName" type="text" placeholder="Middle Name">
                      <mat-error *ngIf="hasError(middleNameCtrl)">
                        This is a required field.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <input matInput readonly formControlName="companyId" type="text" placeholder="Company Id">
                      <mat-error *ngIf="hasError(companyIdCtrl)">
                        This is a required field.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayout.xs="column">
                    <mat-form-field>
                      <mat-select formControlName="approvalStatus" placeholder="Approval Status">
                        <mat-option *ngFor="let status of approvalStatus | keys" [value]="status.key">{{ status.value |
                          approvalstatus }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="hasError(approvalStatusCtrl)">
                        This is a required field.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                      <mat-select formControlName="contactPreference" placeholder="Contact Preference">
                        <mat-option *ngFor="let contact of contactPreferences | keys" [value]="contact.key">{{
                          separateStringByUppercase(contact.value)
                          }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="hasError(contactPreferenceCtrl)">
                        This is a required field.
                      </mat-error>
                    </mat-form-field>
                  </div>



                  <div fxLayout="row" fxLayout.xs="column">

                    <mat-form-field>
                      <input matInput formControlName="email" type="text" placeholder="E-mail">
                      <mat-error *ngIf="hasErrorOfType('email', 'required')
              || hasErrorOfType('email', 'pattern')
              || hasErrorOfType('email', 'maxlength')">
                        Please enter valid email.
                      </mat-error>
                      <mat-error *ngIf="hasErrorOfType('email', 'emailInUse')">
                        Entered email address is duplicate.
                      </mat-error>
                      <mat-checkbox class="verifyBox" *ngIf="!isInvite" formControlName="isEmailVerified">Verified
                      </mat-checkbox>
                      <mat-error *ngIf="hasError(isEmailVerifiedCtrl)">
                        This is a required field.
                      </mat-error>
                    </mat-form-field>


                    <mat-select formControlName="countryCode" id="countryCode" style="width:75px;">
                      <mat-option value="+1">+1</mat-option>
                    </mat-select>
                    <mat-form-field style="width:90%">
                      <input matInput formControlName="cellPhone" type="text" placeholder="Phone number"
                        [mask]="countryCodeCtrl.value | phonemask">
                      <mat-error *ngIf="hasErrorOfType('cellPhone', 'pattern')">
                        Enter a valid mobile number (Example: 1234567890)
                      </mat-error>
                      <mat-checkbox class="verifyBox" *ngIf="!isInvite" formControlName="isCellPhoneVerified">Verified
                      </mat-checkbox>
                      <mat-error *ngIf="hasError(isCellPhoneVerifiedCtrl)">
                        This is a required field.
                      </mat-error>
                    </mat-form-field>



                  </div>
                  <div fxLayout="row" fxLayout.xs="column" *ngIf="person.newEmail || person.newPhoneNumber">
                    <mat-form-field fxFlex="50" *ngIf="person.newEmail">
                      <input matInput formControlName="newEmail" type="text" placeholder="Email - Not verified">
                    </mat-form-field>
                    <mat-form-field fxFlex="50" *ngIf="person.newPhoneNumber">
                      <input matInput formControlName="newPhoneNumber" type="text"
                        placeholder="Phone number - Not verified">
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayout.xs="column">
                    <mat-form-field>
                      <mat-chip-list #skillsChipList formControlName="skills">
                        <mat-chip *ngFor="let skill of skills" [selectable]="selectable" [removable]="removable"
                          (removed)="removeSkill(skill)">
                          {{skill}}
                          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input placeholder="Add Skill" [matChipInputFor]="skillsChipList"
                          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                          (matChipInputTokenEnd)="addSkill($event)">
                      </mat-chip-list>
                    </mat-form-field>
                  </div>

                  <app-address [parent]="personDetailsForm" [address]="person.address" [requiredValidator]="false"
                    [isFormDisabled]="hasGuestRole"></app-address>

                  <div fxLayout="row" fxLayout.xs="column">
                    <mat-form-field class="single">
                      <input matInput formControlName="dateJoined" type="text" placeholder="Date joined">
                    </mat-form-field>
                    <mat-form-field class="single">
                      <input matInput formControlName="lastLoginDate" type="text" placeholder="Last login date/time (CT)">
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayout.xs="column" *ngIf="person.registrationId || (clientId && hasShipping)">
                    <mat-form-field class="single" *ngIf="person.registrationId">
                      <input matInput formControlName="registrationId" type="text" placeholder="Registration ID">
                    </mat-form-field>
                    <mat-form-field class="single" *ngIf="clientId && hasShipping">
                      <input matInput formControlName="clientId" type="text" placeholder="iShip Client ID">
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayout.xs="column">
                    <mat-form-field class="single">
                      <input matInput formControlName="personSource" type="text" placeholder="Person registration source">
                    </mat-form-field>
                  </div>

                  <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="10">
                      <mat-checkbox formControlName="isActive">Is Active</mat-checkbox>
                      <mat-error *ngIf="hasError(isActiveCtrl)">
                        This is a required field.
                      </mat-error>
                    </div>
                    <div fxFlex>
                      <mat-checkbox formControlName="disabledAnalytics">Disable Analytics</mat-checkbox>
                    </div>

                    <div fxFlex="50">
                      <mat-checkbox [disabled]="true" *ngIf="isEditMode && hasShipping"
                        formControlName="isShippingCustApproved">
                        Shipping Customer Approved on Core
                      </mat-checkbox>
                    </div>
                  </div>

                  <div fxLayout="column" class="mt-3">
                    <app-platform-roles
                      [isSuperAdmin]="isSuperAdmin"
                      [isPlatformAdmin]="isPlatformAdmin"
                      [personRoles]="person.roles"
                      [roles]="platformUserRoles" [disableOwnRoles]="disableOwnRoles || hasGuestRole"
                      (roleSelected)="onRoleSelected($event)">
                    </app-platform-roles>
                  </div>

                  <div fxLayout="column" *ngIf="person.companyId" class="mt-3">
                    <app-company-roles [personRoles]="person.roles"
                      [person]="person" [roles]="companyUserRoles" [disableOwnRoles]="disableOwnRoles || hasGuestRole"
                      (roleSelected)="onRoleSelected($event)">
                    </app-company-roles>
                  </div>

                  <div fxLayout="column" *ngIf="person.companyId" class="mt-3">
                    <h2 class="mt-3">Location User roles </h2>
                    <app-location-roles *ngFor="let location of locations" [location]="location" [person]="person"
                      [roles]="businessUserRoles" [disableOwnRoles]="disableOwnRoles || hasGuestRole"
                      (disableSaveButton)="disableButton = $event" (makeFormDirty)="markFormAsDirty($event)">
                    </app-location-roles>
                  </div>
                  <div class="footer">
                    <mat-card-actions align="end" fxLayout="row" fxLayout.xs="column">
                      <button mat-raised-button color="primary" type="button" (click)="onBack()">Cancel</button>
                      <button *ngIf="!isViewOnly" mat-raised-button color="primary" type="button"
                        [disabled]="personDetailsForm.invalid || !personDetailsForm.dirty || disableButton"
                        (click)="onSubmit()">Save</button>
                      <button mat-raised-button color="primary" type="button"
                        *ngIf="(person? isPersonNotRegistered(person) : false) && !hasGuestRole"
                        (click)="inviteInactivePerson()">Save
                        and Invite</button>
                      <button mat-raised-button color="primary" type="button"
                        *ngIf="(person? isPersonReinvitable(person) : false) && !hasGuestRole"
                        (click)="resendInvite()">Resend
                        Invite</button>
                    </mat-card-actions>
                  </div>
                </form>
              </div>
            </mat-tab>
            <mat-tab label="Identity" *ngIf="isEditMode && person.referenceId && !hasGuestRole && !isRegulatoryComplianceAdmin">
              <app-person-identity [person]="person">
              </app-person-identity>
            </mat-tab>
            <mat-tab label="Service Permissions" *ngIf="canEditServicePermissions && !hasGuestRole">
              <app-shipment-permissions [person]="person"
                *ngIf="(isSuperOrPlatformAdmin || isShippingAdmin || isPartnerGatewayAdmin) && hasShipping">
              </app-shipment-permissions>
              <app-commercial-lines-permission *ngIf="(isSuperOrPlatformAdmin || isCLAdmin) && hasCL">
              </app-commercial-lines-permission>
              <app-personal-insurance-permission *ngIf="hasPersonalInsurance && hasPersonalInsurancePermissions"
                [person]="person">
              </app-personal-insurance-permission>
              <app-marketplace-permission *ngIf="hasMarketplace && hasMarketplacePermissions" [person]="person">
              </app-marketplace-permission>
              <app-partner-gateway-permission *ngIf="(isSuperOrPlatformAdmin || isPartnerGatewayAdmin) && hasPG"
                [person]="person" [subscription]="JMPGSubscription">
              </app-partner-gateway-permission>
              <app-appraisal-permission *ngIf="hasAppraisal && hasAppraisalPermissions" [person]="person">
              </app-appraisal-permission>
              <app-jeweler-pages-permission *ngIf="(jewelerPagesEnabled$ | async) && hasJewelerPages && hasJewelerPagesPermissions" [person]="person">
              </app-jeweler-pages-permission>
            </mat-tab>

            <mat-tab
            *ngIf="person?.id && (isSuperOrPlatformAdmin || isRegulatoryComplianceAdmin || isMembershipAdmin)"
            label="OFAC History" [disabled]="!isEditMode">
              <div *ngIf="person.approvalStatus === approvalStatus.Blocked" class="alert alert-warning d-flex justify-content-between align-items-center" role="alert">
                <p class="m-0">For unblocking , please contact Regulatory Compliance and Platform Administrator</p>
              </div>
              <p class="portal-top-text">View screening matches</p>
              <app-screening-results-table 
                [screeningFilter]="getScreeningFilter()"
                [disableActions]="true"
                [hideEntityDetails]="true"
                sortBy="createdOn"
                [person]="person"
                [companyName]="company?.name"
                [hideInitiateButton]="isBlocked"
              ></app-screening-results-table>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>

<app-in-progress [loading]="loadingData"> </app-in-progress>