import { MenuItem } from "../../models/menu-item.model";

const termsAndConditions: MenuItem = {
  link: '/terms-and-conditions',
  icon: 'assets/images/JMicons/form.png',
  displayText: 'Terms and Conditions'
};

export const jmUsersUrl = '/identity/users';

const users: MenuItem =   {
  link: jmUsersUrl,
  icon: 'assets/images/JMicons/JM-icon-7.png',
  displayText: 'Users'
};

export const platformAdminIdentityItems: MenuItem[] = [
  termsAndConditions,
  users
];

export const identitySupportIdentityItems: MenuItem[] = [
  users
];