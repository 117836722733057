<p *ngIf="canSeeReportList" class="portal-top-text">Existing Reports</p>
<div class="portal-example-pager__content" *ngIf="canSeeReportList">
  <div class="list-container example-container">
    <kendo-grid class="no-scrollbar" 
      (dataStateChange)="dataStateChange($event)"
      [data]="dataSource"
      [loading]="loadingData"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [sortable]="true"
      [pageable]="true"
      filterable="menu">
      <kendo-grid-column media="(max-width: 680px)" title="Existing Reports">
        <ng-template kendoGridCellTemplate let-report>
          <!-- template for mobile -->
          <div class="mobile-container">
            <div class="action-buttons">
              <mat-icon *ngIf="report.reportBlobId && !report.failed" class="cursorButtons" matTooltip="Download report"
                matTooltipPosition="before" matTooltipClass="quartz-tooltip" (click)="onDownload(report.id, report.reportName)">
                cloud_download
              </mat-icon>

              <mat-icon *ngIf="showDeleteIcon(report)"
                class="cursorButtons" matTooltip="Delete report" matTooltipPosition="before"
                matTooltipClass="quartz-tooltip" (click)="onDelete(report.id, report.reportType)">delete
              </mat-icon>

              <mat-icon *ngIf="!report.reportBlobId && !report.failed && !report.isScheduled" class="cursorButtons"
                matTooltip="Report request is being processed" matTooltipPosition="before"
                matTooltipClass="quartz-tooltip" (click)="onRefresh(report)">av_timer
              </mat-icon>
  
              <mat-icon *ngIf="report.isScheduled && report.reportProgress === reportProgress.Scheduled && report.scheduledFor > currentDate" 
                class="cursorButtons"
                matTooltip="Cancel report" matTooltipPosition="before"
                matTooltipClass="quartz-tooltip" (click)="onCancel(report)">cancel
              </mat-icon>
            </div>
            <dl>
              <dt>Report Type</dt>
              <dd>{{ getReportType(report.reportType) }}</dd>
              <dt>Created At</dt>
              <dd>{{ report.createdOn | date : 'medium' }}</dd>
              <dt>Scheduled For</dt>
              <dd>{{ report.isScheduled ? (report.scheduledFor | date : 'medium') : '-' }}</dd>
              <dt>Status</dt>
              <dd>{{ report.reportProgress | reportProgress}}</dd>
              <dt>Start Date - End Date</dt>
              <dd>{{ (report.startDate | date : 'mediumDate')}} - {{(report.endDate | date : 'mediumDate')}}</dd>
              <dt>Period</dt>
              <dd>{{ report.period | reportPeriod}}</dd>
            </dl>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column media="(min-width: 680px)" field="reportType" title="Report Type" class="with-ellipsis" 
      [width]="100" 
      [filterable]="true" 
      [sortable]="false">
      <ng-template kendoGridFilterMenuTemplate>
        <mat-form-field >
          <mat-select placeholder="Select report type" [(ngModel)]="selectedReportType" name="reportType" (selectionChange)="onSelectionFilterChange('reportType',selectedReportType)">
              <mat-option *ngFor="let reportType of reportTypes" [value]="reportType.value">
                  {{reportType.key}}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>
        <ng-template kendoGridCellTemplate let-report>
          {{ getReportType(report.reportType) }} 
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column media="(min-width: 680px)" field="createdOn" title="Created At" [width]="100" [filterable]="false">
        <ng-template kendoGridCellTemplate let-report>
          {{ report.createdOn | date : 'medium' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column media="(min-width: 680px)" field="scheduledFor" title="Scheduled For" [width]="100" [filterable]="false">
        <ng-template kendoGridCellTemplate let-report>
          {{ report.isScheduled ? (report.scheduledFor | date : 'medium') : '-' }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column media="(min-width: 680px)" field="reportProgress" title="Status" [width]="100" [filterable]="true" [sortable]="false">
        <ng-template kendoGridFilterMenuTemplate>
          <mat-form-field >
            <mat-select placeholder="Select status" [(ngModel)]="selectedReportProgress" name="reportProgress" (selectionChange)="onSelectionFilterChange('reportProgress', selectedReportProgress)">
                <mat-option *ngFor="let reportProgress of reportProgresses" [value]="reportProgress.value">
                    {{reportProgress.key}}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>
        <ng-template kendoGridCellTemplate let-report>
          {{ report.reportProgress | reportProgress}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column media="(min-width: 680px)" field="createdOn" title="Start Date - End Date" [width]="100" [filterable]="false" [sortable]="false">
        <ng-template kendoGridCellTemplate let-report>
          {{ (report.startDate | date : 'mediumDate')}} - {{(report.endDate | date : 'mediumDate')}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column media="(min-width: 680px)" field="period" title="Period" [width]="100" [filterable]="true" [sortable]="false">
        <ng-template kendoGridFilterMenuTemplate>
          <mat-form-field >
            <mat-select placeholder="Select period" [(ngModel)]="selectedReportPeriod" name="reportPeriod" (selectionChange)="onSelectionFilterChange('period', selectedReportPeriod)">
                <mat-option *ngFor="let reportPeriod of reportPeriods" [value]="reportPeriod.value">
                    {{reportPeriod.key}}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>
        <ng-template kendoGridCellTemplate let-report>
          {{ report.period | reportPeriod}}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column media="(min-width: 680px)" title="Actions" headerClass="actions" [width]="60" [filterable]="false">
        <ng-template kendoGridCellTemplate let-report>
          <div class="text-center">

            <mat-icon *ngIf="report.reportBlobId && !report.failed" class="cursorButtons" matTooltip="Download report"
              matTooltipPosition="before" matTooltipClass="quartz-tooltip" (click)="onDownload(report.id, report.reportName)">
              cloud_download
            </mat-icon>

            <mat-icon *ngIf="showDeleteIcon(report)"
              class="cursorButtons" matTooltip="Delete report" matTooltipPosition="before"
              matTooltipClass="quartz-tooltip" (click)="onDelete(report.id, report.reportType)">delete
            </mat-icon>

              <mat-icon *ngIf="!report.reportBlobId && !report.failed && !report.isScheduled" class="cursorButtons"
                matTooltip="Report request is being processed" matTooltipPosition="before"
                matTooltipClass="quartz-tooltip" (click)="onRefresh(report)">av_timer
              </mat-icon>
  
              <mat-icon *ngIf="report.isScheduled && report.reportProgress === reportProgress.Scheduled && report.scheduledFor > currentDate" 
                class="cursorButtons"
                matTooltip="Cancel report" matTooltipPosition="before"
                matTooltipClass="quartz-tooltip" (click)="onCancel(report)">cancel
              </mat-icon>

          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <app-in-progress [loading]="inProgress"></app-in-progress>
  </div>
</div>
