<mat-card class="summary-card">
  <mat-card-header class="flex-wrap justify-content-between">
    <mat-card-title>{{ reportDetails.name | uppercase }}</mat-card-title>
    <mat-card-subtitle>Report</mat-card-subtitle>
    <app-generate-report (reportGenerationInProgress)="onReportGenerationInProgress($event)" class="mb-lg-auto pb-3"
      [reportType]="reportDetails.type" [showGenerateReport]="showGenerateReport" [isEditButton]="true"
      [oldReport]="oldReport" [enableReportGeneration]="enableReportGeneration" [reportCompletion]="reportCompletion"></app-generate-report>
  </mat-card-header>
  <mat-card-content class="overflow-auto">
    <p>{{ reportDetails.description }}</p>
  </mat-card-content>
</mat-card>