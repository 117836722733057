import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subject } from 'rxjs';
import { ReportDeactivate } from '../../../shared/utils/report-deactivate';
import * as shared from '../../../shared/models/report-request.model';
import { ReportDetails, ScheduledReportsStatus } from '../../../shared/models/report-request.model';
import { AuthService } from '../../../shared/auth.service';
import { concatMap, filter, take, tap } from 'rxjs/operators';
import { UserRoleCode } from '../../../persons/enums/user-role-code';
import { AppConfigurationService } from '../../../shared/services/app-configuration.service';
import { FeatureName } from '../../../shared/enums/feature-name';

const rolesForMembership = [
  UserRoleCode.JMCarePlanAdministrator, UserRoleCode.JMCarePlanSales, UserRoleCode.JMCarePlanFinance,
  UserRoleCode.JMCarePlanCallCenter, UserRoleCode.JMCarePlanClaimProcessor, UserRoleCode.JMCarePlanClaimProcessingManager,
  UserRoleCode.JMShippingAdministrator, UserRoleCode.JMCommercialLinesAdministrator,
  UserRoleCode.JMPersonalLinesClaimsAdministrator, UserRoleCode.JMPersonalLinesClaimsExaminer,
  UserRoleCode.JMPersonalLinesClaimsCallCenter, UserRoleCode.JMAppraisalAdministrator,
  UserRoleCode.JMPartnerGatewayAdministrator, UserRoleCode.PlatformSupportL1,
  UserRoleCode.JMMarketplaceAdministrator, UserRoleCode.JMPointOfSaleAdministrator,
  UserRoleCode.JMStudioAdministrator, UserRoleCode.JMMembershipReportViewer,
  UserRoleCode.PlatformAdministrator, UserRoleCode.JewelerPagesAdministrator,
  UserRoleCode.JMAuctionAdministrator, UserRoleCode.RegulatoryComplianceAdministrator,
  UserRoleCode.MembershipAdministrator
];
@Component({
  selector: 'app-reporting-overview',
  templateUrl: './reporting-overview.component.html',
  styleUrls: ['./reporting-overview.component.scss']
})
export class ReportingOverviewComponent extends ReportDeactivate implements OnInit {

  reportRequested: Subject<boolean> = new Subject();
  scheduledReportsStatuses: ScheduledReportsStatus = {
    reportCompletion: undefined
  };

  reports: ReportDetails[] = [
    {
      name: 'Company',
      description: 'Generate report for Companies registered on Platform',
      type: shared.ReportType.Company,
      allowedRoles: rolesForMembership,
      enabled: true,
      showGenerateReport: false
    },
    {
      name: 'Person',
      description: 'Generate report for all Persons invited to the Platform',
      type: shared.ReportType.Person,
      allowedRoles: rolesForMembership,
      enabled: true,
      showGenerateReport: false
    },
    {
      name: 'Registered Persons',
      description: 'Generate report for Persons registered on Platform',
      type: shared.ReportType.RegisteredPersons,
      allowedRoles: rolesForMembership,
      enabled: true,
      showGenerateReport: false
    }
  ];

  constructor(
    private authService: AuthService,
    private appConfigurationService: AppConfigurationService,
    dialog: MatDialog
  ) {
    super();
    this.dialog = dialog;
  }

  ngOnInit(): void {
    this.getFeatureFlags();
  }

  setReportRequested(reportType: shared.ReportType, inProgress: boolean) {
    if (this.isOldReport(reportType)) {
      this.reportRequested.next(inProgress);
    } else {
      this.reportGenerationInProgress = inProgress;
    }
  }

  isOldReport(reportType: shared.ReportType) {
    return reportType === shared.ReportType.Company ||
      reportType === shared.ReportType.Person ||
      reportType === shared.ReportType.JMCCFinance;
  }

  getFeatureFlags() {
    this.appConfigurationService.getFeatureFlag(FeatureName.JmccTransactionsReporting)
      .pipe(
        take(1),
        tap(flagEnabled => {
          if (flagEnabled) {
            this.reports.push(
              {
                name: 'Transactions Report',
                description: 'Generate reports for Jeweler Program Incentive Payments',
                type: shared.ReportType.JMCCFinance,
                allowedRoles: [
                  UserRoleCode.PlatformAdministrator,
                  UserRoleCode.ViewOnly,
                  UserRoleCode.JMJewelerProgramsFinance,
                  UserRoleCode.JMPointOfSaleAdministrator
                ],
                enabled: true,
                showGenerateReport: false
              });
          } else if (this.authService.isJewelerProgramsFinance && !flagEnabled) {
            this.reports = [];
          }
        }),
        concatMap(() => this.authService.personLoaded),
        filter(res => !!res)
      )
      .subscribe(
        () => {
          this.updateReportDisplay();
          this.isGenerateReportEnabled();
        },
        () => this.reports = []
      );
  }

  private isGenerateReportEnabled() {
    this.appConfigurationService.getFeatureFlag(FeatureName.ZingReportGenerationEnabled)
      .pipe(take(1))
      .subscribe(canGenerateReport => {
        if (canGenerateReport) {
          for (const report of this.reports) {
            report.showGenerateReport = true;
          }
        }
      });
  }

  updateReportDisplay() {
    this.reports.forEach(report => {
      report.display = this.authService.userPlatformRoles
        .some(role => report.allowedRoles.includes(role.code));
    });
  }

  // called in getFeatureFlags(), uncomment when featureFlag is removed and move in ngOnInit()

  /* setDisplay(): void {
    this.authService.personLoaded.pipe(
      filter(res => !!res),
      take(1))
      .subscribe(() => {
        this.reports.forEach(report => {
          report.display = this.authService.userPlatformRoles
            .some(role => report.allowedRoles.includes(role.code));
        });
      });
  } */
}
