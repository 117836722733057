import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '../../../../content/services/content.service';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-forbidden-dialog',
  templateUrl: './forbidden-dialog.component.html'
})
export class ForbiddenDialogComponent{

  phoneNumber$: Observable<string>;

  constructor(
    private contentService: ContentService,
    public dialogRef: MatLegacyDialogRef<ForbiddenDialogComponent>,
  ) {
    this.phoneNumber$ = this.contentService.getHelpDeskNumber();
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
