
import {of,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';
import { map, catchError, tap } from 'rxjs/operators';

import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthService } from '../shared/auth.service';

@Injectable()
export class OnlyLoggedInUsersGuardService implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.canProceed();
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.canProceed();
  }

  private canProceed() {
    return this.authService.isLoggedIn()
      .pipe(
        map(isAuthorized => {
          if (isAuthorized) {
            return true;
          }
          this.router.navigate(['/external/login']);
          return false;
        }),
        catchError((err: any) => {
          console.log('caught error');
          this.router.navigate(['/external/login']);
          return of(false);
        })
      );
  }
}
