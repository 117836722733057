<div *ngIf="showGenerateReport">
  <mat-icon *ngIf="(reportGenerationInProgress | async) && !oldReport"
    class="reportPendingIcon"
    [ngClass]="isEditButton ? 'mr-5' : 'mr-2'"
    matTooltip="Report request is being processed, please do not leave this page."
    matTooltipPosition="before"
    matTooltipClass="quartz-tooltip"
    >av_timer
  </mat-icon>
  <button *ngIf="!isEditButton" mat-raised-button color="primary" [disabled]="((reportGenerationInProgress | async) && !oldReport) || !enableReportGeneration" (click)="onGenerateReportClicked()">
    Generate Report
  </button>
  <button *ngIf="isEditButton" mat-mini-fab 
    [disabled]="((reportGenerationInProgress | async) && !oldReport) || !enableReportGeneration"
    class="create-report-btn"
    (click)="onGenerateReportClicked()">
    <mat-icon>create</mat-icon>
  </button>
</div>
<app-in-progress [loading]="loadingData && oldReport"></app-in-progress>
