import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PdfUploadData } from '../../../models/pdf-upload-data';

@Component({
  selector: 'app-pdf-upload-dialog',
  templateUrl: './pdf-upload-dialog.component.html',
  styleUrls: ['./pdf-upload-dialog.component.scss']
})
export class PdfUploadDialogComponent {
  documentUrl: string;
  filename = '';
  error: string;
  error2: string;
  documentUploaded: { base64data: any; blobData: File };
  title = 'Upload PDF file';
  isPreview = false;
  isDocumentLoaded = false;
  fileLoading = false;

  constructor(private dialogRef: MatDialogRef<PdfUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PdfUploadData) {
    if (data?.title) {
      this.title = data.title;
    }
  }

  onBackClick(): void {
    this.isPreview = false;
  }

  onPreviewClick(): void {
    this.isPreview = true;
  }

  onFileUpload(): void {
    this.dialogRef.close(this.documentUploaded);
  }

  handleDocumentFiles(files: FileList) {
    this.isDocumentLoaded = false;
    this.fileLoading = true;
    this.error = null;
    this.error2 = null;

    const fileToUpload = files.item(0);

    if (this.data.isValidFileType && !this.data.isValidFileType(fileToUpload)) {
      this.error = 'Invalid File Type';
    } else if (this.data.isValidFileSize && !this.data.isValidFileSize(fileToUpload)) {
      this.error = this.data.maximumAllowedSizeString ?
        'Maximum allowed file size is ' + this.data.maximumAllowedSizeString : 'File size too big';
    } else if (this.data.isValidFileName && !this.data.isValidFileName(fileToUpload)) {
      if (this.data.notAllowedCharacters) {
        this.error = 'A file name can\'t contain any of the following characters:';
        this.error2 = this.data.notAllowedCharacters;
      } else {
        this.error = 'Special Character Error';
      }
    }

    if (!this.error) {
      this.isPreview = true;
    }

    const reader = new FileReader();

    reader.onload = (event: any) => {
      this.documentUrl = event.target.result;

      this.documentUploaded = {
        base64data: event.target.result.toString(),
        blobData: fileToUpload
      };

      this.filename = fileToUpload.name;
      this.isDocumentLoaded = true;

      setTimeout(() => this.fileLoading = false, 1000);
    };

    reader.readAsDataURL(fileToUpload);
  }
}
