import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

export interface ShippingUserData {
  userId?: string;
  personId?: string;
  id?: string;
  clientSetting?: ClientSetting;
}

export interface ClientSetting {
  clientId?: string;
  isActive?: boolean;
  isApproved?: boolean;
}

@Injectable()
export class ShippingService {

  public shippingServiceUrl: string;

  constructor(private http: HttpClient) {
    this.shippingServiceUrl = environment.shippingApiURL;
  }

  /**
   * Check if phone number or email already exist on platform.
   *
   * @param email Email value.
   * @param phone Phone number value.
   */

  getShippingUserData(personId: string): Observable<ShippingUserData> {
    const url = `${this.shippingServiceUrl}/api/usersettings?personId=${personId}`;
    return this.http
      .get(url);
  }

  saveShippingUserData(userData: ShippingUserData): Observable<any> {
    const url = `${this.shippingServiceUrl}/api/usersettings`;
    return this.http.post(url, userData);
  }

  updateShippingUserData(userData: ShippingUserData): Observable<any> {
    const url = `${this.shippingServiceUrl}/api/usersettings/${userData.id}`;
    return this.http.put(url, userData);
  }

}
