import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { encodeBase64 } from '@progress/kendo-file-saver';

@Injectable()
export class VerificationService {

  private verifierApiUrl = environment.identityApiUrl + '/Verifier';

  constructor(private http: HttpClient) { }

  public isEmailInUse(email: string) {
    const url = this.verifierApiUrl + `/IsEmailInUse?email=${encodeBase64(email)}`;
    return this.http
      .get<{ email: string; isEmailInUse: string }>(url);
  }

}
