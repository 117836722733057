import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class SecurityQuestionService {
  public readonly apiDomain = environment.identityApiUrl;
  public readonly endpoint = 'api/SecurityQuestions';

  constructor(private http: HttpClient) { }

  /**
   * Return all SecurityQuestion instances.
   */
  getSecurityQuestions() {
    return this.http.post(`${this.apiDomain}/${this.endpoint}/search`, {});
  }

}
