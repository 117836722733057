import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { urlsWithEnabledFirewallBlockWarning } from '../config/firewall-blocked-urls.config';
import { ForbiddenDialogComponent } from '../shared/components/dialogs/forbidden-dialog/forbidden-dialog.component';
import { compareUrls } from '../utils/url-helper-function';

const FIREWALL_FORBIDDEN_ERROR_CODE = 0;

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {

  constructor(private matAlert: MatDialog) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const handleCurrentResponse = urlsWithEnabledFirewallBlockWarning.some(c => !c.disable && compareUrls(error.url, c.url));

        if (handleCurrentResponse && error.status === FIREWALL_FORBIDDEN_ERROR_CODE) {
          this.matAlert.open(ForbiddenDialogComponent);
        }

        return throwError(error);
      })
    );
  }
}
