/**
 * Compares two URls.
 *
 * @param originalUrl The original URL.
 * @param url The URL to compare.
 * @returns `true` if the URLs are the same, `false` otherwise.
 *
 * Url query parameters from original url are going to be ignored. Example:
 * ```
 * var originalUrl = 'www.example.com/api/person?age=18'
 * var url = 'www.example.com/api/person'
 * console.log(compareUrl(originalUrl, url)) // Logs: true
 *```
 *
 * To escape path parameters use `*`. Example:
 * ```
 * var originalUrl = 'www.example.com/api/person/00000000-0000-0000-0000-000000000000/info'
 * var url = `www.example.com/api/person/*\/info`
 * console.log(compareUrl(originalUrl, url)) // Logs: true
 * ```
 */
export function compareUrls(originalUrl: string, url: string): boolean {
  //remove query params
  const urlObj = new URL(originalUrl);
  urlObj.search = '';

  const originalUrlArray: string[] = urlObj.toString().split('/');
  const urlArray: string[] = url.split('/');

  if (originalUrlArray.length !== urlArray.length) {
    return false;
  }

  for (let i = 0; i < originalUrlArray.length; i++) {
    const urlPart = urlArray[i];
    if (urlPart === '*') {
      continue;
    }

    const originalUrlPart = originalUrlArray[i];
    if (originalUrlPart !== urlPart) {
      return false;
    }

  }

  return true;
}
