import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { take, finalize } from 'rxjs/operators';
import { LayoutService } from '../../../layouts/layout.service';
import { PersonService } from '../../services/person.service';
import { Person } from '../../models/person.model';
import { BusinessServiceType } from '../../../shared/enums/business-service-type';

@Component({
  selector: 'app-appraisal-permission',
  templateUrl: './appraisal-permission.component.html',
  styleUrls: ['./appraisal-permission.component.scss']
})
export class AppraisalPermissionComponent implements OnInit {

  @Input() person: Person;
  appraisalForm: UntypedFormGroup;
  isLoading = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private personService: PersonService,
    private layoutService: LayoutService,
    private route: ActivatedRoute
  ) { }

  get appraisalPermissions() {
    return this.person.servicePermissions.find(x => x.serviceType === BusinessServiceType.Appraisal);
  }

  ngOnInit() {
    this.buildAppraisalForm();
    this.loadPersonPermissions();
  }

  buildAppraisalForm() {
    this.appraisalForm = this.formBuilder.group({
      allowed: this.formBuilder.control(null),
    });
  }

  loadPersonPermissions() {
    if (this.appraisalPermissions) {
      this.appraisalForm.get('allowed').setValue(this.appraisalPermissions.allowed);
    } else {
      this.appraisalForm.get('allowed').setValue(true);
    }
  }

  onSubmit() {
    this.isLoading = true;
    if (this.appraisalPermissions) {
      this.appraisalPermissions.allowed = this.appraisalForm.get('allowed').value;
    } else {
      this.person.servicePermissions.push({serviceType: BusinessServiceType.Appraisal,
        allowed: this.appraisalForm.get('allowed').value});
    }

    this.updatePersonPermissions();
  }

  private updatePersonPermissions() {
    this.personService.update(this.route.snapshot.params['id'], this.person)
      .pipe(take(1), finalize(() => this.isLoading = false))
      .subscribe(() =>
        this.layoutService.showUIMessage('Appraisal permissions data updated.')
      ,
      err =>
        this.layoutService.showUIMessage('Unable to update the data for Appraisal permissions.')
      );
  }

  hidePopover(event: Event) {
    const popover = document.getElementById('view-mp-info');
    if (popover.hasAttribute('aria-describedby') && popover !== event.target) {
      popover.click();
    }
  }

}
