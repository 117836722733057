import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '../models/paginated.response';
import { FilterDefinition } from '../models/filter-definition';
import { CompositeFilterDescriptor, FilterDescriptor, toODataString } from '@progress/kendo-data-query';
import { DateTimeRegex } from '../../utils/regex';

@Injectable()
export class BaseHttpService<T> {

  /**
   * Base url of the API (domain)
   */
  public apiDomain: string;

  /**
   * Name of the endpoint which will be used
   */
  public endpoint: string;

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * Return full url for specific endpoint
   */
  protected getFullEndpointUrl(): string {
    return `${this.apiDomain}/api/${this.endpoint}`;
  }

  /**
   * Make http request to get the items
   *
   * @param queryFilter Object containing filter information
   */
  query(queryFilter: FilterDefinition = null): Observable<PaginatedResponse<T>> {

    const oDataModel = this.createDataQueryModel(queryFilter);

    return this.httpClient
      .post<PaginatedResponse<T>>(`${this.getFullEndpointUrl()}/search`, oDataModel);
  }

  /**
   * Make http request for specific item
   *
   * @param id Unique identifier
   */
  getById(id: string): Observable<T> {
    return this.httpClient
      .get<T>(`${this.getFullEndpointUrl()}/${id}`);
  }

  /**
   * Make http request to create new resource on the server
   *
   * @param body New item
   */
  save(body: T) {
    return this.httpClient
      .post(this.getFullEndpointUrl(), body);
  }

  /**
   * Make http request to update specific item
   *
   * @param id Unique identifier
   * @param body Object which contains new data
   */
  update(id: string, body: any = {}) {
    const url = `${this.getFullEndpointUrl()}/${id}`;
    return this.httpClient
      .put(url, body);
  }

  /**
   * Make http request to delete an item
   *
   * @param id Unique identifier
   */
  delete(id: string, comment?: string) {
    const url = `${this.getFullEndpointUrl()}/${id}`;
    if (!comment) {
      return this.httpClient
        .delete(url);
    }
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        reasonToDelete: comment
      }
    };
    return this.httpClient
      .delete(url, options);
  }

  /** Skip single quotes so that odata query works */
  skipODataStringInFilter(queryFilter: CompositeFilterDescriptor | FilterDescriptor) {
    if ('value' in queryFilter) {
      queryFilter = queryFilter as FilterDescriptor;
      if (!queryFilter.value || typeof queryFilter.value !== 'string') {
        return queryFilter;
      }
      const result = {
        ...queryFilter,
        value: queryFilter.value.replace(/'/g, function (match) { return '\\\''; })
      } as FilterDescriptor;
      return result;
    } else {
      queryFilter = queryFilter as CompositeFilterDescriptor;
      return {
        ...queryFilter,
        filters: queryFilter.filters.map(el => this.skipODataStringInFilter(el))
      };
    }
  }

  createDataQueryModel(queryFilter: FilterDefinition = null) {
    const oDataModel = {
      filter: '',
      orderBy: '',
      skip: 0,
      top: 0
    };

    if (queryFilter === null) {
      return oDataModel;
    }

    if (queryFilter.filter?.filters) {
      // escape single quotes, create new filter so that kendo serch is not showing escape chars
      const copy = {
        ...queryFilter.filter,
        filters: queryFilter.filter?.filters?.map(el => this.skipODataStringInFilter(el))
      };
      queryFilter = {
        ...queryFilter,
        filter: copy
      };
    }

    const filterOptions = `${toODataString(queryFilter)}`.split('&');
    for (let i = 0; i < filterOptions.length; i++) {
      const item = filterOptions[i].split('=');
      if (item.length >= 2) {
        switch (item[0]) {
          case '$skip': oDataModel.skip = +item[1];
            break;
          case '$top': oDataModel.top = +item[1];
            break;
          case '$orderby': oDataModel.orderBy = item[1];
            break;
          case '$filter': {
            // we need to decode characters
            item[1] = unescape(item[1]);
            // odata adds two quotes when you search for one so we need to replace it before search
            const replaceChars = { '\\\'\'': '\\\'' };
            item[1] = item[1].replace(/\\''/g, function (match) { return replaceChars[match]; });
            // because the backend uses an older version of OData (older than v4) need to replace the date strings
            item[1] = item[1].replace(new RegExp(DateTimeRegex, 'g'), function (match) { return `datetime${match}`; });
            oDataModel.filter = item[1];
          } break;
        }
      }
    }
    return oDataModel;
  }
}
