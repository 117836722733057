import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CompanyLocation } from '../../locations/models/company-location';
import { StoreType } from '../../locations/models/store-type';
import { BusinessProduct } from '../../locations/models/business-product';
import { Observable ,  Subject } from 'rxjs';
import { PaginatedResponse } from '../models/paginated.response';

@Injectable()
export class LocationService {
  public companyApiDomain = environment.membershipApiUrl + '/api/Companies';
  public apiDomain: string;
  public endpoint: string;
  public location: CompanyLocation;
  private businessServices = new Subject<any>();

  constructor(private http: HttpClient
  ) {
    this.apiDomain = environment.membershipApiUrl;
    this.endpoint = 'Locations';
  }
  /**
   * Update existing location
   *
   * @param companyId CompanyId for which locations is updated
   * @param locationId LocationId of entity being updated
   * @param location Location entity to replace existing one
   */
  updateLocation(companyId: string, locationId: string, location: CompanyLocation): Observable<any> {

    this.cloneCompanyLocationServiceObject(location);
    return this.http
      .put<any>(`${this.companyApiDomain}/${companyId}/${this.endpoint}/${locationId}`, location);
  }

  private cloneCompanyLocationServiceObject(locationupdate: CompanyLocation) {
    this.location = Object.assign({} as CompanyLocation, locationupdate);
    if (this.location.businessServices) {
      this.location.businessServices.forEach(element => {

        delete element.postLoginUrl;
        delete element.postLogoutUrl;

      });
    }
  }

  deleteLocation(companyId: string, locationId: string, comment?: string): Observable<any> {
    if (!comment){
      return this.http
        .delete<any>(`${this.companyApiDomain}/${companyId}/${this.endpoint}/${locationId}`);
    }
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        reasonToDelete: comment
      }
    };
    return this.http
      .delete<any>(`${this.companyApiDomain}/${companyId}/${this.endpoint}/${locationId}`, options);
  }
  /**
   * Save new location
   *
   * @param companyId CompanyId for which locations is updated
   * @param location Location entity to replace existing one
   */
  saveLocation(companyId: string, location: CompanyLocation): Observable<any> {
    return this.http
      .post<any>(`${this.companyApiDomain}/${companyId}/${this.endpoint}`, location);
  }

  /**
   * Make http request for specific item
   *
   * @param companyId CompanyId for which locations are returned
   */
  getLocationsForCompanyId(companyId: string): Observable<any> {
    // TODO: Add filter definition
    return this.http
      .get<any>(`${this.companyApiDomain}/${companyId}/${this.endpoint}`);
  }
  /**
   * Make http request for specific item
   *
   * @param companyId CompanyId for which locations are returned
   */
  getLocation(companyId: string, locationId: string): Observable<CompanyLocation> {
    return this.http
      .get<CompanyLocation>(`${this.companyApiDomain}/${companyId}/${this.endpoint}/${locationId}`);
  }

  /**
   * Make http request for retrieving all available store types
   */
  getStoreTypes(): Observable<StoreType[]> {
    const storeTypesEndpoint = 'storetypes';
    return this.http
      .get<StoreType[]>(`${environment.membershipApiUrl}/api/${storeTypesEndpoint}`);
  }
  /**
   * Make http request for retrieving all available store types
   */
  getProductsAndServices(): Observable<BusinessProduct[]> {
    const storeTypesEndpoint = 'businessproducts';
    return this.http
      .get<BusinessProduct[]>(`${environment.membershipApiUrl}/api/${storeTypesEndpoint}`);
  }

  getBusinessServiceDetails(serviceId: string): Observable<any> {
    const servicesEndpoint = 'businessservices';

    return this.http
      .get<any>(`${environment.membershipApiUrl}/api/${servicesEndpoint}/${serviceId}`);
  }
  getBusinessServices(): Observable<any> {
    const servicesEndpoint = 'businessservices';
    return this.http
      .post<any>(`${environment.membershipApiUrl}/api/${servicesEndpoint}/search`, {});
  }

  getById(companyId: string, locationId: string) {
    const url = `${this.companyApiDomain}/${companyId}/locations/${locationId}`;

    return this.http.get<CompanyLocation>(url);
  }
  setBusinessServicesUpdated(service) {
    this.businessServices.next(service);
  }
  getBusinessServicesUpdated(): Observable<any> {
    return this.businessServices.asObservable();
  }
  getRetailLocationTypes(): Observable<PaginatedResponse<any>> {
    const retailStoreTypeEndpoint = 'api/RetailStoreTypes/search';
    return this.http.post<PaginatedResponse<any>>(`${this.apiDomain}/${retailStoreTypeEndpoint}`, {});
  }
}
