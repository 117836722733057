import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { partnerAffiliations } from '../marketplace-permission-constants';
import { MarketplacePartnerServicePermission } from '../../../models/marketplace-partner-service-permission';
import { MarketplaceAgsServicePermission } from '../../../models/marketplace-ags-service-permission';
import { MarketplacePartnerAffiliationType } from '../../../../companies/models/marketplace-partner-affiliation-type';
import { MarketplaceAgsServicePermissionStatus } from '../../../models/marketplace-ags-service-permission-status';
import { endDateLessThanStartDate } from '../../../../shared/utils/date-range-validator';
import { EndDateLessThanStartDateErrorStateMatcher } from '../../../../shared/utils/date-error-state-matcher';
import { SelectOption } from '../../../../shared/models/select-option.model';

@Component({
  selector: 'app-single-partner-affiliation',
  templateUrl: './single-partner-affiliation.component.html'
})
export class SinglePartnerAffiliationComponent implements OnInit, OnDestroy {
  @Input()
  parentForm: UntypedFormGroup;
  @Input()
  affiliationTypes$: Observable<SelectOption<number>[]>;
  @Input()
  partnerFormGroup: UntypedFormGroup;
  @Input()
  partnerAffiliationData: MarketplacePartnerServicePermission;

  componentActive = true;
  selectedPartner: SelectOption<number>;
  partnerAffiliationArray: UntypedFormArray;
  partnerAffiliationFormGroup: UntypedFormGroup;
  matcher = new EndDateLessThanStartDateErrorStateMatcher();

  get startDateCtrl() { return this.partnerAffiliationFormGroup.get('startDate'); }
  get endDateCtrl() { return this.partnerAffiliationFormGroup.get('endDate'); }
  get memberIdCtrl() { return this.partnerAffiliationFormGroup.get('memberId'); }
  get gnjUUIDCtrl() { return this.partnerAffiliationFormGroup.get('gemAndJewelId'); }
  get statusCtrl() { return this.partnerAffiliationFormGroup.get('status'); }

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.selectedPartner = this.getSelectedPartner(this.partnerFormGroup.get('affiliationType').value);
    this.createPartnerAffiliationForm(this.selectedPartner?.key, this.partnerAffiliationData);
    this.listenOnAffiliationTypeChanges();
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  private listenOnAffiliationTypeChanges(): void {
    this.partnerFormGroup.get('affiliationType').valueChanges
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(affiliationType => {
        this.partnerAffiliationArray.clear();
        this.selectedPartner = this.getSelectedPartner(affiliationType);
        this.createPartnerAffiliationForm(affiliationType);
      });
  }

  private getSelectedPartner(affiliationType: number): SelectOption<number> {
    return partnerAffiliations.find(x => x.key === affiliationType);
  }

  private createPartnerAffiliationForm(partnerAffiliationType?: number, partnerAffiliationData?: MarketplacePartnerServicePermission) {
    const formGroup = this.createFormGroupByAffiliationType(partnerAffiliationType, partnerAffiliationData);
    this.partnerAffiliationArray = this.partnerFormGroup.get('partnerData') as UntypedFormArray;
    this.partnerAffiliationFormGroup = formGroup;
    setTimeout(() => {
      this.partnerAffiliationArray.push(formGroup);
      this.partnerAffiliationArray.updateValueAndValidity();
    });
  }

  private createFormGroupByAffiliationType(affiliationType: number, partnerAffiliation?: MarketplacePartnerServicePermission): UntypedFormGroup {

    switch (affiliationType) {
      case MarketplacePartnerAffiliationType.AGS: {
        return this.getAGSFormGroup(partnerAffiliation);
      }
      default: {
        return this.formBuilder.group({
          startDate: this.formBuilder.control(null),
          endDate:  this.formBuilder.control(null)
        },
        {
          validator: endDateLessThanStartDate
        });
      }
    }
  }

  private getAGSFormGroup(partnerAffiliation?: MarketplacePartnerServicePermission): UntypedFormGroup {
    const agsAffiliation = partnerAffiliation as MarketplaceAgsServicePermission;

    return this.formBuilder.group({
      startDate: this.formBuilder.control(agsAffiliation?.startDate),
      endDate: this.formBuilder.control(agsAffiliation?.endDate),
      memberId: this.formBuilder.control(agsAffiliation?.memberId, [Validators.required]),
      status: this.formBuilder.control(agsAffiliation?.status === MarketplaceAgsServicePermissionStatus.Active)
    },
    {
      validator: endDateLessThanStartDate
    });
  }
}
