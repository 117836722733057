import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../../persons/models/address.model';

@Pipe({
  name: 'address'
})

export class AddressPipe implements PipeTransform {
  transform(value: Address): string {
    let addressString = '';
    if (value) {
      for (const key of Object.keys(value)) {
        const keyValue = value[key];
        if (keyValue && keyValue !== '' && key !== 'longitude' && key !== 'latitude' && key !== 'altitude') {
          addressString += keyValue;
          addressString += ', ';
        }
      }
      addressString = addressString.slice(0, -2);
    }
    return addressString;
  }
}
