<a class="portal-user-menu-button" mat-button [mat-menu-trigger-for]="userDetailsMenu">
  <img class="portal-user-menu-button__avatar" [src]="imageUrl">
  <img class="portal-user-caret" src="assets/images/JMicons/JM-icon-13.png" alt="caret">
</a>
<mat-menu #userDetailsMenu="matMenu" classList="portal-menu-no-padding" class="portal-menu-box">
  <div class="portal-user-menu-header" fxLayout="row" fxLayoutAlign=" center">
    <img class="portal-user-menu-header__avatar" [src]="imageUrl" alt="User avatar"/>
    <div class="portal-user-menu-header__details" fxLayout="column" fxLayoutAlign="center start">
      <h2>{{ showUserName() }}</h2>
    </div>
  </div>
  <mat-nav-list class="portal-user-menu-list">
    <a mat-list-item (click)="signOut()">
      <span i18n class="mat-list-logout-btn">Sign Out</span>
    </a>
  </mat-nav-list>
</mat-menu>
