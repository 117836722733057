import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { FilterDefinition } from '../../../shared/models/filter-definition';
import { BulkProcessState, ProcessType, ScreeningEntityType, ScreeningResult } from '../../models/screening-process';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OfacReportViewModalComponent } from '../ofac-report-view-modal/ofac-report-view-modal.component';
import { ScreeningService } from '../../services/screening.service';
import { finalize, take, takeWhile } from 'rxjs/operators';
import { LayoutService } from '../../../layouts/layout.service';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { flattenFilter, updateFilterForField } from '../../../utils/filter-help-functions';
import { MatchingStatus } from '../../enums/matching-status';
import { AuthService } from '../../../shared/auth.service';
import { DatePipe } from '@angular/common';
import { BusinessService } from '../../../companies/models/business-service';
import { Person } from '../../../persons/models/person.model';
import { DecisionType } from '../../enums/decision-type';
import { ApprovalStatus } from '../../../persons/enums/approval-status';

@Component({
  selector: 'app-screening-results-table',
  templateUrl: './screening-results-table.component.html',
  styleUrls: ['./screening-results-table.component.scss']
})
export class ScreeningResultsTableComponent implements OnInit, OnDestroy {
  componentActive = true;
  dataSource: GridDataResult;
  dataLoading = false;
  filter: CompositeFilterDescriptor;
  state: FilterDefinition;
  selectedMatchingStatus: MatchingStatus;
  selectedEntityType: number;
  public availableSubscriptionTypes: Array<BusinessService> = new Array<BusinessService>();

  matchingStatuses = [
    { value: MatchingStatus.PossibleMatch,
      label: MatchingStatus.PossibleMatch },
    { value: MatchingStatus.ReviewedBlocked,
      label: MatchingStatus.ReviewedBlocked },
    { value: MatchingStatus.ReviewedNoActionNeeded,
      label: MatchingStatus.ReviewedNoActionNeeded }
  ];
  entityTypes = [
    { value: ScreeningEntityType.Person, label: ScreeningEntityType[ScreeningEntityType.Person.toString()] },
    { value: ScreeningEntityType.Company, label: ScreeningEntityType[ScreeningEntityType.Company.toString()] }
  ];

  @Input()
  person: Person = {} as Person;

  @Input()
  companyId: string;

  @Input()
  companyName: string;

  @Input()
  screeningFilter: string;

  @Input()
  disableActions = false;

  @Input()
  hideEntityDetails = false;

  @Input()
  hideCreatedOn = false;

  @Input()
  hideInitiateButton = true;

  @Input()
  sortBy: string;

  isBlockedByCompany: boolean;

  constructor(private dialog: MatDialog,
    private layoutService: LayoutService,
    private screeningService: ScreeningService,
    private authService: AuthService,
    private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.state = {
      skip: 0,
      take: 10,
      sort: this.sortBy ? [{ field: this.sortBy, dir: 'desc' }] : [],
      filter: {
        filters: [],
        logic: 'and'
      }
    };

    this.screeningService.startConnection();
    this.getScreeningResult();
    this.screeningStateUpdate();
  }

  ngOnDestroy() {
    this.componentActive = false;
    this.screeningService.endConnection();
  }

  public dataStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.getScreeningResult();
  }

  onViewReport(screeningResult: ScreeningResult) {
    const reportDialog = this.dialog.open(OfacReportViewModalComponent, {
      width: 'auto',
      height: 'auto',
      maxHeight: '810px',
      minWidth: '70%',
      disableClose: true,
      data: {
        screeningResult,
        disableActions: this.disableActions
      }
    });

    reportDialog?.afterClosed().pipe(take(1)).subscribe(() => this.getScreeningResult());
  }

  onSelectionStatusChange() {
    const filters = flattenFilter(this.state.filter.filters);
    const isfilterPresent: boolean = filters?.some(f => f.field === 'matchingStatus');

    if (!isfilterPresent) {
      this.state.filter.filters.push({ field: 'matchingStatus', operator: 'eq', value: this.selectedMatchingStatus });
    } else {
      updateFilterForField(this.state.filter.filters, 'matchingStatus', this.selectedMatchingStatus);
    }

    this.getScreeningResult();
  }

  onSelectionEntityTypeChange() {
    const filters = flattenFilter(this.state.filter.filters);
    const isfilterPresent: boolean = filters?.some(f => f.field === 'type');

    if (!isfilterPresent) {
      this.state.filter.filters.push({ field: 'type', operator: 'eq', value: this.selectedEntityType });
    } else {
      updateFilterForField(this.state.filter.filters, 'type', this.selectedEntityType);
    }

    this.getScreeningResult();
  }

  private getScreeningResult() {
    this.dataLoading = true;
    this.screeningService.queryScreeningResults(this.screeningFilter, this.state)
      .pipe(
        take(1),
        finalize(() => this.dataLoading = false),
      ).subscribe(
        pagination => {
          const data = pagination?.items?.map(data => this.formatFields(data)) ?? [];
          this.isBlockedByCompany = this.person?.approvalStatus === ApprovalStatus.Blocked
            && !data.some(x => x.decisionType === DecisionType.Approve);
          this.dataSource = {
            data,
            total: pagination?.totalRecordCount ?? 0
          };
        },
        () => this.layoutService.showUIMessage('Unable to get Screening results list.'));
  }

  private formatFields(data: ScreeningResult) {
    return {
      ...data,
      formattedType: ScreeningEntityType[data.type.toString()],
      createdOn: data.createdOn ? new Date(data.createdOn) : null,
      reviewedOn: data.reviewedOn ? new Date(data.reviewedOn) : null
    };
  }

  private createScreeningName() {
    const formattedDate = this.datePipe.transform(new Date(), 'MM/dd/yyyy HH:mm');
    return this.person?.lastName ?
      `${this.person?.lastName}_${this.person?.firstName}_${formattedDate}` :
      `${this.companyName}_${formattedDate}`;
  }

  onInitiateScreening() {
    this.dataLoading = true;

    const screeningRequest = {
      initiatedBy: this.authService.userName,
      subscriptionsTypeName: this.person?.lastName ? `${this.person?.lastName}_${this.person?.firstName}` : this.companyName,
      screeningName: this.createScreeningName(),
      subscriptions: [],
      type: ProcessType.OFACScreening,
      filter: {
        companyids: this.companyId ? [this.companyId] : [],
        personids: this.person.id ? [this.person.id] : [],
      }
    };

    this.screeningService.initiate(screeningRequest)
      .pipe(take(1))
      .subscribe(
        () => this.layoutService.showUIMessage('Screening Initiated'),
        () => this.layoutService.showUIMessage('Error: Please try initiating screening again in some time.')
      );
  }

  screeningStateUpdate() {
    this.screeningService.dataReceived.pipe(takeWhile(() => this.componentActive)).subscribe((result: any) => {
      this.getScreeningResult();
      if (result.state === BulkProcessState.Failed) {
        this.layoutService.showUIMessage('Error: Screening failed, please try again.');
      } else if (result.state === BulkProcessState.Executing) {
        this.layoutService.showUIMessage('Screening currently in progress.');
      } else {
        this.layoutService.showUIMessage('Screening completed.');
      }
    });
  }
}
