import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { encodeBase64 } from '@progress/kendo-file-saver';
import * as cloneDeep from 'lodash.cloneDeep';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProgramEnrollmentData } from '../../program-enrollments/models/program-enrollment.model';
import { BaseHttpService } from '../../shared/services/base-http.service';
import { IncentiveProgramErrorResponse } from '../models/Incentive-program-error';
import { IncentiveProgram, IncentiveProgramValidationDataViewModel } from '../models/incentive-program';

@Injectable({
  providedIn: 'root'
})
export class IncentiveProgramsService extends BaseHttpService<IncentiveProgram>{

  constructor(
    private http: HttpClient) {
    super(http);
    this.apiDomain = environment.membershipApiUrl;
    this.endpoint = 'v1/programs';
  }

  getEnrollmentsByIncentiveProgID(id: string): Observable<ProgramEnrollmentData[]> {
    return this.http.get<ProgramEnrollmentData[]>(`${this.apiDomain}/api/${this.endpoint}/${id}/enrollments`);
  }

  save(incentiveProgram: IncentiveProgram) {
    const incentiveProgramRequest = this.encodeTextFields(incentiveProgram);
    return super.save(incentiveProgramRequest);
  }

  update(id: string, incentiveProgram: IncentiveProgram) {
    const incentiveProgramRequest = this.encodeTextFields(incentiveProgram);
    return super.update(id, incentiveProgramRequest);
  }

  validate(id: string, incentiveProgramFormData: IncentiveProgramValidationDataViewModel) {
    return this.http.post(`${this.apiDomain}/api/${this.endpoint}/${id}/validate`, incentiveProgramFormData);
  }

  deactivate(id: string, comment: string) {
    const deactivateRequest = {
      ReasonToDeactivate: encodeBase64(comment)
    };
    return this.http.put(`${this.apiDomain}/api/${this.endpoint}/${id}/deactivate`, deactivateRequest);
  }

  private encodeTextFields(incentiveProgram: IncentiveProgram): IncentiveProgram {
    const incentiveProgramCopy: IncentiveProgram = cloneDeep(incentiveProgram);

    incentiveProgramCopy.name = encodeBase64(incentiveProgramCopy.name);
    incentiveProgramCopy.description = encodeBase64(incentiveProgramCopy.description);
    incentiveProgramCopy.accountNumber = encodeBase64(incentiveProgramCopy.accountNumber);

    return incentiveProgramCopy;
  }

  validateReport(): Observable<IncentiveProgramErrorResponse> {
    return this.http.post<IncentiveProgramErrorResponse>(`${this.apiDomain}/api/${this.endpoint}/validatereport`, {});
  }
}
