import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MarketplaceServicePermission } from '../../../models/marketplace-service-permission';
import { Person } from '../../../models/person.model';
import { partnerAffiliations } from '../marketplace-permission-constants';
import { BusinessServiceType } from '../../../../shared/enums/business-service-type';
import { MultiplePartnerAffiliationsComponent } from '../../../../shared/components/multiple-partner-affiliation/multiple-partner-affiliations.component';
import { MarketplacePartnerServicePermission } from '../../../models/marketplace-partner-service-permission';

@Component({
  selector: 'app-partner-affiliations',
  templateUrl: './partner-affiliations.component.html'
})
export class PartnerAffiliationsComponent
  extends MultiplePartnerAffiliationsComponent<MarketplacePartnerServicePermission>
  implements OnInit {
  @Input()
  parentForm: UntypedFormGroup;
  @Input()
  partnerPermissionsFormArray: UntypedFormArray;
  @Input()
  person: Person;
  @Output()
  submit = new EventEmitter<void>();

  marketplaceServicePermission: MarketplaceServicePermission;

  constructor(private formBuilder: UntypedFormBuilder) { super(); }

  ngOnInit() {
    this.marketplaceServicePermission = this.getMarketplaceServicePermission(this.person);
    this.affiliationTypes = partnerAffiliations;
    super.ngOnInit();
  }

  onSubmit() {
    this.submit.emit();
  }

  protected loadPartnerAffiliationForms(): boolean {
    let permissionsLoaded = false;
    this.marketplaceServicePermission.partnerPermissions.forEach(affiliation => {
      this.addNewAffiliationForm(affiliation);
      permissionsLoaded = true;
    });

    return permissionsLoaded;
  }

  protected buildPartnerFormGroup(partnerAffiliation?: MarketplacePartnerServicePermission): UntypedFormGroup {
    const initialAffiliationValue = partnerAffiliation?.affiliationType;
    const affiliationTypeFormControl = this.formBuilder.control(initialAffiliationValue);
    this.affiliationTypeFormControls.push(affiliationTypeFormControl);
    this.updateAvailableAffiliations();
    this.listenOnAffiliationTypeChanges(affiliationTypeFormControl);

    return this.formBuilder.group({
      affiliationType: affiliationTypeFormControl,
      partnerData: this.formBuilder.array([])
    });
  }

  private getMarketplaceServicePermission(person: Person): MarketplaceServicePermission {
    return person.servicePermissions.find(x => x.serviceType === BusinessServiceType.Marketplace) as MarketplaceServicePermission;
  }
}
