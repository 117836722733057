<section class="confirmation">

  <h2 mat-dailog-title class="text-center">
    Link Person to company
  </h2>
  <form *ngIf="companyLinkingForm" [formGroup]="companyLinkingForm">
    <mat-form-field class="py-3">
      <input 
        matInput 
        type="text" 
        placeholder="Person email" 
        formControlName="personEmail">
      <mat-error 
        *ngIf="hasErrorOfType('personEmail', 'required') || hasErrorOfType('personEmail', 'email') 
              || hasErrorOfType('personEmail', 'maxlength')">
        Enter a valid email address.
      </mat-error>
      <mat-error 
        *ngIf="hasErrorOfType('personEmail', 'notFound')">
        Person with specified email does not exists on platform. Please enter different one.
      </mat-error>
    </mat-form-field>
  </form>
  <div class="btn-group my-3" role="group">
    <button 
      mat-button 
      [mat-dialog-close]="companyLinkingForm.invalid ? '' : personEmailCtrl.value" 
      [disabled]="companyLinkingForm.invalid || inProgress">Submit</button>
    <button mat-button mat-dialog-close>Cancel</button>
  </div>

</section>