<section>
  <h2> Company User roles </h2>

  <mat-card>
    <mat-card-content>
      <mat-checkbox 
        *ngFor="let role of roles"
        [disabled]="disableOwnRoles"
        [checked]="hasRole(role.id)" 
        (change)="onSelected($event.source.checked, role)"
        class="mr-3">
        {{role.name }}
      </mat-checkbox>
    </mat-card-content>

  </mat-card>
</section>