export class PdfUploadData {
  title?: string;
  isValidFileType?: any;
  isValidFileName?: any;
  isValidFileSize?: any;
  maximumAllowedSizeString?: string;
  notAllowedCharacters?: string;

  constructor(
    title: string = null,
    isValidFileType: any = null,
    isValidFileName: any = null,
    isValidFileSize: any = null,
    maximumAllowedSizeString: string = null,
    notAllowedCharacters: string = null
  ) {
    this.title = title;
    this.isValidFileType = isValidFileType;
    this.isValidFileName = isValidFileName;
    this.isValidFileSize = isValidFileSize;
    this.maximumAllowedSizeString = maximumAllowedSizeString;
    this.notAllowedCharacters = notAllowedCharacters;
  }
};
