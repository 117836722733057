export interface IncentiveProgramError {
  incentiveProgramName: string;
  companyId: string;
  companyName: string;
  errorCode: string;
}

export interface IncentiveProgramErrorResponse {
  errors: IncentiveProgramError[];
  hasErrors: boolean;
}

export const MISSING_PAYEE_CODE = 'MISSING_PAYEE_CODE';
