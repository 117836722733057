import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-simple-pagination',
  templateUrl: './simple-pagination.component.html',
  styleUrls: ['./simple-pagination.component.scss']
})
export class SimplePaginationComponent {
  @Input() hasMore!: boolean;
  @Output() pageChangedEvent = new EventEmitter<number>();

  public currentPage = 0;

  reset() {
    this.currentPage = 0;
    this.pageChangedEvent.emit(this.currentPage);
  }

  goToPreviousPage() {
    if(this.currentPage > 0) {
      this.currentPage--;
    }
    this.pageChangedEvent.emit(this.currentPage);
  }

  goToNextPage() {
    this.currentPage++;
    this.pageChangedEvent.emit(this.currentPage);
  }
}
