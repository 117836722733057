<section class="confirmation">

  <h2 mat-dailog-title class="text-center">
    Invite new Person for this company
  </h2>
  <form *ngIf="invitePersonForm" [formGroup]="invitePersonForm">
    <mat-form-field class="py-3">
      <input 
        matInput 
        type="text" 
        placeholder="Email of inviting person" 
        formControlName="personEmail">
      <mat-error 
        *ngIf="hasErrorOfType('personEmail', 'required') || hasErrorOfType('personEmail', 'email') 
              || hasErrorOfType('personEmail', 'maxlength')">
        Enter a valid email address.
      </mat-error>
      <mat-error 
        *ngIf="hasErrorOfType('personEmail', 'emailInUse')">
        User with entered email already exists on platform. Enter different one.
      </mat-error>
    </mat-form-field>
  </form>
  <div class="btn-group my-3" role="group">
    <button 
      mat-button 
      [mat-dialog-close]="invitePersonForm.invalid ? '' : personEmailCtrl.value" 
      [disabled]="invitePersonForm.invalid || inProgress">Invite</button>
    <button mat-button mat-dialog-close>Cancel</button>
  </div>

</section>