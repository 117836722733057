import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';

import { PasswordUpdate } from '../../models/password-update.model';
import { PasswordRegex } from '../../../utils/regex';
import { AuthService } from '../../../shared/auth.service';

@Component({
  selector: 'app-person-update-password',
  templateUrl: './person-update-password.component.html',
  styleUrls: ['./person-update-password.component.scss']
})
export class PersonUpdatePasswordComponent implements OnInit, OnChanges {

  @Input() generatedPassword: string;
  @Input() userHasEmail: boolean;
  @Output() generatePasswordEvent = new EventEmitter();
  @Output() updatePasswordEvent = new EventEmitter<PasswordUpdate>();

  get newPasswordCtrl() { return this.passwordForm.get('newPassword'); }

  public passwordForm: UntypedFormGroup;
  public passwordValue: string;
  public isViewOnly = false;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.isViewOnly = this.authService.isViewOnly;
    this.createForm();
  }

  ngOnChanges() {
    if (this.generatedPassword) {
      this.newPasswordCtrl.setValue(this.generatedPassword);
    }
  }

  /**
   * Initializes FormGroup instance.
   */
  public createForm() {
    this.passwordForm = this.fb.group({
      newPassword: new UntypedFormControl('', [Validators.required, Validators.pattern(PasswordRegex)])
    });
  }

  /**
   * Generates password automatically.
   */
  public generatePassword() {
    this.generatePasswordEvent.emit();
  }

  /**
   * Submit form for updating password.
   *
   * @param sendEmail Should a new password be sent to an User's email or not.
   */
  public onSubmit(sendEmail: boolean) {
    this.passwordForm.invalid
      ? this.newPasswordCtrl.markAsTouched()
      : this.updatePasswordEvent.emit({ newPassword: this.newPasswordCtrl.value, sendEmail } as PasswordUpdate);
  }

  /**
   * Return true | false depending if there is an error for passed control.
   *
   * @param control Instance of AbstractControl.
   */
  public hasError(control: AbstractControl): boolean {
    return control && (control.touched || control.dirty) && control.invalid;
  }
}
