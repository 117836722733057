export enum RegistrationSource {
  Zing = 0 ,
  IShipJM = 1,
  JewelersMutual = 2,
  GemAndJewel = 3,
  BulkRegistration = 4,
  ConsumerApp = 5,
  Luxsurance = 6,
  PlPortal = 7
}
