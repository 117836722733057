import { SubscriptionKeyInterceptor } from './subscription-key.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContentTypeJsonInterceptor } from './content-type.interceptor';
import { AuthorizationInterceptor } from './authorization.interceptor';
import { ForbiddenInterceptor } from './forbidden.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ContentTypeJsonInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SubscriptionKeyInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ForbiddenInterceptor, multi: true }
];
