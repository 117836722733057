<mat-card *ngIf="location">

  <mat-card-header>
    <mat-card-title>{{ location.locationName }}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-checkbox 
      *ngFor="let role of roles" 
      [disabled]="disableOwnRoles"
      [checked]="hasRole(role.id, location.locationID)" 
      (change)="onSelected($event.source.checked, role, location.locationID)">
      {{role.name }}
    </mat-checkbox>
  </mat-card-content>
  <mat-error *ngIf="showErrorMessage">
    A user cannot have JM Care Plan Repair and JM Care Plan Jeweler roles for the same location.
  </mat-error>

</mat-card>
<app-in-progress [loading]="isLoading"> </app-in-progress>