import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-api-status-details',
  template: `
    <div class="details" *ngIf="data">
      <div class="text-center p-4">
        <img *ngIf="data.status === 'Healthy'" src="assets/images/JMicons/JM-icon-17.png" alt="icon">
        <img *ngIf="data.status !== 'Healthy'" src="assets/images/JMicons/JM-icon-18.png" alt="icon">
      </div>
      <h3 class="text-center">{{ data.name }}</h3>
      <table class="table table-bordered m-0">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Healthy</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let service of data.details.checks">
            <td>{{ service.name }}</td>
            <td>{{ service.isHealthy }}</td>
          </tr>
        </tbody>
      </table>
  </div>`
})
export class ApiStatusDetailComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}
