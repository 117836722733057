import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AccountService } from '../../../companies/services/account.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { AccountUserSettings } from '../../models/commercial-permissions.model';
import { LayoutService } from '../../../layouts/layout.service';

@Component({
  selector: 'app-commercial-lines-permission',
  templateUrl: './commercial-lines-permission.component.html',
  styleUrls: ['./commercial-lines-permission.component.scss']
})
export class CommercialLinesPermissionComponent implements OnInit {

  permissionsForm: UntypedFormGroup;
  accountUserSettings = new AccountUserSettings();
  userId: string;
  isLoading = false;
  hasAccountUserSettings = false;
  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.userId = this.route.snapshot.params['id'];
    this.buildPermissionsForm();
    this.loadAccountUserSettings();
  }

  buildPermissionsForm() {
    this.permissionsForm = this.fb.group({
      allowed: this.fb.control(true),
    });
  }

  loadAccountUserSettings() {
    this.isLoading = true;
    this.accountService.getAccountUserSettings(this.userId)
      .pipe(take(1))
      .subscribe(accountUserSettings => {
        this.accountUserSettings = accountUserSettings;
        this.permissionsForm.get('allowed').setValue(this.accountUserSettings.canAccessAccounts);
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.layoutService.showUIMessage('Unable to get the data for Business Insurance permissions. Try reloading this page.');
      });
  }

  onSubmit() {
    this.isLoading = true;
    this.accountUserSettings.canAccessAccounts = this.permissionsForm.get('allowed').value;
    if (this.accountUserSettings.id !== '00000000-0000-0000-0000-000000000000') {
      this.accountService.updateAccountUserSettings(this.accountUserSettings).pipe(take(1)).
        subscribe(res => {
          this.isLoading = false;
          this.layoutService.showUIMessage('Business Insurance permissions data updated.');
        },
        err => {
          this.isLoading = false;
          this.layoutService.showUIMessage('Unable to update the data for Business Insurance permissions.');
        });
    } else {
      this.accountService.saveAccountUserSettings(this.accountUserSettings).pipe(take(1)).
        subscribe(res => {
          this.loadAccountUserSettings();
          this.isLoading = false;
          this.layoutService.showUIMessage('Business Insurance permissions data saved.');
        },
        err => {
          this.isLoading = false;
          this.layoutService.showUIMessage('Unable to save the data for Business Insurance permissions.');
        });
    }
  }

  hidePopover(e) {
    const popover = document.getElementById('view-cl-info');
    if (popover.hasAttribute('aria-describedby') && popover !== e.target) {
      popover.click();
    }
  }
}
