import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { UserRole } from '../../../security/components/user-role/shared/user-role.model';
import { PersonRole } from '../../models/person-role.model';
import { PersonUserRole } from '../../models/person-user-role.model';
import { PersonService } from '../../services/person.service';
import { Subscription } from 'rxjs';
import { Person } from '../../models/person.model';

@Component({
  selector: 'app-company-roles',
  templateUrl: './company-roles.component.html',
  styleUrls: ['./company-roles.component.scss']
})
export class CompanyRolesComponent implements OnInit, OnDestroy {
  /**
   * Person instance
   */
  @Input() person: Person;

  /**
   * Roles of the specific person instance.
   */
  @Input() personRoles: Array<PersonRole>;

  /**
   * Platform specific User Roles.
   */
  @Input() roles: Array<UserRole> = new Array<UserRole>();

  @Input() disableOwnRoles: boolean;

  @Output() roleSelected = new EventEmitter<boolean>();

  private locationRoleUpdateSubscription: Subscription;

  constructor(private personService: PersonService) {
  }

  ngOnInit() {
    this.locationRoleUpdateSubscription = this.personService.locationRoleUpdateAnnounced.subscribe(personRole => {
      this.evalLocationRoleUpdate(personRole);
    });
  }

  ngOnDestroy() {
    this.locationRoleUpdateSubscription.unsubscribe();
  }

  private evalLocationRoleUpdate(personRole: PersonRole) {
    if (personRole != null) {
      const userRoles = personRole.userRoles
        .filter(userrole => this.roles.findIndex(r => r.code === userrole.code) !== -1);

      if (userRoles != null && userRoles.length > 0) {
        const companySpecificRole = this.personRoles.find(pr => pr.companyId === this.person.companyId && pr.locationId === null);

        userRoles.forEach(userRole => {
          if (this.hasRole(userRole.id)) {
            companySpecificRole.userRoles = companySpecificRole.userRoles.filter(r => r.id !== userRole.id);
          }
        });
      }
    }
  }

  /**
   * Check if user has specific role.
   *
   * @param roleId Role unique identifier.
   */
  public hasRole(roleId: string): boolean {
    const companySpecificRole = this.personRoles.find(pr => pr.companyId === this.person.companyId  && pr.locationId === null);
    return companySpecificRole
      ? companySpecificRole.userRoles.filter(role => role.id === roleId).length > 0
      : false;
  }

  /**
   * On selected event for the Location roles selections.
   *
   * @param checked If checkbox is selected or not.
   * @param role UserRole instance.
   * @param locationID Id of the location.
   */
  public onSelected(checked: boolean, role: UserRole) {
    this.roleSelected.emit(true);
    const personUserRoleInstance: PersonUserRole = {
      id: role.id,
      code: role.code
    } as PersonUserRole;
    let userRoleInstance: PersonRole = null;

    const companySpecificRole = this.personRoles.find(pr => pr.companyId === this.person.companyId && pr.locationId === null);
    if (companySpecificRole) {
      /* array.findIndex must be used here since we are working with array of objects. */
      const indexOfSpecificRole = this.personRoles.findIndex(pr => pr.companyId === this.person.companyId && pr.locationId === null);
      this.personRoles[indexOfSpecificRole].companyId = this.person.companyId;

      /* If checked, add that role to the array, otherwise, remove it. */
      checked
        ? this.personRoles[indexOfSpecificRole].userRoles.push(personUserRoleInstance)
        : this.personRoles[indexOfSpecificRole].userRoles = this.personRoles[indexOfSpecificRole].userRoles.filter(r => r.id !== role.id);

      this.personService.AnnounceCompanyRoleUpdate(this.personRoles[indexOfSpecificRole]);
    } else {
      userRoleInstance = {
        companyId: this.person.companyId,
        locationId: null,
        roleIds: [],
        userRoles: [personUserRoleInstance]
      };
      this.personRoles.push(userRoleInstance);
      this.personService.AnnounceCompanyRoleUpdate(userRoleInstance);
    }
  }
}
