import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { take, finalize } from 'rxjs/operators';
import { LayoutService } from '../../../layouts/layout.service';
import { PersonService } from '../../services/person.service';
import { Person } from '../../models/person.model';
import { BusinessServiceType } from '../../../shared/enums/business-service-type';

@Component({
  selector: 'app-jeweler-pages-permission',
  templateUrl: './jeweler-pages-permission.component.html',
  styleUrls: ['./jeweler-pages-permission.component.scss']
})

export class JewelerPagesPermissionComponent implements OnInit {

  @Input() person: Person;
  jewelerPagesForm: UntypedFormGroup;
  isLoading = false;
  formIsDirty = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private personService: PersonService,
    private layoutService: LayoutService,
    private route: ActivatedRoute
  ) { }

  get jewelerPagesPermission() {
    return this.person.servicePermissions.find(x => x.serviceType === BusinessServiceType.JewelerPages);
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.jewelerPagesForm = this.formBuilder.group({
      allowed: this.formBuilder.control(this.jewelerPagesPermission?.allowed),
    });
  }

  onSubmit() {
    this.isLoading = true;
    if (this.jewelerPagesPermission) {
      this.jewelerPagesPermission.allowed = this.jewelerPagesForm.get('allowed')?.value;
    } else {
      this.person.servicePermissions.push({serviceType: BusinessServiceType.JewelerPages,
        allowed: this.jewelerPagesForm.get('allowed')?.value});
    }
    this.formIsDirty = false;
    this.updatePersonPermissions();
  }

  private updatePersonPermissions() {
    this.personService.update(this.route.snapshot.params['id'], this.person)
      .pipe(take(1), finalize(() => { this.isLoading = false; }))
      .subscribe(res => {
        this.layoutService.showUIMessage('Jeweler Pages permission data updated.');
      },
      err => {
        this.layoutService.showUIMessage('Unable to update the data for Jeweler Pages permission.');
      });
  }

  hidePopover(event: Event) {
    const popover = document.getElementById('view-pl-info');
    if (popover?.hasAttribute('aria-describedby') && popover !== event.target) {
      popover.click();
    }
  }

  setFormToDirty() {
    this.formIsDirty = true;
  }
}
