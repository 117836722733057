import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { BusinessServiceType } from '../../../shared/enums/business-service-type';
import * as moment from 'moment';
import { dateRangeGreaterThanAYear, endDateLessThanStartDate } from '../../../shared/utils/date-range-validator';
import { ReportSettings } from '../../../shared/models/report-settings.model';
import { environment } from '../../../../environments/environment';
import { LayoutService } from '../../../layouts/layout.service';
import { FeatureName } from '../../../shared/enums/feature-name';
import { AppConfigurationService } from '../../../shared/services/app-configuration.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-generate-report-dialog',
  templateUrl: './generate-report-dialog.component.html',
  styleUrls: ['./generate-report-dialog.component.scss']
})
export class GenerateReportDialogComponent implements OnInit {

  public reportForm: UntypedFormGroup;
  public reportType: string;
  public displayTestDataOptions: boolean;
  public maxDate = new Date();
  public testOptionsLabel = '';
  public buttonLabel: string;

  jewelerPagesEnabled$ = this.appConfigurationService.getFeatureFlag(FeatureName.ZingJewelerPagesActive);

  availableSubscriptionTypes = [
    {
      key: BusinessServiceType.CarePlan,
      displayText: 'Care Plan'
    },
    {
      key: BusinessServiceType.Shipping,
      displayText: 'Shipping'
    },
    {
      key: BusinessServiceType.CommercialInsurance,
      displayText: 'Business Insurance'
    },
    {
      key: BusinessServiceType.PersonalInsurance,
      displayText: 'Personal Insurance'
    },
    {
      key: BusinessServiceType.PartnerGateway,
      displayText: 'Partner Gateway'
    },
    {
      key: BusinessServiceType.Appraisal,
      displayText: 'Appraisal'
    },
    {
      key: BusinessServiceType.Marketplace,
      displayText: 'Diamond Marketplace'
    },
    {
      key: BusinessServiceType.PointOfSaleIntegration,
      displayText: 'Point Of Sale Integration'
    },
    {
      key: BusinessServiceType.Custom,
      displayText: 'Custom'
    },
    {
      key: BusinessServiceType.Memo,
      displayText: 'Memo'
    },
    {
      key: BusinessServiceType.Studio,
      displayText: 'Studio'
    },
    {
      key: BusinessServiceType.JewelerPages,
      displayText: 'Jeweler Pages'
    },
    {
      key: BusinessServiceType.Auction,
      displayText: 'Auction'
    }
  ];

  close = () => this.dialogRef.close();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<GenerateReportDialogComponent>,
    private layoutService: LayoutService,
    private appConfigurationService: AppConfigurationService
  ) {
    this.reportType = data.reportType;
    this.displayTestDataOptions = data.displayTestDataOptions;
    this.testOptionsLabel = data.testOptionsLabel;
    this.buttonLabel = data.buttonLabel;
  }

  ngOnInit() {
    this.jewelerPagesEnabled$.pipe(take(1)).subscribe(flagEnabled => {
      this.availableSubscriptionTypes = flagEnabled ?
        this.availableSubscriptionTypes : this.availableSubscriptionTypes.filter(service => service.key !== BusinessServiceType.JewelerPages);
    });

    this.createForm();
  }

  createForm(): void {
    this.reportForm = this.fb.group(
      {
        startDate: new UntypedFormControl(
          {
            value: moment().endOf('day').subtract(7, 'days').utc(true).toDate(),
            disabled: true
          },
          Validators.required
        ),
        endDate: new UntypedFormControl(
          {
            value: moment().endOf('day').utc(true).toDate(),
            disabled: true
          },
          Validators.required
        ),
        subscriptionTypes: this.fb.control(null),
        includeTestData: this.fb.control(false)
      },
      {
        validator: [endDateLessThanStartDate, dateRangeGreaterThanAYear]
      }
    );
  }

  /**
   * Handle report form submission and try to make a request to the server to generate report
   */
  downloadReport(form: UntypedFormGroup) {
    const reportSettings = this.extractFormData(form);
    this.dialogRef.close(reportSettings);
    this.layoutService.showUIMessage('Report generation started.');
  }

  extractFormData(form: UntypedFormGroup): ReportSettings {
    const result: ReportSettings = {
      startDate: moment(form.controls['startDate'].value).toDate(),
      endDate: moment(form.controls['endDate'].value).endOf('day').utc(true).toDate(),
      queryModel: { filter: btoa(this.getFilter(form) ?? ''), orderBy: '', top: 0, skip: 0 },
      context: { includeTestData: form.controls['includeTestData'].value }
    };

    return result;
  }

  /**
   * Generate filter for subscriptions
   *
   * @param form form group
   * @returns filter
   */
  getFilter(form: UntypedFormGroup) {
    const types = form.controls['subscriptionTypes'].value;
    let filter: any;
    if (types && types !== '') {
      const queries = types.map(el => `Subscription eq ${el}`);
      filter = queries.join(' or ');
    }
    return filter;
  }
}
