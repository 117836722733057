<mat-toolbar class="portal-toolbar mat-elevation-z6" [ngClass]="{ 'portal-transparent-toolbar': transparent }">
  <mat-toolbar-row>
    <button *ngIf="showMenuIcon()" mat-icon-button (click)="leftSidenav.open()">
      <mat-icon>menu</mat-icon>
    </button>
    <span portalHeaderTitle class="bold title-ellipsis"></span>
    <span fxFlex></span>
    <portal-theme-switcher-menu fxHide fxShow.gt-sm></portal-theme-switcher-menu>
    <portal-layout-switcher-menu fxHide fxShow.gt-sm></portal-layout-switcher-menu>
    <portal-user-menu></portal-user-menu>
  </mat-toolbar-row>
</mat-toolbar>
