import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CaseMatch, ScreeningEntityType, ScreeningResult } from '../../models/screening-process';
import { ScreeningService } from '../../services/screening.service';
import { take } from 'rxjs/operators';
import { DecisionType } from '../../enums/decision-type';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ofac-report-view-modal',
  templateUrl: './ofac-report-view-modal.component.html'
})
export class OfacReportViewModalComponent implements OnInit {
  public DecisionType = DecisionType;
  public providerResponse: CaseMatch[];
  public reasonToDecision: string;
  public hideActions: boolean;

  get isEntityBlocked() {
    return this.data.screeningResult.decisionType === DecisionType.Approve;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      screeningResult: ScreeningResult;
      disableActions: boolean;
    },
    public dialogRef: MatDialogRef<OfacReportViewModalComponent>,
    private screeningService: ScreeningService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.providerResponse = this.data.screeningResult.providerResponse.sort((a, b) => b.score - a.score);
    this.hideActions = this.data.disableActions || this.isEntityBlocked;
  }

  getTabTitle(entity: CaseMatch) {
    return `${entity.fullName} - ${entity.score}`;
  }

  onDecision(decision: DecisionType) {
    this.screeningService.updateScreeningDecision({
      id: this.data.screeningResult.id,
      decisionType: decision,
      reasonToDecision: btoa(this.reasonToDecision)
    }).pipe(take(1))
      .subscribe(
        () => {
          this.dialogRef.close();
        }
      );
  }

  onViewProfile(){
    const path = this.data.screeningResult.type === ScreeningEntityType.Person ? 'persons/details' : 'companies/detail';
    this.router.navigate([path,this.data.screeningResult.entityId]);
  }
}
