import { Injectable } from '@angular/core';
import { BaseHttpService } from '../../shared/services/base-http.service';
import { BusinessService } from '../models/business-service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '../../shared/models/paginated.response';
import { PointOfSale } from '../models/point-of-sale';

@Injectable()
export class BusinessServicesService extends BaseHttpService<BusinessService> {

  constructor(
    private http: HttpClient
  ) {
    super(http);
    this.apiDomain = environment.membershipApiUrl;
    this.endpoint = 'BusinessServices';
  }

  getAll(): Observable<PaginatedResponse<BusinessService>> {
    return super.query();
  }

  /**
   * Return all available Point of sale types.
   */
  getPointOfSaleTypes(): Observable<PaginatedResponse<PointOfSale>> {
    const pointOfSaleEndpoint = 'api/pointofsale/search';
    return this.http.post<PaginatedResponse<PointOfSale>>(`${this.apiDomain}/${pointOfSaleEndpoint}`, {});
  }
}
