import { Pipe, PipeTransform } from '@angular/core';

const masks = {
  '+1': '(000) 000-0000'
};

// first 10 digits are required with optional up to 5 more digits.
const defaultMask = '000000000099999';

@Pipe({ name: 'phonemask' })
export class PhoneMaskPipe implements PipeTransform {

  transform(countryCode: string) {
    return masks[countryCode] || defaultMask;
  }

}
