import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportDetails, ReportPeriod, ReportType } from '../../models/report-request.model';

@Component({
  selector: 'app-report-summary-card',
  templateUrl: './report-summary-card.component.html'
})
export class ReportSummaryCardComponent {

  @Input()
  reportDetails: ReportDetails;

  @Input()
  showGenerateReport: boolean;

  @Input() oldReport = false;

  @Input() enableReportGeneration = true;

  @Input() reportCompletion: Map<ReportPeriod, Map<ReportType, boolean>>;

  @Output() reportGenerationInProgress = new EventEmitter<boolean>();

  onReportGenerationInProgress(event) {
    this.reportGenerationInProgress.emit(event);
  }

}
