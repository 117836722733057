import { Injectable } from '@angular/core';

@Injectable()
export class PhoneNumberService {

  constructor() { }

  getPhoneNumber(phoneNumber: string): any {
    if (phoneNumber) {
      if (phoneNumber.includes('-')) {
        return phoneNumber.split('-')[1];
      } else if (phoneNumber.startsWith('+')) {
        return phoneNumber.substr(2);
      } else {
        return phoneNumber.substr(1);
      }
    }
    return '';
  }
  getCountryCode(phoneNumber: string): any {
    if (phoneNumber) {
      if (phoneNumber.includes('-')) {
        return phoneNumber.split('-')[0];
      } else if (phoneNumber.startsWith('+')) {
        return phoneNumber.substr(0, 2);
      } else {
        return `+${phoneNumber.substr(0, 1)}`;
      }
    }
    return '+1';
  }
  formatPhoneNumber(phoneNumber: string, countryCode: string): any {
    if (phoneNumber) {
      if (countryCode === '+1' && phoneNumber.length >= 10) {
        const areaCode = phoneNumber.substr(0, 3);
        const firstPart = phoneNumber.substr(3, 3);
        const rest = phoneNumber.substr(6);

        let formated = `(${areaCode}) `;

        if (firstPart) {
          formated += firstPart;
        }

        if (rest) {
          formated += `-${rest}`;
        }

        return formated;
      } else {
        return phoneNumber;
      }
    } else {
      return phoneNumber;
    }
  }
}
