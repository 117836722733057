<div class="page-container">
    <div class="title mt-4 mb-3">
      {{ incentiveProgramName }}
    </div>
    <div class="tnc-panel-wrapper">
      <div class="tnc-panel">
        <div class="tnc-body">
          <iframe id="pdfWrapper" [src]="sanitizedUrl" frameborder="0" type="application/pdf" width="100%"
            height="100%"></iframe>
        </div>
        <a [href]="sanitizedUrl" class="download-url" download>
          Download
        </a>
      </div>
    </div>
  </div>