<div class="flex-layout commercial-lines-permissions" (click)="hidePopover($event)">
  <form [formGroup]="permissionsForm">
    <div fxLayout="row" fxLayout.xs="column">
      <div>
        <h2>Business Insurance Permissions</h2>
      </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column">
      <div fxFlex="25" class="heading-18-bold-onyx permission-cl-label" id="view-cl-label">
        View Business Insurance details
        <img src="/assets/images/JMicons/info_outline.png" id="view-cl-info" width="20" placement="bottom" [ngbPopover]="viewCLPop"
        #popover="ngbPopover">
      </div>
      <div fxFlex="50">
        <mat-checkbox formControlName="allowed">Allowed</mat-checkbox>
      </div>
    </div>
    <div class="footer">
      <mat-card-actions align="end" fxLayout="row" fxLayout.xs="column">
        <button mat-raised-button color="primary" type="button" [disabled]="permissionsForm.invalid" (click)="onSubmit()">Save</button>
      </mat-card-actions>
    </div>
  </form>
</div>
<hr class="divider" />
<app-in-progress [loading]="isLoading"> </app-in-progress>

<ng-template #viewCLPop>
  <div class="row info-wrap" id="viewCLPop">
    <div class="col-10 info-head heading-12-onyx mb-1">
      Allowed must be selected for user to see Business Insurance information.
    </div>
    <mat-icon class="col-2 close-wrap mb-1 text-right" width="12">close</mat-icon>
  </div>
</ng-template>
