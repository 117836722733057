<form class="overflow-hidden" *ngIf="reportForm" [formGroup]="reportForm" (ngSubmit)="downloadReport(reportForm)">
  <h1 mat-dialog-title class="report-form-header">Transactions Reports:</h1>
  <mat-dialog-content class="w-100 m-0 p-0 dialogContent overflow-hidden">
    <mat-form-field>
      <mat-select formControlName="reportTypes" placeholder="Report Types" (selectionChange)="setSelectedType($event.value)" [disabled]="!isDataLoaded">
        <mat-option *ngFor="let type of reportTypes" [value]="type.key">
          {{ type.displayText }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="reportForm.get('reportTypes').hasError('required')">
        Report type is required
      </mat-error>
    </mat-form-field>
    <div class="test-options-container">
      <label>Payment cadence</label>
      <mat-radio-group class="row options" formControlName="reportPeriod" (change)="getSelectedValue($event)">
        <mat-radio-button 
        *ngFor="let selection of selections" 
        class="col-lg-3" 
        [value]="selection.key" 
        [disabled]="getDisabledRadioButton(selection.key)"
        [matTooltip]="getTooltipText(selection.key)"
        matTooltipClass="quartz-tooltip">
          {{ selection.value }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <ng-container *ngIf="reportForm.get('reportPeriod').value === ReportPeriod.Custom">
      <mat-form-field class="col-md-6 col-12 pl-0 mr-0">
        <input matInput formControlName="startDate" [matDatepicker]="startDate" [max]="reportForm.get('endDate').value" placeholder="Select Start Date" />
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="col-md-6 col-12 pl-0 mr-0">
        <input matInput formControlName="endDate" [matDatepicker]="endDate" [min]="reportForm.get('startDate').value" [max]="maxDate" placeholder="Select End Date" />
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
    </mat-form-field>
      <small class="text-danger" *ngIf="reportForm.get('endDate').errors?.invalidDateRange || reportForm.get('startDate').errors?.invalidDateRange">
          <ng-container id="endDateInvalid">Date range must be less then or equal to 6 months</ng-container>
      </small>
    </ng-container>
    <div class="d-md-flex d-block mt-3 mb-2" *ngIf="reportForm.get('reportPeriod').value === ReportPeriod.LastMonth || reportForm.get('reportPeriod').value === ReportPeriod.LastQuarter">
      <label class="col-md-6 col-12 pl-0 mr-0">Start Date: {{ startDate  | date : 'mediumDate'}}</label>
      <label class="col-md-6 col-12 pl-0 mr-0">End Date: {{ endDate | date : 'mediumDate' }}</label>
    </div>
  </mat-dialog-content>
  <div *ngIf="companiesWithoutPayeeCodeCount > 0">
    <h1 mat-dialog-title class="report-form-header">Action Required:</h1>
    <div class="mb-2">
      There are {{ companiesWithoutPayeeCodeCount }} companies that will not be included
      in the report since they are missing payee code:
    </div>
    <div *ngFor="let company of companiesWithoutPayeeCode; let even = even"
      class="d-flex justify-content-center p-2" [ngClass]="{'even-company-row': even}">
      {{ company.companyName }}
    </div>
    <mat-dialog-actions class="mb-0 mt-2" align="end">
      <button mat-raised-button type="button" color="primary" mat-dialog-close (click)="navigateToProgramEnrollments()">
        View All Companies ({{ companiesWithoutPayeeCodeCount }})
      </button>
      <button mat-raised-button type="button" color="primary" mat-dialog-close (click)="navigateToPayeeCode()">Add Payee Code</button>
    </mat-dialog-actions>
  </div>
  <mat-dialog-actions class="mb-0" align="end">
    <button mat-raised-button type="button" color="primary" mat-dialog-close>Cancel</button>
    <button mat-raised-button type="submit" color="primary" [disabled]="!reportForm.valid">
      <mat-icon>queue</mat-icon>&nbsp;&nbsp;&nbsp;{{ buttonLabel || 'Schedule Report' }}
    </button>
  </mat-dialog-actions>
</form>
<app-in-progress [loading]="areCompaniesWithoutPayeeCodeLoading"></app-in-progress>