<div class="dialog-wrapper">
  
  <!-- Header -->
  <h1 mat-dialog-title class="header">
    {{ emailMessageTemplate ? 'Email Message Preview' : 'SMS Message Preview'}}
  </h1>

  <!-- Body -->
  <mat-dialog-content class="mx-1">
    <ng-container *ngIf="emailMessageTemplate">
      <app-email-messages-content [emailMessage]="emailMessageTemplate"></app-email-messages-content>
    </ng-container>
    <ng-container *ngIf="smsMessageTemplate">
      <app-sms-messages-content [smsMessage]="smsMessageTemplate"></app-sms-messages-content>
    </ng-container>
  </mat-dialog-content>

  <!-- Footer -->
  <mat-card-actions align="end" fxLayout="row" fxLayout.xs="column" class="mx-1 my-2">
    <button mat-raised-button mat-dialog-close color="primary">Close</button>
  </mat-card-actions>
  
</div>
