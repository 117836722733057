import { Component, Input } from '@angular/core';
import { EmailMessage, ServiceType } from '../../../models/email-message';

@Component({
  selector: 'app-email-messages-content',
  templateUrl: './email-messages-content.component.html',
  styleUrls: ['./email-messages-content.component.scss']
})
export class EmailMessagesContentComponent {
  @Input() emailMessage: EmailMessage;

  getServiceName(serviceNumber: number) {
    return ServiceType[serviceNumber.toString()];
  }
}
