import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { SelectOption } from '../../models/select-option.model';

@Component({template: ''})
export abstract class MultiplePartnerAffiliationsComponent<TAffiliation> implements OnInit, OnDestroy  {
  @Input()
  partnerPermissionsFormArray: UntypedFormArray;

  isLoading = true;
  componentActive = true;
  affiliationTypes: SelectOption<number>[];
  availableAffiliations$ = new BehaviorSubject<{ value: string; key: number }[]>([]);
  affiliationTypeFormControls: UntypedFormControl[] = [];
  companyPartners: {formGroup: UntypedFormGroup; data: TAffiliation}[] = [];

  ngOnInit() {
    this.availableAffiliations$.next(this.affiliationTypes);
    this.isLoading = !(this.loadPartnerAffiliationForms() || this.addEmptyAffiliationForm());
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  protected abstract buildPartnerFormGroup(partnerAffiliation): UntypedFormGroup;
  protected abstract loadPartnerAffiliationForms(): boolean;

  addEmptyAffiliationForm() {
    this.addNewAffiliationForm();
  }

  protected addNewAffiliationForm(partnerAffiliation?): boolean {
    if (this.availableAffiliations$.value.length) {
      const partnerFormGroup = this.buildPartnerFormGroup(partnerAffiliation);
      if (partnerFormGroup) {
        this.companyPartners.push({data: partnerAffiliation, formGroup: partnerFormGroup});
        setTimeout(() => {
          this.partnerPermissionsFormArray.push(partnerFormGroup);
          this.partnerPermissionsFormArray.updateValueAndValidity();
        });
      }
    }

    return true;
  }

  protected listenOnAffiliationTypeChanges(affiliationTypeFormControl: UntypedFormControl): void {
    affiliationTypeFormControl.valueChanges
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(affiliationType => {
        this.updateAvailableAffiliations();
      });
  }

  protected updateAvailableAffiliations() {
    const selectedAffiliations = this.affiliationTypeFormControls.map(x => Number(x.value));
    const availableAffiliations = this.affiliationTypes.filter(x => !selectedAffiliations.some(a => a === x.key));
    this.availableAffiliations$.next(availableAffiliations);
  }
}
