import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';

import { timer ,  of } from 'rxjs';
import { switchMap, takeWhile, map } from 'rxjs/operators';

import { PersonService } from '../../../../persons/services/person.service';
import { FilterDefinition } from '../../../models/filter-definition';

@Component({
  selector: 'app-company-linking',
  templateUrl: './company-linking.component.html',
  styleUrls: ['./company-linking.component.scss']
})
export class CompanyLinkingComponent implements OnInit, OnDestroy {

  //#region Public properties

  get personEmailCtrl() { return this.companyLinkingForm.controls['personEmail']; }

  //#endregion

  public componentActive = true;

  public inProgress = false;
  public companyLinkingForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private personService: PersonService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  /**
   * Initialize FormGroup instance.
   */
  public createForm() {
    this.companyLinkingForm = this.fb.group({
      personEmail: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
        Validators.maxLength(50)
      ], [this.getPersonByEmail.bind(this)])
    });
  }

  /**
   * Performs async validation to check is there any user already registered with the specific email address.
   *
   * @param control AbstractControl instance.
   */
  public getPersonByEmail(control: AbstractControl) {
    this.inProgress = true;

    return timer(1500).pipe(
      switchMap(() => {
        if (!control.value) {
          this.inProgress = false;
          return of(null);
        }
        return this.personService.query(this.generateEmailQuery(control.value)).pipe(
          takeWhile(() => this.componentActive),
          map(({ totalRecordCount }) => {
            this.inProgress = false;
            return totalRecordCount === 0 ? { notFound: true } : null;
          }));
      })
    );
  }

  /**
   * Return true if control is touched or dirty and has invalid state.
   *
   * @param controlName Name of the FormControl.
   * @param errorType Type of error.
   */
  public hasErrorOfType(controlName: string, errorType: string): boolean {
    const ctrl = this.companyLinkingForm.controls[controlName];
    return ctrl && (ctrl.touched || ctrl.dirty) && ctrl.hasError(errorType);
  }

  /* Generate query for getting person by email. */
  private generateEmailQuery(email: string) {
    const filter: FilterDefinition = {
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'email',
            operator: 'eq',
            value: email
          }
        ]
      }
    };

    return filter;
  }
}
