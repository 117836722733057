import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmationWithCommentData } from '../../../models/confirmation-with-comment-data';

@Component({
  selector: 'app-confirmation-with-comment',
  templateUrl: './confirmation-with-comment.component.html',
  styleUrls: ['./confirmation-with-comment.component.scss']
})
export class ConfirmationWithCommentComponent implements OnInit {

  dialogData: ConfirmationWithCommentData;

  public comment: string;
  public confirmationForm: UntypedFormGroup;
  get commentCtrl() { return this.confirmationForm.controls['comment']; }

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ConfirmationWithCommentComponent>,
    @Inject(MAT_DIALOG_DATA) data: ConfirmationWithCommentData
  ) {
    if (data) {
      this.dialogData = {...data};
    }
  }

  ngOnInit() {
    this.createForm();
  }

  public createForm() {
    this.confirmationForm = this.fb.group({
      comment: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(1000),
        Validators.minLength(25)
      ])
    });
  }

  public hasErrorOfType(controlName: string, errorType: string): boolean {
    const ctrl = this.confirmationForm.controls[controlName];
    return ctrl && (ctrl.touched || ctrl.dirty) && ctrl.hasError(errorType);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogData.isConfirmed = true;
  }
}
