import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { CompanyService } from '../../../companies/services/company.service';
import { takeWhile, switchMap, map, take } from 'rxjs/operators';
import { Person } from '../../models/person.model';
import { LayoutService } from '../../../layouts/layout.service';
import { PersonService } from '../../services/person.service';
import { ApprovalStatus } from '../../enums/approval-status';
import { ContactPreference } from '../../enums/contact-preference';
import { UserRole } from '../../../security/components/user-role/shared/user-role.model';
import { UserRoleService } from '../../../security/services/user-role.service';
import { Address } from '../../../shared/models/address';
import { AuthService } from '../../../shared/auth.service';
import { LocationService } from '../../../shared/services/location.service';
import { CompanyLocation } from '../../../locations/models/company-location';
import { CommunicationPreference } from '../../models/communication-preferences.model';
import { InviteService } from '../../../shared/services/invite.service';
import { timer, of, Subscription } from 'rxjs';
import { ProfileService } from '../../../shared/services/profile.service';
import { ShippingService } from '../../../shared/services/shipping.service';
import { EmailRegex, PhoneNumberRegex } from '../../../utils/regex';
import { PhoneNumberService } from '../../../shared/services/phone-number.service';
import { Company } from '../../../companies/models/company';
import { BusinessServiceType } from '../../../shared/enums/business-service-type';
import { UserRoleType } from '../../../security/enums/user-role-type';
import { BusinessService } from '../../../companies/models/business-service';
import { BusinessServicesService } from '../../../companies/services/business-services.service';
import { UserRoleCode } from '../../enums/user-role-code';
import { PersonRole } from '../../models/person-role.model';
import { environment } from '../../../../environments/environment';
import { AppConfigurationService } from '../../../shared/services/app-configuration.service';
import { FeatureName } from '../../../shared/enums/feature-name';
import { RegistrationSource } from '../../../shared/enums/registration-source';
import * as moment from 'moment';
import { ShipmentServicePermission } from '../../models/service-permissions.model';
import { PermissionLevel } from '../../enums/permission-level';

const defaultDates = ['0001-01-01T00:00:00', '0001-01-01T00:00:00Z'];
@Component({
  selector: 'app-person-details',
  templateUrl: './person-details.component.html',
  styleUrls: ['./person-details.component.scss']
})
export class PersonDetailsComponent implements OnInit, OnDestroy {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  isInvite = false;
  isShippingCustomerApproved = false;
  clientId = '';
  roleSelected = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  isBlocked: boolean;

  //#region Public properties

  get firstNameCtrl() { return this.personDetailsForm.get('firstName'); }
  get lastNameCtrl() { return this.personDetailsForm.get('lastName'); }
  get emailCtrl() { return this.personDetailsForm.get('email'); }
  get cellPhoneCtrl() { return this.personDetailsForm.get('cellPhone'); }
  get countryCodeCtrl() { return this.personDetailsForm.get('countryCode'); }
  get isActiveCtrl() { return this.personDetailsForm.get('isActive'); }
  get disabledAnalyticsCtrl() { return this.personDetailsForm.get('disabledAnalytics'); }
  get isShippingCustApprovedCtrl() { return this.personDetailsForm.get('isShippingCustApproved'); }
  get middleNameCtrl() { return this.personDetailsForm.get('middleName'); }
  get companyIdCtrl() { return this.personDetailsForm.get('companyId'); }
  get approvalStatusCtrl() { return this.personDetailsForm.get('approvalStatus'); }
  get contactPreferenceCtrl() { return this.personDetailsForm.get('contactPreference'); }
  get addressCtrl() { return this.personDetailsForm.get('address'); }
  get skills() { return this.person.skills; }
  get isEmailVerifiedCtrl() { return this.personDetailsForm.get('isEmailVerified'); }
  get isCellPhoneVerifiedCtrl() { return this.personDetailsForm.get('isCellPhoneVerified'); }

  jewelerPagesEnabled$ = this.appConfigurationService.getFeatureFlag(FeatureName.ZingJewelerPagesActive);

  get isSuperAdmin() {
    return this.authService.isSuperAdministrator;
  }

  get isPlatformAdmin() {
    return this.authService.isPlatformAdmin;
  }

  get isSuperOrPlatformAdmin() {
    return this.authService.isSuperOrPlatformAdministrator;
  }

  get isShippingAdmin() {
    return this.authService.isShippingAdmin;
  }

  get isCLAdmin() {
    return this.authService.isCommercialLinesAdmin;
  }

  get isPersonalLinesUser() {
    return this.authService.isPersonalLinesUser;
  }

  get isPartnerGatewayAdmin() {
    return this.authService.isPartnerGatewayAdmin;
  }

  get isAppraisalAdmin() {
    return this.authService.isAppraisalAdmin;
  }

  get isMarketplaceAdmin() {
    return this.authService.isMarketplaceAdmin;
  }

  get isPlafotmSupportLevelOne() {
    return this.authService.isPlatformSupportLevelOne;
  }

  get isPOSAdmin() {
    return this.authService.isPOSAdmin;
  }

  get isJewelerPagesAdmin() {
    return this.authService.isJewelerPagesAdmin;
  }

  get isViewOnly() {
    return this.authService.isViewOnly;
  }

  get isMembershipAdmin() {
    return this.authService.isMembershipAdmin;
  }

  get canEditServicePermissions() {
    return this.isEditMode
           && (this.isSuperOrPlatformAdmin || this.isShippingAdmin || this.isCLAdmin ||
              this.isPersonalLinesUser || this.isPartnerGatewayAdmin || this.isAppraisalAdmin ||
              this.isMarketplaceAdmin || this.isPOSAdmin || this.isJewelerPagesAdmin || this.isMembershipAdmin)
           && !this.isAccountLocked
           && this.person
           && (this.hasShipping || this.hasCL || this.hasPersonalInsurance || this.hasMarketplace || this.hasPG || this.hasAppraisal ||
              this.hasJewelerPages);
  }

  //#endregion

  //#region Public fields

  public componentActive = true;

  public isEditMode = false;
  // Property for keeping the details about Person.
  public person: Person = {} as Person;
  public loadingData = false;
  public personDetailsForm: UntypedFormGroup;
  public approvalStatus = ApprovalStatus;
  public contactPreferences = ContactPreference;
  public locations: Array<CompanyLocation> = new Array<CompanyLocation>();
  public hasGuestRole = false;
  /**
   * UserRoles business specific instances.
   */
  public companyUserRoles: Array<UserRole> = [];

  /**
   * UserRoles business specific instances.
   */
  public businessUserRoles: Array<UserRole> = [];

  /**
   * UserRoles business specific instances.
   */
  public platformUserRoles: Array<UserRole> = [];

  public guestExperianceEnabled = environment.guestExperienceEnabled;

  //#endregion
  isAccountLocked = false;
  lockedUntil: any;
  inviteAlreadyExists = false;
  disableOwnRoles = false;
  company: Company;
  subscriptions = new Array<Subscription>();
  JMPGSubscription: BusinessService;

  disableButton = false;
  constructor(
    private location: Location,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private layoutService: LayoutService,
    private personService: PersonService,
    private userRoleService: UserRoleService,
    private authService: AuthService,
    private locationService: LocationService,
    private inviteService: InviteService,
    private profileService: ProfileService,
    private shippingService: ShippingService,
    private phoneNumberService: PhoneNumberService,
    private companyService: CompanyService,
    private businessServicesService: BusinessServicesService,
    private appConfigurationService: AppConfigurationService,
    private datePipe: DatePipe
  ) { }

  //#region Life cycle methods

  ngOnInit() {
    this.prepareDataForDisplay();
    this.getUserRoles();
  }

  ngOnDestroy() {
    // Clean all subscriptions.
    this.subscriptions.forEach(s => s.unsubscribe());
    this.componentActive = false;
  }

  //#endregion

  //#region Public methods

  /**
   * Prepares important data for display.
   */
  public prepareDataForDisplay() {
    const personId = this.route.snapshot.params['id'];
    const companyId = this.route.snapshot.params['companyId'];
    this.isEditMode = personId ? true : false;
    this.isEditMode ? this.getPersonDetails(personId) : this.createInitialPerson(companyId);
    // Set isInvite property to hide the checkboxes for manual verification of email and phone when person is invited
    if (this.router.url.includes('invite')) {
      this.isInvite = true;
    }
  }

  /**
   * Get specific Person object data.
   *
   * @param personId Person unique identifier.
   */
  public getPersonDetails(personId: string) {
    this.loadingData = true;

    this.personService.getById(personId)
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        person => {
          this.loadingData = false;
          this.person = person;
          this.fixPersonErroneousRoles();
          this.createInitialFormGroup();
          if (this.person.companyId) {
            this.getCompanyDetails(this.person.companyId);
            this.getLocationsByCompanyId(this.person.companyId);
          }
          this.validateInviteByEmail(this.person.email);
          this.getAccountLockoutDetails(this.person.referenceId);

          if (this.person.referenceId === this.authService.userReferenceId) {
            this.disableOwnRoles = true;
          }

          this.filterGuestRoleForNonGuestUsers();
        },
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Unable to get the data for person. Try reloading this page.');
        }
      );
  }

  /**
   * Create initial Person instance, when Person is being invited.
   *
   * @param companyId Unique identifier of the Company for which Person is invited.
   */
  public createInitialPerson(companyId: string) {
    this.person = {
      address: null,
      approvalStatus: null,
      cellPhone: null,
      communicationPreference: new CommunicationPreference(),
      companyId,
      email: null,
      firstName: null,
      isActive: false,
      disableAnalytics: false,
      lastName: null,
      middleName: null,
      referenceId: null,
      roles: [],
      skills: [],
      servicePermissions: []
    } as Person;

    this.createInitialFormGroup();
    if (companyId) { this.getLocationsByCompanyId(companyId); }
  }

  /**
   * Create initial FormGroup, which is same for both edit and invite scenario.
   */
  public createInitialFormGroup() {
    /* If user does not have contact preference, set it to null. */
    const contactPreference = this.person.communicationPreference
      ? this.person.communicationPreference.contactPreference
      : null;

    // eslint-disable-next-line max-len
    const lastLoginDate = !this.person.lastLoginDate || defaultDates.includes(this.person.lastLoginDate) ? null : new Date(Date.parse(this.person.lastLoginDate) - 60 * 60 * 1000 * 5).toUTCString();
    const formattedLastLoginDate = lastLoginDate && this.datePipe.transform(new Date(lastLoginDate.slice(0, -4)), 'MM/dd/yyyy HH:mm');

    const dateJoined = this.person.dateJoined ? moment.utc(this.person.dateJoined).format('MM/DD/YYYY') : null;

    this.isBlocked = this.person.approvalStatus === ApprovalStatus.Blocked;

    this.personDetailsForm = this.fb.group({
      firstName: new UntypedFormControl(this.person.firstName, Validators.required),
      lastName: new UntypedFormControl(this.person.lastName, Validators.required),
      email: new UntypedFormControl(this.person.email,
        [
          Validators.required,
          Validators.pattern(EmailRegex),
          Validators.maxLength(50)
        ], [this.isEmailInUse.bind(this)]),
      countryCode: new UntypedFormControl(this.phoneNumberService.getCountryCode(this.person.cellPhone)),
      cellPhone: new UntypedFormControl(
        this.phoneNumberService.getPhoneNumber(this.person.cellPhone),
        Validators.pattern(PhoneNumberRegex)),
      isEmailVerified: new UntypedFormControl(this.person.isEmailVerified ? this.person.isEmailVerified : false),
      isCellPhoneVerified: new UntypedFormControl(this.person.isCellPhoneVerified ? this.person.isCellPhoneVerified : false),
      companyId: new UntypedFormControl(this.person.companyId),
      isActive: new UntypedFormControl({ value: this.person.isActive, disabled: this.isBlocked }, Validators.required),
      disabledAnalytics: new UntypedFormControl(this.person.disableAnalytics),
      isShippingCustApproved: new UntypedFormControl(this.isShippingCustomerApproved),
      middleName: new UntypedFormControl(this.person.middleName),
      approvalStatus: new UntypedFormControl({ value: this.person.approvalStatus, disabled: this.isBlocked }, Validators.required),
      contactPreference: new UntypedFormControl(contactPreference, Validators.required),
      skills: new UntypedFormControl([]),
      newEmail: new UntypedFormControl({ value: this.person.newEmail, disabled: true }),
      newPhoneNumber: new UntypedFormControl({ value: this.person.newPhoneNumber, disabled: true }),
      registrationId: new UntypedFormControl({ value: this.person.registrationId, disabled: true }),
      clientId: new UntypedFormControl({ value: this.clientId, disabled: true }),
      personSource: new UntypedFormControl({ value: this.getRegistrationSourceById(this.person.personSource), disabled: true }),
      lastLoginDate: new UntypedFormControl({ value: formattedLastLoginDate, disabled: true }),
      dateJoined: new UntypedFormControl({ value: dateJoined, disabled: true }),
    });

    this.isEditMode
      ? this.companyIdCtrl.enable()
      : this.companyIdCtrl.disable();
  }

  /**
   * Get shipping user data.
   */
  getShippingData(personId: string) {
    this.loadingData = true;
    this.shippingService
      .getShippingUserData(personId)
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(
        shippingUserData => {
          this.loadingData = false;
          if (shippingUserData && shippingUserData.id && shippingUserData.clientSetting) {
            this.isShippingCustomerApproved = shippingUserData.clientSetting.isApproved;
            this.isShippingCustApprovedCtrl.setValue(this.isShippingCustomerApproved);
            if (shippingUserData.clientSetting.clientId) {
              this.clientId = shippingUserData.clientSetting.clientId;
              this.personDetailsForm.get('clientId').setValue(this.clientId);
            }
          }
        },
        () => {
          this.loadingData = false;
          this.isShippingCustomerApproved = false;
          this.isShippingCustApprovedCtrl.setValue(false);
        }
      );
  }

  /**
   * Fetch the details for specific company using Company service
   *
   * @param companyId Id of the company
   */
  getCompanyDetails(companyId: string) {
    this.loadingData = true;
    const sub = this.companyService
      .getById(companyId)
      .subscribe(
        data => {
          this.loadingData = false;
          this.company = data;
          this.getBusinessServices();
          if (this.hasShipping) {
            this.getShippingData(this.person.id);
          }
        },
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Unable to get the data for company. Try reloading this page.');
        }
      );

    this.subscriptions.push(sub);
  }

  getBusinessServices() {
    this.loadingData = true;
    const sub = this.businessServicesService
      .getAll()
      .subscribe(
        data => {
          this.loadingData = false;
          this.JMPGSubscription = data.items.find(s => s.serviceType === BusinessServiceType.PartnerGateway);
        },
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Unable to get business services data. Try reloading this page');
        }
      );
    this.subscriptions.push(sub);
  }

  /**
   * Fetch the details for  Subscription Active for company
   *
   * @param BusinessServiceType BusinessServiceType
   */
  isSubscriptionActive(serviceType: BusinessServiceType): boolean {
    if (this.company.hasSubscriptions) {
      const subIndex = this.company.subscriptions.findIndex(s => s.isActive && s.serviceType === serviceType);
      return subIndex > -1;
    }
    return false;
  }
  /**
   * Fetch the details for  Subscription Active for company
   */
  get hasShipping(): boolean {
    return typeof this.company !== 'undefined' && this.company !== null &&
      this.isSubscriptionActive(BusinessServiceType.Shipping);
  }

  get hasCL(): boolean {
    return typeof this.company !== 'undefined' && this.company !== null &&
      this.isSubscriptionActive(BusinessServiceType.CommercialInsurance);
  }

  get hasPersonalInsurance(): boolean {
    return typeof this.company !== 'undefined' && this.company !== null &&
      this.isSubscriptionActive(BusinessServiceType.PersonalInsurance);
  }

  get hasPersonalInsurancePermissions(): boolean {
    return this.authService.hasPermissionForServiceType(BusinessServiceType.PersonalInsurance);
  }

  get hasMarketplace(): boolean {
    return typeof this.company !== 'undefined' && this.company !== null &&
      this.isSubscriptionActive(BusinessServiceType.Marketplace);
  }

  get hasMarketplacePermissions(): boolean {
    return this.authService.hasPermissionForServiceType(BusinessServiceType.Marketplace);
  }

  get hasPG(): boolean {
    return typeof this.company !== 'undefined' && this.company !== null &&
      this.isSubscriptionActive(BusinessServiceType.PartnerGateway);
  }

  get hasAppraisal(): boolean {
    return typeof this.company !== 'undefined' && this.company !== null &&
      this.isSubscriptionActive(BusinessServiceType.Appraisal);
  }

  get hasAppraisalPermissions(): boolean {
    return this.authService.hasPermissionForServiceType(BusinessServiceType.Appraisal);
  }

  get hasJewelerPages(): boolean {
    return typeof this.company !== 'undefined' && this.company !== null &&
      this.isSubscriptionActive(BusinessServiceType.JewelerPages);
  }

  get hasJewelerPagesPermissions(): boolean {
    return this.authService.hasPermissionForServiceType(BusinessServiceType.JewelerPages);
  }

  get isRegulatoryComplianceAdmin(): boolean {
    return this.authService.isRegulatoryComplianceAdmin;
  }

  /**
   * Get all of the Location instances for specific Company.
   *
   * @param companyId Company unique identifier.
   */
  public getLocationsByCompanyId(companyId: string) {
    this.loadingData = true;

    this.locationService.getLocationsForCompanyId(companyId)
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        locations => {
          this.loadingData = false;
          this.locations = locations;
        },
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Unable to get the data for person. Try reloading this page.');
        }
      );
  }

  /**
   * Get every available UserRole and populate different types of arrays for specific components.
   */
  public getUserRoles() {
    this.loadingData = true;

    this.userRoleService.query()
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        ({ items }) => {
          this.loadingData = false;
          // eslint-disable-next-line no-bitwise
          this.businessUserRoles = items.filter(role => +(role.roleType & UserRoleType.Location) !== 0);
          // eslint-disable-next-line no-bitwise
          this.platformUserRoles = items.filter(role => +(role.roleType & UserRoleType.Platform) !== 0
            // eslint-disable-next-line max-len
            && role.code !== UserRoleCode.PlatformSupportL2 && role.code !== UserRoleCode.PlatformSupportL3);
          this.filterGuestRoleForNonGuestUsers();
          // eslint-disable-next-line no-bitwise
          this.companyUserRoles = items.filter(role => +(role.roleType & UserRoleType.Company) !== 0);
          this.fixPersonErroneousRoles();
        },
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('User roles did not load. Try again later.');
        }
      );
  }

  /**
   * Return user to the previous page.
   */
  public onBack() {
    this.location.back();
  }

  /**
   * Update Person.
   */
  public onSubmit() {
    if (this.personDetailsForm.valid) {
      this.loadingData = true;
      this.preparePersonForSubmitting();

      const isUserBA = this.person.roles.some(role => role.userRoles.some(role => role.code === UserRoleCode.BusinessAdministrator));
      if (isUserBA) {
        const shippingPermission = this.person.servicePermissions
          .find(sp => sp.serviceType === BusinessServiceType.Shipping) as ShipmentServicePermission;
        if (shippingPermission) {
          shippingPermission.manageShipments = PermissionLevel.CreatedByEntireOrganization;
        }
      }

      this.isEditMode ? this.updatePerson(this.person.id, this.person) : this.invitePerson(this.person);
    } else {
      /* If form is invalid, provide proper feedback. */
      this.markControlsAsDirty();
    }
  }

  /**
   * Map value from Form Controls to the instance of Person.
   */
  public preparePersonForSubmitting() {
    this.person.firstName = this.firstNameCtrl.value;
    this.person.middleName = this.middleNameCtrl.value;
    this.person.lastName = this.lastNameCtrl.value;
    this.person.email = this.emailCtrl.value;
    this.person.cellPhone = this.formatPhoneNumber(this.countryCodeCtrl.value, this.cellPhoneCtrl.value);
    this.person.companyId = this.companyIdCtrl.value;
    this.person.approvalStatus = this.approvalStatusCtrl.value;
    this.person.isActive = this.isActiveCtrl.value;
    this.person.disableAnalytics = this.disabledAnalyticsCtrl.value;
    this.person.communicationPreference.contactPreference = this.contactPreferenceCtrl.value;
    this.person.isCellPhoneVerified = this.isCellPhoneVerifiedCtrl.value;
    this.person.isEmailVerified = this.isEmailVerifiedCtrl.value;
    this.person.address = Object.assign(this.personDetailsForm.value.address || {} as Address,
      {
        addressLine1: this.personDetailsForm.value.address.addressLine1,
        addressLine2: this.personDetailsForm.value.address.addressLine2,
        city: this.personDetailsForm.value.address.city,
        countryId: this.personDetailsForm.value.address.country,
        stateId: this.personDetailsForm.value.address.state,
        postCode: this.personDetailsForm.value.address.postCode
      });
    // Remove orphan roles before saving person.
    if (this.person.roles && this.person.roles.length > 0) {
      this.person.roles = this.person.roles.filter(role => role.userRoles && role.userRoles.length > 0);
    }
  }

  /**
   * Update Person instance with data provided from Form.
   *
   * @param personId Unique identifier for Person instance.
   * @param person Person instance.
   */
  public updatePerson(personId: string, person: Person) {
    this.personService.update(personId, person)
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Update was successful.');
          this.onBack();
        },
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Unable to update the person. Please try again later.');
        }
      );
  }

  formatPhoneNumber(countryCode: any, phone: any): any {
    if (!countryCode || !phone) {
      return '';
    }
    phone = phone.replace(/\D+/g, '');
    return `${countryCode}-${phone}`;
  }

  /**
   * Send invite to the potential user.
   *
   * @param email Email of potential user.
   */
  public invitePerson(person: Person) {
    this.inviteService.sendInvite(person)
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        res => {
          this.loadingData = false;
          res
            ? this.layoutService.showUIMessage('Person invite has been sent successfully.')
            : this.layoutService.showUIMessage('Person invite has not been sent.');

          this.router.navigate(['/companies/detail', person.companyId]);
        },
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Person invite has not been sent.');
        }
      );
  }

  /**
   * Resend invite to already invited user
   */
  public resendInvite() {
    this.loadingData = true;
    this.inviteService.resendInvite(this.person.email)
      .subscribe(
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Person invite has been resent successfully.');
          this.router.navigate(['/companies/detail', this.person.companyId]);
        },
        err => {
          this.loadingData = false;
          if (err.status === 404) {
            this.layoutService.showUIMessage('Person invite not found');
          } else {
            this.layoutService.showUIMessage('Person invite has not been resent.');
          }
        }
      );
  }

  /**
   * Marks all FromControls as dirty and touched.
   */
  public markControlsAsDirty() {
    Object.keys(this.personDetailsForm.controls).forEach(key => {
      this.personDetailsForm.get(key).markAsDirty();
      this.personDetailsForm.get(key).markAsTouched();
    });
  }

  /**
   * Return true | false depending if there is an error for passed control.
   *
   * @param control Instance of AbstractControl.
   */
  public hasError(control: AbstractControl): boolean {
    return control && (control.touched || control.dirty) && control.invalid;
  }

  /**
   * Return true if control is touched or dirty and has invalid state.
   *
   * @param controlName Name of the control.
   */
  hasErrorOfType(controlName: string, errorType: string): boolean {
    const ctrl = this.personDetailsForm.controls[controlName];
    return ctrl && (ctrl.touched || ctrl.dirty) && ctrl.hasError(errorType);
  }

  /**
   * Separate string value by uppercase letters (used for enum names).
   *
   * @param value String value which needs separation.
   */
  public separateStringByUppercase(value: string): string {
    return value.match(/[A-Z][a-z]+/g).join(' ');
  }

  public addSkill(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      const existingBrand = this.person.skills.findIndex(function(element) {
        return element === value;
      });
      if (existingBrand < 0) {
        this.person.skills.push(value);
      }
    }
    if (input) {
      input.value = '';
    }
  }

  public removeSkill(skill: string): void {
    const index = this.person.skills.indexOf(skill);
    if (index >= 0) {
      this.person.skills.splice(index, 1);
    }
  }

  /**
   * Check if invite link can be resent to the user.
   *
   * @param person
   */
  public isPersonReinvitable(person: Person) {
    return person.companyId && person.approvalStatus === 3;
  }

  /**
   * Get Account Lockout Details for the selected person.
   *
   * @param userId Reference id of Person.
   */
  public getAccountLockoutDetails(userId: string) {
    if (userId !== null && userId !== undefined && userId !== '') {
      this.loadingData = true;
      this.personService.getAccountLockoutDetails(userId)
        .pipe(
          takeWhile(() => this.componentActive)
        ).subscribe(
          res => {
            this.loadingData = false;
            this.isAccountLocked = res.isLocked;
            this.lockedUntil = res.lockedUntil;
          },
          () => {
            this.loadingData = false;
            this.layoutService.showUIMessage('Getting Account Lockout Details Failed. Please try again Later.');
          });
    }
  }

  /**
   * Unlock the selected User's Account.
   */
  public unlockAccount() {
    this.loadingData = true;

    this.personService.setAccountLockoutDetails(this.person.referenceId)
      .pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        () => {
          this.loadingData = false;
          this.isAccountLocked = false;
          this.layoutService.showUIMessage('The Account has been unlocked.');
        },
        () => {
          this.loadingData = false;
          this.layoutService.showUIMessage('Unlocking the Account Failed. Please try again Later.');
        });
  }

  /**
   * Check if the person is not registered and if the invite already exists.
   *
   * @param person
   */
  isPersonNotRegistered(person: Person) {
    return person.id && !person.referenceId && !this.inviteAlreadyExists && this.isActiveCtrl.value;
  }

  /**
   * Sends the invite to the Inactive person.
   */
  inviteInactivePerson() {
    if (this.personDetailsForm.valid) {
      this.loadingData = true;
      this.preparePersonForSubmitting();

      this.invitePerson(this.person);
    } else {
      /* If form is invalid, provide proper feedback. */
      this.markControlsAsDirty();
    }
  }
  //#endregion
  /**
   * checks for any existing invite for the email id
   *
   * @param email Email Id of the Person
   */
  private validateInviteByEmail(email: string) {
    if (email !== null && email !== undefined && email !== '') {
      this.inviteService.validateInvite(email).pipe(
        takeWhile(() => this.componentActive)
      ).subscribe(
        res => {
          this.inviteAlreadyExists = res.inviteExists;
        });
    }
  }
  /**
   * Performs async validation to check is there any user already registered with the specific email address
   *
   * @param control Control
   */
  private isEmailInUse(control: AbstractControl) {
    return timer(500).pipe(
      switchMap(() => {
        if (!control.value || control.value === this.person.email) {
          return of(null);
        }
        return this.profileService.validateEmail(control.value).pipe(
          map(({ emailExists }) => emailExists ? { emailInUse: true } : null));
      })
    );
  }

  /**
   * Fix person roles that have companyId null where locationId is not null, it should never happen
   */
  private fixPersonErroneousRoles() {
    if (this.person && this.person.roles && this.platformUserRoles.length > 0){
      const erroneousRole = this.person.roles.find(pr => pr.companyId === null && pr.locationId !== null);
      if (erroneousRole) {
        const platformUserRolesIds = this.platformUserRoles.map(x => x.id);
        const platformUserRolesForTransfer = erroneousRole.userRoles.filter(x => platformUserRolesIds.includes(x.id));
        const platformSpecificRole = this.person.roles.find(pr => pr.companyId === null && pr.locationId === null);
        // Add companyId and remove platform user roles from business user roles
        erroneousRole.userRoles = erroneousRole.userRoles.filter(x => !platformUserRolesIds.includes(x.id));
        erroneousRole.companyId = this.person.companyId;
        // Add platform user roles to currect group, where companyId and locationId are null
        if (platformSpecificRole) {
          platformSpecificRole.userRoles = platformSpecificRole.userRoles.concat(platformUserRolesForTransfer);
        } else {
          const userRoleInstance: PersonRole = {
            companyId: null,
            locationId: null,
            roleIds: [],
            userRoles: platformUserRolesForTransfer
          };
          this.person.roles.push(userRoleInstance);
        }
      }
    }
  }

  onRoleSelected(selected: boolean) {
    this.roleSelected = selected;
    this.personDetailsForm.markAsDirty();
  }

  markFormAsDirty(isDirty: boolean) {
    if (isDirty) {
      this.personDetailsForm.markAsDirty();
    }
  }

  filterGuestRoleForNonGuestUsers() {
    const shouldKeepGuestRole = this.guestExperianceEnabled && this.canSeeGuestRole();
    if (this.person.roles && !shouldKeepGuestRole) {
      this.platformUserRoles = this.platformUserRoles.filter(r => r.code !== UserRoleCode.JMGuestPerson);
    }
  }

  canSeeGuestRole() {
    this.hasGuestRole = this.person
      && this.person.roles
      && this.person.roles.length > 0
      && this.person.roles.some(r => r.userRoles.some(role => role.code === UserRoleCode.JMGuestPerson));
    if(this.hasGuestRole) {
      this.personDetailsForm.disable();
    }
    return this.hasGuestRole;
  }

  /**
   * Return string representation of the registration source
   *
   * @param registrationSource Registration source
   */
  getRegistrationSourceById(registrationSource: RegistrationSource) {
    switch (registrationSource) {
      case RegistrationSource.Zing:
        return 'Zing';
      case RegistrationSource.IShipJM:
        return 'IShip';
      case RegistrationSource.JewelersMutual:
        return 'Jewelers Mutual';
      case RegistrationSource.GemAndJewel:
        return 'Gem & Jewel';
      case RegistrationSource.BulkRegistration:
        return 'Bulk Registration';
      default:
        return '';
    }
  }

  getScreeningFilter() {
    return `entityId eq guid'${this.person.id}'`;
  }
}
