<div class="flex-layout jewelry-claims-permissions h-auto max-width: 100%" (click)="hidePopover($event)">
    <form [formGroup]="jewelerPagesForm">
      <div>
        <h2>
          Jeweler Pages Permissions
        </h2>
      </div>
      <div class="row">
        <div class="col-md-3">
          View and edit Jeweler Pages details
          <img src="/assets/images/JMicons/info_outline.png" id="view-pl-info" width="20" [placement]="['bottom-left', 'bottom-right', 'right']" [ngbPopover]="jewelerPagesPopup" #popover="ngbPopover">
        </div>
        <div class="col-md-3 pl-md-2">
          <mat-checkbox formControlName="allowed" (change)="setFormToDirty()">Allowed</mat-checkbox>
        </div>
      </div>
      <div class="row footer">
        <mat-card-actions align="end" class="col save-container">
          <button mat-raised-button color="primary" type="button" [disabled]="jewelerPagesForm.invalid || !formIsDirty" (click)="onSubmit()">Save</button>
        </mat-card-actions>
      </div>
    </form>
  </div>
  <hr class="pb-1rem"/>
  
  
  <ng-template #jewelerPagesPopup>
    <div class="row info-wrap popup-wrap" id="jewelerPagesPopup">
      <div class="col-10 info-head font-weight-bold mb-1">
        Allowed must be selected for user to view and edit Jeweler Page.
      </div>
      <mat-icon class="col-2 close-wrap mb-1 text-right" width="12">close</mat-icon>
    </div>
  </ng-template>
  
  <app-in-progress [loading]="isLoading"></app-in-progress>