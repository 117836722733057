import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Person } from '../../persons/models/person.model';

@Injectable()
export class InviteService {

  private inviteApiUrl = environment.membershipApiUrl + '/api/Invites';

  constructor(private http: HttpClient) { }

  /**
   * Send invite to the potential user.
   *
   * @param person Email of potential user.
   */
  public sendInvite(person: Person) {
    const companyId = person.companyId;
    const email = person.email;

    return this.http.post(this.inviteApiUrl, { email, companyId, person });
  }

  /**
   * Resend invite to the already invited user
   *
   * @param email of the invitee
   */
  public resendInvite(email: string) {
    const url = `${this.inviteApiUrl}/resend`;
    return this.http.post(url, JSON.stringify(email));
  }

  /**
   * Resend invite to the already invited user
   *
   * @param email of the invitee
   */
  public validateInvite(email: string) {
    const url = `${this.inviteApiUrl}/exists`;
    return this.http.post<{
      inviteExists: boolean;
    }>(url, JSON.stringify(email));
  }
}
