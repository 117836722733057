import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MarketplacePartnerAffiliationType } from '../../../companies/models/marketplace-partner-affiliation-type';
import { LayoutService } from '../../../layouts/layout.service';
import { BusinessServiceType } from '../../../shared/enums/business-service-type';
import { PartnerService } from '../../../shared/services/partner.service';
import { affiliateEndDateLessThanStartDate } from '../../../shared/utils/date-range-validator';
import { MarketplaceAgsServicePermission } from '../../models/marketplace-ags-service-permission';
import { MarketplaceAgsServicePermissionStatus } from '../../models/marketplace-ags-service-permission-status';
import { MarketplacePartnerServicePermission } from '../../models/marketplace-partner-service-permission';
import { MarketplaceServicePermission } from '../../models/marketplace-service-permission';
import { Person } from '../../models/person.model';
import { PersonService } from '../../services/person.service';
import { partnerAffiliations } from './marketplace-permission-constants';

@Component({
  selector: 'app-marketplace-permission',
  templateUrl: './marketplace-permission.component.html',
  styleUrls: ['./marketplace-permission.component.scss']
})
export class MarketPlacePermissionComponent implements OnInit, OnDestroy {

  @Input() person: Person;
  marketPlaceForm: UntypedFormGroup;
  partnerPermissionsFormArray: UntypedFormArray;
  componentActive = true;
  isLoading = false;
  formIsDirty = false;
  isAgsEnabled = environment.agsEnabled;
  affiliationTypes = partnerAffiliations;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private personService: PersonService,
    private layoutService: LayoutService,
    private partnerService: PartnerService,
    private route: ActivatedRoute
  ) { }

  get marketPlacePermissions() {
    return this.person.servicePermissions.find(x => x.serviceType === BusinessServiceType.Marketplace) as MarketplaceServicePermission;
  }
  get allowMarketplaceAllowedCtrl() { return this.marketPlaceForm.controls['allowed']; }
  get allowMarketplaceAffiliationTypeCtrl() { return this.marketPlaceForm.controls['affiliationType']; }
  get partnerPermissions() { return this.marketPlaceForm.get('partnerPermissions') as UntypedFormArray; }

  ngOnInit() {
    this.buildmarketPlaceForm();
    this.loadPersonPermissions();
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  buildmarketPlaceForm() {
    this.partnerPermissionsFormArray = this.formBuilder.array([]);
    this.marketPlaceForm = this.formBuilder.group({
      allowed: this.formBuilder.control(null),
      partnerPermissions: this.partnerPermissionsFormArray
    },
    {
      validator: affiliateEndDateLessThanStartDate
    });
  }

  loadPersonPermissions() {
    if (this.marketPlacePermissions) {
      this.marketPlaceForm.get('allowed').setValue(this.marketPlacePermissions.allowed);
    }
  }

  getPartnerAffiliationData(partnerFormGroup: AbstractControl): MarketplacePartnerServicePermission {
    const partnerAffiliationType = partnerFormGroup.get('affiliationType').value;
    const partnerDataValues = partnerFormGroup.value?.partnerData[0];

    if (!partnerDataValues) {
      return null;
    }

    switch (partnerAffiliationType) {
      case MarketplacePartnerAffiliationType.AGS: {
        const agsAffiliation = new MarketplaceAgsServicePermission();
        agsAffiliation.affiliationType = partnerAffiliationType;
        agsAffiliation.memberId = partnerDataValues.memberId;
        agsAffiliation.startDate = partnerDataValues.startDate;
        agsAffiliation.endDate = partnerDataValues.endDate;
        agsAffiliation.status =  partnerDataValues.status ?
          MarketplaceAgsServicePermissionStatus.Active : MarketplaceAgsServicePermissionStatus.NotActive;
        return agsAffiliation;
      }
      default: {
        return null;
      }
    }
  }

  onSubmit() {
    this.isLoading = true;
    if (this.marketPlacePermissions) {
      this.marketPlacePermissions.allowed = this.marketPlaceForm.get('allowed').value;
    } else {
      this.person.servicePermissions.push({serviceType: BusinessServiceType.Marketplace,
        allowed: this.marketPlaceForm.get('allowed').value});
    }
    this.formIsDirty = false;
    this.updatePersonPermissions();
  }

  private removeDeletedPartnerAffiliationsFromPerson(newPartnersList: MarketplacePartnerServicePermission[]): void {
    const marketplaceSubscription = this.getMarketplaceServicePermission(this.person);
    marketplaceSubscription.partnerPermissions = this.filterDeletedPartners(marketplaceSubscription, newPartnersList);
  }

  private getPartnersModelList(): MarketplacePartnerServicePermission[] {
    const partners: MarketplacePartnerServicePermission[] = [];
    this.partnerPermissionsFormArray.controls.forEach(partnerFormGroup => {
      const partnerAffiliation = this.getPartnerAffiliationData(partnerFormGroup);
      if (partnerAffiliation) {
        partners.push(partnerAffiliation);
      }
    });
    return partners;
  }

  private filterDeletedPartners(marketplaceSubscription: MarketplaceServicePermission
    , newPartnersList: MarketplacePartnerServicePermission[]): MarketplacePartnerServicePermission[] {

    return marketplaceSubscription.partnerPermissions
      .filter(personPartner => newPartnersList.some(newPartner => newPartner.affiliationType === personPartner.affiliationType));
  }

  private updateSinglePartnerPermission(partnerAffiliation: MarketplacePartnerServicePermission, partnerFormGroup: AbstractControl) {
    this.partnerService.setPartnerPermission(this.route.snapshot.params['id'], partnerAffiliation)
      .pipe(take(1), finalize(() => this.isLoading = false))
      .subscribe(() =>
        this.layoutService.showUIMessage('Marketplace partner affiliation data updated.')
      ,
      () => {
        this.setFormControlError(partnerFormGroup);
        this.layoutService.showUIMessage('Unable to update the data for Marketplace partner affiliation.');
      });
  }

  private setFormControlError(partnerFormGroup: AbstractControl) {
    const affiliationTypeCtrl = partnerFormGroup.get('affiliationType');
    const partnerAffiliationType = +affiliationTypeCtrl.value;
    if (partnerAffiliationType === MarketplacePartnerAffiliationType.AGS) {
      const membershipIdCtrl = partnerFormGroup.get(['partnerData', 0, 'memberId']);
      membershipIdCtrl.setErrors({ 'incorrect': true });
      membershipIdCtrl.markAsTouched();
    }
  }

  private updatePartnerPermissions() {
    this.partnerPermissionsFormArray.controls.forEach(partnerFormGroup => {
      const partnerAffiliation = this.getPartnerAffiliationData(partnerFormGroup);
      if (partnerAffiliation) {
        this.updateSinglePartnerPermission(partnerAffiliation, partnerFormGroup);
      }
    });
  }

  private updatePersonPermissions() {
    const partners: MarketplacePartnerServicePermission[] = this.getPartnersModelList();
    const shouldUpdatePermission = !!partners.length;
    this.removeDeletedPartnerAffiliationsFromPerson(partners);

    this.personService.update(this.route.snapshot.params['id'], this.person)
      .pipe(take(1))
      .subscribe(() => {
        this.layoutService.showUIMessage('Marketplace permissions data updated.');
        if (shouldUpdatePermission) {
          this.updatePartnerPermissions();
        } else {
          this.isLoading = false;
        }
      },
      () => {
        this.isLoading = false;
        this.layoutService.showUIMessage('Unable to update the data for Marketplace permissions.');
      });
  }

  hidePopover(event: Event) {
    const popover = document.getElementById('view-mp-info');
    if (popover.hasAttribute('aria-describedby') && popover !== event.target) {
      popover.click();
    }
  }

  setFormToDirty() {
    this.formIsDirty = true;
  }

  private getMarketplaceServicePermission(person: Person): MarketplaceServicePermission {
    return person.servicePermissions.find(x => x.serviceType === BusinessServiceType.Marketplace) as MarketplaceServicePermission;
  }
}
