import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router
} from '@angular/router';
import { Observable ,  of } from 'rxjs';

import { AuthService } from '../shared/auth.service';
import { UserRoleCode } from '../persons/enums/user-role-code';
import { filter, switchMap, take, tap, map } from 'rxjs/operators';

@Injectable()
export class RoleGuard implements CanLoad, CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canLoad(route: Route): Observable<boolean> | boolean {
    const { allowedRoles } = route.data || <Array<UserRoleCode>>[];
    return this.isAuthorized(allowedRoles);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const { allowedRoles } = next.routeConfig.data || <Array<UserRoleCode>>[];
    return this.isAuthorized(allowedRoles);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
    Observable<boolean> | boolean {
    const { allowedRoles } = next.data || <Array<UserRoleCode>>[];
    return this.isAuthorized(allowedRoles);
  }

  private isAuthorized(allowedRoles = []) {
    return this.authService.getUserDetails()
      .pipe(
        map(p => p ? true : false),
        filter(p => p),
        take(1),
        switchMap(
          () => {
            const {
              isSuperOrPlatformAdministrator,
              isViewOnly,
              userPlatformRoles
            } = this.authService;

            // Super or Platform administrator can do anything (ignore roles defined at the route level)
            if (isSuperOrPlatformAdministrator || isViewOnly) {
              return of(true);
            }

            if (allowedRoles.length > 0) {
              // If route is protected by roles
              // Compare it with the list of roles assigned to the user
              const canContinue = userPlatformRoles
                .some(r => allowedRoles.includes(r.code));

              if (!canContinue) {
                this.router.navigateByUrl('/external/forbidden');
              }

              return of(canContinue);
            }

            // If we reach this point it's obvious that we have a user who should not be able to access this area
            this.router.navigateByUrl('/external/forbidden');
            return of(false);
          }
        )
      );

  }
}
