<div class="row flex-layout title-left-side" fxLayout="row" fxLayoutAlign="left">
  <div class="col-sm-12 col-xl-2 col-lg-4">
    <p class="portal-top-text pr-2">View all persons</p>
  </div>
  <div class="col-sm-12 col-xl-2 col-lg-4">
    <button *ngIf="showReinviteButton && isPartnerGatewayAdmin" class="mt-4 fit-content cancel-button" mat-raised-button color="primary" 
    [disabled]="isResendInvitationsInProgress" (click)="onClickResendInvitations()">
      Resend invitations
    </button>
  </div>
  <div class="col-sm-12 col-xl-4 col-lg-4">
    <p *ngIf="totalInvites > 0" class="portal-top-text pr-2">Sending {{ sentInvites }} of {{ totalInvites }}...</p>
  </div>
</div>

<div class="table-and-butons">
  <div
    *ngIf="companyId && !companyHasRestrictedAccess && !isViewOnly"
    class="title-right-side mt-3 mr-2">
      <a
        [routerLink]="['invite']"
        class="btn-portal-add mr-4"
        matTooltip="Invite person"
        matTooltipPosition="before"
        matTooltipClass="quartz-tooltip">
        <img class="mb-2" src="assets/images/JMicons/JM-icon-15.png" alt="add">
      </a>
      <a
        (click)="openCompanyLinkingDialog()"
        class="btn-portal-add mr-4"
        matTooltip="Link person to company"
        matTooltipPosition="before"
        matTooltipClass="quartz-tooltip">
        <i class="fas fa-link"></i>
      </a>
  </div>
  <div class="portal-example-pager__content">
    <div class="example-container list-container">
      <kendo-grid class="no-scrollbar" (dataStateChange)="dataStateChange($event)"
        [data]="dataSource"
        [loading]="loadingData"
        [pageSize]="state.take"
        [skip]="state.skip"
        [sort]="state.sort"
        [filter]="state.filter"
        [sortable]="true"
        [pageable]="false"
        filterable="menu">
        <kendo-grid-column media="(max-width: 680px)" title="Persons">
          <ng-template kendoGridCellTemplate let-person>
            <!-- template for mobile -->
            <div class="mobile-container">
              <div class="action-buttons">
                <mat-icon
                  *ngIf="person? isPersonReinvitable(person) : false"
                  (click)="resendInvite(person.email)"
                  class="cursorButtons" 
                  matTooltip="Resend Invite"
                  matTooltipPosition="before"
                  matTooltipClass="quartz-tooltip">send</mat-icon>
                <mat-icon 
                  [routerLink]="['/persons/details', person.id]"
                  class="cursorButtons" 
                  matTooltip="Edit person details"
                  matTooltipPosition="before"
                  matTooltipClass="quartz-tooltip">edit</mat-icon>
                <mat-icon
                  *ngIf="isSuperOrPlatformAdmin || isMembershipAdmin"
                  (click)="openDeleteDialog(person.id)" 
                  class="cursorButtons" 
                  matTooltip="Delete person"
                  matTooltipPosition="before"
                  matTooltipClass="quartz-tooltip">delete</mat-icon>
                <mat-icon 
                  *ngIf="person.companyId"
                  [routerLink]="['/companies/detail', person.companyId]"
                  class="cursorButtons" 
                  matTooltip="View Person company"
                  matTooltipPosition="before"
                  matTooltipClass="quartz-tooltip">work</mat-icon>
                <mat-icon
                  *ngIf="!person.companyId"
                  class="cursorButtons" 
                  matTooltip="No registered company"
                  matTooltipPosition="before"
                  matTooltipClass="quartz-tooltip">work_off</mat-icon>
              </div>
              <dl>
                <dt>First Name</dt>
                <dd>{{ person.firstName }}</dd>
                <dt>Last Name</dt>
                <dd>{{ person.lastName }}</dd>
                <dt>E-mail</dt>
                <dd>{{ person.email }}</dd>
                <dt>Is Active</dt>
                <dd>{{ person.isActive }}</dd>
                <dt>Status</dt>
                <dd>{{ approvalStatus[person.approvalStatus] | approvalstatus }}</dd>
                <dt>Registration Id</dt>
                <dd>{{ person.registrationId }}</dd>
              </dl>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column media="(min-width: 680px)" field="firstName" title="First Name" [width]="80"></kendo-grid-column>
        <kendo-grid-column media="(min-width: 680px)" field="lastName" title="Last Name" [width]="80"></kendo-grid-column>
        <kendo-grid-column media="(min-width: 680px)" field="email" title="E-mail" [width]="100"></kendo-grid-column>
        <kendo-grid-column media="(min-width: 680px)" field="isActive" title="Is Active" [width]="50">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-boolean-filter-menu [column]="column" [filter]="filter" [filterService]="filterService">
            </kendo-grid-boolean-filter-menu>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column media="(min-width: 680px)" field="approvalStatus" title="Status" [width]="50" class="with-ellipsis">
          <!-- TODO Move the filter to reusable component -->
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <kendo-grid-string-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="eq">
                <kendo-filter-eq-operator></kendo-filter-eq-operator>
                <kendo-filter-neq-operator></kendo-filter-neq-operator>
            </kendo-grid-string-filter-menu>            
          </ng-template>
          <ng-template kendoGridCellTemplate let-person>
            {{ approvalStatus[person.approvalStatus] | approvalstatus }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column media="(min-width: 680px)" field="registrationId" title="Registration Id" [width]="50"></kendo-grid-column>
        <kendo-grid-column media="(min-width: 680px)" title="Actions" headerClass="actions" [width]="80">
          <ng-template kendoGridCellTemplate let-person>
            <div class="text-right">
              <mat-icon
                *ngIf="person? isPersonReinvitable(person) : false"
                (click)="resendInvite(person.email)"
                class="cursorButtons" 
                matTooltip="Resend Invite"
                matTooltipPosition="before"
                matTooltipClass="quartz-tooltip">send</mat-icon>
              <mat-icon
                [routerLink]="['/persons/details', person.id]" 
                class="cursorButtons" 
                matTooltip="Edit person details"
                matTooltipPosition="before"
                matTooltipClass="quartz-tooltip">edit</mat-icon>
              <mat-icon
                *ngIf="(isSuperOrPlatformAdmin || isMembershipAdmin) && !isGuestPerson(person)"
                (click)="openDeleteDialog(person.id)" 
                class="cursorButtons" 
                matTooltip="Delete person"
                matTooltipPosition="before"
                matTooltipClass="quartz-tooltip">delete</mat-icon>
              <mat-icon
                *ngIf="person.companyId"
                [routerLink]="['/companies/detail', person.companyId]"
                class="cursorButtons" 
                matTooltip="View Person company"
                matTooltipPosition="before"
                matTooltipClass="quartz-tooltip">work</mat-icon>
              <mat-icon
                *ngIf="!person.companyId"
                class="cursorButtons" 
                matTooltip="No registered company"
                matTooltipPosition="before"
                matTooltipClass="quartz-tooltip">work_off</mat-icon>            
            </div>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      <app-simple-pagination [hasMore]="hasMore" (pageChangedEvent)="onPageChange($event)"></app-simple-pagination> 
    </div>
  </div>
</div>
