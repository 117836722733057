import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class ProfileService {

  public apiDomain: string;
  public endpoint: string;

  constructor(private http: HttpClient) {
    this.apiDomain = environment.membershipApiUrl;
    this.endpoint = '';
  }

  /**
   * Get all of users location and respective roles within those locations.
   */
  public getLocationsAndRoles() {
    this.endpoint = 'companies/profile/persons';

    return this.http.get(`${this.apiDomain}/api/${this.endpoint}`);
  }

  /**
   * Check if phone number or email already exist on platform.
   *
   * @param email Email value.
   * @param phone Phone number value.
   */
  validateEmail(email = null): Observable<any> {
    this.endpoint = 'persons/profile';
    return this.http
      .post(`${this.apiDomain}/api/${this.endpoint}/validateEmail`, { email});
  }

}
