import { CreateShipment } from './../../models/shipping-permissions.model';
import { ShipmentServicePermission } from './../../models/service-permissions.model';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize, startWith, take, takeWhile } from 'rxjs/operators';
import { PermissionLevel } from '../../enums/permission-level';
import { LayoutService } from '../../../layouts/layout.service';
import { Person } from '../../models/person.model';
import { BusinessServiceType } from '../../../shared/enums/business-service-type';
import { PersonService } from '../../services/person.service';
import { UserRoleCode } from '../../enums/user-role-code';

@Component({
  selector: 'app-shipment-permissions',
  templateUrl: './shipment-permissions.component.html',
  styleUrls: ['./shipment-permissions.component.scss']
})
export class ShipmentPermissionsComponent implements OnInit, OnDestroy {
  @Input() person: Person;
  permissionLevels = [];
  permissionsForm: UntypedFormGroup;
  isLoading = false;

  defaultCreateShipment: CreateShipment = {
    allowed: true,
    domesticShipmentsOnly: false,
    allowShipmentsBelowValue: false,
    allowShipmentsBelowCost: false,
    shipmentsBelowValue: 0,
    shipmentsBelowCost: 0
  };
  defaultShipmentPermission = {
    manageShipments: PermissionLevel.CreatedByEntireOrganization,
    createShipments: this.defaultCreateShipment,
    serviceType: BusinessServiceType.Shipping,
    allowed: false
  };

  shipmentPermission: ShipmentServicePermission;

  hasLoadedPermission: boolean;
  componentActive = true;
  formIsDirty = false;
  isUserBA: boolean;

  get allowShipmentsBelowValueCtrl() { return this.permissionsForm.controls['allowShipmentsBelowValue']; }
  get allowShipmentsBelowCostCtrl() { return this.permissionsForm.controls['allowShipmentsBelowCost']; }
  get isShippingAllowedCtrl() { return this.permissionsForm.controls['isShippingAllowed']; }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private personService: PersonService,
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.formIsDirty = false;
    this.buildPermissionsForm();
    this.listenForPermissionChanges();
    this.shipmentPermission =
      this.person.servicePermissions.find(sp => sp.serviceType === BusinessServiceType.Shipping) as ShipmentServicePermission;
    this.isUserBA = this.person.roles.some(r => r.userRoles.some(role => role.code === UserRoleCode.BusinessAdministrator));
    this.fillPermissionsForm();
    this.fillPermissionsLevels();
  }

  fillPermissionsLevels() {
    if (!this.isUserBA) {
      this.permissionLevels.push({
        'name': 'Created by User',
        'value': PermissionLevel.CreatedByUser
      });
    }
    this.permissionLevels.push({
      'name': 'Created by Entire Organization',
      'value': PermissionLevel.CreatedByEntireOrganization
    });
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  fillPermissionsForm() {
    if (this.shipmentPermission) {
      const createPermissions = this.shipmentPermission.createShipments || this.defaultCreateShipment;
      const { allowed, ...createPermissionsForm } = createPermissions;

      const createShipments = {
        ...createPermissionsForm,
        shipmentsBelowCost: createPermissionsForm.allowShipmentsBelowCost
          ? createPermissionsForm.shipmentsBelowCost.toFixed(2) : null,
        shipmentsBelowValue: createPermissionsForm.allowShipmentsBelowValue
          ? createPermissionsForm.shipmentsBelowValue.toFixed(2) : null
      };
      const value = {
        ...createShipments,
        manageShipments: this.shipmentPermission.manageShipments !== null
        && this.shipmentPermission.manageShipments !== undefined
        && !this.isUserBA
          ? this.shipmentPermission.manageShipments : this.defaultShipmentPermission.manageShipments,
        isShippingAllowed: this.shipmentPermission.allowed
      };
      this.permissionsForm.setValue(value);
      this.hasLoadedPermission = true;
    } else {
      this.shipmentPermission = {...this.defaultShipmentPermission};
      this.hasLoadedPermission = false;
    }
  }

  buildPermissionsForm() {
    this.permissionsForm = this.fb.group({
      manageShipments: this.fb.control(PermissionLevel.CreatedByEntireOrganization),
      domesticShipmentsOnly: this.fb.control(false),
      allowShipmentsBelowValue: this.fb.control(false),
      allowShipmentsBelowCost: this.fb.control(false),
      shipmentsBelowValue: this.fb.control(null, [Validators.required, Validators.min(0)]),
      shipmentsBelowCost: this.fb.control(null, [Validators.required, Validators.min(0)]),
      isShippingAllowed: this.fb.control(false)
    });

    this.permissionsForm.get('shipmentsBelowValue').disable();
    this.permissionsForm.get('shipmentsBelowCost').disable();

  }

  listenForPermissionChanges() {
    this.allowShipmentsBelowValueCtrl.valueChanges
      .pipe(takeWhile(() => this.componentActive))
      .subscribe(allowShipmentsBelowValue => {
        if (allowShipmentsBelowValue) {
          this.permissionsForm.get('shipmentsBelowValue').enable();
        } else {
          this.permissionsForm.get('shipmentsBelowValue').setValue(null);
          this.permissionsForm.get('shipmentsBelowValue').disable();
        }
      });

    this.allowShipmentsBelowCostCtrl.valueChanges
      .pipe(
        takeWhile(() => this.componentActive))
      .subscribe(allowShipmentsBelowCost => {
        if (allowShipmentsBelowCost) {
          this.permissionsForm.get('shipmentsBelowCost').enable();
        } else {
          this.permissionsForm.get('shipmentsBelowCost').setValue(null);
          this.permissionsForm.get('shipmentsBelowCost').disable();
        }
      });

  }

  /**
   * Return true if control is touched or dirty and has invalid state
   *
   * @param controlName Name of the control
   */
  hasErrorOfType(controlName: string, errorType: string): boolean {
    if (!this.permissionsForm) {
      return false;
    }
    const ctrl = this.permissionsForm.controls[controlName];
    return ctrl && (ctrl.touched || ctrl.dirty) && ctrl.hasError(errorType);
  }

  /**
   * On form submit event handler used to persist changes.
   */
  onSubmit() {
    this.fillShipmentPermissionData();

    this.isLoading = true;
    this.personService.update(this.person.id, this.person)
      .pipe(take(1), finalize(() => this.isLoading = false))
      .subscribe(res => {
        this.layoutService.showUIMessage('Shipment permissions data updated.');
      },
      err => {
        this.layoutService.showUIMessage('Unable to update the data for shipment permissions.');
      });

    this.formIsDirty = false;
  }

  /**
   * Gather and populate shipment permission data.
   */
  fillShipmentPermissionData() {
    const currentFormRawValue = this.permissionsForm.getRawValue();
    this.shipmentPermission.createShipments = {
      allowed: true,
      domesticShipmentsOnly: currentFormRawValue.domesticShipmentsOnly,
      allowShipmentsBelowValue: currentFormRawValue.allowShipmentsBelowValue,
      allowShipmentsBelowCost: currentFormRawValue.allowShipmentsBelowCost,
      shipmentsBelowCost: currentFormRawValue.shipmentsBelowCost,
      shipmentsBelowValue: currentFormRawValue.shipmentsBelowValue
    };

    this.shipmentPermission.manageShipments = currentFormRawValue.manageShipments;
    this.shipmentPermission.allowed = currentFormRawValue.isShippingAllowed;

    if (!this.hasLoadedPermission) {
      this.person.servicePermissions.push(this.shipmentPermission);
    }
  }

  /**
   * Close both popovers if user clicked anywhere on page
   * Close first popover if second is opened
   *
   * @param e click event
   */
  hidePopovers(e) {
    const create = document.getElementById('create-shipments-info');
    const manage = document.getElementById('manage-shipments-info');

    if (manage == null || create == null) {
      return;
    }

    // two popovers are open, close first that was opened
    if (create.hasAttribute('aria-describedby') && manage.hasAttribute('aria-describedby')) {
      if (create === e.target) {
        manage.click();
      }
      if (manage === e.target) {
        create.click();
      }
    } else if (create.hasAttribute('aria-describedby') && create !== e.target && manage !== e.target) {
      // create popover is opened, and we didn't click to open another one so close this one
      create.click();
    } else if (manage.hasAttribute('aria-describedby') && create !== e.target && manage !== e.target) {
      // manage popover is opened, and we didn't click to open another one so close this one
      manage.click();
    }
  }

  setFormToDirty() {
    this.formIsDirty = true;
  }

}
