import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '../../guards/leave.guard';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';

export class ReportDeactivate implements CanComponentDeactivate {

  reportGenerationInProgress: boolean;
  content = 'Leaving this page will cancel the report generation.';

  protected dialog: MatDialog;

  constructor() { }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.reportGenerationInProgress) {
      return this.openConfirmationDialog();
    }
    return true;
  }

  /**
   * Opens the dialog to ask the user to confirm navigating away
   */
  openConfirmationDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        content: this.content,
        title: 'Are you sure you want to leave?'
      }
    });
    return dialogRef.afterClosed();
  }
}
