import { LayoutService } from './../../../layouts/layout.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Subscription } from 'rxjs';
import { ApiHealth } from '../../models/api-health';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { UserRoleCode } from '../../../persons/enums/user-role-code';
import { map, take } from 'rxjs/operators';
import { AppConfigurationService } from '../../services/app-configuration.service';
import { FeatureName } from '../../enums/feature-name';
import { ModuleName } from '../../enums/module-name';
import { moduleRoleAccess } from '../../../module-role-access';
import { availableHealthChecks } from './available-health-checks';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss']
  })
export class HealthCheckComponent implements OnInit, OnDestroy {

  availableHealthChecks = availableHealthChecks;

  // Contains a list of available APIs
  get apiList() { return this.layoutService.apiList; }

  sidenavSections = [];

  // Keep the references on all subscription objects
  subscriptions = new Array<Subscription>();
  visibleSection: { name: string; visibility: boolean };

  studioEnabled$ = this.appConfigurationService.getFeatureFlag(FeatureName.ZingStudioActive);
  jewelerPagesEnabled$ = this.appConfigurationService.getFeatureFlag(FeatureName.ZingJewelerPagesActive);

  get isCarePlanAdmin() {
    return this.authService
      .userPlatformRoles
      .some(role =>
        role.code === UserRoleCode.JMCarePlanAdministrator
      || role.code === UserRoleCode.JMCarePlanSales
      || role.code === UserRoleCode.JMCarePlanFinance
      || role.code === UserRoleCode.JMCarePlanClaimProcessor
      || role.code === UserRoleCode.JMCarePlanClaimProcessingManager
      || role.code === UserRoleCode.JMCarePlanCallCenter
      );
  }

  get isOtherAdmins() {
    return this.authService.isSuperOrPlatformAdministrator
        || this.authService.isShippingAdmin
        || this.authService.isCommercialLinesAdmin
        || this.authService.isPersonalLinesUser
        || this.authService.isAppraisalAdmin
        || this.authService.isPartnerGatewayAdmin
        || this.authService.isMarketplaceAdmin
        || this.authService.isPlatformSupportLevelOne
        || this.authService.isPOSAdmin
        || this.authService.isStudioAdmin
        || this.authService.isJewelerPagesAdmin
        || this.authService.isAuctionAdmin
        || this.authService.isViewOnly;
  }

  get isAdmin() {
    return this.authService
      .userPlatformRoles
      .some(role =>
        role.code === UserRoleCode.SuperAdministrator
      || role.code === UserRoleCode.PlatformAdministrator
      || role.code === UserRoleCode.ViewOnly
      );
  }

  constructor(
    public matAlert: MatDialog,
    private http: HttpClient,
    private router: Router,
    private authService: AuthService,
    private layoutService: LayoutService,
    private appConfigurationService: AppConfigurationService
  ) {
    this.sidenavSections = this.layoutService.getSidebarSectionVisibility();
  }

  ngOnInit() {
    this.getFeatureFlags();
  }

  private getFeatureFlags() {
    if (this.layoutService.isServiceStatusLoaded) {
      return;
    }

    const requests$ = [
      this.jewelerPagesEnabled$.pipe(take(1)),
      this.studioEnabled$.pipe(take(1))
    ];
    forkJoin(requests$).subscribe(([isJewelerPagesEnabled, isStudioEnabled]) => {
      if (!isJewelerPagesEnabled) {
        this.availableHealthChecks = this.availableHealthChecks.filter(api => api.name !== ModuleName.JewelerPages);
      }

      if (!isStudioEnabled) {
        this.availableHealthChecks = this.availableHealthChecks.filter(api => api.name !== ModuleName.Studio);
      }

      this.refresh();
    });

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  isLogVisible() {
    if ((this.authService.isJewelerProgramsFinance
      || this.authService.isMembershipReportViewer)
      && !(this.isCarePlanAdmin|| this.isOtherAdmins)) {
      return false;
    }
    return true;
  }

  toggleSectionVisibility(sectionName: string) {
    this.layoutService.toggleSidebarSection(sectionName);
    this.navigateToFirstItemInSection(sectionName);
  }

  refresh() {
    this.layoutService.apiList = [];

    this.availableHealthChecks.forEach(healthCheck => {
      const rolesAllowedToAccessHealthCheck = moduleRoleAccess.forModule(healthCheck.name as ModuleName);
      const isAllowedToAccessHealthCheck = this.authService.hasAnyRole(rolesAllowedToAccessHealthCheck);
      if(isAllowedToAccessHealthCheck){
        this.layoutService.apiList.push(healthCheck);
      }
    });

    this.layoutService.isServiceStatusLoaded = true;
    this.visibleSection = this.layoutService.getSidebarSectionVisibility().find(s => s.visibility);
    this.apiList.forEach(api => this.updateApiStatus(api));
  }

  navigateToFirstItemInSection(sectionName: string) {
    let url = '';

    switch (sectionName) {
      case 'Communication':
        url = this.isAdmin ? '/communications/messaging-account/list' : '/communications/templates/list';
        break;
      case 'Risk':
        url = this.authService.isRegulatoryComplianceAdministrator ? '/risk/ofac-screening' : '/risk/master-configurations';
        break;
      case 'Membership':
        url = this.authService.isMembershipReportViewer || this.authService.isJewelerProgramsFinance ? '/reporting' : '/companies';
        break;
      case 'Content':
        url = '/content/placeholder/list';
        break;
      case 'Identity':
        url = this.authService.isSuperOrPlatformAdministrator || this.authService.isViewOnly ? '/terms-and-conditions' : '/identity/users';
        break;
      case 'Appraisals':
        url = '/appraisals/reporting';
        break;
      case 'Diamond Marketplace':
        url = '/diamondmarketplace';
        break;
      case 'Point Of Sale Integration':
        url = this.authService.isCustomerService ?  '/pointofsaleintegration/transactions' : '/pointofsaleintegration/reporting';
        break;
      case 'Studio':
        url = '/studio';
        break;
      case 'Memo':
        url = '/memo/reporting';
        break;
      case 'Jeweler Pages':
        url = '/jewelerpages/reporting';
        break;
      case 'Auction':
        url = '/auction/reporting';
        break;
      case 'Promo Code':
        url = '/promocodes';
        break;
      default:
        break;
    }

    if (url.length > 0) {
      this.router.navigateByUrl(url);
    }
  }

  isSectionVisible(sectionName: string) {
    const section = this.sidenavSections.find(s => s.name === sectionName);

    return section && section.visibility;
  }

  /**
   * Return true if swagger is enabled for specific API
   *
   * @param api Object containing data for the API
   */
  isSwaggerEnabled(api: ApiHealth): boolean {
    return api.swaggerLink && api.status === 'Healthy';
  }

  getLogsLink(apiName: string) {
    if (apiName === 'Membership') {
      return `/party/logs`.toLowerCase();
    } else if (apiName === 'Communication') {
      return '/communications/logs';
    }

    return `/${apiName.replace(/ /g, '')}/logs`.toLowerCase();
  }

  getConfigureTooltipText(apiName: string) {
    return `Configure ${apiName} services`;
  }

  getHealthCheckDetailsLink(apiName: string) {
    if (apiName === 'Membership') {
      return `/party/health-check/details`.toLowerCase();
    } else if (apiName === 'Communication') {
      return '/communications/health-check/details';
    }

    return `/${apiName.replace(/ /g, '')}/health-check/details`.toLowerCase();
  }

  /**
   * Update API status
   *
   * @param api API which status need to be updated
   */
  onRefresh(api: ApiHealth): void {
    this.updateApiStatus(api);
  }

  /**
   *  Make an GET request to update API status
   *
   * @param api API which status needs to be updated
   */
  updateApiStatus(api: ApiHealth): void {
    api.status = 'Checking';
    const sub = this.http.get(api.url)
      .pipe(
        map((data: any) => {
          if (data.code == null) {
            return this.mapToHealthDetailsViewModel(data);
          }

          return data;
        }))
      .subscribe(
        (data: any) => {
          api.status = data.code === 200 ? 'Healthy' : 'Error';
          api.details = data;
        },
        () => api.status = 'Error'
      );

    this.subscriptions.push(sub);
  }

  mapToHealthDetailsViewModel(data: any): any {
    const HealthyStatus = 'Healthy';
    const LegacyOkCode = 200;
    const LegacyErrorCode = 503;
    const result: any = {};
    result.code = data.status === HealthyStatus ? LegacyOkCode : LegacyErrorCode;
    result.checks = [];

    const entries = data.entries;
    for (const property in entries) {
      if(entries.hasOwnProperty(property)) {
        result.checks.push({
          name: property,
          message: entries[property].description || 'OK',
          elapsed: entries[property].duration,
          exception: entries[property].exception,
          isHealthy: entries[property].status === HealthyStatus
        });
      }
    }

    return result;
  }

  showDetails(api: ApiHealth) {
    let url: string;

    if (api.name === 'Communication') {
      url = '/communications/health-check/details';
    } else {
      url = `/${api.name.replace(/ /g, '')}/health-check/details`;
    }

    this.router.navigateByUrl(url.toLowerCase());
  }

  configurable(apiName: string) {
    return !['Shipping', 'Payment', 'Billing'].includes(apiName);
  }
}
