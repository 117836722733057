<div fxLayout="column" fxFlexFill>
  <mat-toolbar class="portal-side-menu-top-toolbar">
    <mat-toolbar-row fxLayout="row">
      <span class="portal-toolbar-brand-text" i18n>ZING</span>
      <span class="portal-toolbar-brand-sub-text" i18n>Platform Services</span>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="portal-sidnav-menu portal-hide-scrollbars" fxFlex="nogrow noshrink">
    <mat-nav-list>
      <a mat-list-item routerLink="/dashboards/home" routerLinkActive="portal-menu--active">
        <h3 matLine>
          <span class="nav-icon">
            <img src="assets/images/JMicons/JM-icon-1.png" alt="icon" class="menu-icon">
          </span>
          Dashboard
        </h3>
      </a>
    </mat-nav-list>

    <!-- KPI Dashboard menu -->
    <mat-expansion-panel expanded="false" *ngIf="showKpiDashboard()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <a mat-list-item [routerLink]="getKpiLink()" class="text-white">
            <h3 matLine class="p-0">
              <span class="nav-icon">
                <img src="assets/images/JMicons/JM-icon-1.png" alt="icon" class="menu-icon">
              </span>
              KPI Dashboard
            </h3>
          </a>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <a mat-list-item routerLink="/dashboards/kpi/reporting" routerLinkActive="portal-menu--active">
          <h3 matLine>
            <span class="nav-icon">
              <img src="assets/images/JMicons/directory_white.png" alt="icon">
            </span>
            Reporting
          </h3>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>
    <!-- End of KPI Dashboard menu -->

    <!-- Communication service menu -->
    <mat-expansion-panel expanded="true" *ngIf="sidenavSections[0].visibility">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="nav-icon">
            <img src="assets/images/icons/Communication-white.svg" alt="icon" class='main-menu-icon'>
          </span>
          <h3>Communication</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <a mat-list-item *ngFor="let item of getCommunicationItems()"
          [routerLink]="item.link" 
          routerLinkActive="portal-menu--active">
          <h3 matLine>
            <span class="nav-icon">
              <img [src]="item.icon" alt="icon">
            </span>
            {{ item.displayText }}
          </h3>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>
    <!-- End of Communication service menu -->

    <!-- Risk service menu -->
    <mat-expansion-panel expanded="true" *ngIf="sidenavSections[1].visibility">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="nav-icon">
            <img src="assets/images/icons/Risk-white.svg" alt="icon" class='main-menu-icon'>
          </span>
          <h3>Risk</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <a mat-list-item *ngFor="let item of getRiskItems()"
          [routerLink]="item.link" [fragment]="item.fragment"
          routerLinkActive="portal-menu--active"
          [routerLinkActiveOptions]="ofacRouterLinkOptions">
          <h3 matLine>
            <span class="nav-icon">
              <img [src]="item.icon" alt="icon">
            </span>
            {{ item.displayText }}
          </h3>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>
    <!-- End of Risk service menu -->

    <!-- Membership service menu -->
    <mat-expansion-panel expanded="true" *ngIf="sidenavSections[2].visibility">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="nav-icon">
            <img src="assets/images/icons/Membership-white.svg" alt="icon" class='main-menu-icon'>
          </span>
          <h3>Membership</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <a mat-list-item *ngFor="let item of getMembershipMenuItems()"
          [routerLink]="item.link" 
          routerLinkActive="portal-menu--active">
          <h3 matLine>
            <span class="nav-icon">
              <img [src]="item.icon" alt="icon">
            </span>
            {{ item.displayText }}
          </h3>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>
    <!-- End of Membership service menu -->

    <!-- Entity service menu -->
    <mat-expansion-panel expanded="true" *ngIf="sidenavSections[3].visibility" >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="nav-icon">
            <img src="assets/images/icons/Identity-white.svg" alt="icon" class='main-menu-icon'>
          </span>
          <h3>Entity</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <a mat-list-item *ngFor="let item of getEntityItems()"
          [routerLink]="item.link" 
          routerLinkActive="portal-menu--active">
          <h3 matLine>
            <span class="nav-icon">
              <img [src]="item.icon" alt="icon">
            </span>
            {{ item.displayText }}
          </h3>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>
    <!-- End of Entity service menu -->

    <!-- Content service menu -->
    <mat-expansion-panel expanded="true" *ngIf="sidenavSections[4].visibility">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="nav-icon">
            <img src="assets/images/icons/Content-white.svg" alt="icon" class='main-menu-icon'>
          </span>
          <h3>Content</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <a mat-list-item *ngFor="let item of getContentItems()"
          [routerLink]="item.link" 
          routerLinkActive="portal-menu--active">
          <h3 matLine>
            <span class="nav-icon">
              <img [src]="item.icon" alt="icon">
            </span>
            {{ item.displayText }}
          </h3>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>
    <!--End of Content service menu -->

    <!-- Identity service menu -->
    <mat-expansion-panel expanded="true" *ngIf="sidenavSections[5].visibility">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="nav-icon">
            <img src="assets/images/icons/Identity-white.svg" alt="icon" class='main-menu-icon'>
          </span>
          <h3>Identity</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <a mat-list-item *ngFor="let item of identityItems$ | async"
          [routerLink]="item.link" 
          routerLinkActive="portal-menu--active">
          <h3 matLine>
            <span class="nav-icon">
              <img [src]="item.icon" alt="icon">
            </span>
            {{ item.displayText }}
          </h3>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>
    <!--End of Identity service menu -->

    <!-- Appraisals service menu -->
    <mat-expansion-panel expanded="true" *ngIf="sidenavSections[6].visibility">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="nav-icon">
            <img src="assets/images/icons/Billing-white.svg" alt="icon" class='main-menu-icon'>
          </span>
          <h3>Appraisals</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <a mat-list-item *ngFor="let item of getAppraisalsItems()"
          [routerLink]="item.link" 
          routerLinkActive="portal-menu--active">
          <h3 matLine>
            <span class="nav-icon">
              <img [src]="item.icon" alt="icon">
            </span>
            {{ item.displayText }}
          </h3>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>
    <!--End of Appraisals service menu -->
    
    <!-- POSI service menu -->
    <mat-expansion-panel expanded="true" *ngIf="sidenavSections[7]?.visibility">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="nav-icon">
            <img src="assets/images/icons/Billing-white.svg" alt="icon" class='main-menu-icon'>
          </span>
          <h3>Point Of Sale Integration</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list>
        <a mat-list-item *ngFor="let item of getPosiItems()"
          [routerLink]="item.link" 
          routerLinkActive="portal-menu--active">
          <h3 matLine>
            <span class="nav-icon">
              <img [src]="item.icon" alt="icon">
            </span>
            {{ item.displayText }}
          </h3>
        </a>
      </mat-nav-list>
    </mat-expansion-panel>
    <!--End of POSI service menu -->

  </div>
</div>