import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { encodeBase64 } from '@progress/kendo-file-saver';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, skip, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  ReportPrevalidateResponse,
  ReportRequest,
  ScheduledReportRequestViewModel,
  ScheduledReportsStatus
} from '../../shared/models/report-request.model';
import { AuthService } from '../auth.service';
import { ZingHubHttpClient } from '../components/signalr-clients/zing-hub-http-client';
import { BaseHttpService } from './base-http.service';

export enum REPORT_GENERATION {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

/*  This service is used for generic reports of xlsx and pdf type,
//  see ReportRequest for format of generating a report'  */
@Injectable()
export class ReportingService extends BaseHttpService<ReportRequest> {

  dataReceived = new EventEmitter<object>();
  requestInProgress$ = new BehaviorSubject(false);

  private connectionEstablished$ = new BehaviorSubject(false);
  private _hubConnection: HubConnection;
  private groupId = null;
  hubConnectionRetryCount = 0;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    super(http);
    this.apiDomain = environment.reportingApiUrl;
    this.endpoint = 'Reports';
    this.createConnection();
    this.registerOnServerEvents();
  }

  private createConnection(): void {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.apiDomain}/reporthub`, {
        httpClient: new ZingHubHttpClient(),
        accessTokenFactory: () =>  this.authService.accessToken
      })
      .withHubProtocol(new MessagePackHubProtocol())
      .build();
  }

  /**
   * Starts a connection with the Hub
   */
  private startConnection(): void {
    if (this.connectionEstablished$.value || this.groupId == null) {
      throw new Error('Connection already established or groupId is null.');
    }

    this._hubConnection
      .start()
      .then(() => this.subscribeToGroup())
      .then(() => this.connectionEstablished$.next(true))
      .catch(() => {
        // retry 3 times if error
        if (this.hubConnectionRetryCount < 3) {
          this.hubConnectionRetryCount++;
          this.startConnection();
        } else {
          this.endConnection();
        }
      });
  }

  /**
   * Registers the possible events that may be generated by the hub
   */
  private registerOnServerEvents(): void {
    this._hubConnection.on('messageReceived', (data: any) => {
      this.dataReceived.emit(data);
    });

    this._hubConnection.on('reportGenerated', (data: any) => {
      this.dataReceived.emit({ status: REPORT_GENERATION.SUCCESS, uri: data });
      this.endConnection();
    });

    this._hubConnection.on('reportFailed', (data: any) => {
      this.dataReceived.emit({
        status: REPORT_GENERATION.FAILURE,
        error: data
      });
      this.endConnection();
    });
  }

  /**
   * Ends the connection with the Hub
   */
  private endConnection(): void {
    if (this.connectionEstablished$.value) {
      this.unsubscribeFromGroup()
        .then(() => this._hubConnection.stop())
        .then(() => this.reset())
        .catch(() => this.reset());
    } else {
      this.reset();
    }
  }

  /**
   * Resets the field values
   */
  private reset(): void {
    this.connectionEstablished$.next(false);
    this.requestInProgress$.next(false);
    this.groupId = null;
  }

  /**
   * Internal subscribe to the group the connection is at
   */
  private async subscribeToGroup() {
    return this._hubConnection.invoke('subscribeToGroup', this.groupId);
  }

  /**
   * Internal unsubscribe from the group the connection is at
   */
  private async unsubscribeFromGroup() {
    return this._hubConnection.invoke('unsubscribeFromGroup', this.groupId);
  }

  /**
   * Sets the group for the client to listen on for report update
   *
   * @param groupId The identifier returned by the api call when requesting a report
   */
  private initializeGroup(groupId: string) {
    if (this.groupId == null) {
      this.groupId = groupId;
    }
  }

  /**
   * Makes a request to the report api for a report
   *
   * @param requestData The necessary data for the report service
   */
  public requestReport(
    requestData: ReportRequest
  ): Observable<ReportPrevalidateResponse> {
    return this.http.post<ReportPrevalidateResponse>(
      `${this.apiDomain}/api/${this.endpoint}`,
      requestData
    );
  }

  /**
   * Makes a request to the report api for a report
   *
   * @param requestData The necessary data for the report service
   */
  public requestScheduleReports(
    requestData: ScheduledReportRequestViewModel
  ): Observable<ReportPrevalidateResponse> {
    return this.http.post<ReportPrevalidateResponse>(
      `${this.apiDomain}/api/${this.endpoint}/schedule`,
      requestData
    );
  }

  /**
   * Process Request Report
   *
   * @param requestid
   */
  public processReport(
    requestid: string
  ): Observable<ReportPrevalidateResponse> {
    return this.http.get<ReportPrevalidateResponse>(
      `${this.apiDomain}/api/${this.endpoint}/${requestid}/process`
    );
  }

  /**
   * Downloads the report from the specified url
   *
   * @param url The uri containing the blob
   */
  public receiveReport(uri: string): Observable<Blob> {
    return this.http.get<Blob>(`${this.apiDomain}${uri}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        Authorization: 'Bearer ' + this.authService.accessToken
      }),
      responseType: 'blob' as 'json'
    });
  }

  /**
   * Request a report, connects to report request hub, then processes a it
   *
   * @param requestData
   */
  public processReportRequest(requestData: ReportRequest): Observable<any> {
    if (this.requestInProgress$.value) {
      return throwError('Request already in progress');
    }

    let requestId = '';
    return of({}).pipe(
      tap(() => this.requestInProgress$.next(true)),
      switchMap(() => this.requestReport(requestData)),
      switchMap(res => {
        requestId = res.id;
        this.hubConnectionRetryCount = 0;
        this.initializeGroup(requestId);
        this.startConnection();
        return this.connectionEstablished$;
      }),
      skip(1), // skip initial false
      take(1),
      switchMap(isConnected =>
        isConnected
          ? this.processReport(requestId)
          : throwError('Could not connect to reporting hub.')
      ),
      catchError(err => {
        this.endConnection();
        return throwError(err);
      })
    );
  }

  public processReportNoUpdate(requestData: ReportRequest) {
    const scheduledReport = new ScheduledReportRequestViewModel();
    scheduledReport.endDate = requestData.endDate;
    scheduledReport.startDate = requestData.startDate;
    scheduledReport.period = requestData.reportPeriod;
    scheduledReport.reportType = requestData.reportType;

    if(requestData.reports) {
      return this.requestScheduleReports(scheduledReport)
        .pipe(take(1));
    }
    return this.requestReport(requestData)
      .pipe(take(1), switchMap(res => this.processReport(res.id)));
  }

  public receiveDashboardReport(year: number): Observable<any> {
    const oDataModel = {
      filter: `Year eq ${year}`
    };

    return this.http.post<any>(`${this.apiDomain}/api/DashboardReport/search`, oDataModel);
  }

  public lastScheduledReportsStatuses(): Observable<ScheduledReportsStatus> {
    return this.http.get<ScheduledReportsStatus>(
      `${this.apiDomain}/api/${this.endpoint}/schedule/laststatus`
    );
  }

  public cancelScheduledReport(id: string, reasonForCancelation: string): Observable<any>  {
    const reason = {'reasonForCancelation': encodeBase64(reasonForCancelation)};
    return this.http.put<any>(`${this.apiDomain}/api/Reports/${id}/cancel`,reason);
  }
}
