import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

export function endDateLessThanStartDate(
  control: UntypedFormGroup
): { [key: string]: boolean } | null {
  const startDate = moment(control.get('startDate').value).toDate();
  const endDate = moment(control.get('endDate').value).toDate();
  return startDate > endDate ? { endDateLessThanStartDate: true } : null;
}

export function dateRangeValidator(firstDateControl: string, secondDateControl: string): ValidatorFn {
  return (control: UntypedFormGroup): ValidationErrors | null => {
    const startDate = moment(control.get(firstDateControl).value).toDate();
    const endDate = moment(control.get(secondDateControl).value).toDate();
    return startDate > endDate ? { endDateLessThanStartDate: true } : null;
  };
}

export function affiliateEndDateLessThanStartDate(
  control: UntypedFormGroup
): { [key: string]: boolean } | null {
  return control.value.startDate && control.value.endDate && control.value.startDate > control.value.endDate ?
    { affiliateEndDateLessThanStartDate: true } : null;
}

export function dateRangeGreaterThanNinety(
  control: UntypedFormGroup
): { [key: string]: boolean } | null {
  const startDate = moment(control.get('startDate').value);
  const endDate = moment(control.get('endDate').value);
  return endDate.diff(startDate, 'day') > 90 ? { dateRangeGreaterThanNinety: true } : null;
}

export function dateRangeBothOrNoneRequired(
  form: UntypedFormGroup
): { [key: string]: boolean } | null {
  const startDate = form.get('startDate').value;
  const endDate = form.get('endDate').value;
  if (startDate && !endDate) {
    return { 'endDateRequired': true };
  } else if (!startDate && endDate) {
    return { 'startDateRequired': true };
  }
  return null;
}

export function dateRangeGreaterThanAYear(
  control: UntypedFormGroup
): { [key: string]: boolean } | null {
  const startDate = moment(control.get('startDate').value);
  const endDate = moment(control.get('endDate').value);
  return endDate.diff(startDate, 'day') > 365 ? { dateRangeGreaterThanAYear: true } : null;
}
