import { Component } from '@angular/core';
import { AuthService } from '../../../shared/auth.service';

@Component({
  selector: 'portal-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {

  public imageUrl = 'assets/images/layouts/avatar.png';

  constructor(
    private authService: AuthService,
  ) { }

  // Return the name for current user
  public showUserName() {
    return this.authService.userName;
  }

  // Handles clicking on the 'Sign out' button
  public signOut() {
    this.authService.logout();
  }
}
