import { environment } from '../../environments/environment';
import {
  OpenIdConfiguration,
  AuthWellKnownEndpoints,
  LogLevel
} from 'angular-auth-oidc-client';

export function getOidcConfig(): OpenIdConfiguration {
  const domain = window.location.origin;

  const config: OpenIdConfiguration = {
    authority: environment.identityApiUrl,
    redirectUrl: `${domain}/external/callback`,
    clientId: environment.clientId,
    responseType: 'code',
    // resource: 'partyAPI',
    scope: 'openid profile partyAPI platformAPI',
    // hd_param: string,
    postLogoutRedirectUri: `${domain}/external/login`,
    startCheckSession: true,
    silentRenew: true,
    silentRenewUrl: `${domain}/silent-renew.html`,
    // silent_renew_offset_in_seconds: number,
    // silent_redirect_url: string,
    postLoginRoute: '/dashboards/home',
    // HTTP 403
    forbiddenRoute: '/external/login',
    // HTTP 401
    unauthorizedRoute: '/signin',
    // auto_userinfo: boolean,
    // auto_clean_state_after_authentication: boolean,
    // trigger_authorization_result_event: boolean,
    logLevel: environment.authLogConsoleDebugActive ? LogLevel.Debug : environment.authLogConsoleWarningActive
      ? LogLevel.Warn : LogLevel.Error,
    maxIdTokenIatOffsetAllowedInSeconds: environment.authMaxTokenOffset,
    // config.storage: any;
    authWellknownEndpoints: getWellKnownEndpoints()
  };

  return config;
}

export function getWellKnownEndpoints(): AuthWellKnownEndpoints {
  const endpoints: AuthWellKnownEndpoints = {
    issuer: environment.identityApiUrl,
    jwksUri: environment.identityApiUrl + `/.well-known/openid-configuration/jwks`,
    authorizationEndpoint: environment.identityApiUrl + `/connect/authorize`,
    tokenEndpoint: environment.identityApiUrl + `/connect/token`,
    userInfoEndpoint: environment.identityApiUrl + `/connect/userinfo`,
    endSessionEndpoint: environment.identityApiUrl + `/connect/endsession`,
    checkSessionIframe: environment.identityApiUrl + `/connect/checksession`,
    revocationEndpoint: environment.identityApiUrl + `/connect/revocation`,
    introspectionEndpoint: environment.identityApiUrl + `/connect/introspect`,
  };

  return endpoints;
}
