<p class="portal-top-text">Select type of report to be generated</p>
<div class="portal-example-page zing-first-container">
  <div class="portal-example-pager__content">
    <div class="example-container list-container position-relative">
      <div *ngIf="reports.length === 0">
        No types of reports defined
      </div>
      <div class="row">
        <div *ngFor="let report of reports" [ngClass]="{ 'col-sm-12 col-md-6 col-lg-3 mb-sm-0 mb-3': report.display }">
          <app-report-summary-card *ngIf="report.display" 
            (reportGenerationInProgress)="setReportRequested(report.type, $event)"
            [reportDetails]="report" 
            [showGenerateReport]="report.showGenerateReport" 
            [oldReport]="isOldReport(report.type)" 
            [enableReportGeneration]="report.enabled" 
            [reportCompletion]="scheduledReportsStatuses.reportCompletion">
          </app-report-summary-card>
        </div>
      </div>
    </div>
  </div>
</div>
<app-report-list 
[reportRequested]="reportRequested"></app-report-list>
