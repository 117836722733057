<div class="confirmation">
    <h2 mat-dialog-title><mat-icon color="warn">warning</mat-icon> Warning</h2>
    <p>
        You are setting the company status to deactivated status which will restrict Zing use. Please keep in mind only a Platform Admin can activate company in future.
        <br>
        Are you sure you want to proceed?
    </p>
    <div class="btn-group" role="group">
        <button mat-button [mat-dialog-close]="true">Yes</button>
        <button mat-button mat-dialog-close>No</button>
    </div>
</div>
