import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';

import { UserRole } from '../../../security/components/user-role/shared/user-role.model';
import { PersonRole } from '../../models/person-role.model';
import { PersonUserRole } from '../../models/person-user-role.model';
import { CompanyLocation } from '../../../locations/models/company-location';
import { Person } from '../../models/person.model';
import { PersonService } from '../../services/person.service';
import { Subscription } from 'rxjs';
import { UserRoleCode } from '../../enums/user-role-code';

@Component({
  selector: 'app-location-roles',
  templateUrl: './location-roles.component.html',
  styleUrls: ['./location-roles.component.scss']
})
export class LocationRolesComponent implements OnInit, OnDestroy {

  /**
   * Person instance.
   */
  @Input() person: Person;

  /**
   * Business/Location specific User roles.
   */
  @Input() roles: Array<UserRole> = new Array<UserRole>();

  /**
   * Location for which roles are shown.
   */
  @Input() location: CompanyLocation;

  @Input() disableOwnRoles: boolean;
  @Output() disableSaveButton = new EventEmitter<boolean>();
  @Output() roleSelected = new EventEmitter<boolean>();
  @Output() makeFormDirty = new EventEmitter<boolean>();

  public isLoading = false;

  private companyRoleUpdateSubscription: Subscription;
  showErrorMessage = false;

  constructor(private personService: PersonService) {
  }

  ngOnInit() {
    this.companyRoleUpdateSubscription = this.personService.companyRoleUpdateAnnounced.subscribe((personRole: PersonRole) => {
      this.evalPlatformRoleUpdate(personRole);
    });
  }

  ngOnDestroy() {
    this.companyRoleUpdateSubscription.unsubscribe();
  }

  private evalPlatformRoleUpdate(personRole: PersonRole) {
    if (personRole != null) {
      const userRoles = personRole.userRoles
        .filter(userrole => this.roles.findIndex(r => r.code === userrole.code) !== -1);

      if (userRoles != null && userRoles.length > 0) {
        const personRoleByLocation = this.person.roles.find(pr => pr.locationId === this.location.locationID);
        userRoles.forEach(userRole => {
          if (this.hasRole(userRole.id, this.location.locationID)) {
            personRoleByLocation.userRoles = personRoleByLocation.userRoles.filter(r => r.id !== userRole.id);
            this.showErrorMessage = false;
            this.disableSaveButton.emit(false);
          }
        });
      }
    }
  }

  /**
   * Check if user has specific role for specific location.
   *
   * @param roleId Role unique identifier.
   */
  public hasRole(roleId: string, locationId: string): boolean {
    this.roleSelected.emit(true);
    const personRoleByLocation = this.person.roles.find(pr => pr.locationId === locationId);
    return personRoleByLocation
      ? personRoleByLocation.userRoles.filter(role => role.id === roleId).length > 0
      : false;
  }

  /**
   * On selected event for the Location roles selections.
   *
   * @param checked If checkbox is selected or not.
   * @param role UserRole instance.
   * @param location CompanyLocation instance.
   */
  public onSelected(checked: boolean, userRole: UserRole, locationId: string) {
    this.roleSelected.emit(true);
    this.makeFormDirty.emit(true);
    const personRoleByLocation = this.person.roles.find(pr => pr.locationId === locationId);

    /* If Location instance is found in PersonRoles, then go and add/remove already defined roles for that Location,
       otherwise, create new PersonRole instance and add it to Location which is currently not assigned to that Person. */
    personRoleByLocation
      ? this.addOrRemoveRoleFromExistingLocation(checked, userRole, personRoleByLocation)
      : this.addRoleToNewLocation(userRole, locationId);

  }

  //#region Private methods

  /**
   * Create new role/roles for the Location which Person did not have before.
   *
   * @param checked If checkbox is selected or not.
   * @param userRole UserRole instance.
   * @param location CompanyLocation instance.
   */
  public addRoleToNewLocation(userRole: UserRole, locationId: string, announceUpdate: boolean = true) {
    const roleInstance = {
      id: userRole.id,
      code: userRole.code
    } as PersonUserRole;

    /* Create new PersonRole instance, since it does not exist for selected Location. */
    const newPersonRole = {
      companyId: this.person.companyId,
      locationId,
      roleIds: null,
      userRoles: [roleInstance]
    } as PersonRole;

    /* Add new PersonRole to the list of current Person roles. */
    this.person.roles.push(newPersonRole);
    if (announceUpdate) {
      this.personService.AnnounceLocationRoleUpdate(newPersonRole);
    }
  }

  /**
   * Add new role or remove already added one.
   *
   * @param checked If checkbox is selected or not.
   * @param userRole UserRole instance.
   * @param locationId CompanyLocation unique identifier.
   * @param personRole PersonRole instance which is being updated.
   */
  public addOrRemoveRoleFromExistingLocation(checked: boolean, userRole: UserRole, personRole: PersonRole, announceUpdate: boolean = true) {
    const roleInstance = {
      id: userRole.id,
      code: userRole.code
    } as PersonUserRole;

    const addRepairHasJeweler = roleInstance.code === UserRoleCode.JMCarePlanRepairCenter
      && personRole.userRoles.filter(r => r.code === UserRoleCode.JMCarePlanJeweler).length === 1;
    const addJewelerHasRepair = roleInstance.code === UserRoleCode.JMCarePlanJeweler
      && personRole.userRoles.filter(r => r.code === UserRoleCode.JMCarePlanRepairCenter).length === 1;

    if (checked && (addRepairHasJeweler || addJewelerHasRepair)) {
      this.showErrorMessage = true;
      this.disableSaveButton.emit(true);
    } else {
      this.showErrorMessage = false;
      this.disableSaveButton.emit(false);
    }
    /* If checked, add that role to the array, otherwise, remove it. */
    checked
      ? personRole.userRoles.push(roleInstance)
      : personRole.userRoles = personRole.userRoles.filter(r => r.id !== userRole.id);
    if (announceUpdate) {
      this.personService.AnnounceLocationRoleUpdate(personRole);
    }
  }

  //#endregion
}
