import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BaseHttpService } from './../../shared/services/base-http.service';
import { Company } from './../models/company';
import { Observable } from 'rxjs';
import { PaginatedResponse } from './../../shared/models/paginated.response';
import {
  ProgramEnrollment,
  ProgramEnrollmentData } from '../../program-enrollments/models/program-enrollment.model';
import * as cloneDeep from 'lodash.cloneDeep';
import { encodeBase64 } from '@progress/kendo-file-saver';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseHttpService<Company> {
  constructor(
    private http: HttpClient
  ) {
    super(http);
    this.apiDomain = environment.membershipApiUrl;
    this.endpoint = 'Companies';
  }

  /**
   * Links Company with Person instance.
   *
   * @param email of the Person which we are linking company to.
   * @param companyId Id of Company.
   */
  linkPersonWithCompany(email: string, companyId: string) {
    return this.http.put<boolean>(`${this.apiDomain}/api/${this.endpoint}/associateCompany`, { email, companyId });
  }

  searchCompaniesByName(searchTerm: string): Observable<PaginatedResponse<Company>> {
    const filter = {
      filter: `contains(name,'${searchTerm}')`,
      orderBy: 'CreatedOn desc',
      skip: 0,
      top: 20
    };
    return this.http.post<any>(`${this.apiDomain}/api/${this.endpoint}/search`, filter);
  }

  getIncentiveProgramsForCompany(companyId: string) {
    return this.http.get<any>(`${this.apiDomain}/api/v1/${this.endpoint}/${companyId}/programs`);
  }

  saveProgramEnrollment(companyId: string, programId: string, programEnrollment: ProgramEnrollment) {
    const url = `${this.apiDomain}/api/v1/${this.endpoint}/${companyId}/programs/${programId}/enrollments`;
    return this.http.post<any>(url, new ProgramEnrollmentData(programEnrollment));
  }
}
