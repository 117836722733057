import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedMaterialModule } from '../../../shared/shared-material.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { InProgressModule } from '../../../shared/components/in-progress.module';
import { ScreeningResultsTableComponent } from './screening-results-table.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedMaterialModule,
    GridModule,
    InProgressModule,
    RouterModule
  ],
  declarations: [
    ScreeningResultsTableComponent
  ],
  exports: [
    ScreeningResultsTableComponent
  ]
})

export class ScreeningResultsTableModule {}

