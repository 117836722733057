<form [formGroup]="permissionsForm">
  <div class="shipping-permissions" (click)="hidePopovers($event)">
    <div class="shipping-permission-block">
      <h2>Shipment Permissions?</h2>
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="25">
          <div class="heading-18-bold-onyx" class="permission-shipments-label" id="create-shipments-label">
            Allow Shipment Services
          </div>
        </div>
        <div fxFlex="50">
          <div fxLayout="row" fxLayout.xs="column">
            <mat-checkbox formControlName="isShippingAllowed" (click)="setFormToDirty()">Allowed</mat-checkbox>
          </div>
        </div>
      </div>
    <div *ngIf="isShippingAllowedCtrl.value">
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="25">
          <label class="columnLabel">Action</label>
        </div>
        <div fxFlex="50">
          <label class="columnLabel">Permission Level</label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="25" class="manageShipments">
          <div class="heading-18-bold-onyx" class="permission-shipments-label" id="manage-shipments-label">
            Manage Shipments
            <img src="/assets/images/JMicons/info_outline.png" id="manage-shipments-info" width="20" placement="bottom"
              [ngbPopover]="manageShipmentsPop" #popover="ngbPopover">
          </div>
        </div>
        <div fxFlex="50">
          <mat-form-field>
            <mat-select formControlName="manageShipments" (click)="setFormToDirty()" id="select-persmission-level">
              <mat-option *ngFor="let level of permissionLevels" [value]="level.value">{{ level.name }}</mat-option>
              <mat-error *ngIf="permissionsForm.get('manageShipments').hasError('required')">
                Select Permission Level
              </mat-error>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <hr class="divider" />
      <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="25">
          <div class="heading-18-bold-onyx" class="permission-shipments-label" id="create-shipments-label">
            Create Shipments
            <img src="/assets/images/JMicons/info_outline.png" id="create-shipments-info" width="20" placement="bottom"
              [ngbPopover]="createShipmentsPop" #popover="ngbPopover">
          </div>
        </div>
        <div fxFlex="50">
          <div fxLayout="row" fxLayout.xs="column">
            <!-- Currently hidden -->
            <div fxFlex="50" *ngIf="false">
              <mat-checkbox formControlName="domesticShipmentsOnly" (click)="setFormToDirty()">Domestic Shipments Only</mat-checkbox>
            </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="50" class="mr-5">
              <mat-checkbox formControlName="allowShipmentsBelowValue" (click)="setFormToDirty()">Shipments Below Value</mat-checkbox>
            </div>
            <div fxFlex="50">
              <mat-checkbox formControlName="allowShipmentsBelowCost" (click)="setFormToDirty()">Shipments Below Cost</mat-checkbox>
            </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column">
            <div fxFlex="50" class="mr-5">
              <mat-form-field>
                <input matInput type="text" id="valueInput" formControlName="shipmentsBelowValue" (click)="setFormToDirty()" mask="separator.2"
                  thousandSeparator=","
                  [leadZero]="true" prefix="$" placeholder="Shipment below value">
                <mat-error *ngIf="hasErrorOfType('shipmentsBelowValue', 'required')">
                  Please enter Shipment Below Value
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="50">
              <mat-form-field>
                <input matInput type="text" id="costInput" (click)="setFormToDirty()" formControlName="shipmentsBelowCost" mask="separator.2"
                  thousandSeparator=","
                  [leadZero]="true" prefix="$" placeholder="Shipment below cost">
                <mat-error *ngIf="hasErrorOfType('shipmentsBelowCost', 'required')">
                  Please enter Shipment Below Cost
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="footer">
        <mat-card-actions align="end" fxLayout="row" fxLayout.xs="column">
          <button mat-raised-button color="primary" type="button" [disabled]="permissionsForm.invalid || !formIsDirty" (click)="onSubmit()">Save</button>
        </mat-card-actions>
      </div>
    </div>
  </div>
</form>
<hr class="divider" />

<ng-template #manageShipmentsPop>
  <div class="row info-wrap" id="manageShipmentsPop">
    <div class="col-10 info-head heading-12-onyx mb-1">
      Allow Users to
    </div>
    <mat-icon class="col-2 close-wrap mb-1 text-right" width="12">close</mat-icon>
    <div class="col-12 info-body heading-onyx">
      View shipment details, edit shipments, buy labels, file claims, and complete other shipment-related activities.
    </div>
    <div class="col-12 info-head heading-12-onyx mb-1 mt-2">
      Permission Level
    </div>
    <div class="col-12 info-body heading-onyx">
      Manage shipments created by the entire organization or select Created by User from the drop-down to manage only
      shipments
      the user creates.
    </div>
  </div>
</ng-template>

<ng-template #createShipmentsPop>
  <div class="row info-wrap" id="createShipmentsPop">
    <div class="col-10 info-head heading-12-onyx mb-1">Optional permissions include:</div>
    <mat-icon class="col-2 close-wrap mb-1 text-right" width="12">close</mat-icon>
    <div class="col-12 info-body heading-onyx">
      <div>a. shipments below a specific value, and/or</div>
      <div>b. shipments below a specific cost.</div>
    </div>
  </div>
</ng-template>

<app-in-progress [loading]="isLoading"> </app-in-progress>