export const programEnrolmentAgreementTemplateConfig: { incentiveProgramName: string; template: string }[] = [
  {
    incentiveProgramName: 'JMCC POS Incentive Program - Application Based',
    template: 'JMCC POS Incentive Program - Application Based.pdf'
  },
  {
    incentiveProgramName: 'JMCC POS Incentive Program - Description Based',
    template: 'JMCC POS Incentive Program - Description Based.pdf'
  }
];
