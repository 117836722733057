import { Directive, HostListener, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

import { formatValueWithTwoDecimals } from '../utils/formatters';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[formatDecimals]' })
export class FormatDecimalsDirective implements OnInit {

  get controlValue(): string {
    return this.ngControl.value;
  }

  constructor(
    private ngControl: NgControl,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.onBlur();
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (event.key === '.' && (!this.controlValue || this.controlValue.indexOf('.') !== -1)) {
      event.preventDefault();
    }
  }

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    if (event.key === 'Backspace' && !this.controlValue) {
      this.removeDollarSign();
    }
  }

  @HostListener('blur') onBlur() {
    if (!this.controlValue) {
      this.removeDollarSign();
      return;
    }

    const newValue = formatValueWithTwoDecimals(this.controlValue);

    this.ngControl.control.setValue(newValue);
  }

  removeDollarSign() {
    this.renderer.setProperty(this.el.nativeElement, 'value', '');
  }

}
