import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { moduleNames } from '../../shared/constants';
import { PlaceholderName } from '../models/placeholder-name';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  private placeholderUrl = environment.contentApiURL + '/api/contents';
  private placeholderNames: { [key in PlaceholderName]?: string } = {};
  private helpDeskNumber: string;

  constructor(private http: HttpClient) { }

  getHelpDeskNumber() {
    if (!this.helpDeskNumber) {
      return this.getPlaceholderValue(PlaceholderName.HelpDeskNumber, moduleNames.membership)
        .pipe(
          tap((result: string) => this.helpDeskNumber = result)
        );
    }

    return of(this.helpDeskNumber);
  }

  private getPlaceholderValue(placeholderName: PlaceholderName, moduleName: string) {
    const url = `${this.placeholderUrl}?moduleName=${moduleName}&placeholderName=${placeholderName}`;

    if (!this.placeholderNames[placeholderName]) {
      return this.http.get(url)
        .pipe(
          map(result => result[placeholderName] as string),
          filter(result => !!result),
          tap((result: string) => this.placeholderNames[placeholderName] = result)
        );
    }

    return of(this.placeholderNames[placeholderName]);
  }

}
