import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-activate-deactivate-confirmation',
  templateUrl: './activate-deactivate-confirmation.component.html'
})
export class ActivateDeactivateConfirmationComponent {

  constructor(
    public dialogRef: MatDialogRef<ActivateDeactivateConfirmationComponent>
  ) { }

  closeDialog() {
    this.dialogRef.close();
  }

}
