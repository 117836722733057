<div class="confirmation">
    <h2 mat-dialog-title><mat-icon color="warn">warning</mat-icon>{{ title }}</h2>
    <p>{{ displayText }}</p>
    <div>
        <div *ngIf="isDeleteConfirmed">
            <form [formGroup]="deleteConfirmationForm">
                <mat-form-field>
                  <input 
                    matInput 
                    type="text" 
                    placeholder="Reason to delete" 
                    formControlName="deleteComment">
                  <mat-error 
                    *ngIf="hasErrorOfType('deleteComment', 'required') || hasErrorOfType('deleteComment', 'maxlength')
                          || hasErrorOfType('deleteComment', 'minlength')">
                    Enter text between 25 and 1000 characters
                  </mat-error>
                </mat-form-field>
              </form>
        </div>
        <div class="btn-group" role="group">
            <button *ngIf="isDeleteConfirmed" mat-button [disabled]="deleteConfirmationForm.invalid" [mat-dialog-close]="deleteConfirmationForm.invalid ? '' : deleteCommentCtrl.value">Delete</button>
            <button mat-button mat-dialog-close>{{ isDeleteConfirmed ? 'Cancel' : 'No' }}</button>
            <button *ngIf="!isDeleteConfirmed" mat-button (click)="onConfirmDelete()">Yes</button>
        </div>
    </div>
</div>