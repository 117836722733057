<div class="flex-layout" fxLayout="column" fxLayoutAlign="left">
  <div class="p-4">

    <app-person-update-password
      [generatedPassword]="generatedPassword"
      [userHasEmail] = "userHasEmail"
      (generatePasswordEvent)="generatePassword()"
      (updatePasswordEvent)="updatePassword($event)"> </app-person-update-password>

    <mat-divider class="my-3"></mat-divider>

    <app-person-update-security-question 
      [securityQuestions]="securityQuestions"
      [usersSecurityQuestions]="usersSecurityQuestions"
      (changeSecurityQuestionsEvent)="changeSecurityQuestions($event)">
     </app-person-update-security-question>

  </div>
</div>

<app-in-progress [loading]="isLoading"> </app-in-progress>