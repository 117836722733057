import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import { ReportSettings } from '../../models/report-settings.model';
import { dateRangeGreaterThanAYear, endDateLessThanStartDate } from '../../utils/date-range-validator';

@Component({
  selector: 'app-generate-report-dialog-simple',
  templateUrl: './generate-report-dialog-simple.component.html',
  styleUrls: ['./generate-report-dialog-simple.component.scss']
})
export class GenerateReportDialogSimpleComponent implements OnInit {
  public reportForm: UntypedFormGroup;
  public maxDate = moment().add(1, 'days').endOf('day').toDate();
  public reportType: string;
  public displayTestDataOptions: boolean;
  public buttonLabel: string;
  public reportName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<GenerateReportDialogSimpleComponent>
  ) {
    this.reportType = data.reportType;
    this.displayTestDataOptions = data.displayTestDataOptions;
    this.reportName = data.reportName ?? 'Report Settings';
    this.buttonLabel = data.buttonLabel ?? 'Request a Report';
  }

  ngOnInit() {
    this.createForm();
  }

  createForm(): void {
    this.reportForm = this.fb.group(
      {
        startDate: new UntypedFormControl(
          {
            value: moment().startOf('year').toDate(),
            disabled: true
          },
          Validators.required
        ),
        endDate: new UntypedFormControl(
          {
            value: moment().add(1, 'days').endOf('day').toDate(),
            disabled: true
          },
          Validators.required
        ),
        reportType: this.fb.control(null),
        includeTestData: this.fb.control(false)
      },
      {
        validator: [endDateLessThanStartDate, dateRangeGreaterThanAYear]
      }
    );
  }

  /**
   * Handle report form submission and try to make a request to the server to generate report
   */
  generateReport(form: UntypedFormGroup) {
    const reportSettings = this.extractFormData(form);
    this.dialogRef.close(reportSettings);
  }

  extractFormData(form: UntypedFormGroup): ReportSettings {
    const result: ReportSettings = {
      startDate: moment(form.controls['startDate'].value).toDate(),
      endDate: moment(form.controls['endDate'].value).startOf('day').toDate(),
      context: { includeTestData: form.controls['includeTestData'].value }
    };

    return result;
  }

}
