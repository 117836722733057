<form
  *ngIf="reportForm"
  [formGroup]="reportForm"
  (ngSubmit)="generateReport(reportForm)"
  style="overflow-y: hidden; overflow-x: hidden;"
>
  <h1 mat-dialog-title class="report-form-header">
    {{reportName}}:
  </h1>
  <mat-dialog-content style="overflow-y: hidden; overflow-x: hidden;">
    <mat-form-field>
      <input
        matInput
        formControlName="startDate"
        [matDatepicker]="startDate"
        [max]="maxDate"
        placeholder="Select Start Date"
      />
      <mat-datepicker-toggle
        matSuffix
        [for]="startDate"
      ></mat-datepicker-toggle>
      <mat-datepicker #startDate [disabled]="false"></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        formControlName="endDate"
        [matDatepicker]="endDate"
        [max]="maxDate"
        placeholder="Select End Date"
      />
      <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
      <mat-datepicker #endDate [disabled]="false"></mat-datepicker>
      <small
        class="text-danger"
        *ngIf="reportForm.errors?.endDateLessThanStartDate"
      >
        <ng-container id="endDateInvalid">
          End Date must be greater than or equal to Start Date
        </ng-container>
      </small>
      <small class="text-danger" *ngIf="reportForm.errors?.dateRangeGreaterThanAYear">
        <ng-container id="dateRangeInvalid">
          Date range cannot be greater than one year
        </ng-container>
      </small>
    </mat-form-field>
    <div class="test-options-container" *ngIf="displayTestDataOptions">
      <label>Include Test Users</label> 
      <mat-radio-group class="row options" formControlName="includeTestData">
        <mat-radio-button class="col-4 col-sm-2" [value]=true>Yes</mat-radio-button>
        <mat-radio-button class="col-4 col-sm-2" [value]=false>No</mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="mb-0" align="end">
    <button mat-raised-button type="button" color="primary" mat-dialog-close>
      Cancel
    </button>
    <button
      mat-raised-button
      type="submit"
      color="primary"
      [disabled]="!reportForm.valid"
    >
      <mat-icon>queue</mat-icon>&nbsp;&nbsp;&nbsp;{{ buttonLabel }}
    </button>
  </mat-dialog-actions>
</form>
