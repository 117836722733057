<div [formGroup]="parentForm">
  <div>
    <div fxFlex="25">
      <div class="mb-4">
        Partner Affiliation
      </div>
    </div>
    <div fxFlex="75" fxFlex.xs formArrayName="partnerPermissions">
      <app-single-partner-affiliation 
        *ngFor="let partner of companyPartners"
        [parentForm]="parentForm" 
        [partnerFormGroup]="partner.formGroup"
        [partnerAffiliationData]="partner.data"
        [affiliationTypes$]="availableAffiliations$">
      </app-single-partner-affiliation>
    </div>
  </div>
  <div class="row footer">
    <mat-card-actions align="end" class="col save-container">
      <button mat-flat-button class="mat-text-btn" type="button" (click)="addEmptyAffiliationForm()"
        [disabled]="partnerPermissionsFormArray.controls.length >= affiliationTypes.length"><mat-icon>add</mat-icon> Add Another Affiliation</button>
      <button mat-raised-button color="primary" type="button" [disabled]="parentForm.invalid || !parentForm.dirty"
        (click)="onSubmit()">Save</button>
    </mat-card-actions>
  </div>
</div>