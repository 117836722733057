export enum ModuleName {
  Communication = 'Communication',
  Risk = 'Risk',
  Identity = 'Identity',
  Membership = 'Membership',
  Content = 'Content',
  Payment = 'Payment',
  Shipping = 'Shipping',
  Billing = 'Billing',
  Appraisals = 'Appraisals',
  PointOfSaleIntegration = 'Point Of Sale Integration',
  DiamondMarketplace = 'Diamond Marketplace',
  Memo = 'Memo',
  Studio = 'Studio',
  JewelerPages = 'Jeweler Pages',
  Auction = 'Auction',
  PromoCode = 'Promo Code',
  Security = 'Security',
  Entity = 'Entity',
  Party = 'Party',
  BulkUpload = 'Bulk Upload',
  TermsAndConditions = 'Terms And Conditions',
  RetailLocation = 'Retail Location',
  ProgramEnrollments = 'Program Enrollments',
  IncentivePrograms = 'Incentive Programs'
}
