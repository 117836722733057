import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DeactivationConfirmationData } from '../../../models/deactivation-confirmation-data';

@Component({
  selector: 'app-deactivation-confirmation',
  templateUrl: './deactivation-confirmation.component.html'
})
export class DeactivationConfirmationComponent {
  deactivationConfirmationData: DeactivationConfirmationData;

  constructor(
    public dialogRef: MatDialogRef<DeactivationConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) data: DeactivationConfirmationData
  ) {
    if (data) {
      this.deactivationConfirmationData = data;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
