<div [formGroup]="parentForm">
  <div formArrayName="partnerPermissions">
    <div [formGroup]="partnerFormGroup" fxLayout="row wrap" fxLayout.xs="column">
      <mat-form-field fxFlex="200px">
        <mat-select formControlName="affiliationType" placeholder="Affiliation(s)">
          <mat-option>Affiliation(s)</mat-option>
          <mat-option *ngIf="selectedPartner"
          [value]="selectedPartner.key">
          {{ selectedPartner.value }}
        </mat-option>
          <mat-option
            *ngFor="let type of (affiliationTypes$ | async)"
            [value]="type.key">
            {{ type.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field [formGroup]="partnerAffiliationFormGroup" *ngIf="memberIdCtrl" class="input-form-field" fxFlex.sm="45" fxFlex.md="30" fxFlex.gt-xs="20">
        <input matInput type="text" formControlName="memberId" placeholder="Member ID" required />
        <mat-error *ngIf="memberIdCtrl.hasError('required')">
          This is a required field
        </mat-error>
        <mat-error *ngIf="memberIdCtrl.hasError('incorrect')">
          Member ID is not valid.
        </mat-error>
      </mat-form-field>
      <mat-form-field [formGroup]="partnerAffiliationFormGroup" *ngIf="gnjUUIDCtrl" class="input-form-field" fxFlex.sm="45" fxFlex.md="30" fxFlex.gt-xs="20">
        <input matInput type="text" formControlName="gemAndJewelId" placeholder="Company email or UUID" required />
        <mat-error *ngIf="gnjUUIDCtrl.hasError('required')">
          This is a required field
        </mat-error>
      </mat-form-field>
      <mat-form-field [formGroup]="partnerAffiliationFormGroup" fxFlex.sm="45" fxFlex.md="30" fxFlex.gt-xs="15">
        <input matInput formControlName="startDate" [matDatepicker]="startDate" placeholder="Start Date">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field [formGroup]="partnerAffiliationFormGroup" fxFlex.sm="45" fxFlex.md="30" fxFlex.gt-xs="15">
        <input [errorStateMatcher]="matcher" matInput formControlName="endDate" type="text" [matDatepicker]="endDate" placeholder="End Date">
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
        <mat-error *ngIf="partnerAffiliationFormGroup.errors?.endDateLessThanStartDate">End date cannot be earlier than start date</mat-error>
      </mat-form-field>
      <div [formGroup]="partnerAffiliationFormGroup" *ngIf="statusCtrl" class="mt-2" fxFlex="76px" fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center"
        fxLayoutAlign.xs="start start">
        <mat-checkbox matInput formControlName="status">Is Active</mat-checkbox>
      </div>
    </div>
  </div>
</div>