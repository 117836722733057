<div class="sms-message-details position-relative">
  <div class="d-back d-lg-none">
    <a class="d-lg-none" (click)="goToPreviousPage()" href="javascript:void(0)">
      <img src="../../../../../assets/images/JMicons/JM-icon-16.png" alt="back"> GO BACK</a>
  </div>
  <h2 class="d-lg-none">Health Details</h2>
  <p class="portal-top-text">
    View details of the health check
  </p>
  <div class="health-details example-container clearfix m-3 p-3" *ngIf="apiHealthDetails">
    <div class="details">
      <div class="text-center p-4">
        <img *ngIf="apiHealthDetails.code === 200" src="assets/images/JMicons/JM-icon-17.png" alt="icon">
        <img *ngIf="apiHealthDetails.code !== 200" src="assets/images/JMicons/JM-icon-18.png" alt="icon">
      </div>
      <h2 class="text-center">{{ apiName }}</h2>
      <div>
        <ul class="list-group">
          <li class="list-group-item">Started At UTC:
            <strong>{{ apiHealthDetails.startedAtUtc}}</strong>
          </li>
          <li class="list-group-item">End At UTC:
            <strong>{{ apiHealthDetails.endAtUtc}}</strong>
          </li>
          <li class="list-group-item">Code:
            <strong>{{ apiHealthDetails.code}}</strong>
          </li>
          <li class="list-group-item">Reason:
            <strong>{{ apiHealthDetails.reason}}</strong>
          </li>
        </ul>
      </div>
      <div class="mt-4">
        <table class="table table-bordered ">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Message</th>
              <th scope="col">Elapsed</th>
              <th scope="col">Run</th>
              <th scope="col">Path</th>
              <th scope="col">Healthy</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let service of apiHealthDetails.checks">
              <td>{{ service.name }}</td>
              <td>{{ service.message }}</td>
              <td>{{ service.elapsed }}</td>
              <td>{{ service.run }}</td>
              <td>{{ service.path }}</td>
              <td>{{ service.isHealthy }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="float-right mt-2">
        <button class="footer-button" mat-raised-button color="primary" (click)="goToPreviousPage()">Back</button>
      </div>
    </div>
  </div>
  <app-in-progress [loading]="loadingData"></app-in-progress>
</div>