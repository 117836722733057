<div fxLayout="column">
  <h2> Update Security Questions </h2>
  <form [formGroup]="questionsForm" *ngIf="questionsForm">
    <div fxLayout="row" fxLayout.xs="column">
      <div fxFlex="35">
        <mat-form-field>
          <mat-select placeholder="Security Question 1" formControlName="firstQuestion">
            <mat-option *ngFor="let question of getAvailableQuestions('firstQuestion')" [value]="question.id">
              {{question.questionText}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="20" fxFlexOffset="40px" fxFlexOffset.xs="0">
        <mat-form-field>
          <mat-label>Enter an answer</mat-label>
          <input matInput formControlName="firstQuestionAnswer">
          <mat-error *ngIf="hasErrorOfType('firstQuestionAnswer', 'required')"> Enter an answer for selected security
            question </mat-error>
          <mat-error
            *ngIf="hasErrorOfType('firstQuestionAnswer', 'minlength') || hasErrorOfType('firstQuestionAnswer', 'maxlength')">
            Enter a valid answer that is between 5 and 20 characters in length.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column">
      <div fxFlex="35">
        <mat-form-field>
          <mat-select placeholder="Security Question 2" formControlName="secondQuestion">
            <mat-option *ngFor="let question of getAvailableQuestions('secondQuestion')" [value]="question.id">
              {{question.questionText}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="20" fxFlexOffset="40px" fxFlexOffset.xs="0">
        <mat-form-field>
          <mat-label>Enter an answer</mat-label>
          <input matInput formControlName="secondQuestionAnswer">
          <mat-error *ngIf="hasErrorOfType('secondQuestionAnswer', 'required')"> Enter an answer for selected security
            question </mat-error>
          <mat-error
            *ngIf="hasErrorOfType('secondQuestionAnswer', 'minlength') || hasErrorOfType('secondQuestionAnswer', 'maxlength')">
            Enter a valid answer that is between 5 and 20 characters in length.
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="mt-3" fxLayout="row" fxLayout.xs="column">
    <button [disabled]="isViewOnly" mat-raised-button color="primary" type="button" (click)="onSubmit()">Update Security Questions</button>
  </div>
</div>