import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { EmailMessage, ServiceType } from '../../../../communications/models/email-message';
import { SmsMessage } from '../../../../communications/models/sms-message';

@Component({
  selector: 'app-email-sms-preview-dialog',
  templateUrl: './email-sms-preview-dialog.component.html',
  styleUrls: ['./email-sms-preview-dialog.component.scss']
})
export class EmailSmsPreviewDialogComponent {

  emailMessageTemplate: EmailMessage;
  smsMessageTemplate: SmsMessage;

  constructor(
    public dialogRef: MatDialogRef<EmailSmsPreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.emailMessageTemplate = data.emailMessage;
    this.smsMessageTemplate = data.smsMessage;
  }

  getServiceName(serviceNumber: number) {
    return ServiceType[serviceNumber.toString()];
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
