import { Pipe, PipeTransform } from '@angular/core';
import { ApprovalStatus, noneStatus, pendingInviteStatus } from '../../persons/enums/approval-status';

/**
 * Get text representation from ApprovalStatus enumeration values
 */
@Pipe({ name: 'approvalstatus' })
export class ApprovalStatusPipe implements PipeTransform {

  transform(status: string) {
    if (status.includes(ApprovalStatus[ApprovalStatus.None])) {
      return noneStatus;
    }
    if (status.includes(ApprovalStatus[ApprovalStatus.PendingInvite])) {
      return pendingInviteStatus;
    }
    return status;
  }

}
