<div class="dialog-wrapper">
  <h1 mat-dialog-title class="header">
    {{ title }}
  </h1>
  <mat-dialog-content>
    <div *ngIf="!isPreview" class="input-wrapper">
      <input type="file" (change)="handleDocumentFiles($any($event.target).files)" hidden accept="application/pdf"
        #documentInput>
      <div *ngIf="!isPreview" class="row justify-content-between">
        <span class="filename">{{ filename ? filename : 'No file choosen' }}</span>
        <button class="choose-btn" mat-raised-button type="button" color="primary" (click)="documentInput.click()">
          Choose file
        </button>
      </div>
      <div *ngIf="!isPreview" class="error mt-4">{{ error }}</div>
      <div *ngIf="!isPreview && error2" class="error">{{ error2 }}</div>
    </div>
    <div *ngIf="isPreview" class="pdf-viewer-wrapper">
      <pdf-viewer class="pdf-viewer" [src]="documentUploaded?.base64data" [fit-to-page]="true" [show-all]="true"
        [autoresize]="true" [original-size]="false"></pdf-viewer>
    </div>
    <mat-dialog-actions class="mb-0 mt-2" align="end">
      <button *ngIf="!isPreview" mat-raised-button type="button" color="primary" mat-dialog-close>
        Cancel
      </button>
      <button *ngIf="isPreview" mat-raised-button (click)="onBackClick()" type="button" color="primary">
        Back
      </button>
      <button *ngIf="!isPreview && documentUrl && !error" mat-raised-button (click)="onPreviewClick()" type="button"
        color="primary" [disabled]="error">
        Preview
      </button>
      <button *ngIf="isPreview && documentUrl" (click)="onFileUpload()" mat-raised-button cdkFocusInitial type="button"
        color="primary" [disabled]="!isDocumentLoaded || error">
        <mat-icon>upload</mat-icon>
        Upload
      </button>
    </mat-dialog-actions>
  </mat-dialog-content>
  <app-in-progress [loading]="fileLoading"></app-in-progress>
</div>