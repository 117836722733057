import { Component, Input, Output, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UserRole } from '../../../security/components/user-role/shared/user-role.model';
import { UserRoleCode } from '../../enums/user-role-code';
import { PersonRole } from '../../models/person-role.model';
import { PersonUserRole } from '../../models/person-user-role.model';
import { PersonService } from '../../services/person.service';

@Component({
  selector: 'app-platform-roles',
  templateUrl: './platform-roles.component.html',
  styleUrls: ['./platform-roles.component.scss']
})
export class PlatformRolesComponent {

  @Input() isSuperAdmin: boolean;

  @Input() isPlatformAdmin: boolean;

  /**
   * Roles of the specific person instance.
   */
  @Input() personRoles: Array<PersonRole>;

  /**
   * Platform specific User Roles.
   */
  @Input() roles: Array<UserRole> = new Array<UserRole>();

  @Input() disableOwnRoles: boolean;

  @Output() roleSelected = new EventEmitter<boolean>();

  public guestExperianceEnabled = environment.guestExperienceEnabled;

  constructor(private personService: PersonService) {

  }

  /**
   * Check if user has specific role.
   *
   * @param roleId Role unique identifier.
   */
  public hasRole(roleId: string): boolean {
    const platformSpecificRole = this.personRoles.find(pr => pr.companyId === null && pr.locationId === null);
    return platformSpecificRole
      ? platformSpecificRole.userRoles.filter(role => role.id === roleId).length > 0
      : false;
  }

  /**
   *  Checks whether the role check box should be disabled or not by applying a set of rules.
   * @param role to check
   * @returns true if the control should be disabled.
   */
  public shouldDisableRoleCheckBox(role: UserRole): boolean {
    return this.disableOwnRoles
      || this.isViewOnly(role) && !this.isSuperAdmin
      || this.requiresSuperAdministrator(role) && !this.isSuperAdmin
      || this.isGuestRole(role)
      || !(this.isSuperAdmin || this.isPlatformAdmin);
  }

  /**
   *  Checks if role is typeof GuestRole
   */
  private isGuestRole(role: UserRole): boolean {
    return this.guestExperianceEnabled && role.code === UserRoleCode.JMGuestPerson;
  }

  /**
   * Whether or not the role requires super administrator priviledge in order to be editable.
   * @param role to check
   * @returns true if role requires super administrator priviledge
   */
  private requiresSuperAdministrator(role: UserRole): boolean {
    return role.code === UserRoleCode.SuperAdministrator || role.code === UserRoleCode.PlatformAdministrator;
  }

  /**
   * Whether or not the role is ViewOnly.
   * @param role to check
   * @returns true if role is the ViewOnly role
   */
  private isViewOnly(role: UserRole): boolean {
    return role.code === UserRoleCode.ViewOnly;
  }

  /**
   * On selected event for the Location roles selections.
   *
   * @param checked If checkbox is selected or not.
   * @param role UserRole instance.
   * @param locationID Id of the location.
   */
  public onSelected(checked: boolean, role: UserRole) {
    this.roleSelected.emit(true);
    const personUserRoleInstance: PersonUserRole = {
      id: role.id,
      code: role.code
    } as PersonUserRole;
    let userRoleInstance: PersonRole = null;

    const platformSpecificRole = this.personRoles.find(pr => pr.companyId === null && pr.locationId === null);
    if (platformSpecificRole) {
      /* array.findIndex must be used here since we are working with array of objects. */
      const indexOfSpecificRole = this.personRoles.findIndex(e => e.companyId === null && e.locationId === null);
      /* If checked, add that role to the array, otherwise, remove it. */
      checked
        ? this.personRoles[indexOfSpecificRole].userRoles.push(personUserRoleInstance)
        : this.personRoles[indexOfSpecificRole].userRoles = this.personRoles[indexOfSpecificRole].userRoles.filter(r => r.id !== role.id);

      this.personRoles[indexOfSpecificRole].userRoles = this.personRoles[indexOfSpecificRole]
        .userRoles.filter(r => this.roles.findIndex(pr => pr.code === r.code) !== -1);

    } else {
      userRoleInstance = {
        companyId: null,
        locationId: null,
        roleIds: [],
        userRoles: [personUserRoleInstance]
      };
      this.personRoles.push(userRoleInstance);
      this.personService.AnnounceCompanyRoleUpdate(userRoleInstance);
    }
  }
}
