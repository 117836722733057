
import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-publish-confirmation',
  template: `<div class="confirmation">
    <h2 mat-dialog-title><mat-icon color="info">info</mat-icon> Publish</h2>
    <p>Are you sure you want to publish this item?</p>
    <div>
      <div class="btn-group" role="group">
        <button mat-button mat-dialog-close>No</button>
        <button mat-button [mat-dialog-close]="true">Yes</button>
      </div>
    </div>
  </div>`
})
export class PublishConfirmationComponent {
  constructor(
    public dialogRef: MatDialogRef<PublishConfirmationComponent>
  ) { }

  closeDialog() {
    this.dialogRef.close();
  }
}
