export enum ApprovalStatus {
  None,
  Approved,
  Rejected,
  Invited,
  PendingInvite,
  PendingReview,
  Blocked
}

export const noneStatus = 'Pending Approval';

export const pendingInviteStatus = 'Pending Invite';
