import { GenerateReportDialogComponent } from './generate-report-dialog/generate-report-dialog.component';
import { GenerateReportDropdownDialogComponent } from './generate-report-dropdown-dialog/generate-report-dropdown-dialog.component';
import { ReportListComponent } from './report-list/report-list.component';
import { ReportingOverviewComponent } from './reporting-overview/reporting-overview.component';

export { GenerateReportDialogComponent } from './generate-report-dialog/generate-report-dialog.component';
export { ReportingOverviewComponent } from './reporting-overview/reporting-overview.component';

export const REPORTING_COMPONENTS = [
  GenerateReportDialogComponent,
  GenerateReportDropdownDialogComponent,
  ReportingOverviewComponent,
  ReportListComponent,
];
