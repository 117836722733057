import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class PartnerService {

  public membershipApiUrl: string;

  constructor(private http: HttpClient) {
    this.membershipApiUrl = environment.membershipApiUrl;
  }

  setPartnerPermission(id: string, body: any = {}): Observable<any> {
    const url = `${this.membershipApiUrl}/api/Persons/${id}/partnerpermission`;
    return this.http
      .put(url, body);
  }
}
