import { IsActiveMatchOptions } from '@angular/router';

export const SCHEDULED_FRAGMENT = 'scheduled';
export const ON_DEMAND_FRAGMENT = 'on-demand';

export const ROUTER_LINK_OPTIONS: IsActiveMatchOptions = {
  queryParams: 'ignored',
  matrixParams: 'ignored',
  paths: 'exact',
  fragment: 'exact'
};
